import { Typography } from "components/mui-core";
import React from "react";
import {
  dangerColor,
  successColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

interface ITestErrorProps {
  message?: React.ReactNode;
  style?;
  className?: string;
}

export function TextError({ message, style, className }: ITestErrorProps) {
  return message ? (
    <Typography
      variant="body1"
      style={{
        color: dangerColor[0],
        ...style,
      }}
      className={className}>
      {message}
    </Typography>
  ) : null;
}

export function TextNotification({ message }) {
  return (
    <Typography
      variant="body1"
      style={{
        color: successColor[0],
      }}>
      {message}
    </Typography>
  );
}
