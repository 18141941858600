import { getEndpoint } from "./environments";

declare global {
  interface Window {
    discardNodeProxy?: boolean;
  }
}

const getHosts = (key: string) => {
  return getEndpoint(key);
};

export default getHosts;
