import {
  combineReducers,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import { ICampaignServer } from "model/campaigns";
import { applyActiveCampaignExtraReducers } from "./activeCampaignExtraReducers";
import { applyCampaignsExtraReducers } from "./campaignsExtraReducers";
import { IArchivedStatus } from "./types";

type ICampaignsState = {
  loadingState: LoadingState;
  data: { [id: string]: ICampaignServer };
  error?: null | SerializedError;
};

//Slice
const getCampaignsSlice = (archivedStatus: IArchivedStatus) =>
  createSlice({
    name: archivedStatus,
    initialState: {
      loadingState: LoadingState.initial,
      data: {},
      error: null,
    } as ICampaignsState,
    reducers: {
      resetLoadingState(state) {
        state.loadingState = LoadingState.initial;
      },
    },
    extraReducers: (builder) => {
      applyCampaignsExtraReducers(builder, archivedStatus);

      if (archivedStatus === "active") {
        applyActiveCampaignExtraReducers(builder);
      }
    },
  });

export const activeSlice = getCampaignsSlice("active");
export const archivedSlice = getCampaignsSlice("archived");

export default combineReducers({
  active: activeSlice.reducer,
  archived: archivedSlice.reducer,
});
