import { Metrics } from "@dataseat-dsp-client/lib-reporter";
import {
  ANONYMITY_SERVICE_DIMENSION,
  DEAL_ID_DIMENSION,
  NETWORK_ANONYMITY_DIMENSION,
} from "./dimensions";

export const DIMENSIONS_FOR_ADMIN_ONLY: Array<string | RegExp> = [
  DEAL_ID_DIMENSION.id,
  /AVA_AUDIENCE/,
  NETWORK_ANONYMITY_DIMENSION.id,
  ANONYMITY_SERVICE_DIMENSION.id,
];
export const MEASURES_FOR_ADMIN_ONLY: Set<string> = new Set([
  Metrics.delta,
  Metrics.commission,
]);
