import { dbTableCampaign } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { Cohorts } from "@dataseat-dsp-client/lib-reporter";

export const toCohortedEventName = dbTableCampaign.toCohortedEventName;
export const fromCohortedEventName = dbTableCampaign.fromCohortedEventName;

export type CohortedEventName = dbTableCampaign.CohortedEventName;

export const isCohortableCustomEventName =
  dbTableCampaign.isCohortableCustomEventName;
export const makeCohortableCustomEventName =
  dbTableCampaign.makeCohortableCustomEventName;
