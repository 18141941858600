"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCohortedMeasureExpr = exports.getCohortCondition = void 0;
const queryMap_1 = require("../query/queryMap");
const templates_1 = require("../query/templates");
const cohorts_1 = require("./cohorts");
const queryMap_2 = require("./queryMap");
const cohortWithPrevious = (cohort) => {
    const lastCohort = "90+";
    const cohortValues = Object.values(cohorts_1.Cohorts);
    if (cohort === lastCohort)
        return cohortValues;
    const cohortNumber = parseInt(cohort);
    return cohortValues.reduce((acc, c) => {
        if (c === lastCohort || parseInt(c) > cohortNumber)
            return acc;
        return [...acc, c];
    }, []);
};
const getCohortCondition = (cohort) => ({
    $in: ["EVENT_COHORT", cohortWithPrevious(cohort)],
});
exports.getCohortCondition = getCohortCondition;
//in case of regular event or metric, which is not depending on other events, will need cohorted value
function getCohortedMeasureExpr({ columnType, eventName, cohort, onException, }) {
    if (columnType === "Cohorted") {
        const cohortCondition = (0, exports.getCohortCondition)(cohort);
        const selectExpr = queryMap_2.CAMPAIGN_SELECT_QUERY_MAP[eventName];
        let innerExprPath;
        if (!selectExpr) {
            onException === null || onException === void 0 ? void 0 : onException(`CAMPAIGN_QUERY_MAP.SELECT[eventName] is ${selectExpr} for event ${eventName}, columnType ${columnType}, cohort ${cohort}`);
            return queryMap_1.DUMMY_EXPRESSION;
        }
        return (0, templates_1.withCondition)(selectExpr, cohortCondition, innerExprPath);
    }
    onException === null || onException === void 0 ? void 0 : onException(`No query expression for cohorted, eventName:${eventName}, columnType:${columnType}, cohort:${cohort}`);
    return queryMap_1.DUMMY_EXPRESSION;
}
exports.getCohortedMeasureExpr = getCohortedMeasureExpr;
