import { LoadingState } from "model/Types/loading";
import { arrayToPlayablesObject } from "./helpers";
import {
  changePlayableArchiveStatus,
  createUpdatePlayable,
  fetchPlayables,
} from "./playablesAsyncActions";
import { IPlayablesState } from "./playablesSlice";

// GET ACTIVE / ARCHIVED
export function getPlayablesReducer(
  builder,
  archivedStatus: "active" | "archived"
) {
  builder.addCase(
    fetchPlayables(archivedStatus).pending,
    (state: IPlayablesState) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
      state.error = null;
    }
  );
  builder.addCase(
    fetchPlayables(archivedStatus).fulfilled,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.loaded;

      state[archivedStatus] = arrayToPlayablesObject(action.payload);
      if (archivedStatus === "active") {
        state.lastActivePlayablesFetch = +new Date();
      }
      state.error = null;
    }
  );
  builder.addCase(
    fetchPlayables(archivedStatus).rejected,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

// CREATE / UPDATE
export function createUpdatePlayableReducer(
  builder,
  action: "create" | "update"
) {
  builder.addCase(
    createUpdatePlayable(action).pending,
    (state: IPlayablesState) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    }
  );
  builder.addCase(
    createUpdatePlayable(action).fulfilled,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.loaded;
      state.active[action.payload.id] = action.payload;
    }
  );
  builder.addCase(
    createUpdatePlayable(action).rejected,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.failed;
      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

// ARCHIVE / UNARCHIVE
export function changePlayableArchiveStatusReducer(
  builder,
  archiveStatus: "archive" | "unarchive"
) {
  builder.addCase(
    changePlayableArchiveStatus(archiveStatus).pending,
    (state: IPlayablesState) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    }
  );
  builder.addCase(
    changePlayableArchiveStatus(archiveStatus).fulfilled,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.loaded;

      const currentStatus = archiveStatus === "archive" ? "active" : "archived";
      const newStatus = archiveStatus === "archive" ? "archived" : "active";

      const playableId = action.payload;
      const playable = state[currentStatus][playableId];
      delete state[currentStatus][playableId];
      state[newStatus][playableId] = playable;
    }
  );
  builder.addCase(
    changePlayableArchiveStatus(archiveStatus).rejected,
    (state: IPlayablesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}
