import {
  addDays,
  differenceInDays,
  endOfISOWeek,
  endOfMonth,
  getDay,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { ISOstring } from "model/modelTypes";

export const getIsoDateNow = () => new Date().toISOString() as ISOstring;

export const startOfIsoDay = (isoDay: ISOstring) => {
  const [day] = isoDay.split("T");
  return day + "T" + "00:00:00.000Z";
};
export const endOfIsoDay = (isoDay: ISOstring) => {
  const [day] = isoDay.split("T");
  return day + "T" + "23:59:59.999Z";
};

const dateFnsWrapper =
  (dateFnsFn) =>
  (date: ISOstring, ...args) =>
    dateFnsFn(new Date(date), ...args).toISOString();
export const addDayIso = dateFnsWrapper(addDays);
export const subDaysIso = dateFnsWrapper(subDays);
export const subMonthsIso = dateFnsWrapper(subMonths);
export const subWeeksIso = dateFnsWrapper(subWeeks);

const dateFnsWrapperForStartEnd =
  (dateFnsFn) =>
  (date: ISOstring, ...args) =>
    convertDateTimeToSameISO(
      dateFnsFn(convertISOtoSameDateTime(date), ...args)
    );
export const startOfISOWeekIso = dateFnsWrapperForStartEnd(startOfISOWeek);
export const endOfISOWeekIso = dateFnsWrapperForStartEnd(endOfISOWeek);
export const startOfMonthIso = dateFnsWrapperForStartEnd(startOfMonth);
export const endOfMonthIso = dateFnsWrapperForStartEnd(endOfMonth);

export const isoDay = (date) => getDay(convertISOtoSameDateTime(date)); //0 is Sunday, 1 is Monday, etc.
export const isoDayStartMondayAs1 = (isoDate: ISOstring) =>
  ((isoDay(isoDate) + 6) % 7) + 1;

export const differenceInDaysIso = (date1: ISOstring, date2: ISOstring) =>
  differenceInDays(new Date(date1), new Date(date2));

//Use this to make Date with same year, month and day as ISO date, to fake showing utc dates in pickers.
//Example why just new Date(isoDate) not working:
//if we are in Pacific TZ, isoDate = 2am January 18 UTC,
//then new Date(isoDate) will be 6pm January 17 in Pacific,
//so it's different day number.
export function convertISOtoSameDate(isoDate: ISOstring): Date {
  let localDate = new Date(isoDate);
  localDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate()
  );
  return localDate;
}
//Use this to make ISO string with same year, month and day as local date, to fake showing utc dates in pickers.
export function convertDateToSameISO(date): ISOstring {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date.toISOString();
}

export function convertISOtoSameDateTime(isoDate: ISOstring): Date {
  let localDate = new Date(isoDate);
  localDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes()
  );
  return localDate;
}
export function convertDateTimeToSameISO(date): ISOstring {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    )
  );
  return date.toISOString();
}

export const has12AMTime = (isoDate?: ISOstring) => {
  if (!isoDate) return false;
  const [, time] = isoDate.split("T");
  return time === "00:00:00.000Z";
};

export const has235959Time = (isoDate?: ISOstring) => {
  if (!isoDate) return false;
  const [, time] = isoDate.split("T");
  return time === "23:59:59.999Z";
};
