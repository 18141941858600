"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOR_MEASURES = exports.Metrics = exports.NumericDimensions = exports.Events = void 0;
//Events: numeric values in EVENT_NAME column in CAMPAIGN table
var Events;
(function (Events) {
    //isolated events, not based on other events
    Events["bid"] = "bid";
    Events["impression"] = "impression";
    Events["click"] = "click";
    Events["installDeprecated"] = "install";
    Events["pvInstall"] = "pvInstall";
    Events["pcInstall"] = "pcInstall";
    Events["pvaInstall"] = "pvaInstall";
    Events["pcaInstall"] = "pcaInstall";
    Events["pvnInstall"] = "pvnInstall";
    Events["pcnInstall"] = "pcnInstall";
    Events["mmpInstall"] = "mmpInstall";
    Events["skadInstall"] = "skadInstall";
    Events["skanAssist"] = "skanAssist";
    Events["skanRedownload"] = "skadRedownload";
    Events["skadSecondPostback"] = "skadSecondPostback";
    Events["skadThirdPostback"] = "skadThirdPostback";
    Events["reattribution"] = "reattribution";
    Events["spend"] = "spend";
    Events["rejectedInstall"] = "rejectedInstall";
    //borEvents: based on roasEvents from campaign, "bor" means "based on roas"
    Events["sales"] = "sales";
    Events["revenue"] = "revenue";
})(Events = exports.Events || (exports.Events = {}));
//Numeric Dimensions: columns with numeric values in CAMPAIGN table
var NumericDimensions;
(function (NumericDimensions) {
    NumericDimensions["priceOriginal"] = "priceOriginal";
    NumericDimensions["SKAN_CONVERSION_VALUE"] = "CONVERSION_VALUE";
    NumericDimensions["SKAN_LOWER_DIGITS"] = "SKAD_CAMPAIGN_ID";
})(NumericDimensions = exports.NumericDimensions || (exports.NumericDimensions = {}));
//Metrics: calculated based on Events
var Metrics;
(function (Metrics) {
    //calculated based on fixed events
    Metrics["winrate"] = "winrate";
    Metrics["ecpm"] = "ecpm";
    Metrics["ctr"] = "ctr";
    Metrics["ecpc"] = "ecpc";
    Metrics["ir"] = "ir";
    Metrics["ecpi"] = "ecpi";
    Metrics["cpr"] = "cpr";
    Metrics["cti"] = "cti";
    Metrics["skanCTI"] = "skanCTI";
    Metrics["cvr"] = "cvr";
    Metrics["skanCPI"] = "skanCPI";
    Metrics["skanIR"] = "skanIR";
    Metrics["ipm"] = "ipm";
    Metrics["skanIPM"] = "skanIPM";
    Metrics["bidfloorSumDividend"] = "bidfloorSumDividend";
    Metrics["bidFloor"] = "bidFloor";
    Metrics["priceOriginalSum"] = "priceOriginalSum";
    Metrics["commission"] = "commission";
    Metrics["delta"] = "delta";
    //based on optimisationEvent from campaign.
    Metrics["cpa"] = "cpa";
    Metrics["skanCpa"] = "skanCpa";
    Metrics["skanPcpa"] = "skanPcpa";
    //borMetrics: based on roasEvents from campaign. "bor" means "based on roas"
    Metrics["roas"] = "roas";
    //skan ratio ones
    Metrics["skanFineRatio"] = "skanFineRatio";
    Metrics["skanCoarseRatio"] = "skanCoarseRatio";
    Metrics["skanDigit1Ratio"] = "skanDigit1Ratio";
    Metrics["skanDigit2Ratio"] = "skanDigit2Ratio";
    Metrics["skanSourceAppIdRatio"] = "skanSourceAppIdRatio";
    Metrics["skanTier0Ratio"] = "skanTier0Ratio";
    //skan ratio utility ones: needed only for calculating total in reports
    Metrics["skanFineRatioDividend"] = "skanFineRatioDividend";
    Metrics["skanCoarseRatioDividend"] = "skanCoarseRatioDividend";
    Metrics["skanDigit1RatioDividend"] = "skanDigit1RatioDividend";
    Metrics["skanDigit2RatioDividend"] = "skanDigit2RatioDividend";
    Metrics["skanSourceAppIdRatioDividend"] = "skanSourceAppIdRatioDividend";
    Metrics["skanFineRatioDivisor"] = "skanFineRatioDivisor";
    Metrics["skanCoarseRatioDivisor"] = "skanCoarseRatioDivisor";
    Metrics["skanDigit1RatioDivisor"] = "skanDigit1RatioDivisor";
    Metrics["skanDigit2RatioDivisor"] = "skanDigit2RatioDivisor";
    Metrics["skanSourceAppIdRatioDivisor"] = "skanSourceAppIdRatioDivisor";
})(Metrics = exports.Metrics || (exports.Metrics = {}));
//borMetrics + borEvents: based on roasEvents from campaign. "bor" means "based on roas"
exports.BOR_MEASURES = {
    [Events.sales]: true,
    [Events.revenue]: true,
    [Metrics.roas]: true,
    [Metrics.cpa]: true,
    [Metrics.skanCpa]: true,
    [Metrics.skanPcpa]: true,
};
