import { ILineitemStatusServer } from "model/lineitems";
import { Integer, Microdollars } from "model/modelTypes";

export enum CampaignRunStateServer {
  Stopped = "STOPPED",
  Running = "RUNNING",
}

export type ICampaignStatusServer = {
  bid: Integer;
  budget: Integer;
  clicks: Integer;
  commission: number;
  id: string;
  impression: Integer;
  lineitems: Array<ILineitemStatusServer>;
  runState: CampaignRunStateServer;
  spent: Microdollars;
};

export enum CampaignRunState {
  Running = "running",
  Draft = "draft",
  RunningButFinished = "runningButFinished",
  Finished = "finished",
  Paused = "paused",
  RunningButPaused = "runningButPaused",
  Unknown = "unknown",
}
