import CircularProgress from "@mui/material/CircularProgress";
import cx from "classnames";
import { makeStyles } from "components/mui-core";
import { Integer } from "model/modelTypes";
import { primaryColor } from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

type StyleProps = { color: string };
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: ({ color }: Partial<StyleProps>) => color,
    "& svg": {
      width: "auto !important",
      height: "auto !important",
      margin: "0 !important",
    },
  },
});

type Props = {
  size: Integer;
  className?: string;
  color?: string;
};
function CircularCenteredLoader({
  size,
  className,
  color = primaryColor[2],
}: Props) {
  const classes = useStyles({ color });

  return (
    <div className={cx(classes.root, className)}>
      <CircularProgress
        color="inherit"
        size={size}
      />
    </div>
  );
}

CircularCenteredLoader.defaultProps = {
  size: 100,
};

export default CircularCenteredLoader;
