import { createSlice, SerializedError } from "@reduxjs/toolkit";
import {
  BudgetRangeServer,
  CampaignBudgetListResponse,
} from "model/campaigns/budgets";
import { Microdollars } from "model/modelTypes";
import { LoadingState } from "model/Types/loading";
import {
  getAllCampaignsBudgets,
  getAllCurrentBudgetsSpend,
} from "./budgetsAsyncActions";

export type ICampaignsBudgetsMap = {
  [campaignId: string]: BudgetRangeServer[];
};
export interface ICampaignsBudgetsState {
  loadingState: LoadingState;
  error: null | SerializedError;
  loadedTimestamp: number;
  budgetsMap: ICampaignsBudgetsMap;
  currentBudgetsSpends: { [campaignId: string]: Microdollars };
}

const INITIAL_STATE: ICampaignsBudgetsState = {
  loadingState: LoadingState.initial,
  error: null,
  budgetsMap: {},
  loadedTimestamp: 0,
  currentBudgetsSpends: {},
};

const appsSlice = createSlice({
  name: "campaignsBudgets",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    // getAllCampaignsBudgets
    builder.addCase(
      getAllCampaignsBudgets.pending,
      (state: ICampaignsBudgetsState) => {
        state.loadingState = LoadingState.loading;
        state.loadedTimestamp = Date.now();
      }
    );
    builder.addCase(
      getAllCampaignsBudgets.fulfilled,
      (state: ICampaignsBudgetsState, action) => {
        const allBudgets: CampaignBudgetListResponse[] = action.payload;
        allBudgets?.forEach((budget) => {
          const campaignId = budget.budgetRange?.campaignId;
          if (!campaignId) return;
          if (!state.budgetsMap[campaignId]) {
            state.budgetsMap[campaignId] = [];
          }
          state.budgetsMap[campaignId].push({
            ...budget.budgetRange,
            rawBudget: budget,
          });
        });
        state.loadingState = LoadingState.loaded;
      }
    );
    builder.addCase(
      getAllCampaignsBudgets.rejected,
      (state: ICampaignsBudgetsState, action) => {
        state.loadingState = LoadingState.failed;
        state.error = action.error;
      }
    );
    //getAllCurrentBudgetsSpend
    builder.addCase(
      getAllCurrentBudgetsSpend.fulfilled,
      (state: ICampaignsBudgetsState, action) => {
        const currentBudgetsSpends = action.payload;
        currentBudgetsSpends?.forEach((response) => {
          if (response.status === "fulfilled") {
            const campaignId = response?.value?.[0]?.campaignId;
            if (campaignId) {
              state.currentBudgetsSpends[campaignId] =
                response?.value?.[0]?.spend;
            }
          }
        });
      }
    );
  },
});

export default appsSlice.reducer;
