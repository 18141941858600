import { endpointMaker } from "configuration";
import { downloadFileByUrl } from "helpers";
import { ICompletedMetadata } from "redux/csvDownloads/types";

const INITIAL_DELAY = 1000;
const POLL_FREQUENCY = 10000;

type Args = {
  userFriendlyFilename: string;
  reportUrl?: string;
  errorLogUrl?: string;
  reportFilename: string;
  errorLogFilename?: string;
  signal?: AbortSignal;
};
async function loadCsvReportWhenReady({
  userFriendlyFilename,
  reportUrl,
  errorLogUrl,
  reportFilename,
  errorLogFilename,
  signal,
}: Args): Promise<ICompletedMetadata> {
  return new Promise((res, rej) => {
    if (!reportUrl) {
      rej("Generating report failed. Report url is missing");
      return;
    }

    try {
      let timeoutId;
      const loadFileIfReady = async () => {
        // Stop if was canceled
        if (signal?.aborted) {
          rej("Generating report failed. Request was canceled");
          clearTimeout(timeoutId);
          return;
        }

        //First, check for errors
        if (errorLogFilename && errorLogUrl) {
          const errorResp = await fetch(
            endpointMaker.nodeServices.checkS3ObjectExists(errorLogFilename)
          );
          if (errorResp.ok && errorResp.status === 200) {
            clearTimeout(timeoutId);
            downloadFileByUrl(errorLogUrl);
            rej("Generating report failed. Log file is being downloaded");
            return;
          }
        }

        //If no errors, check that report file already exists. If so, download
        const reportResp = await fetch(
          endpointMaker.nodeServices.checkS3ObjectExists(reportFilename)
        );
        if (reportResp.ok && reportResp.status === 200) {
          //report was loaded
          clearTimeout(timeoutId);
          downloadFileByUrl(reportUrl, userFriendlyFilename);
          const metadata: ICompletedMetadata = await reportResp.json();
          res(metadata);
        } else {
          //report not ready yet, renew timeout
          timeoutId = setTimeout(loadFileIfReady, POLL_FREQUENCY);
        }
      };

      timeoutId = setTimeout(() => loadFileIfReady(), INITIAL_DELAY);
    } catch (err) {
      rej(err);
    }
  });
}

export default loadCsvReportWhenReady;
