import cx from "classnames";
import { makeStyles } from "components/mui-core";
import React from "react";
import { Button as VendorButton } from "vendor/components";
import type { MuiDashboardColor } from "vendor/types";

const useButtonStyles = makeStyles({
  roundIcon: {
    padding: 12,
    borderRadius: "50%",

    "& svg": {
      marginRight: 0,
    },
  },
  roundIconSm: {
    padding: 6,
  },
  squareWithIcon: {
    padding: 5,
    "& svg": {
      marginRight: 0,
    },
  },
});

export type ButtonProps = {
  color?: MuiDashboardColor;
  children?: React.ReactNode;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  size?: "sm" | "lg";
  style?: Partial<CSSStyleDeclaration>;
  simple?: boolean;
  roundWithIcon?: boolean;
  squareWithIcon?: boolean;
  block?: boolean;
  link?: boolean;
  autoFocus?: boolean;
};

function Button({
  className,
  roundWithIcon,
  squareWithIcon,
  ...rest
}: ButtonProps) {
  const cls = useButtonStyles();
  const finalClassName = cx(className, {
    [cls.roundIcon]: !!roundWithIcon,
    [cls.roundIconSm]: !!roundWithIcon && rest.size === "sm",
    [cls.squareWithIcon]: !!squareWithIcon,
    [cls.squareWithIcon]: !!squareWithIcon && rest.size === "sm",
  });
  const sentryClass = `SentryClass_${rest["data-qa"] || "Button"}`;
  return (
    <VendorButton
      {...rest}
      muiClasses={{ label: sentryClass }}
      className={finalClassName}
    />
  );
}

export default Button;
