import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type CohortedMeasuresFlatListItem =
  analyticsReport.CohortedMeasuresFlatListItem;
export type CohortedMeasuresFlatListType =
  analyticsReport.CohortedMeasuresFlatListType;

export type MeasuresCompoundObject = analyticsReport.MeasuresCompoundObject;

export const cohortedColumnsToCohortedMeasureItem =
  analyticsReport.cohortedColumnsToCohortedMeasureItem;
