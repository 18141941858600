import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { IRootState } from "redux/reducer";
import { getAppFromLocalStorage } from "./appsUtils";

export const getAppData = createAsyncThunk(
  "apps/getAppData",
  async (bundleId: string) => {
    const cachedAppData = getAppFromLocalStorage(bundleId);
    if (cachedAppData) return { id: bundleId, app: cachedAppData };

    return request.getJson(endpointMaker.apps.appById(bundleId));
  }
);

export const getAppListData = createAsyncThunk(
  "apps/getAppListData",
  async (bundleIds: string[], thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const missingBundleIds = bundleIds.filter((id) => !state.apps[id]);

    if (missingBundleIds.length === 0) return null;

    const allGenresResponse = await request.postJson(
      endpointMaker.apps.appsByBundles(),
      missingBundleIds
    );

    const allGenres = {};
    const allSubgenres = {};

    for (let i = 0; i < allGenresResponse.length; i++) {
      const appBundle = allGenresResponse[i];

      const genres = [
        ...(appBundle.dataAiInformation?.appClassification?.categories
          ?.map((c: any) => (c.categoryClass === "Genre" ? c.category : null))
          ?.filter((d: any) => d !== null) || []),
        ...(appBundle.dataAiInformation?.appClassification?.secondaryCategories
          ?.map((c: any) => (c.categoryClass === "Genre" ? c.category : null))
          ?.filter((d: any) => d !== null) || []),
      ];

      const subGenres = [
        ...(appBundle.dataAiInformation?.appClassification?.categories
          ?.map((c: any) =>
            c.categoryClass === "Subgenre" ? c.category : null
          )
          ?.filter((d: any) => d !== null) || []),
        ...(appBundle.dataAiInformation?.appClassification?.secondaryCategories
          ?.map((c: any) =>
            c.categoryClass === "Subgenre" ? c.category : null
          )
          ?.filter((d: any) => d !== null) || []),
      ];

      allGenres[appBundle.id] = genres.join(", ");
      allSubgenres[appBundle.id] = subGenres.join(", ");
    }

    // TODO: read from localStorage
    const missingBundleIdsResponse = await request.postJson(
      endpointMaker.apps.apps(),
      missingBundleIds
    );

    const withGenre: any = [];

    for (let i = 0; i < missingBundleIdsResponse.length; i++) {
      const data = missingBundleIdsResponse[i];

      withGenre.push({
        ...data,
        genres: allGenres[data.id],
        subgenres: allSubgenres[data.id],
      });
    }

    return withGenre;
  }
);
