import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { WeekDay } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { ScheduleFrequency } from "@dataseat-dsp-client/lib-reporter";

export type ISchedule = analyticsReport.ISchedule;
export type IDailySchedule = analyticsReport.IDailySchedule;
export type IWeeklySchedule = analyticsReport.IWeeklySchedule;
export type IMonthlySchedule = analyticsReport.IMonthlySchedule;
export type IMonthDay = analyticsReport.IMonthDay;

export const SCHEDULE_EMAIL_AS_OPTIONS =
  analyticsReport.SCHEDULE_EMAIL_AS_OPTIONS;
export const SCHEDULE_FREQUENCY_OPTIONS =
  analyticsReport.SCHEDULE_FREQUENCY_OPTIONS;
export const SCHEDULE_FREQUENCY_DICT = analyticsReport.SCHEDULE_FREQUENCY_DICT;
export const WEEKDAY_OPTIONS = analyticsReport.WEEKDAY_OPTIONS;
export const WEEKDAY_DICT = analyticsReport.WEEKDAY_DICT;
export const DAY_OF_MONTH_OPTIONS = analyticsReport.DAY_OF_MONTH_OPTIONS;
export const SUPPORTED_DATE_RANGES = analyticsReport.SUPPORTED_DATE_RANGES;
export const getDefaultDayOfPeriod = analyticsReport.getDefaultDayOfPeriod;
export const getDefaultPeriodByDateRange =
  analyticsReport.getDefaultPeriodByDateRange;
