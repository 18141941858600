"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.percentageToDecimalPlace = exports.dollarsToMicro = exports.microToDollars = exports.microToDollarsStringShort = exports.microToDollarsString = exports.stringDollarsToMicro = exports.stringToDollars = void 0;
const string_1 = require("./string");
function stringToDollars(value = "0") {
    return parseFloat(value);
}
exports.stringToDollars = stringToDollars;
function stringDollarsToMicro(value = "0") {
    return dollarsToMicro(stringToDollars(value));
}
exports.stringDollarsToMicro = stringDollarsToMicro;
const microToDollarsString = (micro = 0) => {
    const dollars = microToDollars(micro);
    const result = Number.isFinite(dollars) ? dollars : 0;
    return (0, string_1.numberToCurrency)(result);
};
exports.microToDollarsString = microToDollarsString;
const microToDollarsStringShort = (micro = 0) => {
    const dollars = microToDollars(micro);
    const result = Number.isFinite(dollars) ? dollars : 0;
    return (0, string_1.numberToCurrency)(result);
};
exports.microToDollarsStringShort = microToDollarsStringShort;
function microToDollars(value = 0) {
    return (Math.round(Math.round(value) / 10000) / 100);
}
exports.microToDollars = microToDollars;
function dollarsToMicro(value = 0) {
    return Math.round(value * 1000000);
}
exports.dollarsToMicro = dollarsToMicro;
const percentageToDecimalPlace = (a, b) => b > 0 ? Math.round((1000 * a) / b) / 10 : 0;
exports.percentageToDecimalPlace = percentageToDecimalPlace;
