import endpointMaker from "configuration/endpoints";
import { differenceInMilliseconds } from "date-fns";
import { FIVE_MINUTES_MS } from "helpers";
import request from "http/request";
import store from "redux/store";

const DICTIONARY_LOAD_THROTTLE_MS = FIVE_MINUTES_MS;

const REQUEST_DICTIONARY = "REQUEST_DICTIONARY";
const requesDictionary = (key) => ({
  type: REQUEST_DICTIONARY,
  key,
});

const OK_DICTIONARY = "OK_DICTIONARY";
const okDictionary = (key, values) => ({
  type: OK_DICTIONARY,
  key,
  values,
});

const FAIL_DICTIONARY = "FAIL_DICTIONARY";
const failDictionary = (key, error) => ({
  type: FAIL_DICTIONARY,
  key,
  error,
});

function loadDictionary(key) {
  return (dispatch) => {
    const state = store.getState();

    if (!key) {
      return;
    }
    const lastLoadedTimestamp = state.dictionaries.loadedTimestamp[key];

    if (
      lastLoadedTimestamp &&
      differenceInMilliseconds(Date.now(), lastLoadedTimestamp) <
        DICTIONARY_LOAD_THROTTLE_MS
    ) {
      return;
    }

    dispatch(requesDictionary(key));

    request
      .getJson(endpointMaker.dictionary.byKey(key))
      .then((data) => dispatch(okDictionary(key, data)))
      .catch((err) => dispatch(failDictionary(key, err)));
  };
}

const NAMES = { REQUEST_DICTIONARY, OK_DICTIONARY, FAIL_DICTIONARY };
const actions = { loadDictionary, failDictionary };
export { actions, NAMES };
