"use strict";
//Bidfloor types:
//normalized bidfloor ( NORMALIZED_BID_FLOOR on backend )
//bucket bidfloor = normalized bidfloor / 1000000 (BUCKET_BID_FLOOR on backend)
//bidfloor that we show on UI = bucket bidfloor / requests
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBucketBidFloor = exports.convertNormalizedToBucketBidFloor = void 0;
const convertNormalizedToBucketBidFloor = (normalizedBidFloor) => normalizedBidFloor / 1000000;
exports.convertNormalizedToBucketBidFloor = convertNormalizedToBucketBidFloor;
const convertBucketBidFloor = (bucketBidFloor, { requests }) => (bucketBidFloor / (parseInt(requests) || 1)).toFixed(2);
exports.convertBucketBidFloor = convertBucketBidFloor;
