import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import TypeToConfirm from "components/FormControls/TypeToConfirm";
import React from "react";

const useStyles = makeStyles({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "900px",
    backgroundColor: "white",
    padding: "32px",
    borderRadius: "8px",
  },
  bottomToolbar: {
    display: "flex",
    gap: "15px",
    justifyContent: "end",
  },
});

const OrganizationForm = ({ onSubmit, onCancel, errors }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState({
    organizationName: "",
    billingCommission: "",
    adServingCommission: "",
    commissionIncluded: true,
    excludeConfirmed: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    organizationName: false,
    billingCommission: false,
    adServingCommission: false,
  });
  const [formValid, setFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmit(formValues);
  };

  React.useEffect(() => {
    const isBillingCommissionValid =
      Number(formValues.billingCommission) >= 0 &&
      Number(formValues.billingCommission) <= 100;
    const isAdServingCommissionValid =
      Number(formValues.adServingCommission) >= 0 &&
      Number(formValues.adServingCommission) <= 100;

    setFormErrors({
      organizationName: formValues.organizationName.trim() === "",
      billingCommission: !isBillingCommissionValid,
      adServingCommission: !isAdServingCommissionValid,
    });

    setFormValid(
      formValues.organizationName.trim() !== "" &&
        isBillingCommissionValid &&
        isAdServingCommissionValid
    );
  }, [formValues]);

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.modal}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <Typography
            variant="h5"
            component="h2">
            Add organization
          </Typography>
        </Grid>

        {!!errors?.length && (
          <Grid
            item
            xs={12}>
            <Alert severity="error">{errors?.join(", ")}</Alert>
          </Grid>
        )}

        <Grid
          item
          xs={12}>
          <TextField
            fullWidth
            label="Organization name"
            value={formValues.organizationName}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                organizationName: e.target.value,
              }))
            }
            required
            disabled={loading}
            error={formErrors.organizationName}
            helperText={
              formErrors.organizationName &&
              "Organization name must not be empty"
            }
          />
        </Grid>
        <Grid
          item
          xs={6}>
          <TextField
            fullWidth
            label="Billing commission %"
            type="number"
            value={formValues.billingCommission}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                billingCommission: e.target.value,
              }))
            }
            required
            disabled={loading}
            inputProps={{ min: 0, max: 100 }}
            error={formErrors.billingCommission}
            helperText={
              formErrors.billingCommission &&
              "Billing commission values must be between 0 and 100"
            }
          />
        </Grid>
        <Grid
          item
          xs={6}>
          <TextField
            fullWidth
            label="Total commission %"
            type="number"
            value={formValues.adServingCommission}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                adServingCommission: e.target.value,
              }))
            }
            required
            disabled={loading}
            inputProps={{ min: 0, max: 100 }}
            error={formErrors.adServingCommission}
            helperText={
              formErrors.adServingCommission &&
              "Ad serving commission values must be between 0 and 100"
            }
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <FormControl>
            <FormLabel>Commission in Reporting*:</FormLabel>
            <RadioGroup
              defaultValue="included"
              onChange={(_, v) => {
                setFormValues((prev) => ({
                  ...prev,
                  commissionIncluded: v === "included",
                }));
              }}
              name="radio-buttons-group">
              <FormControlLabel
                value="included"
                control={<Radio />}
                label="Included"
              />
              <FormControlLabel
                value="excluded"
                control={<Radio />}
                label="Excluded"
              />
            </RadioGroup>

            {!formValues.commissionIncluded && (
              <TypeToConfirm
                value={formValues.excludeConfirmed}
                text={
                  'Type in "Exclude" to confirm commission should not be in reporting'
                }
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    excludeConfirmed: e.target.value,
                  }))
                }
                disabled={loading}
                error={
                  formValid &&
                  formValues.excludeConfirmed.toLowerCase() !== "exclude"
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}>
          <Box className={classes.bottomToolbar}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !formValid ||
                loading ||
                (!formValues.commissionIncluded &&
                  formValues.excludeConfirmed.toLowerCase() !== "exclude")
              }>
              Create
            </Button>

            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrganizationForm;
