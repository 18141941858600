import request from "http/request";

interface BaseRequest {
  signal?: AbortSignal;
  body?;
  url: string;
  options?;
}

export const post = ({ body, url, signal, options }: BaseRequest) => {
  return request.post(url, body, signal, options);
};

export const postJson = ({ body, url, signal, options }: BaseRequest) => {
  return request.postJson(url, body, signal, options);
};

export const get = ({ url, options }: BaseRequest) => {
  return request.getJson(url, undefined, options);
};

export const postAsText = ({
  url,
  options,
  body,
}: Omit<BaseRequest, "body"> & { body: string }) => {
  return request.postAsText(url, body, undefined, options);
};
