import { captureException } from "@sentry/browser";
import ERRORS, { ERROR_MESSAGES } from "./errors";

const forceIncludeAuthHeader = (url) => {
  return (
    url.includes("billing-reporter") ||
    window.location.host.includes("localhost")
  );
};

const REQUEST_OPTIONS = (url = "", headers = {}) => ({
  mode: "cors",
  ...(forceIncludeAuthHeader(url) ? {} : { credentials: "include" }),
  headers: new Headers({
    // Auth:
    //   localStorage.getItem("auth") ||
    //   localStorage.getItem("authorization"),
    Authorization:
      localStorage.getItem("authorization") || localStorage.getItem("auth"),
    "Content-Type": "application/json",
    ...headers,
  }),
});

//temporary check, while mainUrl/fallbackUrl in geo AutocompleteProvider
const isNotGeoDbUrl = (url) => !url?.includes("geodb");

class Request {
  onExpireSession = () => {
    if (!window.location.pathname.includes("/login")) {
      window.location.href = `${window.location.origin}/login/`;
    }

    this.onExpiredSessionHandler();
  };
  onExpiredSessionHandler = () => {
    // will be implemented in userActions.ts
  };
  setExpireSessionHandler(onExpireSession) {
    this.onExpiredSessionHandler = onExpireSession;
  }
  onServiceUnavailable = () => {
    //do nothing
  };
  setServiceUnavailableHandler(onServiceUnavailable) {
    this.onServiceUnavailable = onServiceUnavailable;
  }
  handleErrorStatuses = async (resp) => {
    if (resp.status === ERRORS.UNAUTHORIZED.status) {
      let statusMessage;
      try {
        const respJson = await resp.clone().json();
        statusMessage = respJson?.statusMessage;
      } finally {
        this.onExpireSession();
        if (!statusMessage) {
          //No valid auth session is the default reason for 401
          //eslint-disable-next-line  no-unsafe-finally
          return resp;
        }
      }
    }

    if (
      resp.status === ERRORS.SERVICE_UNAVAILABLE.status ||
      resp.status === ERRORS.BAD_GATEWAY.status
    ) {
      if (isNotGeoDbUrl(resp.url)) {
        //temporary check, while mainUrl/fallbackUrl in geo AutocompleteProvider
        this.onServiceUnavailable();
      }
    }

    if (resp?.status?.toString()[0] !== "2") {
      let error = { status: resp.status };
      try {
        const err = await resp.clone().json();
        error.message =
          err.message || err.error || err.errors || err.statusMessage;
        if (Array.isArray(error.message)) {
          error.message = error.message[0];
        }
      } catch {
        error.message = await resp.clone().text();
      } finally {
        error.message = error.message || `${error.status} network error`;
        if (error.status === ERRORS.BAD_REQUEST.status) {
          if (
            ERROR_MESSAGES.FORBIDDEN_ORGANIZATION.regexp.test(error.message)
          ) {
            error.status = ERRORS.FORBIDDEN;
            const org = error.message?.match(
              ERROR_MESSAGES.FORBIDDEN_ORGANIZATION.regexp
            )?.groups?.organizationGroup;
            error.message = `${ERROR_MESSAGES.FORBIDDEN_ORGANIZATION.label} ${
              org || ""
            }`;
          }

          captureException(
            `Network error on ${resp.url}. Status: ${error.status} Message: ${error.message}`
          );
        }
        //eslint-disable-next-line  no-unsafe-finally
        throw error;
      }
    }

    return resp;
  };
  get(url, signal = undefined, options = {}) {
    return fetch(url, { ...REQUEST_OPTIONS(), ...options, signal }).then(
      this.handleErrorStatuses
    );
  }

  async getTextAsset(url, signal) {
    const response = await this.get(url, signal);
    return response.clone().text();
  }

  async getJson(url, saveCache = false, signal, options = {}) {
    try {
      const response = await this.get(url, signal, options).then(
        this.handleErrorStatuses
      );
      if (saveCache) {
        const cache = await caches.open("api");
        cache.put(url, response.clone());
      }
      return response.clone().json();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  post(url, body, signal, options = {}) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      ...REQUEST_OPTIONS(url),
      ...options,
      signal,
    }).then(this.handleErrorStatuses);
  }

  postJson(url, body, signal, options = {}) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      ...REQUEST_OPTIONS(),
      ...options,
      signal,
    })
      .then(this.handleErrorStatuses)
      .then((resp) => resp.json());
  }

  put(url, body, signal, options = {}) {
    return fetch(url, {
      method: "PUT",
      body,
      ...REQUEST_OPTIONS(),
      ...options,
      signal,
    })
      .then(this.handleErrorStatuses)
      .then((resp) => resp);
  }

  putJson(url, body, signal, options = {}) {
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      ...REQUEST_OPTIONS(),
      ...options,
      signal,
    })
      .then(this.handleErrorStatuses)
      .then((resp) => resp.json());
  }

  postAsText(url, body, signal, options = {}) {
    return fetch(url, {
      method: "POST",
      body,
      ...REQUEST_OPTIONS("", { "Content-Type": "text/plain" }),
      ...options,
      signal,
    }).then(this.handleErrorStatuses);
  }

  patch(url, body, signal, options = {}) {
    return fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
      ...REQUEST_OPTIONS(),
      ...options,
      signal,
    }).then(this.handleErrorStatuses);
  }

  xhrUploadBlob({ path, file, onSuccess, onError, onProgress }) {
    const xhr = new XMLHttpRequest();

    xhr.onload = (e) => {
      if (e.target.status === 200) {
        onSuccess && onSuccess(e);
      } else {
        if (e.target.status === ERRORS.UNAUTHORIZED.status) {
          this.onExpireSession();
        }
        onError && onError(e);
      }
    };

    xhr.onerror = (e) => {
      onError && onError(e);
    };

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress && onProgress(e);
      }
    };

    xhr.open("POST", path, true);

    if (process.env.REACT_APP_USE_AUTH_HEADER === "true") {
      xhr.withCredentials = true;
      const auth = localStorage.getItem("auth");
      const authorization = localStorage.getItem("authorization");
      xhr.setRequestHeader("auth", auth || authorization);
      xhr.setRequestHeader("authorization", authorization || auth);
    }

    xhr.send(file);
  }

  delete(url, signal) {
    return fetch(url, {
      method: "DELETE",
      ...REQUEST_OPTIONS(),
      signal,
    }).then(this.handleErrorStatuses);
  }

  async getJsonCache(url) {
    const cache = await caches.open("api");
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
      return cachedResponse.clone().json();
    }
    return null;
  }
}

const request = new Request();
export default request;
