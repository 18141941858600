import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import cx from "classnames";
import { makeStyles } from "components/mui-core";

export const useStyles = makeStyles({
  root: { width: "100%", height: 4 },
});

type ILinear = {
  isLoading?: boolean;
  className?: string;
} & LinearProgressProps;

function Linear({ isLoading = true, className, ...muiProps }: ILinear) {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      {isLoading && (
        <LinearProgress
          color="secondary"
          {...muiProps}
        />
      )}
    </div>
  );
}

export default Linear;
