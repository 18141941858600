import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Button } from "vendor/components";

const useStyles = makeStyles({
  dialog: {
    padding: "20px 20px 10px 20px",
  },
  title: {
    margin: "0 0 5px 0 !important",
    padding: "0 !important",
    fontSize: "22px",
  },
  modalBody: {
    margin: "0 0 5px 0 !important",
    padding: "0 !important",
  },
  modalFooter: {
    margin: "10px 0 5px 0 !important",
    padding: "0 !important",
  },
  button: {
    fontSize: "12px !important",
    padding: "12px 20px !important",
    // textTransform: 'initial !important' as any
  },
  modalFooterCenter: {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...(props as any)}
    />
  );
});

interface IModalConfirmationProps {
  isShown;
  id;
  handleYes;
  handleNo?;
  customTitle;
  customText;
  yesButtonTitle?: string;
  noButtonTitle?: string;
  focus?: "yesButton" | "noButton" | "none";
  StateComponent?: React.ComponentType<any>;
  validateState?: (localState: any) => boolean;
  loading?: boolean;
}

function ModalConfirmation({
  isShown,
  id,
  handleYes,
  handleNo,
  customTitle,
  customText,
  yesButtonTitle = "Yes",
  noButtonTitle = "No",
  loading,
  focus,
  StateComponent,
  validateState,
}: IModalConfirmationProps) {
  const classes = useStyles();
  const [localState, setLocalState] = React.useState<any>();

  return (
    <Dialog
      classes={{
        // root: classes.center + ' ' + classes.modalRoot,
        paper: classes.dialog,
      }}
      open={isShown}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={() => handleNo(id)}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      data-qa="notice-modal">
      <DialogTitle className={classes.title}>
        <h4 className={classes.title}>{customTitle}</h4>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        {customText}
        {StateComponent ? (
          <StateComponent
            value={localState}
            onChange={setLocalState}
          />
        ) : null}
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}>
        {handleNo && (
          <Button
            autoFocus={focus === "noButton"}
            onClick={() => handleNo(id)}
            data-qa="notice-modal_button-no"
            type="submit"
            color="transparent"
            disabled={loading}
            className={classes.button}>
            {noButtonTitle}
          </Button>
        )}
        {handleYes && (
          <Button
            type="submit"
            variant="contained"
            data-qa="notice-modal_button-yes"
            color="primary"
            autoFocus={focus === "yesButton"}
            onClick={() => handleYes(id)}
            disabled={(validateState && !validateState(localState)) || loading}
            className={classes.button}>
            {yesButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalConfirmation;
