import { isAlpha, isLAN } from "./hosts";

export enum Environment {
  Localhost = "localhost:[0-9]+",
  Default = "default",
}

export const ENVIRONMENTS = {
  [Environment.Localhost]: {
    account: "dsp-account.dataseat.com",
    api: "dsp-api.dataseat.com",
    publisher: "dsp-publisher-dmp.dataseat.com",
    compression: "compression.dataseat.com",
    reporter: "reporter.dataseat.com",
    billingReporter: {
      url: "billing-reporter.dataseat.com",
      useProxy: false,
      useHttps: true,
    },
    audience: "audience.dataseat.com",
    bidderEast: {
      url: "rtb-lb-us-chartboost.dataseat.com",
      useProxy: false,
      useHttps: true,
    },
    bidderWest: {
      url: "rtb-lb-us-west.dataseat.com",
      useProxy: false,
      useHttps: true,
    },
    geodb: "geodb.dataseat.com",
    history: "mongohistory.dataseat.com",
    monetizationApps: {
      url: "sdk.dataseat.com",
      useLocationHost: true,
      useHttps: false,
      suffix: "/clients/v2",
    },
    content: { url: "content.inhousedsp.com", useProxy: true },
    //to test/debug with deployed node-service instead of local, use it url like this:
    //nodeServices: { url: "node-services-alpha.dataseat.com", useProxy: false, useHttps: true },
    nodeServices: { url: "localhost:8081", useProxy: false, useHttps: false },
    predictModelManager: "model-manager.dataseat.com",
    fetchAppInfo: true,
    useAuthHeader: true,
    useSentry: false,
    useGa: false,
    fetchCreativeApprovals: false,
  },
  [Environment.Default]: {
    account: "http://dsp-account.dsp.svc.cluster.local:8080",
    api: "http://dsp-missioncontrol.dsp.svc.cluster.local:8080",
    publisher: "http://dsp-publisher-dmp.dsp.svc.cluster.local:8080",
    compression: "http://dsp-creative-compression.dsp.svc.cluster.local:8080",
    reporter: "http://dsp-reporter.dsp.svc.cluster.local:8080",
    billingReporter: {
      url: "billing-reporter",
      useProxy: false,
      useHttps: true,
    },
    audience: "http://dsp-audience.dsp.svc.cluster.local:8080",
    bidderEast: {
      url: "rtb-lb-us-chartboost",
      useProxy: false,
      useHttps: true,
    },
    bidderWest: {
      url: "rtb-lb-us-west",
      useProxy: false,
      useHttps: true,
    },
    geodb: "http://dsp-geodb.dsp.svc.cluster.local:8080",
    history: "http://dsp-mongohistory.dsp.svc.cluster.local:8080",
    monetizationApps: {
      url: "sdk",
      useLocationHost: true,
      useHttps: true,
      suffix: "/clients/v2",
    },
    content: { url: "content.inhousedsp.com", useProxy: true },
    nodeServices: {
      url: isAlpha ? "node-services-alpha" : "node-services",
      useProxy: false,
      useHttps: true,
    },
    predictModelManager: "model-manager.dataseat.com",
    fetchAppInfo: true,
    useAuthHeader: true,
    useSentry: true,
    useGa: true,
    fetchCreativeApprovals: true,
  },
};

export const getEnvironmentKeyAndHost = (force?: Environment) => {
  let host = window.location.host;
  const hostArr = window.location.host.split(".");
  hostArr.shift();

  if (hostArr.length > 1) {
    host = hostArr.join(".");
  }

  let environment =
    force ?? Object.keys(ENVIRONMENTS).find((key) => host.match(key));
  if (isLAN) {
    environment = Environment.Localhost;
  }
  return { environment, host };
};

export const getEnvironment = (force?: Environment) => {
  const { environment, host } = getEnvironmentKeyAndHost(force);

  if (environment === Environment.Localhost) {
    return ENVIRONMENTS[Environment.Localhost];
  } else {
    return {
      ...ENVIRONMENTS[Environment.Default],
      account: `${ENVIRONMENTS[Environment.Default].account}`,
      api: `${ENVIRONMENTS[Environment.Default].api}`,
      publisher: `${ENVIRONMENTS[Environment.Default].publisher}`,
      compression: `${ENVIRONMENTS[Environment.Default].compression}`,
      reporter: `${ENVIRONMENTS[Environment.Default].reporter}`,
      billingReporter: {
        ...ENVIRONMENTS[Environment.Default].billingReporter,
        url: `${ENVIRONMENTS[Environment.Default].billingReporter.url}.${host}`,
      },
      audience: `${ENVIRONMENTS[Environment.Default].audience}`,
      bidderEast: {
        ...ENVIRONMENTS[Environment.Default].bidderEast,
        url: `${ENVIRONMENTS[Environment.Default].bidderEast.url}.${host}`,
      },
      bidderWest: {
        ...ENVIRONMENTS[Environment.Default].bidderWest,
        url: `${ENVIRONMENTS[Environment.Default].bidderWest.url}.${host}`,
      },
      geodb: `${ENVIRONMENTS[Environment.Default].geodb}`,
      history: `${ENVIRONMENTS[Environment.Default].history}`,
      monetizationApps: {
        ...ENVIRONMENTS[Environment.Default].monetizationApps,
        url: `${
          ENVIRONMENTS[Environment.Default].monetizationApps.url
        }.${host}`,
      },
      content: { url: "content.inhousedsp.com", useProxy: true },
      nodeServices: {
        ...ENVIRONMENTS[Environment.Default].nodeServices,
        url: `${ENVIRONMENTS[Environment.Default].nodeServices.url}.${host}`,
      },
    };
  }
};

export const checkForMisconfigurations = (endpoint: string, environment) => {
  if (
    typeof environment[endpoint] !==
      typeof getEnvironment(Environment.Default)[endpoint] &&
    window.location.host.includes("localhost")
  ) {
    //eslint-disable-next-line  no-console
    console.log(
      `%cMisconfiguration found within '${endpoint}' endpoint. Please ensure 'Default' environment has the same structure as 'Localhost' to prevent errors in dev/prod environments.`,
      "color: white; font-size: 28px; background-color: red; padding: 20px; text-align: center;"
    );
  }
};

export const getEndpoint = (endpoint: string): string => {
  const environment = getEnvironment();

  checkForMisconfigurations(endpoint, environment);

  if (environment[endpoint]) {
    if (typeof environment[endpoint] === "string") {
      return `${window.location.origin}/proxy/${environment[endpoint]}`;
    } else if (environment[endpoint]?.useProxy) {
      return `${window.location.origin}/proxy/${environment[endpoint].url}`;
    } else {
      const e = environment[endpoint];

      return `${e.useHttps ? "https" : "http"}://${
        e.useLocationHost ? `${window.location.host}/proxy/` : ""
      }${e.url}${e.suffix || ""}`;
    }
  } else {
    //eslint-disable-next-line  no-console
    console.error(`${endpoint} is not defined`);
    return "";
  }
};
