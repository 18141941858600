import {
  adaptV4Theme,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import {
  grayColor,
  linkedinColor,
  primaryColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: primaryColor[0] },
      secondary: { main: linkedinColor },
      error: { main: "rgb(255, 0, 0)" },
      disabled: { main: grayColor },
    },
    breakpoints: {
      values: {
        xs: 0, //stats 2021:
        sm: 768, //ipad 39% among tablets
        md: 1280,
        lg: 1536, //9.8% among desktop
        xl: 1920, //23% among desktop
      },
    },
  })
);

function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default ThemeProvider;
