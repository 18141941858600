import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type AppMeasureType = analyticsReport.AppMeasureType;

export const appMeasuresDict = analyticsReport.appMeasuresDict;

//reexport enum
export { CALCULATED_EVENTS_DICT } from "@dataseat-dsp-client/lib-reporter";

export const filterInAppEvents = analyticsReport.filterInAppEvents;
