"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayToObject = void 0;
function arrayToObject({ arr, keyModifier = (elem) => elem, valueModifier = (elem) => elem, }) {
    return arr.reduce((acc, elem, index) => {
        acc[keyModifier(elem, index)] = valueModifier(elem, index);
        return acc;
    }, {});
}
exports.arrayToObject = arrayToObject;
