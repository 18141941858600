import { getPreviousQuicklink } from "components/DateTime/common";
import { numberFromString } from "helpers";
import get from "lodash.get";
import { DataDisplayMode, ILabeledData } from "model/report";
import { TimeRange } from "model/report/dateRange";
import { DimensionIds } from "model/reporterDb/dbTableTraffic";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import {
  ETrafficEvents,
  IReportTable,
  ITrafficEventColumn,
} from "model/TrafficPage";
import { SplitType } from "model/TrafficPage/splitsAndFilters";

export const DEFAULT_TRAFFIC_TABLE: IReportTable = {
  id: Math.random(),
  splits: [],
  filters: {},
  dateRange: {
    dates: [new Date(), new Date()],
    range: TimeRange.today,
  },
  granularity: GRANULARITY_TYPES.ALL,
  view: DataDisplayMode?.table,
};

export const CUSTOM_CELL_WIDTH = {
  [ETrafficEvents.requestsWithLogVolume]: 120,
  [ETrafficEvents.bidFloor]: 100,
  [DimensionIds.APP_BUNDLE_COLUMN_ID]: 250,
};

export const COLUMN_WIDTH = 170;
const SIDE_PADDING = 20;

export const getTableCardWidth = (
  columns: ITrafficEventColumn[],
  splits: SplitType[]
) => {
  return 2 * SIDE_PADDING + getTableWidth(columns, splits);
};

export const getTableWidth = (
  columns: ITrafficEventColumn[],
  splits?: SplitType[]
) => {
  let columnsWidth = 0;
  columns
    .filter((c) => c.isShownInTable)
    .forEach((c) => {
      columnsWidth += CUSTOM_CELL_WIDTH[c.id];
    });

  return (
    columnsWidth +
    (splits?.length ? CUSTOM_CELL_WIDTH[splits[0].id] || COLUMN_WIDTH : 0) +
    (splits?.length || 0 > 1 ? 35 : 0)
  );
};

export const getTableTotals = (data: ILabeledData) => {
  return data.reduce((acc, row) => {
    Object.entries(row).forEach(([key, value]) => {
      if (key === "rows") return; // skip internal tables and metrics
      acc[key] = get(acc, key, 0) + numberFromString(value);
    });
    return acc;
  }, {});
};

export const convertCreativeSize = (value: string) => {
  return value?.replace(/[\\[\]]/gi, "");
};

export const isSplitChartView = (view, splits) =>
  view === DataDisplayMode.charts && splits.length === 2;

export const enabledTrafficTimeRanges = {
  [TimeRange?.today]: true,
  [TimeRange?.yesterday]: true,
  [TimeRange?.last7Days]: true,
};

export const clearTablesCache = (username: string) => {
  localStorage.removeItem(`traffic-${username}`);
};

export const updateTablesCache = (
  table: IReportTable | number | string,
  action: "add" | "remove" | "update",
  username: string
) => {
  const cashedTables: IReportTable[] = JSON.parse(
    localStorage.getItem(`traffic-${username}`) || "[]"
  );

  let newTables: IReportTable[] = [];

  if (action === "remove") {
    const tbId = table as number;
    newTables = cashedTables.filter((ctb) => ctb.id !== tbId);
  }

  if (action === "add" || action === "update") {
    const tb = table as IReportTable;

    if (!tb.dateRange?.range) {
      const quicklink = getPreviousQuicklink(
        tb.dateRange.dates[0],
        tb.dateRange.dates[1]
      );
      tb.dateRange.range =
        quicklink?.id && enabledTrafficTimeRanges[quicklink?.id]
          ? enabledTrafficTimeRanges[quicklink?.id]
          : TimeRange.custom;
    }

    if (action === "add") {
      newTables = [...cashedTables, tb];
    }

    if (action === "update") {
      newTables = cashedTables.map((ctb) => {
        if (ctb.id.toString() === tb.id) {
          return tb;
        } else {
          return ctb;
        }
      });
    }
  }

  localStorage.setItem(`traffic-${username}`, JSON.stringify(newTables));
};
