import {
  getUserInfo,
  loginWithCredentials,
  loginWithMFA,
  logout,
} from "./userAsyncActions";

export function applyUserExtraReducers(builder) {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  builder.addCase(loginWithCredentials.fulfilled, (state, { payload }) => {
    return {
      username: payload.username,
      mfa: {
        forceUseOfMFA: payload?.forceUseOfMFA,
        mfaEnabled: !!payload?.mfaEnabled,
        mfaSharedSecret: !!payload?.mfaSharedSecret,
      },
      isLoggedIn: !payload?.mfaEnabled && !payload?.forceUseOfMFA,
      hasError: false,
      errorStatus: null,
      errorMessage: "",
    };
  });
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  builder.addCase(loginWithCredentials.rejected, (state, { payload }) => {
    return {
      username: payload.username,
      isLoggedIn: false,
      hasError: true,
      errorStatus: payload.status,
      errorMessage: payload.message,
    };
  });

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  builder.addCase(loginWithMFA.fulfilled, (state, { payload }) => {
    return {
      username: payload.username,
      mfa: {
        forceUseOfMFA: payload?.forceUseOfMFA,
        mfaEnabled: !!payload?.mfaEnabled,
        mfaSharedSecret: !!payload?.mfaSharedSecret,
      },
      isLoggedIn: true,
      hasError: false,
      errorStatus: null,
      errorMessage: "",
    };
  });
  builder.addCase(loginWithMFA.rejected, (state, { payload }) => {
    return {
      username: payload.username,
      mfa: state.mfa,
      isLoggedIn: false,
      hasError: true,
      errorStatus: payload.status,
      errorMessage: payload.message,
    };
  });
  builder.addCase(logout.fulfilled, () => {
    return {
      username: "",
      mfa: {},
      isLoggedIn: false,
      hasError: false,
      errorStatus: null,
      errorMessage: "",
    };
  });
  builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
    return {
      ...state,
      isLoggedIn: true,
      mfa: {
        mfaEnabled: !!payload?.mfaEnabled,
        mfaSharedSecret: !!payload?.mfaSharedSecret,
      },
      role: payload.role,
      username: state.username || payload.username,
      defaultOrganizationId: payload.organizationId,
      defaultAccountId: payload.accountId,
    };
  });
  builder.addCase(getUserInfo.rejected, (state) => {
    return {
      ...state,
      mfa: {},
      isLoggedIn: false,
      hasError: false,
      errorStatus: null,
      errorMessage: "",
    };
  });
}
