import { isCohortableAppMeasure } from "./measuresAsColumns/cohortedReportColumns";
import { ColumnTypes } from "./measuresAsColumns/columnTypes";

export const migrateTypeToColumnType = (item) => {
  if (item.type) {
    if (item.type === ColumnTypes.AppMeasure) {
      item.columnType = isCohortableAppMeasure(item)
        ? ColumnTypes.CohortedAppMeasure
        : ColumnTypes.AppMeasure;
    } else if (item.type === ColumnTypes.AppMeasureCPA) {
      item.columnType = isCohortableAppMeasure(item)
        ? ColumnTypes.CohortedAppMeasureCPA
        : ColumnTypes.AppMeasureCPA;
    } else {
      item.columnType = item.type;
    }

    delete item.type;
  }

  return item;
};
