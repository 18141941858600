/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ETrafficEvents,
  IReportTable,
  TRAFFIC_EVENT_COLUMNS,
  TReportData,
  TStatsBySplit,
} from "model/TrafficPage/trafficTable";
import { convertCreativeSize, isSplitChartView } from "routes/Traffic/helpers";
//@ts-ignore NOTE: web worker imported via webpack loader
import Worker from "worker-loader!workers/worker.ts";
import {
  getTrafficQuery,
  getTrafficQueryFromTable,
  populateFullDateRange,
} from "./helpers";
// TODO: perhaps move this somewhere shared?
import { getQuickLinks } from "components/DateTime/quickLinksModel";
import { sortAlphaNum } from "helpers";
import orderBy from "lodash/orderBy";
import { DataDisplayMode } from "model/report";
import { TimeRange } from "model/report/dateRange";
import {
  ReportNormalizer,
  parseWithNames,
  sortColumnAlpha,
  sortColumnNumeric,
} from "model/report/normalization";
import {
  DimensionIds,
  convertBucketBidFloor,
} from "model/reporterDb/dbTableTraffic";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import { DEFAULT_SORT_BY, TRAFFIC_FILTERS_DICTIONARY } from "model/TrafficPage";
import { normalizeChartData } from "model/TrafficPage/normalizeChartData";
import { IRootState } from "redux/reducer";
import { fetchReport } from "services/reporter/fetch";

// NOTE: This amount is 5 items more than is defined in Traffic/SplitChart
// This is to make sure it still triggers UI warning that the data is capped
const CAPPED_DATA = 15;

/**
 * SPLIT CHART ONLY: Processing split chart data will be diverted to a web worker
 * @param table
 * @param orderedByVolume Top data ordered by requests that we can pass to
 * the web worker in order to get only the top CAPPED_DATA items processed.
 */
const onReportReady =
  (table: IReportTable, orderedByVolume?: string[]) =>
  (rawTextData: string): Promise<TReportData> => {
    const { granularity, splits, view, dateRange } = table;

    const groupBy =
      granularity === "ALL"
        ? splits.filter((s) => s.id !== DimensionIds.GRANULARITY_COLUMN_ID)
        : splits;

    // SPLIT CHART DATA -> send to web worker
    if (view === DataDisplayMode.charts && groupBy.length === 2) {
      const topCappedData = orderedByVolume?.slice(0, CAPPED_DATA);

      //eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const worker = new Worker();
        worker.postMessage(["traffic", rawTextData, groupBy, topCappedData]);
        worker.addEventListener("message", (event) => {
          const { groupBy, data: formattedData } = event.data;

          // NOTE: only limited processing can be done in web worker
          // as it doesn't like high amount of imported modules
          const filteredData = formattedData.map((dataRow) => ({
            ...dataRow,
            data: dataRow.data.filter((d) => !!d.GRANULARITY),
          }));

          const fullRangeData = populateFullDateRange(
            filteredData,
            dateRange.dates,
            granularity,
            groupBy[1].id
          );

          const sortedData = fullRangeData.sort(
            (a, b) => b[DEFAULT_SORT_BY] - a[DEFAULT_SORT_BY]
          );

          const normalizedData = sortedData.map((dataRow) => ({
            ...dataRow,
            data: dataRow.data.map(
              normalizeChartData(granularity, "GRANULARITY")
            ),
          }));

          resolve({ groupBy, data: normalizedData });
        });
      });
    }

    const groupByIds = groupBy.reduce((acc: string[], { id }) => {
      if (TRAFFIC_FILTERS_DICTIONARY[id]?.combineDimensionIds) {
        acc = [
          ...acc,
          ...(TRAFFIC_FILTERS_DICTIONARY[id]?.combineDimensionIds || []),
        ];
      } else {
        acc.push(id);
      }
      return acc;
    }, []);

    const normalizeValuesFnDict = {
      bidFloor: (val, row) =>
        convertBucketBidFloor(val, { requests: row.requests }),
    };

    return new Promise<TReportData>((resolve) => {
      const normalizedData = new ReportNormalizer({
        measures: [
          ...groupBy.map(({ id, name }) => ({
            id,
            label: name,
            columnType: "Dimension",
          })),
          ...TRAFFIC_EVENT_COLUMNS,
        ],
        granularity,
        groupBy: groupByIds,
        rawTextData,
        view,
        dateRange: dateRange.dates,
        normalizeValuesFnDict,
      }).normalize();

      const data = normalizedData.sort(
        (a, b) => b[DEFAULT_SORT_BY] - a[DEFAULT_SORT_BY]
      );

      resolve({ groupBy, data });
    });
  };

const fetchReportData = ({
  table,
  orderedByVolume,
}: {
  table: IReportTable;
  orderedByVolume?: string[];
}): Promise<[IReportTable, TReportData]> => {
  const tableWithUpdatedDates = { ...table };
  const quickLinks = getQuickLinks();

  if (![TimeRange.custom, TimeRange.allTime].includes(table.dateRange.range)) {
    const ql = quickLinks[table.dateRange.range];
    if (ql?.value?.[0] && ql?.value?.[1]) {
      tableWithUpdatedDates.dateRange = {
        ...tableWithUpdatedDates.dateRange,
        dates: [new Date(ql.value[0]), new Date(ql?.value[1])],
      };
    }
  }

  return fetchReport(getTrafficQueryFromTable(tableWithUpdatedDates))
    .then((resp) => resp.text())
    .then(onReportReady(tableWithUpdatedDates, orderedByVolume))
    .then((data) => [tableWithUpdatedDates, data]);
};

/**
 * SPLIT CHART ONLY: Fetches TOTAL stats for each line displayed in Split Chart View
 * @param table
 */
const fetchStats = (
  organizationId: string | undefined,
  accountId: string | undefined,
  table: IReportTable
): Promise<{
  statsBySplit: TStatsBySplit;
  orderedByVolume: string[];
}> => {
  const { splits, filters, dateRange } = table;
  // NOTE: splits[0] is GRANULARITY and only 1 other is allowed
  const selectedSplit = splits[1].id;

  return fetchReport(
    getTrafficQuery({
      splits,
      filterBy: filters,
      dateRange: dateRange.dates,
      granularity: GRANULARITY_TYPES.ALL,
    })
  )
    .then((resp) => resp.text())
    .then((rawData) => {
      return parseWithNames(rawData, [
        selectedSplit,
        ...TRAFFIC_EVENT_COLUMNS.map(({ id }) => id),
      ]);
    })
    .then((reporterData) => {
      const normalized: any[] = reporterData.map(
        normalizeChartData(GRANULARITY_TYPES.ALL)
      );

      const sortedByRequests = orderBy(
        normalized,
        [ETrafficEvents.requestsWithLogVolume],
        ["desc"]
      );

      const statsBySplit: TStatsBySplit = {};
      sortedByRequests.forEach((d) => {
        statsBySplit[d[selectedSplit] || "UNKNOWN"] = d;
      });

      const topDataByRequestVolume = sortedByRequests.map((d) =>
        d[selectedSplit].toString()
      );

      return {
        statsBySplit,
        orderedByVolume: topDataByRequestVolume,
      };
    })
    .catch((e) => {
      return e;
    });
};

export const setTableWithData = createAsyncThunk(
  "traffic/setTableWithData",
  async (table: IReportTable, thunkAPI) => {
    const { splits = [], view } = table;
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    // If view is SplitChart fetch stats per line
    if (isSplitChartView(view, splits)) {
      const { statsBySplit, orderedByVolume } = await fetchStats(
        organizationId,
        accountId,
        table
      );
      const [updatedTable, reportData] = await fetchReportData({
        table,
        orderedByVolume,
      });

      return {
        ...updatedTable,
        reportData,
        statsBySplit,
        orderedByVolume,
      };
    }

    const [updatedTable, reportData] = await fetchReportData({
      table,
    });
    return { ...updatedTable, reportData };
  }
);

export const updateTableReportData = createAsyncThunk(
  "traffic/updateTableReportData",
  async ({ table }: { table: IReportTable }, thunkAPI) => {
    const { splits = [], view } = table;
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    // If view is SplitChart fetch stats per line
    if (isSplitChartView(view, splits)) {
      const { statsBySplit, orderedByVolume } = await fetchStats(
        organizationId,
        accountId,
        table
      );
      const [updatedTable, reportData] = await fetchReportData({
        table,
        orderedByVolume,
      });

      return {
        ...updatedTable,
        reportData,
        statsBySplit,
        orderedByVolume,
      };
    }

    const [updatedTable, reportData] = await fetchReportData({
      table,
    });
    return { ...updatedTable, reportData };
  }
);

const SORT_METHOD_DICTIONARY = {
  [ETrafficEvents.bidFloor]: sortColumnNumeric,
  [ETrafficEvents.requestsWithLogVolume]: sortColumnNumeric,
};

export const sortReportData = createAsyncThunk(
  "traffic/sortReportData",
  async (
    {
      tableId,
      sortBy,
    }: { tableId: string | number; sortBy: { id: string; desc: boolean } },
    thunkAPI
  ) => {
    const { id, desc } = sortBy;
    const state = thunkAPI.getState() as IRootState;
    const selectedTable = state.trafficPage.tables.find(
      (tb) => tb.id.toString() === tableId.toString()
    );

    if (TRAFFIC_FILTERS_DICTIONARY[id]?.combineDimensionIds) {
      const { combineDimensionIds, isEmptyValue } =
        TRAFFIC_FILTERS_DICTIONARY[id];
      const data = selectedTable?.reportData?.data.slice().sort((a, b) => {
        const dId = combineDimensionIds?.find((t) => !isEmptyValue?.(a?.[t]));
        if (dId) {
          const valA = convertCreativeSize(a[dId]);
          const valB = convertCreativeSize(b[dId]);
          return desc ? sortAlphaNum(valB, valA) : sortAlphaNum(valA, valB);
        }

        return 0;
      });
      return { data, sortBy: id, desc };
    }

    const customSortMethod = SORT_METHOD_DICTIONARY[id] || sortColumnAlpha;

    const data = selectedTable?.reportData?.data
      .slice()
      .sort(customSortMethod(id, desc));

    return { data, sortBy: id, desc };
  }
);
