import {
  warningColor,
  whiteColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react.jsx";

type Props = {
  text?: string;
  dataQa?: string;
};

function InlinePageWarning({ text, dataQa = "warning" }: Props) {
  if (!text) {
    return null;
  }
  return (
    <div
      data-qa={dataQa}
      style={{
        backgroundColor: warningColor[1],
        padding: 10,
        color: whiteColor,
      }}>
      ⚠ {text}
    </div>
  );
}

export default InlinePageWarning;
