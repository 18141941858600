import { Dollars, Microdollars } from "model/modelTypes";
import { numberToCurrency } from "./string";

export function stringToDollars(value = "0"): Dollars {
  return parseFloat(value) as Dollars;
}

export function stringDollarsToMicro(value = "0"): Microdollars {
  return dollarsToMicro(stringToDollars(value));
}

type microToDollarsType = (
  micro: Microdollars,
  opts?: { showZeroCents?: boolean }
) => string;

export const microToDollarsString: microToDollarsType = (
  micro = 0 as Microdollars
) => {
  const dollars = microToDollars(micro);
  const result = Number.isFinite(dollars) ? dollars : 0;
  return numberToCurrency(result);
};

export const microToDollarsStringShort: microToDollarsType = (
  micro = 0 as Microdollars
) => {
  const dollars = microToDollars(micro);
  const result = Number.isFinite(dollars) ? dollars : 0;
  return numberToCurrency(result);
};

export function microToDollars(
  value: Microdollars = 0 as Microdollars
): Dollars {
  return (Math.round(Math.round(value) / 10000) / 100) as Dollars;
}

export function microToDollarsNoRounding(
  value: Microdollars = 0 as Microdollars
): Dollars {
  return (value / 1000000) as Dollars;
}

export function dollarsToMicro(value: Dollars = 0 as Dollars): Microdollars {
  return Math.round(value * 1000000) as Microdollars;
}

export const percentageToDecimalPlace = (a, b) =>
  b > 0 ? Math.round((1000 * a) / b) / 10 : 0;
