import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { ICampaignStatusServer } from "model/campaigns";

export const fetchAllCampaignsStatuses = createAsyncThunk<
  ICampaignStatusServer[]
>("campaignStatus/fetchAllCampaignsStatuses", async (arg, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const { organizationId, accountId } = state.auth;

  return request.getJson(
    endpointMaker.stats.campaignListStats(organizationId, accountId)
  );
});
