import { dateIsBetweenDates, getIsoDateNow } from "helpers";
import { BudgetRangeServer } from "model/campaigns/budgets";
import { ISOstring, Microdollars } from "model/modelTypes";
import { IRootState } from "redux/reducer";
import store from "redux/store";
import { createSelector } from "reselect";
import { ICampaignsBudgetsState } from "./budgetsSlice";

export const getCampaignsBudgetsState = (
  state: IRootState
): ICampaignsBudgetsState => state.campaignsBudgets;

export const useAllCampaignBudgets = () =>
  store.getState().campaignsBudgets.budgetsMap;

export const useAllCampaignCurrentSpends = () =>
  store.getState().campaignsBudgets.currentBudgetsSpends;

export const getCampaignBudgets = (campaignId: string) =>
  createSelector(
    getCampaignsBudgetsState,
    ({ budgetsMap }): BudgetRangeServer[] => budgetsMap[campaignId]
  );

export const isCurrentCampaignBudget = (b: {
  start: ISOstring;
  finish: ISOstring;
}) => dateIsBetweenDates(getIsoDateNow(), b.start, b.finish);

export const getCurrentCampaignBudget = createSelector(
  //input selectors
  [getCampaignsBudgetsState, (state, campaignId) => campaignId],
  //output selector
  ({ budgetsMap }, campaignId): BudgetRangeServer | undefined =>
    budgetsMap[campaignId]?.find(isCurrentCampaignBudget)
);

export const getCurrentCampaignBudgetSpend = createSelector(
  //input selectors
  [getCampaignsBudgetsState, (state, campaignId) => campaignId],
  //output selector
  ({ currentBudgetsSpends }, campaignId): Microdollars | undefined =>
    currentBudgetsSpends[campaignId]
);
