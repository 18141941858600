import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type CohortedColumnType = analyticsReport.CohortedColumnType;
export type CohortedEventDictionaryType =
  analyticsReport.CohortedEventDictionaryType;

export type CohortedMeasureType = analyticsReport.CohortedMeasureType;
export type CohortedMeasuresDictType = analyticsReport.CohortedMeasuresDictType;

export const getCohortOrderedColumnIdByEventId =
  analyticsReport.getCohortOrderedColumnIdByEventId;
export const getEventIdByCohortColumnId =
  analyticsReport.getEventIdByCohortColumnId;
export const getCohortColumnId = analyticsReport.getCohortColumnId;
export const getCohortColumnLabels = analyticsReport.getCohortColumnLabels;
export const isCohortableAppMeasure = analyticsReport.isCohortableAppMeasure;
export const getCohortableAppMeasures =
  analyticsReport.getCohortableAppMeasures;
export const isCohortableColumnType = analyticsReport.isCohortableColumnType;

export const COHORT_ABLE_BASE_EVENT_IDS =
  analyticsReport.COHORT_ABLE_BASE_EVENT_IDS;
export const COHORT_ABLE_EVENT_COLUMNS =
  analyticsReport.COHORT_ABLE_EVENT_COLUMNS;
export const COHORT_ABLE_EVENT_DICT = analyticsReport.COHORT_ABLE_EVENT_DICT;
