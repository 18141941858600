"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COARSE_CONVERSION_NUMBER_LABELS = exports.CREATIVE_TYPE_LABELS = exports.SKAN_FIDELITY_LABELS = void 0;
exports.SKAN_FIDELITY_LABELS = {
    "-1": "null",
    "0": "view-through",
    "1": "click-through",
};
exports.CREATIVE_TYPE_LABELS = {
    rich_media: "Rich Media",
    R: "Rich Media",
    V: "Vast Video",
    "VAST 2.0": "Vast Video",
    N: "Native",
};
exports.COARSE_CONVERSION_NUMBER_LABELS = {
    "": "null/unknown",
    "0": "null/unknown",
    "1": "Low",
    "2": "Medium",
    "3": "High",
};
