const INCONSISTENT_SAVE_SERVER_MESSAGE = "Please get new version";

//backend errors are unpredictable
const findMessage = (e: any) =>
  (typeof e === "string" && e) ||
  (typeof e?.message === "string" && e.message) ||
  (Array.isArray(e?.message) && e.message.join("; "));

const isInconsistentSaveError = (message: string) =>
  message.includes(INCONSISTENT_SAVE_SERVER_MESSAGE);

export { findMessage, isInconsistentSaveError };
