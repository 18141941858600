import get from "lodash.get";
import type { FileSizeBytes, FileSizeMBs } from "model/modelTypes";

type OnErrorHandler = (err: string | Event | Error) => void;

type GetImageWidthAndHeightType = (
  src: string,
  onSuccess?: (meta: { width: number; height: number }) => void,
  onError?: OnErrorHandler
) => void;

export const getImageWidthAndHeight: GetImageWidthAndHeightType = (
  src,
  onSuccess,
  onError
) => {
  const img = new Image();

  img.onload = (e) => {
    const { width, height } = e.target as HTMLImageElement;

    if (onSuccess) {
      onSuccess({ width, height });
    }
  };

  img.onerror = (error) => {
    if (onError) {
      onError(error);
    }
  };

  img.src = src;
};

type getVideoMetadataType = (
  src: string,
  onSuccess?: (meta: any) => void,
  onError?: OnErrorHandler
) => void;

export const getVideoMetadata: getVideoMetadataType = (
  src,
  onSuccess,
  onError
) => {
  const vid = document.createElement("video");
  vid.preload = "metadata";

  vid.onloadedmetadata = (meta) => {
    const duration = get(meta, "currentTarget.duration", 0);
    const width = get(meta, "currentTarget.videoWidth", 0);
    const height = get(meta, "currentTarget.videoHeight", 0);

    if (onSuccess) {
      onSuccess({ height, width, duration });
    }
  };

  vid.onerror = (err) => {
    if (onError) {
      onError(err);
    }
  };

  vid.src = src;
};

type GetMetaFromFileBlobType = (
  file: File,
  onSuccess?: (meta: any) => void,
  onError?: OnErrorHandler
) => void;

export const getMetaFromFileBlob: GetMetaFromFileBlobType = (
  file,
  onSuccess,
  onError
) => {
  const { name: filename, size, type } = file;

  if (!file || !filename || !size || !type) {
    return onError && onError(new Error(`Could not get metadata for ${file}`));
  }

  const defaultMeta = { filename, size, type };
  const [kind] = type.split("/");
  if (kind !== "image") {
    onSuccess && onSuccess(defaultMeta);
  } else {
    const metaReader = new FileReader();

    metaReader.onload = (e) => {
      const src = e?.target?.result;

      if (src && typeof src === "string") {
        getImageWidthAndHeight(
          src,
          (meta = { width: 0, height: 0 }) => {
            onSuccess && onSuccess({ ...defaultMeta, ...meta });
          },
          onError
        );
      }
    };

    metaReader.readAsDataURL(file);
  }
};

export const formatVideoDuration = (number: string | number = "0"): string => {
  try {
    const sec_num = typeof number === "string" ? parseInt(number, 10) : number;
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = Math.round(sec_num - hours * 3600 - minutes * 60);

    const padded = (num) => `${num}`.padStart(2, "0");

    return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`;
  } catch (e) {
    return `${number}`;
  }
};

type HorVertOrSquareType = (options: {
  width: number;
  height: number;
}) => "square" | "horizontal" | "vertical";

export const horVertOrSquare: HorVertOrSquareType = ({ width, height }) => {
  if (width === height) return "square";
  if (width > height) return "horizontal";
  return "vertical";
};

const mimeTypeMap = {
  gif: "image/gif",
  png: "image/png",
  mp4: "video/mp4",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  css: "text/css",
  js: "text/javascript",
};

type MimeTypeFromSrcType = (src: string) => string;
export const mimeTypeFromSrc: MimeTypeFromSrcType = (src: string) => {
  let mimeType = "image/";

  src.toLowerCase().replace(/\.(\w+)$/gm, (match, ext) => {
    mimeType = mimeTypeMap[ext] || "text/plain";
    return match;
  });

  return mimeType;
};

export const getFileSize = async (url: string) => {
  const response = await fetch(`/proxy/${url.replace("https://", "")}`, {
    method: "HEAD",
  });

  return parseInt(
    response.headers.get("content-length") ?? "0"
  ) as FileSizeBytes;
};

export const bytesToMBs = (
  size: FileSizeBytes
  /*si: boolean = true*/
): FileSizeMBs => {
  // Paul suggested to do size / 1024 but show as MBs
  // if (si) return (size / 1000) as FileSizeMBs;

  return (size / (1024 * 1024)) as FileSizeMBs;
};
