import {
  archiveCampaign,
  createCampaign,
  updateCampaign,
} from "./campaignActions";

export function applyActiveCampaignExtraReducers(builder) {
  builder.addCase(createCampaign.fulfilled, (state, action) => {
    state.data[action.payload.id] = action.payload;
  });
  builder.addCase(createCampaign.rejected, (state, action) => {
    throw new Error(action.error.message);
  });

  builder.addCase(updateCampaign.fulfilled, (state, action) => {
    state.data[action.payload.id] = action.payload;
  });
  builder.addCase(updateCampaign.rejected, (state, action) => {
    throw new Error(action.error.message);
  });

  builder.addCase(archiveCampaign.fulfilled, (state, action) => {
    delete state.data[action.payload];
  });
}
