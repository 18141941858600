import { differenceInHours } from "date-fns";
import { Integer } from "model/modelTypes";

const PMMODELS_LOCAL_STORAGE_KEY = "pendingManagedModels";
const STORE_MAX_HOURS_AGO = 24;

type StoredPMModels = {
  lastStoringTimestamp: Integer;
  pendingManagedModels: {
    [name: string]: { modelTimestamp: Integer };
  };
};

export function addPMModelToLocalStorage(modelName) {
  const pmModels = getPMModelsFromLocalStorage() ?? ({} as StoredPMModels);
  pmModels.lastStoringTimestamp = new Date().getTime();
  pmModels.pendingManagedModels = pmModels.pendingManagedModels ?? {};
  pmModels.pendingManagedModels[modelName] = {
    modelTimestamp: new Date().getTime(),
  };
  localStorage.setItem(PMMODELS_LOCAL_STORAGE_KEY, JSON.stringify(pmModels));
}

export function getPMModelsFromLocalStorage(
  withCleanup?: boolean
): StoredPMModels | undefined {
  const storedPMModels = localStorage.getItem(PMMODELS_LOCAL_STORAGE_KEY);
  let parsedModels: StoredPMModels | undefined = undefined;
  if (storedPMModels) {
    try {
      parsedModels = JSON.parse(storedPMModels);
    } catch (e) {
      if (withCleanup) {
        localStorage.removeItem(PMMODELS_LOCAL_STORAGE_KEY);
      }
      return undefined;
    }
  }
  if (
    !parsedModels?.lastStoringTimestamp ||
    (parsedModels?.lastStoringTimestamp &&
      differenceInHours(Date.now(), parsedModels.lastStoringTimestamp) >
        STORE_MAX_HOURS_AGO)
  ) {
    if (withCleanup) {
      localStorage.removeItem(PMMODELS_LOCAL_STORAGE_KEY);
    }
    return undefined;
  } else {
    const recentModels = Object.entries(
      parsedModels.pendingManagedModels
    ).reduce((acc, [mName, mValue]) => {
      if (
        mValue?.modelTimestamp &&
        differenceInHours(Date.now(), mValue.modelTimestamp) <=
          STORE_MAX_HOURS_AGO
      ) {
        acc[mName] = mValue;
      }
      return acc;
    }, {});
    if (withCleanup) {
      localStorage.setItem(
        PMMODELS_LOCAL_STORAGE_KEY,
        JSON.stringify(recentModels)
      );
    }
    return {
      pendingManagedModels: recentModels,
      lastStoringTimestamp: parsedModels.lastStoringTimestamp,
    };
  }
}
