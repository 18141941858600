import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import {
  BudgetRangeServer,
  CampaignBudgetListResponse,
} from "model/campaigns/budgets";
import { ReporterTables } from "model/reporterDb/dbSchema";
import {
  CAMPAIGN_SELECT_QUERY_MAP,
  DimensionIds,
} from "model/reporterDb/dbTableCampaign";
import {
  TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED,
  WHERE_VALUES_EXPR,
} from "model/reporterDb/query";
import { IRootState } from "redux/reducer";
import { fetchFlatReportParsed } from "services/reporter/fetch/fetchFlatReportParsed";
import { isCurrentCampaignBudget } from "./selectors";

export const getAllCampaignsBudgets = createAsyncThunk(
  "budgets/getAllCampaignsBudgets",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.campaigns.allCampaignsBudgets(organizationId, accountId)
    );
  }
);

export const getAllCurrentBudgetsSpend = createAsyncThunk(
  "budgets/getAllCurrentBudgetsSpend",
  async (budgets: CampaignBudgetListResponse[]) => {
    const currentBudgets: BudgetRangeServer[] = budgets
      .filter((b) => isCurrentCampaignBudget(b.budgetRange))
      .map((b) => b.budgetRange);

    if (currentBudgets.length === 0) {
      return Promise.resolve([]);
    }

    const budgetsSpendQueries = currentBudgets.map((b) => ({
      from: ReporterTables.Campaign,
      select: [CAMPAIGN_SELECT_QUERY_MAP.spend],
      where: [
        WHERE_VALUES_EXPR(DimensionIds.CAMPAIGN_ID_COLUMN_ID, [b.campaignId]),
        TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED(b.start, b.finish),
      ],
      groupBy: [DimensionIds.CAMPAIGN_ID_COLUMN_ID],
    }));

    return Promise.allSettled(
      budgetsSpendQueries.map((query) =>
        fetchFlatReportParsed({
          query,
          eventNames: ["campaignId", "spend"],
        })
      )
    );
  }
);

export const getAllCampaignsBudgetsWithSpend = () => (dispatch) =>
  dispatch(getAllCampaignsBudgets()).then((action) => {
    const budgets = action.payload;
    dispatch(getAllCurrentBudgetsSpend(budgets));
  });
