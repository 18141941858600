import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  addYears,
  compareAsc,
  differenceInCalendarISOWeeks,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  differenceInDays,
  differenceInHours,
  endOfDay,
  format as formatDateFns,
  isAfter,
  parseISO,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { longDateFormat } from "./date";

export function seedDummyDates(
  dateKey: string,
  dateRange?: Date[],
  granularity?: "HOUR" | "DAY" | "MONTH" | "YEAR" | string
): Array<{ [key: string]: string }> {
  if (!dateRange || !granularity) return [];

  const dummyData: any = [];

  switch (granularity) {
    case "YEAR": {
      const startDate = startOfYear(dateRange[0]);
      const endDate = startOfYear(dateRange[1]);
      const diff = differenceInCalendarYears(endDate, startDate);

      for (let i = 0; i <= diff; i++) {
        dummyData.push({
          [dateKey]: formatDateFns(
            addYears(new Date(startDate), i),
            longDateFormat
          ),
        });
      }
      break;
    }
    case "MONTH": {
      const startDate = startOfMonth(dateRange[0]);
      const endDate = startOfMonth(dateRange[1]);
      const diff = differenceInCalendarMonths(endDate, startDate);

      for (let i = 0; i <= diff; i++) {
        dummyData.push({
          [dateKey]: formatDateFns(
            addMonths(new Date(startDate), i),
            longDateFormat
          ),
        });
      }
      break;
    }
    case "WEEK": {
      const startDate = startOfISOWeek(dateRange[0]);
      const endDate = startOfISOWeek(dateRange[1]);
      const diff = differenceInCalendarISOWeeks(endDate, startDate);

      for (let i = 0; i <= diff; i++) {
        dummyData.push({
          [dateKey]: formatDateFns(
            addWeeks(new Date(startDate), i),
            longDateFormat
          ),
        });
      }
      break;
    }
    case "DAY": {
      const startDate = startOfDay(dateRange[0]);
      const endDate = startOfDay(dateRange[1]);
      const diff = differenceInDays(endDate, startDate);

      for (let i = 0; i <= diff; i++) {
        dummyData.push({
          [dateKey]: formatDateFns(
            addDays(new Date(startDate), i),
            longDateFormat
          ),
        });
      }
      break;
    }
    case "HOUR": {
      const startDate = startOfDay(dateRange[0]);
      const endDate = endOfDay(dateRange[1]);
      const diff = differenceInHours(endDate, startDate);

      for (let i = 0; i <= diff; i++) {
        dummyData.push({
          [dateKey]: formatDateFns(
            addHours(new Date(startDate), i),
            longDateFormat
          ),
        });
      }
      break;
    }
  }

  return dummyData;
}

type IData = Array<{
  [key: string]: any;
}>;
export function fillMissedDates<T>(
  data: T[],
  dateKey: string,
  dateRange: Date[],
  granularity: "HOUR" | "DAY" | "MONTH" | "YEAR" | string,
  dummyData: { [key: string]: any } = {}
): T[] {
  const dummyDates = seedDummyDates(dateKey, dateRange, granularity);

  data = [...data].sort((a, b) =>
    compareAsc(new Date(a[dateKey]), new Date(b[dateKey]))
  );
  const dataWithoutMissedDates: IData = [];
  let i = 0;
  let j = 0;
  //Take to pick next date from data, but if date is missed take it from dummy
  while (i < dummyDates.length) {
    const dataItem = data[j];
    const dummyItem = { ...dummyDates[i], ...dummyData };
    let picked;
    if (!dataItem?.[dateKey]) {
      picked = dummyItem;
      j++;
    } else if (
      isAfter(parseISO(dataItem[dateKey]), parseISO(dummyItem[dateKey]))
    ) {
      picked = dummyItem;
    } else {
      picked = dataItem;
      j++;
    }
    dataWithoutMissedDates.push(picked);
    i++;
  }
  return dataWithoutMissedDates as T[];
}
