import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import { ICampaignStatusServer } from "model/campaigns";
import {
  changeCampaignStatusReducer,
  clearStatusesOnAccountChangeReducer,
  fetchAllCampaignStatusesReducer,
} from "./campaignStatusExtraReducers";
import { changeLineItemStatusReducer } from "./lineitemStatusExtraReducer";
import { addNewLineitemStatusReducer } from "./lineitemStatusReducer";

type ICampaignStatusState = {
  loadingState: LoadingState;
  data: { [id: string]: ICampaignStatusServer };
  error?: null | SerializedError;
};

const campaignStatusSlice = createSlice({
  name: "campaignStatus",
  initialState: {
    loadingState: LoadingState.initial,
    data: {},
    error: null,
  } as ICampaignStatusState,
  reducers: {
    addNewLineitemStatus: addNewLineitemStatusReducer,
  },
  extraReducers: (builder) => {
    fetchAllCampaignStatusesReducer(builder);
    changeCampaignStatusReducer(builder);
    changeLineItemStatusReducer(builder);
    clearStatusesOnAccountChangeReducer(builder);
  },
});

export const actions = campaignStatusSlice.actions;

export default campaignStatusSlice.reducer;
