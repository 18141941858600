import { USER_ROLES } from "model/auth";
import { Integer } from "model/modelTypes";
import { IOrganizationItem } from "model/organizations";
import { useSelector } from "redux/hooks";
import { IRootState } from "redux/reducer";
import { createSelector } from "reselect";

export const useOrganizations: () => IOrganizationItem[] | undefined = () =>
  useSelector((state: IRootState) => state.auth?.organizations);

export const useOrganizationId: () => string = () =>
  useSelector((state) => state.auth.organizationId) || "";

export const useAccountId: () => string = () =>
  useSelector((state) => state.auth.accountId) || "";

export const useIsLoggedIn: () => boolean | undefined = () =>
  useSelector((state) => state.auth.isLoggedIn);

export const useIsLoginFailed: () => boolean = () =>
  useSelector((state) => !!state.auth.hasError);

export const useRole: () => string | undefined = () =>
  useSelector((state) => state.auth.role);

export const useUsername: () => string = () =>
  useSelector((state) => state.auth.username);

export const getUserIdFromParam = (state, props) =>
  props?.match?.params?.userId;

export function useIsAdminCheck(): boolean | undefined {
  return useSelector((state) =>
    state?.auth?.role
      ? state.auth.role === USER_ROLES.ADMIN
        ? true
        : false
      : undefined
  );
}

export function useIsReadonlyCheck(): boolean | undefined {
  return useSelector((state) =>
    state?.auth?.role
      ? state.auth.role === USER_ROLES.USER_READ_ONLY
        ? true
        : false
      : undefined
  );
}

export function useHasUserRoleCheck(): boolean | undefined {
  return useSelector((state) =>
    state?.auth?.role
      ? state.auth.role === USER_ROLES.USER
        ? true
        : false
      : undefined
  );
}

export function useError(): {
  hasError?: boolean;
  errorStatus?: Integer;
  errorMessage?: string;
} {
  return useSelector((state) => ({
    hasError: state?.auth?.hasError,
    errorStatus: state?.auth?.errorStatus,
    errorMessage: state?.auth?.errorMessage,
  }));
}

export const useOrgSwitchError: () => string | undefined = () =>
  useSelector((state) => state.auth.orgSwitchError);

export function useForceMFA(): boolean | undefined {
  return useSelector(
    (state) =>
      state?.auth?.mfa?.forceUseOfMFA &&
      (!state?.auth?.mfa?.mfaEnabled || !state?.auth?.mfa?.mfaSharedSecret)
  );
}
export function useHasMFACheck(): boolean | undefined {
  return useSelector(
    (state) => state?.auth?.mfa?.mfaEnabled && state?.auth?.mfa?.mfaSharedSecret
  );
}

export const getCurrentOrganization = createSelector(
  (state: IRootState) => state.auth?.organizations,
  (state: IRootState) => state.auth?.organizationId,
  (orgs, id) => orgs?.find((o) => o.id === id)
);
