import { dbQuery } from "@dataseat-dsp-client/lib-reporter";

export type SumDimensionTemplate = dbQuery.SumDimensionTemplate;
export const sumDimensionTemplate = dbQuery.sumDimensionTemplate;

export type CountTemplate = dbQuery.CountTemplate;
export const countTemplate = dbQuery.countTemplate;

export type SumTemplate = dbQuery.SumTemplate;
export const sumTemplate = dbQuery.sumTemplate;

export type ValueTemplate = dbQuery.ValueTemplate;
export const valueTemplate = dbQuery.valueTemplate;

export type CountMultiValueTemplate = dbQuery.CountMultiValueTemplate;
export const countMultiValueTemplate = dbQuery.countMultiValueTemplate;

export const multiTemplate = dbQuery.multiTemplate;

export const valueMultiTemplate = dbQuery.valueMultiTemplate;
export const countMultiTemplate = dbQuery.countMultiTemplate;
export const countMultiValueMultiTemplate =
  dbQuery.countMultiValueMultiTemplate;

export type AliasExprTemplate = dbQuery.AliasExprTemplate;
export const aliasExprTemplate = dbQuery.aliasExprTemplate;

export type WithConditionType = dbQuery.WithConditionType;
export const withCondition = dbQuery.withCondition;

export type DivExprTemplate = dbQuery.DivExprTemplate;
export const divTemplate = dbQuery.divTemplate;

export type MulExprTemplate = dbQuery.MulExprTemplate;
export const mulTemplate = dbQuery.mulTemplate;
