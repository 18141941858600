export interface ICreativeApprovalStatus {
  label: TCreativeApprovalStatusType;
  tooltip: string;
  disapprovalReason: string;
  timestamp: Date;
}

export type TCreativeApprovalStatusType =
  | "APPROVED"
  | "DISAPPROVED"
  | "PENDING_REVIEW"
  | "SUBMITTED"; // SUBMITTED is only set from the UI

export interface IComplianceTopic {
  evidences: any[];
  helpCenterUrl: string;
  policyTopic: string;
}

export enum PolicyCompliance {
  deals = "dealsPolicyCompliance",
  network = "networkPolicyCompliance",
  platform = "platformPolicyCompliance",
  china = "chinaPolicyCompliance",
  russia = "russiaPolicyCompliance",
}

export const POLICY_COMPLIANCE_REQUIRED_FOR_APPROVAL = {
  [PolicyCompliance.deals]: true,
  [PolicyCompliance.network]: true,
};

// API Response - ICreativeApprovalStatus[]
export interface ICreativeApprovalStatusServer {
  accountId: number;
  creativeFormat: "VIDEO" | "HTML";
  creativeId: string;
  creativeServingDecision: {
    [PolicyCompliance.china]: {
      status: TCreativeApprovalStatusType;
      topics?: IComplianceTopic;
    };
    [PolicyCompliance.deals]: {
      status: TCreativeApprovalStatusType;
      topics?: IComplianceTopic;
    };
    [PolicyCompliance.network]: {
      status: TCreativeApprovalStatusType;
      topics?: IComplianceTopic;
    };
    [PolicyCompliance.platform]: {
      status: TCreativeApprovalStatusType;
      topics?: IComplianceTopic;
    };
    [PolicyCompliance.russia]: {
      status: TCreativeApprovalStatusType;
      topics?: IComplianceTopic;
    };
    detectedClickThroughUrls: string[];
    lastStatusUpdate: Date;
  };
}
