import SnackbarOnAction from "components/Notifications/SnackbarOnAction";
import SnackbarOnError from "components/Notifications/SnackbarOnError";
import SnackbarOnSave from "components/Notifications/SnackbarOnSave";
import SnackbarOnWarning from "components/Notifications/SnackbarOnWarning";
import React from "react";
import ReactDOM from "react-dom";

function withSnackbar(Component) {
  return function SnackbarProvider(props) {
    const [saveNotification, setSaveNotification] = React.useState();
    const [errorNotification, setErrorNotification] = React.useState();
    const [actionNotification, setActionNotification] = React.useState();
    const [warningNotification, setWarningNotification] = React.useState();

    const [errorDuration, setErrorDuration] = React.useState();

    const [mainDom, setMainDom] = React.useReducer(
      () => document.getElementsByTagName("main")?.[0],
      ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useLayoutEffect(setMainDom, []);

    const NotificatonComponent = (
      <>
        {saveNotification && (
          <SnackbarOnSave
            onClose={() => setSaveNotification("")}
            isOpen={!!saveNotification}
            message={saveNotification}
          />
        )}
        {errorNotification && (
          <SnackbarOnError
            duration={errorDuration}
            onClose={() => setErrorNotification("")}
            isOpen={!!errorNotification}
            message={errorNotification}
          />
        )}
        {actionNotification && (
          <SnackbarOnAction
            onClose={() => setActionNotification("")}
            isOpen={!!actionNotification}
            message={actionNotification}
          />
        )}
        {warningNotification && (
          <SnackbarOnWarning
            onClose={() => setWarningNotification("")}
            isOpen={!!warningNotification}
            message={warningNotification}
          />
        )}
      </>
    );

    return (
      <>
        <Component
          {...props}
          showSaveSnackbar={setSaveNotification}
          showActionSnackbar={setActionNotification}
          showErrorSnackbar={(message, duraton) => {
            setErrorNotification(message);
            setErrorDuration(duraton);
          }}
          showWarningSnackbar={setWarningNotification}
        />
        {mainDom
          ? ReactDOM.createPortal(NotificatonComponent, mainDom)
          : NotificatonComponent}
      </>
    );
  };
}

export default withSnackbar;
