import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type EventColumnType = analyticsReport.EventColumnType;
export type MetricColumnType = analyticsReport.MetricColumnType;

export const EVENT_COLUMNS: Array<EventColumnType> =
  analyticsReport.EVENT_COLUMNS;
export const METRIC_COLUMNS: MetricColumnType[] =
  analyticsReport.METRIC_COLUMNS;

export const UTILITY_COLUMNS_ID_CHECK =
  analyticsReport.UTILITY_COLUMNS_ID_CHECK;
