import { captureException } from "@sentry/browser";
import {
  addMinutes,
  compareAsc,
  compareDesc,
  differenceInDays,
  differenceInHours,
  format as formatDateFns,
  isBefore,
  parse,
  parseISO,
  toDate,
} from "date-fns";
import { Percentage } from "model/modelTypes";
import { percentage } from "./primitive";

export const parseDate = (
  date: Date | string | number | null,
  format?: string
): Date | null => {
  if (!date) return null;
  if (date instanceof Date) return date;

  if (typeof date === "number") return toDate(date);

  // timestamp's length is 13 symbols (+new Date() or Date.now())
  if (typeof date === "string" && /^\d{13}$/.test(date)) {
    return parse(date, "T", new Date());
  }

  if (format) return parse(date, format, new Date());

  return parseISO(date, { additionalDigits: 2 });
};

export const monAndDayFormat = "MMM dd";

export const monDayAndTimeFormat = `${monAndDayFormat} HH:mm`;

export const shortYearMonAndDayFormat = `yy ${monAndDayFormat}`;

export const timeFormat = "HH:mm";

export const dateFormat = "dd/MM/yyyy";

export const shortTimeFormat = "H:mm";

export const timeWithSecondsFormat = "HH:mm:ss";

export const longDateFormat = `yyyy-MM-dd ${timeWithSecondsFormat}`;

export const longDateFormatNoSec = `yyyy-MM-dd ${timeFormat}`;

export const namedMonthFormat = "dd MMM yyyy";

export const namedMonthAndDayFormat = "MMM dd yyyy";

export const formatDate = (
  date?: string | Date | null,
  format: string = dateFormat
): string => {
  if (!date) return "";
  if (typeof date === "string") return date;

  try {
    const formattedDate = formatDateFns(date, format);
    return formattedDate;
  } catch (error) {
    // eslint-disable-next-line no-console
    captureException(`Failed to format date: ${date} using format: ${format}`);
  }

  return "";
};

export const getPace = (
  start?: Date | null,
  finish?: Date | null
): Percentage => {
  if (!start || !finish) return 0 as Percentage;

  const today = new Date();
  if (isBefore(finish, today)) return 100 as Percentage;

  const isShortRange = differenceInDays(finish, start) < 8;
  const differenceFn = isShortRange ? differenceInHours : differenceInDays;
  const totalLifetime = differenceFn(finish, start);
  const currentLifetime = differenceFn(today, start);
  return percentage({ total: totalLifetime, part: currentLifetime });
};

export const dateSortDesc =
  (format = "yyyy-MM-dd HH:mm:ss") =>
  (d1, d2) =>
    compareDesc(parse(d1, format, new Date()), parse(d2, format, new Date()));

export const dateSortAsc =
  (format = "yyyy-MM-dd HH:mm:ss") =>
  (d1, d2) =>
    compareAsc(parse(d1, format, new Date()), parse(d2, format, new Date()));

export const dateSortAscByField =
  (dateField: string, format = "yyyy-MM-dd HH:mm:ss") =>
  (d1, d2) =>
    compareAsc(
      parse(d1[dateField], format, new Date()),
      parse(d2[dateField], format, new Date())
    );

export const MIN_POSSIBLE_DATE = new Date("1/1/2019");

// TODO: Might need to rework because if date is string then we might "add" 2 times the UTC
export const dateToUtc = (date: Date | number | string) =>
  addMinutes(
    typeof date === "string" ? new Date(date) : date,
    -new Date(date).getTimezoneOffset()
  );

// TODO: Might need to rework because if date is string then we might "add" 2 times the UTC
export const utcToDate = (date: Date | number | string) =>
  addMinutes(
    typeof date === "string" ? new Date(date) : date,
    new Date(date).getTimezoneOffset()
  );

export const dateIsBetweenDates = (
  date: Date | string | number,
  from: Date | string | number,
  to: Date | string | number
) =>
  new Date(date).getTime() >= new Date(from).getTime() &&
  new Date(date).getTime() <= new Date(to).getTime();

export const dateUtcIsBetweenDates = (date: Date, from: Date, to: Date) =>
  dateIsBetweenDates(dateToUtc(date), dateToUtc(from), dateToUtc(to));

export const dateIsBefore = (
  date: Date | string | number,
  compareTo: Date | string | number
) => new Date(date).getTime() < new Date(compareTo).getTime();

export const dateIsAfter = (
  date: Date | string | number,
  compareTo: Date | string | number
) => new Date(date).getTime() > new Date(compareTo).getTime();

export const dateUtcIsBefore = (date: Date, compareTo: Date) =>
  dateIsBefore(dateToUtc(date), dateToUtc(compareTo));
