import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { LoadingState } from "model/Types/loading";
import type { IAudience } from "model/audiences";

type AudiencesState = {
  loadingState: LoadingState;
  list: IAudience[];
  error: null | SerializedError;
};

//Async actions
export const fetchAudiences = createAsyncThunk<IAudience[]>(
  "audiences/fetchAudiences",
  async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.audience.audiences(organizationId, accountId)
    );
  }
);

export const archiveAudience = createAsyncThunk(
  "audiences/archiveAudience",
  async (audience: IAudience, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .getJson(
        endpointMaker.audience.archiveAudience(
          organizationId,
          accountId,
          audience.type,
          audience.name
        )
      )
      .then((resp) => {
        if (resp === false) {
          throw `Audience ${audience.name} with id ${audience.id} was not archived`;
        } else {
          return audience.id;
        }
      });
  }
);

export const unarchiveAudience = createAsyncThunk(
  "audiences/unarchiveAudience",
  async (audience: IAudience, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .getJson(
        endpointMaker.audience.unarchiveAudience(
          organizationId,
          accountId,
          audience.type,
          audience.id
        )
      )
      .then((resp) => {
        if (resp === false) {
          throw `Audience ${audience.name} with id ${audience.id} was not unarchived`;
        } else {
          return audience.id;
        }
      });
  }
);

//Slice
const audiencesSlice = createSlice({
  name: "audiences",
  initialState: {
    loadingState: LoadingState.initial,
    list: [] as IAudience[],
    error: null,
  } as AudiencesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAudiences.pending, (state) => {
      state.loadingState = LoadingState.loading;
      state.error = null;
    });
    builder.addCase(fetchAudiences.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;
      state.list = action.payload;
    });
    builder.addCase(fetchAudiences.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;
      state.error = action.error;
    });
    //archive
    builder.addCase(archiveAudience.pending, (state) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    });
    builder.addCase(archiveAudience.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;

      const archivedAudienceId = action.payload;
      const archivedAudience = state.list.find(
        (a) => a.id === archivedAudienceId
      );
      if (archivedAudience) {
        archivedAudience.archived = true;
      }
    });
    builder.addCase(archiveAudience.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
    //unarchive
    builder.addCase(unarchiveAudience.pending, (state) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    });
    builder.addCase(unarchiveAudience.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;

      const unarchivedAudienceId = action.payload;
      const unarchivedAudience = state.list.find(
        (a) => a.id === unarchivedAudienceId
      );
      if (unarchivedAudience) {
        unarchivedAudience.archived = true;
      }
    });
    builder.addCase(unarchiveAudience.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
  },
});

export default audiencesSlice.reducer;

//Selectors
export const selectAudiences = (state) => state.audiences.list;
export const selectAudiencesLoadingState = (state) => [
  state.audiences.loadingState,
  state.audiences.error,
];
export const selectAudienceById = (state, id) =>
  state.audiences.list.find((a) => a.id === id);
