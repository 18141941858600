import { dbTableCampaign } from "@dataseat-dsp-client/lib-reporter";

export type MetricDictionaryType<T extends string> =
  dbTableCampaign.MetricDictionaryType<T>;

export const EVENTS_LABELED = dbTableCampaign.EVENTS_LABELED;
export const METRICS_LABELED = dbTableCampaign.METRICS_LABELED;
export const UTILITY_COLUMNS_LABELED = dbTableCampaign.UTILITY_COLUMNS_LABELED;
export const NUMERIC_DIMENSION_LABELED =
  dbTableCampaign.NUMERIC_DIMENSION_LABELED;
export const NUMERIC_MEASURES_LABELED =
  dbTableCampaign.NUMERIC_MEASURES_LABELED;
