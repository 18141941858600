import { Milliseconds, Seconds } from "model/modelTypes";

//MS
export const FULL_DAY_MS: Milliseconds = 86400000;
export const TWELVE_HOURS_MS: Milliseconds = 43200000;
export const SIX_HOURS_MS: Milliseconds = 21600000;
export const THREE_HOUR_MS: Milliseconds = 10800000;
export const HOUR_MS: Milliseconds = 3600000;
export const HALF_HOUR_MS: Milliseconds = 1800000;
export const FIFTEEN_MINUTES_MS: Milliseconds = 900000;
export const TEN_MINUTES_MS: Milliseconds = 600000;
export const FIVE_MINUTES_MS: Milliseconds = 300000;
export const ONE_MINUTE_MS: Milliseconds = 60000;
export const HALF_MINUTE_MS: Milliseconds = 30000;

//S
export const SIX_HOURS_S: Seconds = 21600;
export const HOUR_S: Seconds = 3600;
export const HALF_HOUR_S: Seconds = 1800;
