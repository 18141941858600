import { isAlpha, isDev } from "configuration/endpoints";
import get from "lodash/get";
import Recoil, { useRecoilValue } from "recoil";
import { toggleWithId } from "./FeatureTogglesState";

export const ALL_FEATURES = {
  scheduleReportTestOnlyFeature: {
    id: "scheduleReportTestOnlyFeature",
    label: "Schedule Report Test",
  },
  testForceMFA: {
    id: "testForceMFA",
    label: "Test force MFA",
  },
  bulkEditLineitems: {
    id: "bulkEditLineitems",
    label: "Bulk Edit Lineitems",
  },
  htmlExternalAsset: {
    id: "htmlExternalAsset",
    label: "HTML External assets creative",
  },
  createEditModels: {
    id: "createEditModels",
    label: "create and edit Models",
  },
  bundleInAppEvents: {
    id: "bundleInAppEvents",
    label: "Bundle InApp Events",
  },
  videoVastSvec: {
    id: "videoVastSvec",
    label: "Video Vast Svec",
  },
  oldInventoryDownload: {
    id: "oldInventoryDownload",
    label: "Old Inventory Downloads",
  },
  capPacingColumn: {
    id: "capPacingColumn",
    label: "capPacingColumn",
  },
  newLiWLBundleTable: {
    id: "newLiWLBundleTable",
    label: "New Line Item Whitelist Bundle Table",
  },
};

export type Feature = keyof typeof ALL_FEATURES;

type FeaturesEnabled = {
  [key in Feature]?: boolean;
};

export function getEnabledFeaturesList(): FeaturesEnabled {
  const params = new URLSearchParams(get(window, "location.search", []));
  return Object.keys(ALL_FEATURES).reduce((acc, param) => {
    acc[param] = !!params.get(param);
    return acc;
  }, {});
}

export const useReleaseCheck = () => {
  return true;
  //after-release temporary check allowing quick rollback
};

export const useFeatureCheck = (featureName: Feature) => {
  const featureEnabled = useNoAlphaFeatureCheck(featureName);
  return isAlpha || isDev || featureEnabled;
};

export const useNoAlphaFeatureCheck = (featureName: Feature) => {
  const isEnabled: boolean = useRecoilValue(toggleWithId(featureName));
  const params = new URLSearchParams(get(window, "location.search", []));
  const isUrlEnabled = !!params.get(featureName);
  return isEnabled || isUrlEnabled;
};

export const useNoHookOnlyUrlFeatureCheck = (featureName: Feature) => {
  const params = new URLSearchParams(get(window, "location.search", []));
  return !!params.get(featureName);
};

type useFeatureTogglesListT = () => Array<{
  id: Feature;
  label: string;
  value: boolean;
  onChange: Recoil.SetterOrUpdater<boolean>;
}>;

export const useFeatureTogglesList: useFeatureTogglesListT = () => {
  return Object.entries(ALL_FEATURES).map(([featureId, { label }]) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue] = Recoil.useRecoilState<boolean>(
      toggleWithId(featureId)
    );
    const id = featureId as Feature;
    return { id, label, value, onChange: setValue };
  });
};
