import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type DatePeriod = analyticsReport.DatePeriod;
export type DatePeriodSingular = analyticsReport.DatePeriodSingular;

export const PERIOD_TYPES = analyticsReport.PERIOD_TYPES;
export const PERIOD_SINGULAR_TYPES = analyticsReport.PERIOD_SINGULAR_TYPES;
export const periodTypesToSingular = analyticsReport.periodTypesToSingular;

export type DefaultFiltersType = analyticsReport.DefaultFiltersType;

export type PeriodConfig = analyticsReport.PeriodConfig;
export type PeriodConfigDict = analyticsReport.PeriodConfigDict;

export const PERIOD_CONFIGS = analyticsReport.PERIOD_CONFIGS;
export const DATE_RANGE_DICT = analyticsReport.DATE_RANGE_DICT;

export const getDateRangeOptionByPeriodConfig =
  analyticsReport.getDateRangeOptionByPeriodConfig;
export const getDateRangeDict = analyticsReport.getDateRangeDict;
export const getDateRange = analyticsReport.getDateRange;
export const getDateRangeBoundary = analyticsReport.getDateRangeBoundary;

export const subtractPeriod = analyticsReport.subtractPeriod;
export const startOf = analyticsReport.startOf;
export const endOf = analyticsReport.endOf;
