import { ICreativeApprovalStatusServer } from "model/creative/creativeApprovalStatus";
import { LoadingState } from "model/Types/loading";
import {
  archiveCreative,
  createCreative,
  loadActiveCreatives,
  loadArchivedCreatives,
  loadCreativeApprovalStatus,
  loadCreativeListApprovalStatuses,
  unarchiveCreative,
  updateCreative,
} from "./creativesAsyncActions";
import { ICreativesState } from "./creativesSlice";
import {
  arrayToCreativesObject,
  getKindAndCreative,
  getNewApprovalStatus,
  updateCreativesObject,
} from "./helpers";

// Active Creatives
export function fetchActiveCreativesReducer(builder) {
  builder.addCase(loadActiveCreatives.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
    state.errorLoadingActiveCreatives = null;
  });
  builder.addCase(
    loadActiveCreatives.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      // NOTE: Creatives object is being updated because approval status is being
      // fetched every 60 mins and creatives are being fetched every 5 mins
      state.active = updateCreativesObject(state.active, action.payload);
      state.lastActiveCreativesFetch = +new Date();
      state.errorLoadingActiveCreatives = null;
    }
  );
  builder.addCase(
    loadActiveCreatives.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.errorLoadingActiveCreatives = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

// Create
export function createCreativeReducer(builder) {
  builder.addCase(createCreative.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
  });
  builder.addCase(
    createCreative.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;
      state.active[action.payload.id] = action.payload;
    }
  );
  builder.addCase(createCreative.rejected, (state: ICreativesState, action) => {
    state.loadingState = LoadingState.failed;
    state.error = action.error?.message
      ? action.error
      : new Error("Unknown error");
  });
}

// Update
export function updateCreativeReducer(builder) {
  builder.addCase(updateCreative.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
  });
  builder.addCase(
    updateCreative.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      const creativeId = action.payload.id;

      const { kind, creative } = getKindAndCreative({
        state,
        id: creativeId,
      });

      if (!kind) return;

      const { approvalStatus } = creative;

      state[kind][creativeId] = {
        approvalStatus,
        ...action.payload,
      };
    }
  );
  builder.addCase(updateCreative.rejected, (state: ICreativesState, action) => {
    state.loadingState = LoadingState.failed;

    state.error = action.error?.message
      ? action.error
      : new Error("Unknown error");
  });
}

// Creative Approval Status
export function loadCreativeApprovalStatusReducer(builder) {
  builder.addCase(
    loadCreativeApprovalStatus.pending,
    (state: ICreativesState) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    }
  );
  builder.addCase(
    loadCreativeApprovalStatus.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      const { id, approvalStatus } = getNewApprovalStatus(action.payload);
      const { kind } = getKindAndCreative({
        state,
        id,
      });

      if (!kind) return;

      state[kind][id].approvalStatus = approvalStatus;
    }
  );
  builder.addCase(
    loadCreativeApprovalStatus.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

export function loadCreativeListApprovalStatusesReducer(builder) {
  builder.addCase(
    loadCreativeListApprovalStatuses.pending,
    (state: ICreativesState) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
      state.errorLoadingCreativesApprovals = null;
    }
  );
  builder.addCase(
    loadCreativeListApprovalStatuses.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      if (!action.payload) return;

      action.payload?.forEach((item: ICreativeApprovalStatusServer) => {
        const { id, approvalStatus } = getNewApprovalStatus(item);

        const { kind } = getKindAndCreative({
          state,
          id,
        });

        if (!kind) return;

        if (!approvalStatus.timestamp) {
          // eslint-disable-next-line no-console
          console.log("approvalStatus", approvalStatus, item);
        }

        state[kind][id].approvalStatus = approvalStatus;
      });
      if (!action.skipUpdatingTimestamp) {
        state.lastCreativesApprovalFetch = +new Date();
      }

      state.errorLoadingCreativesApprovals = null;
    }
  );
  builder.addCase(
    loadCreativeListApprovalStatuses.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.errorLoadingCreativesApprovals = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

export function loadArchivedCreativesReducer(builder) {
  builder.addCase(loadArchivedCreatives.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
  });
  builder.addCase(
    loadArchivedCreatives.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      state.archived = arrayToCreativesObject(action.payload);
    }
  );
  builder.addCase(
    loadArchivedCreatives.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

export function archiveCreativeReducer(builder) {
  builder.addCase(archiveCreative.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
  });
  builder.addCase(
    archiveCreative.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      const creativeId = action.payload;
      const archivedCreative = state.active[creativeId];
      delete state.active[creativeId];
      state.archived[creativeId] = archivedCreative;
    }
  );
  builder.addCase(
    archiveCreative.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}

export function unarchiveCreativeReducer(builder) {
  builder.addCase(unarchiveCreative.pending, (state: ICreativesState) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
  });
  builder.addCase(
    unarchiveCreative.fulfilled,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.loaded;

      const creativeId = action.payload;
      const unarchivedCreative = state.archived[creativeId];
      delete state.archived[creativeId];
      state.active[creativeId] = unarchivedCreative;
    }
  );
  builder.addCase(
    unarchiveCreative.rejected,
    (state: ICreativesState, action) => {
      state.loadingState = LoadingState.failed;

      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    }
  );
}
