import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { useRole } from "redux/auth/selectors";
import AuthProvider from "routes/Auth/AuthProvider";
import NavigationLayout from "./NavigationLayout";
import { getProtectedRoutesByRole } from "./protectedRoutesByRole";

function useProtectedRoutesByRole() {
  const role = useRole();
  const protectedRouts = React.useMemo(
    () => getProtectedRoutesByRole(role),
    [role]
  );

  return protectedRouts;
}

function ProtectedRoutes() {
  const protectedRoutes = useProtectedRoutesByRole();

  return (
    <AuthProvider>
      <NavigationLayout>
        <Suspense fallback={""}>
          <Switch>{protectedRoutes}</Switch>
        </Suspense>
      </NavigationLayout>
    </AuthProvider>
  );
}

export default ProtectedRoutes;
