import endpointMaker from "configuration/endpoints";
import request from "http/request";
import store from "redux/store";

const fetchReport = (query, signal?: AbortSignal) =>
  request.post(
    endpointMaker.reporter.report(
      store.getState()?.auth?.organizationId,
      store.getState()?.auth?.accountId
    ),
    query,
    signal
  );

export default fetchReport;
