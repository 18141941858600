export const formatRemainingTime = (createdAt: Date, seconds: number): string => {
  const now = new Date();
  const targetDate = new Date(createdAt.getTime() + seconds * 1000);
  const remainingTime = Math.floor(
    (targetDate.getTime() - now.getTime()) / 1000
  );

  if (remainingTime <= 0) {
    return "soon";
  }

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  const minutes = Math.floor(remainingTime / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) {
    return rtf.format(weeks, "week");
  } else if (days > 0) {
    return rtf.format(days, "day");
  } else if (hours > 0) {
    return rtf.format(hours, "hour");
  } else if (minutes > 0) {
    return rtf.format(minutes, "minute");
  } else {
    return rtf.format(remainingTime, "second");
  }
};
