"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReporterTables = void 0;
//Main tables in Clickhouse db
//that UI consumes through Reporter API
var ReporterTables;
(function (ReporterTables) {
    ReporterTables["Campaign"] = "CAMPAIGN";
    ReporterTables["Traffic"] = "TRAFFIC";
})(ReporterTables = exports.ReporterTables || (exports.ReporterTables = {}));
