"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCohortedBorMeasureExpr = exports.getBorMeasureExpr = exports.getSkanEventsFromRoasEvents = exports.getNonSkanEventsFromRoasEvents = void 0;
const queryMap_1 = require("../query/queryMap");
const templates_1 = require("../query/templates");
const cohorts_1 = require("./cohorts");
const customEvent_1 = require("./customEvent");
const numericMeasures_1 = require("./numericMeasures");
const queryMap_2 = require("./queryMap");
const queryMapCohorted_1 = require("./queryMapCohorted");
const getNonSkanEventsFromRoasEvents = (events) => events === null || events === void 0 ? void 0 : events.filter((ev) => !(0, customEvent_1.isSkanEvent)(ev));
exports.getNonSkanEventsFromRoasEvents = getNonSkanEventsFromRoasEvents;
const getSkanEventsFromRoasEvents = (events) => events === null || events === void 0 ? void 0 : events.filter((ev) => (0, customEvent_1.isSkanEvent)(ev));
exports.getSkanEventsFromRoasEvents = getSkanEventsFromRoasEvents;
function getBorMeasureExpr({ eventName, roasEventsInCampaign, onException, }) {
    if (!(roasEventsInCampaign === null || roasEventsInCampaign === void 0 ? void 0 : roasEventsInCampaign.length))
        return queryMap_1.DUMMY_EXPRESSION;
    const mmpEvents = (0, exports.getNonSkanEventsFromRoasEvents)(roasEventsInCampaign);
    const skanEvents = (0, exports.getSkanEventsFromRoasEvents)(roasEventsInCampaign);
    if (mmpEvents === null || mmpEvents === void 0 ? void 0 : mmpEvents.length) {
        if (eventName === numericMeasures_1.Events.sales) {
            return (0, templates_1.countMultiTemplate)(mmpEvents);
        }
        if (eventName === numericMeasures_1.Events.revenue) {
            return (0, templates_1.valueMultiTemplate)(mmpEvents);
        }
        if (eventName === numericMeasures_1.Metrics.roas) {
            return (0, templates_1.divTemplate)((0, templates_1.mulTemplate)((0, templates_1.valueMultiTemplate)(mmpEvents), 1000000), (0, templates_1.aliasExprTemplate)("$SPENT"));
        }
        if (eventName === numericMeasures_1.Metrics.cpa) {
            return (0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$SPENT"), (0, templates_1.countMultiTemplate)(mmpEvents));
        }
    }
    if (skanEvents === null || skanEvents === void 0 ? void 0 : skanEvents.length) {
        const cpaExpr = (0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$SPENT"), (0, templates_1.countMultiTemplate)(skanEvents));
        if (eventName === numericMeasures_1.Metrics.skanCpa) {
            return cpaExpr;
        }
        if (eventName === numericMeasures_1.Metrics.skanPcpa) {
            return (0, templates_1.mulTemplate)((0, templates_1.mulTemplate)(cpaExpr, queryMap_2.CAMPAIGN_SELECT_QUERY_MAP.skanCoarseRatio), (0, templates_1.subTemplate)(1, queryMap_2.CAMPAIGN_SELECT_QUERY_MAP.skanTier0Ratio));
        }
    }
    return queryMap_1.DUMMY_EXPRESSION;
}
exports.getBorMeasureExpr = getBorMeasureExpr;
function getCohortedBorMeasureExpr({ eventName, cohort, roasEventsInCampaign, onException, }) {
    const cohortCondition = (0, queryMapCohorted_1.getCohortCondition)(cohort);
    const mmpEvents = (0, exports.getNonSkanEventsFromRoasEvents)(roasEventsInCampaign);
    //const skanEvents = getSkanEventsFromRoasEvents(roasEventsInCampaign);
    if (!(roasEventsInCampaign === null || roasEventsInCampaign === void 0 ? void 0 : roasEventsInCampaign.length)) {
        return queryMap_1.DUMMY_EXPRESSION;
    }
    if (mmpEvents === null || mmpEvents === void 0 ? void 0 : mmpEvents.length) {
        const roasEventsInCampaign_COHORT = mmpEvents.map(cohorts_1.makeCohortableCustomEventName);
        if (eventName === numericMeasures_1.Events.sales) {
            return (0, templates_1.withCondition)((0, templates_1.countMultiTemplate)(roasEventsInCampaign_COHORT), cohortCondition);
        }
        if (eventName === numericMeasures_1.Events.revenue) {
            return (0, templates_1.withCondition)((0, templates_1.countMultiValueMultiTemplate)(roasEventsInCampaign_COHORT), cohortCondition);
        }
        if (eventName === numericMeasures_1.Metrics.roas) {
            return (0, templates_1.withCondition)((0, templates_1.divTemplate)((0, templates_1.mulTemplate)((0, templates_1.countMultiValueMultiTemplate)(roasEventsInCampaign_COHORT), 1000000), (0, templates_1.aliasExprTemplate)("$SPENT")), cohortCondition, ["$div", "args", "0", "expr", "$mul", "args", "0", "expr", "$sum"]);
        }
        if (eventName === numericMeasures_1.Metrics.cpa) {
            return (0, templates_1.withCondition)((0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$SPENT"), (0, templates_1.countMultiTemplate)(roasEventsInCampaign_COHORT)), cohortCondition, ["$div", "args", "1", "expr", "$sum"]);
        }
    }
    // SKAN CPA, SKAN pCPA don't have cohorts for now
    //if (skanEvents?.length) {
    //  if (eventName === Metrics.skanCpa) { .. }
    //}
    return queryMap_1.DUMMY_EXPRESSION;
}
exports.getCohortedBorMeasureExpr = getCohortedBorMeasureExpr;
