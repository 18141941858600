import { toNumber } from "helpers/primitive";
import { SplitType } from "model/AnalyticsPage";
import { DimensionIds } from "model/reporterDb/dbTableTraffic";
import {
  EXACT_QUERY_CURRY,
  STRICT_MULTI_LIKE_QUERY_CURRY,
} from "model/reporterDb/query";

const CREATIVE_SIZE_QUERY = (bannerFilterId, videoFilterId) => (value) => ({
  $or: [
    ...STRICT_MULTI_LIKE_QUERY_CURRY(bannerFilterId)(
      value.map((v) => v && v.replace(/_/g, ""))
    ).$or,
    ...STRICT_MULTI_LIKE_QUERY_CURRY(videoFilterId)(
      value.map((v) => v && v.replace(/_/g, ""))
    ).$or,
  ],
});
const NUMERIC_EXACT_QUERY_CURRY = (filterId) => (value) =>
  EXACT_QUERY_CURRY(filterId)(value.map((v) => toNumber(v)));

export const ETrafficFilterId = {
  APP_BUNDLE: DimensionIds.APP_BUNDLE_COLUMN_ID,
  CONNECTION: DimensionIds.CONNECTION,
  GEO_COUNTRY: DimensionIds.GEO_COUNTRY,
  DEVICE_OS: DimensionIds.DEVICE_OS,
  DEVICE_VERSION: DimensionIds.DEVICE_VERSION,
  DEVICE_OS_VERSION: DimensionIds.DEVICE_OS_VERSION,
  DEVICE_TYPE: DimensionIds.DEVICE_TYPE,
  EXCHANGE: DimensionIds.EXCHANGE,
  INVENTORY_CATEGORIES: DimensionIds.INVENTORY_CATEGORIES,
  INVENTORY_CREATIVE_TYPE: DimensionIds.INVENTORY_CREATIVE_TYPE,
  INVENTORY_TYPE: DimensionIds.INVENTORY_TYPE,
  CREATIVE_FORMAT_SIZE: DimensionIds.CREATIVE_FORMAT_SIZE,
  TRACKING_ALLOWED: DimensionIds.TRACKING_ALLOWED,
  TAG_ID: DimensionIds.TAG_ID,
};

export interface ITrafficDimension extends Partial<SplitType> {
  id;
  name: string;
  type?: "Dimension" | "Granularity";
  query;
  dictionary?;
  staticDictionary?: { label: string; value: string | number }[];
  combineDimensionIds?: string[];
  isMandatory?: boolean;
  isEmptyValue?;
  tooltip?;
}

// NOTE: Values for filters without dictionary are being queried from Reporter
export const TRAFFIC_FILTERS_DICTIONARY: {
  [key: string]: ITrafficDimension;
} = {
  [ETrafficFilterId.APP_BUNDLE]: {
    id: ETrafficFilterId.APP_BUNDLE,
    name: "App Bundle Id",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.APP_BUNDLE),
  },
  [ETrafficFilterId.CONNECTION]: {
    id: ETrafficFilterId.CONNECTION,
    dictionary: "device-connection-type",
    name: "Connection",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.CONNECTION),
  },
  [ETrafficFilterId.GEO_COUNTRY]: {
    id: ETrafficFilterId.GEO_COUNTRY,
    name: "Country",
    dictionary: "country",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.GEO_COUNTRY),
  },
  [ETrafficFilterId.DEVICE_OS]: {
    id: ETrafficFilterId.DEVICE_OS,
    name: "Device OS",
    dictionary: "device-os-type",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.DEVICE_OS),
  },
  [ETrafficFilterId.DEVICE_VERSION]: {
    id: ETrafficFilterId.DEVICE_VERSION,
    name: "Device OS Minor",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.DEVICE_VERSION),
  },
  [ETrafficFilterId.DEVICE_OS_VERSION]: {
    id: ETrafficFilterId.DEVICE_OS_VERSION,
    name: "Device OS Version",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.DEVICE_OS_VERSION),
  },
  [ETrafficFilterId.DEVICE_TYPE]: {
    id: ETrafficFilterId.DEVICE_TYPE,
    name: "Device Type",
    dictionary: "device-type",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.DEVICE_TYPE),
  },
  [ETrafficFilterId.EXCHANGE]: {
    id: ETrafficFilterId.EXCHANGE,
    name: "Exchange",
    dictionary: "exchange",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.EXCHANGE),
  },
  [ETrafficFilterId.INVENTORY_CATEGORIES]: {
    id: ETrafficFilterId.INVENTORY_CATEGORIES,
    name: "Inventory Categories",
    dictionary: "categories",
    query: STRICT_MULTI_LIKE_QUERY_CURRY(ETrafficFilterId.INVENTORY_CATEGORIES),
  },
  [ETrafficFilterId.INVENTORY_CREATIVE_TYPE]: {
    id: ETrafficFilterId.INVENTORY_CREATIVE_TYPE,
    name: "Inventory Creative Type",
    dictionary: "inventory-creative-type",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.INVENTORY_CREATIVE_TYPE),
  },
  [ETrafficFilterId.INVENTORY_TYPE]: {
    id: ETrafficFilterId.INVENTORY_TYPE,
    name: "Inventory Type",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.INVENTORY_TYPE),
  },
  [ETrafficFilterId.CREATIVE_FORMAT_SIZE]: {
    id: ETrafficFilterId.CREATIVE_FORMAT_SIZE,
    name: "Creative Size",
    dictionary: "creative-sizes",
    combineDimensionIds: [
      DimensionIds.BANNER_FORMAT_SIZE,
      DimensionIds.VIDEO_PLAYER_ORIENTATION,
    ],
    type: "Dimension",
    isEmptyValue: (v) => !v || v === "0x0",
    tooltip: {
      [DimensionIds.BANNER_FORMAT_SIZE]: "Banner Format Size",
      [DimensionIds.VIDEO_PLAYER_ORIENTATION]: "Video Player Size",
    },
    query: CREATIVE_SIZE_QUERY(
      DimensionIds.BANNER_FORMAT_SIZE,
      DimensionIds.VIDEO_PLAYER_ORIENTATION
    ),
  },
  [ETrafficFilterId.TRACKING_ALLOWED]: {
    id: ETrafficFilterId.TRACKING_ALLOWED,
    name: "Has Device ID",
    staticDictionary: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    query: NUMERIC_EXACT_QUERY_CURRY(ETrafficFilterId.TRACKING_ALLOWED),
  },
  [ETrafficFilterId.TAG_ID]: {
    id: ETrafficFilterId.TAG_ID,
    name: "Tag ID",
    query: EXACT_QUERY_CURRY(ETrafficFilterId.TAG_ID),
  },
};

export enum ETrafficSplitId {
  GRANULARITY = "GRANULARITY",
}

export const TRAFFIC_SPLITS_DICTIONARY: {
  [key in ETrafficSplitId]: Partial<ITrafficDimension>;
} = {
  [ETrafficSplitId.GRANULARITY]: {
    id: ETrafficSplitId.GRANULARITY,
    name: "Time",
  },
};

const GRANULARITY_DICTIONARY = [
  { value: "ALL", id: "ALL", name: "All" },
  { value: "DAY", id: "DAY", name: "Day" },
  { value: "WEEK", id: "WEEK", name: "Week" },
];

export const GRANULARITY_DIMENSION: SplitType = {
  type: "Granularity",
  id: DimensionIds.GRANULARITY_COLUMN_ID,
  name: "Time",
  dictionary: GRANULARITY_DICTIONARY,
  isMandatory: false,
};

export const ADDITIONAL_TRAFFIC_DIMENSIONS = [
  GRANULARITY_DIMENSION,
  TRAFFIC_FILTERS_DICTIONARY.CREATIVE_FORMAT_SIZE,
];
