import CssBaseline from "@mui/material/CssBaseline";
import { LinearProgressLoader } from "components/Loaders";
import { makeStyles } from "components/mui-core";
import { SnackbarOnError } from "components/Notifications";
import InlinePageWarning from "components/Notifications/InlinePageWarning";
import React from "react";
import { useDispatch } from "react-redux";
import { authSlice, useOrgSwitchError } from "redux/auth";
import { useAccountId, useOrganizationId } from "redux/auth/selectors";
import useServiceUnavailable, {
  SERVICE_UNAVAILABLE_WARNING,
} from "redux/auth/useServiceUnavailable";
import TabContent from "../TabContent/TabContent";
import NavigationMenu from "./NavigationMenu/NavigationMenu";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

const SIDE_MENU = "ds-side-menu";

function Layout({ navigationMenuItems, rootPath, children }) {
  const classes = useStyles();
  const organizationId = useOrganizationId();
  const accountId = useAccountId();
  const orgSwitchError = useOrgSwitchError();
  const dispatch = useDispatch();

  const [isServiceUnavailable] = useServiceUnavailable();

  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const savedOpenState = localStorage.getItem(SIDE_MENU);
    if (savedOpenState) setOpen(savedOpenState === "open");
  }, []);

  const expandNavigation = () => {
    localStorage.setItem(SIDE_MENU, "open");
    setOpen(true);
  };

  const collapseNavigation = () => {
    localStorage.setItem(SIDE_MENU, "closed");
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationMenu
        open={open}
        collapseNavigation={collapseNavigation}
        expandNavigation={expandNavigation}
        menuItems={navigationMenuItems}
        rootPath={rootPath}
      />
      <TabContent isExpanded={!open}>
        {isServiceUnavailable && (
          <InlinePageWarning text={SERVICE_UNAVAILABLE_WARNING} />
        )}
        {orgSwitchError && (
          <SnackbarOnError
            isOpen
            message={orgSwitchError}
            onClose={() => {
              dispatch(authSlice.actions.setOrgSwitchError(""));
            }}
          />
        )}
        {organizationId && accountId ? children : <LinearProgressLoader />}
      </TabContent>
    </div>
  );
}

export default Layout;
