import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { ICampaignServer } from "model/campaigns";
import { fetchCampaigns } from "./campaignsActions";
import { activeSlice, archivedSlice } from "./campaignsSlice";
import { findMessage, isInconsistentSaveError } from "./serverErrors";

export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (campaign: Omit<ICampaignServer, "id">, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request.postJson(
      endpointMaker.api.campaignsByAccount(organizationId, accountId),
      campaign
    );
  }
);

//Don't use updateCampaign directly, use updateCampaignConsistently action instead
export const updateCampaign = createAsyncThunk(
  "campaigns/updateCampaign",
  async (campaign: ICampaignServer, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .postJson(
        endpointMaker.api.campaignsByAccount(organizationId, accountId),
        campaign
      )
      .catch((e) => {
        throw new Error(findMessage(e));
      });
  }
);

export const updateCampaignConsistently =
  (campaign: ICampaignServer, onError?) => async (dispatch) => {
    try {
      const response = await dispatch(updateCampaign(campaign));
      if (response.type === "campaigns/updateCampaign/rejected") {
        const e = response.error;
        if (isInconsistentSaveError(e.message)) {
          dispatch(fetchCampaigns.active());
        }
        onError?.(e);
        return Promise.reject(e);
      }

      // dispatch(
      //   updateLineitemsStatusAfterCampaignUpdate(
      //     response.payload as ICampaignServer
      //   )
      // );
      return Promise.resolve(response.payload);
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };
// function updateLineitemsStatusAfterCampaignUpdate(
//   updatedCampaign: ICampaignServer
// ) {
//   return async function (dispatch) {
//     if (updatedCampaign.isSkanSupportedCampaign) {
//       const lisToStop = getLisToStopForExploreMode(updatedCampaign);
//       lisToStop?.forEach((li) =>
//         dispatch(
//           stopLineItem({ campaignId: updatedCampaign.id, lineItemId: li.id })
//         )
//       );
//     }
//   };
// }

export type TCampaignAction = "startCampaign" | "stopCampaign";

export const getStartOrStopCampaign = (
  action: TCampaignAction,
  typePrefix: string
) =>
  createAsyncThunk(typePrefix, async (campaignId: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .get(endpointMaker.api[action](organizationId, accountId, campaignId))
      .then(() => campaignId);
  });

export const startOrStopCampaign = {
  start: getStartOrStopCampaign("startCampaign", "campaign/startCampaign"),
  stop: getStartOrStopCampaign("stopCampaign", "campaign/stopCampaign"),
};

export const startCampaign = startOrStopCampaign.start;
export const stopCampaign = startOrStopCampaign.stop;

export const archiveCampaign = createAsyncThunk(
  "campaigns/archiveCampaign",
  async ({ campaignId }: { campaignId: string; onError?: any }, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .get(
        endpointMaker.api.archiveCampaign(organizationId, accountId, campaignId)
      )
      .then(() => {
        thunkAPI.dispatch(archivedSlice.actions.resetLoadingState());

        return campaignId;
      });
  }
);

export const unarchiveCampaign = createAsyncThunk(
  "campaigns/unarchiveCampaign",
  async (campaignId: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .get(
        endpointMaker.api.unarchiveCampaign(
          organizationId,
          accountId,
          campaignId
        )
      )
      .then(() => {
        thunkAPI.dispatch(activeSlice.actions.resetLoadingState());
        return campaignId;
      });
  }
);
