import {
  abbreviateAndFormat,
  floatFormatted,
  microToDollars,
  NumberAbbreviations,
  numberFormatted,
} from "helpers";
import { Microdollars } from "model/modelTypes";

export const NAN_VALUE_LABEL = "-";

export const isInfinity = (value) => {
  return value.toString() === "Infinity";
};

export const onlyNumericCalculatePercent = (value) => {
  if (isNaN(value) || isInfinity(value)) {
    return NAN_VALUE_LABEL;
  }
  return Math.round(value * 10000) / 100;
};

export const onlyNumericPercent = (value, withSign = false) => {
  if (isNaN(value) || isInfinity(value)) {
    return NAN_VALUE_LABEL;
  }

  const v = Number(value).toFixed(2);

  return withSign ? `${v}%` : v;
};

export const onlyNumericDollar = (value) => {
  if (isNaN(value) || isInfinity(value)) {
    return NAN_VALUE_LABEL;
  }
  return microToDollars(value);
};

export const onlyNumericFloat = (value) => {
  return isNaN(value) || isInfinity(value)
    ? NAN_VALUE_LABEL
    : floatFormatted(value);
};

export const eventNumberFormatted = (
  num = 0,
  abbreviate?: boolean,
  startFrom?: NumberAbbreviations
) => {
  if (abbreviate) return abbreviateAndFormat({ num, startFrom });

  return numberFormatted(num);
};

export const eventDollarFormatted = (
  num = 0,
  abbreviate?: boolean,
  startFrom?: NumberAbbreviations
) => {
  if (abbreviate) {
    return abbreviateAndFormat({
      num: microToDollars(num as Microdollars),
      isCurrency: true,
      startFrom,
    });
  }

  return onlyNumericDollar(num);
};
