"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundUp = exports.isFloat = exports.addFloats = exports.percentage = exports.calcObjectsDelta = exports.calcDelta = exports.safeDivision = exports.booleanSort = exports.priceSort = exports.percentSort = exports.numberSort = exports.stringSort = exports.floatFormatted = exports.numberFromString = exports.toNumber = exports.FORMATTED_NUMBER_REGEXP = exports.THOUSANDS_DELIMITER_REGEXP = void 0;
exports.THOUSANDS_DELIMITER_REGEXP = new RegExp(",", "g");
// optional '$' sign, non-capturing group for 0 or more digits plus comma,
// one or more digits, optional dot followed by zero or more digits.
exports.FORMATTED_NUMBER_REGEXP = /^\$?(?:\d+,)*\d+\.?\d*$/m;
const toNumber = (numRaw, defaultValue = 0) => {
    let num = numRaw;
    if (typeof num === "string") {
        num = num.replace(exports.THOUSANDS_DELIMITER_REGEXP, "");
    }
    return Number.isFinite(parseFloat(num)) ? parseFloat(num) : defaultValue;
};
exports.toNumber = toNumber;
const numberFromString = (value) => {
    // one or more digit(s) optionally followed by a dot and zero or more digits
    // optionally started with negative sign
    const digitsOnlyRegExp = /(-?\d+\.?\d*)/m;
    if (typeof value !== "string")
        return value;
    const valueWithoutDelimiters = value.replace(exports.THOUSANDS_DELIMITER_REGEXP, "");
    const match = valueWithoutDelimiters.match(digitsOnlyRegExp);
    if (!match)
        return 0;
    const [digitsOnly] = match;
    return (0, exports.toNumber)(digitsOnly);
};
exports.numberFromString = numberFromString;
function floatFormatted(numRaw = 0, decimal = 2) {
    if (typeof numRaw === "string") {
        return parseFloat(numRaw).toLocaleString("en-US", {
            maximumFractionDigits: decimal,
        });
    }
    return numRaw.toLocaleString("en-US", { maximumFractionDigits: decimal });
}
exports.floatFormatted = floatFormatted;
const stringSort = (a, b) => {
    return a.localeCompare(b);
};
exports.stringSort = stringSort;
const numberSort = (a, b) => {
    if (!a)
        return -1;
    if (!b)
        return 1;
    return (0, exports.numberFromString)(a) - (0, exports.numberFromString)(b);
};
exports.numberSort = numberSort;
exports.percentSort = exports.numberSort;
exports.priceSort = exports.percentSort;
const booleanSort = (a) => {
    return a === true ? -1 : 1;
};
exports.booleanSort = booleanSort;
const safeDivision = (a = 0, b = 0) => {
    if (b === 0)
        return 0;
    return a / b;
};
exports.safeDivision = safeDivision;
const calcDelta = ({ prevVal, newVal }) => {
    if (prevVal === undefined || newVal === undefined) {
        return 0;
    }
    const prevNum = typeof prevVal === "string" ? (0, exports.numberFromString)(prevVal) : (0, exports.toNumber)(prevVal);
    if (prevNum === 0)
        return 0;
    const newNum = typeof newVal === "string" ? (0, exports.numberFromString)(newVal) : (0, exports.toNumber)(newVal);
    return Math.round((100 * (newNum - prevNum)) / prevNum);
};
exports.calcDelta = calcDelta;
function calcObjectsDelta({ prevData, newData, }) {
    if (!newData || !prevData) {
        return {};
    }
    return Object.entries(newData).reduce((acc, [key, val]) => {
        acc[key] = (0, exports.calcDelta)({ prevVal: prevData[key], newVal: val });
        return acc;
    }, {});
}
exports.calcObjectsDelta = calcObjectsDelta;
function percentage({ part, total, }) {
    if (!part && total) {
        return 0;
    }
    return Math.abs(100 * (0, exports.safeDivision)(part, total));
}
exports.percentage = percentage;
function addFloats(floats, decimalPlaces = 2) {
    const sum = floats.reduce((acc, float) => acc + (0, exports.toNumber)(float), 0);
    return parseFloat(sum.toFixed(decimalPlaces));
}
exports.addFloats = addFloats;
function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}
exports.isFloat = isFloat;
function roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
}
exports.roundUp = roundUp;
