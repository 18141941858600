import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import {
  fetchOptimiserRules,
  IOptimiserRule,
} from "./campaignOptimiserActions";

interface ICampaignOptimiserState {
  loadingState: LoadingState;
  data: IOptimiserRule[];
  error?: null | SerializedError;
}

const initialState: ICampaignOptimiserState = {
  loadingState: LoadingState.initial,
  data: [],
  error: null,
};

const campaignOptimiserSlice = createSlice({
  name: "campaignOptimiser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOptimiserRules.pending, (state) => {
      if (state.loadingState !== LoadingState.loaded) {
        state.loadingState = LoadingState.loading;
      }
    });
    builder.addCase(fetchOptimiserRules.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;
      state.data = action.payload;
    });
    builder.addCase(fetchOptimiserRules.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;
      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
  },
});

export default campaignOptimiserSlice.reducer;
