import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import React, { ReactNode } from "react";

type ModalOverlayProps = {
  isOpen?: boolean;
  id: string;
  className?: string;
  onClose?: (id: string) => void;
  onConfirm?: (id: string) => void;
  onCancel?: (id: string) => void;
  title?: string;
  children?: React.ReactChildren | ReactNode;
  fullScreen?: boolean;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  dataQa?: string;
  confirmDisabled?: boolean;
};
function ModalOverlay({
  isOpen,
  id,
  className,
  onClose,
  onConfirm,
  onCancel,
  title,
  fullScreen,
  children,
  confirmButtonTitle = "Confirm",
  cancelButtonTitle = "Cancel",
  dataQa,
  confirmDisabled = false,
}: ModalOverlayProps) {
  return (
    <Dialog
      open={!!isOpen}
      keepMounted
      onClose={() => onClose?.(id)}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      fullScreen={fullScreen}>
      <div data-qa={dataQa} />
      {title && (
        <DialogTitle>
          <h4>{title}</h4>
        </DialogTitle>
      )}
      <DialogContent className={className}>{children}</DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button
            onClick={() => onConfirm(id)}
            color="primary"
            data-qa="modal-btn-confirm"
            disabled={confirmDisabled}>
            {confirmButtonTitle}
          </Button>
        )}
        {onCancel && (
          <Button
            onClick={() => onCancel(id)}
            color="info">
            {cancelButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalOverlay;
