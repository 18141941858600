import * as Sentry from "@sentry/browser";
import React from "react";
import ErrorMessage from "./ErrorMessage";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo = {}) {
    Sentry.captureException(
      `Something went wrong: ${
        error?.message
      } --- ${error} +++ ${JSON.stringify(errorInfo)}`
    );
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
