"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateUtcIsBefore = exports.dateIsAfter = exports.dateIsBefore = exports.dateUtcIsBetweenDates = exports.dateIsBetweenDates = exports.utcToDate = exports.dateToUtc = exports.MIN_POSSIBLE_DATE = exports.dateSortAscByField = exports.dateSortAsc = exports.dateSortDesc = exports.formatDate = exports.namedMonthAndDayFormat = exports.namedMonthFormat = exports.longDateFormatNoSec = exports.longDateFormat = exports.timeWithSecondsFormat = exports.shortTimeFormat = exports.dateFormat = exports.timeFormat = exports.shortYearMonAndDayFormat = exports.monDayAndTimeFormat = exports.monAndDayFormat = exports.parseDate = void 0;
const date_fns_1 = require("date-fns");
const parseDate = (date, format) => {
    if (!date)
        return null;
    if (date instanceof Date)
        return date;
    if (typeof date === "number")
        return (0, date_fns_1.toDate)(date);
    // timestamp's length is 13 symbols (+new Date() or Date.now())
    if (typeof date === "string" && /^\d{13}$/.test(date)) {
        return (0, date_fns_1.parse)(date, "T", new Date());
    }
    if (format)
        return (0, date_fns_1.parse)(date, format, new Date());
    return (0, date_fns_1.parseISO)(date, { additionalDigits: 2 });
};
exports.parseDate = parseDate;
exports.monAndDayFormat = "MMM dd";
exports.monDayAndTimeFormat = `${exports.monAndDayFormat} HH:mm`;
exports.shortYearMonAndDayFormat = `yy ${exports.monAndDayFormat}`;
exports.timeFormat = "HH:mm";
exports.dateFormat = "dd/MM/yyyy";
exports.shortTimeFormat = "H:mm";
exports.timeWithSecondsFormat = "HH:mm:ss";
exports.longDateFormat = `yyyy-MM-dd ${exports.timeWithSecondsFormat}`;
exports.longDateFormatNoSec = `yyyy-MM-dd ${exports.timeFormat}`;
exports.namedMonthFormat = "dd MMM yyyy";
exports.namedMonthAndDayFormat = "MMM dd yyyy";
const formatDate = (date, format = exports.dateFormat) => {
    if (!date)
        return "";
    if (typeof date === "string")
        return date;
    try {
        const formattedDate = (0, date_fns_1.format)(date, format);
        return formattedDate;
    }
    catch (error) {
        //
    }
    return "";
};
exports.formatDate = formatDate;
const dateSortDesc = (format = "yyyy-MM-dd HH:mm:ss") => (d1, d2) => (0, date_fns_1.compareDesc)((0, date_fns_1.parse)(d1, format, new Date()), (0, date_fns_1.parse)(d2, format, new Date()));
exports.dateSortDesc = dateSortDesc;
const dateSortAsc = (format = "yyyy-MM-dd HH:mm:ss") => (d1, d2) => (0, date_fns_1.compareAsc)((0, date_fns_1.parse)(d1, format, new Date()), (0, date_fns_1.parse)(d2, format, new Date()));
exports.dateSortAsc = dateSortAsc;
const dateSortAscByField = (dateField, format = "yyyy-MM-dd HH:mm:ss") => (d1, d2) => (0, date_fns_1.compareAsc)((0, date_fns_1.parse)(d1[dateField], format, new Date()), (0, date_fns_1.parse)(d2[dateField], format, new Date()));
exports.dateSortAscByField = dateSortAscByField;
exports.MIN_POSSIBLE_DATE = new Date("1/1/2019");
// TODO: Might need to rework because if date is string then we might "add" 2 times the UTC
const dateToUtc = (date) => (0, date_fns_1.addMinutes)(typeof date === "string" ? new Date(date) : date, -new Date(date).getTimezoneOffset());
exports.dateToUtc = dateToUtc;
// TODO: Might need to rework because if date is string then we might "add" 2 times the UTC
const utcToDate = (date) => (0, date_fns_1.addMinutes)(typeof date === "string" ? new Date(date) : date, new Date(date).getTimezoneOffset());
exports.utcToDate = utcToDate;
const dateIsBetweenDates = (date, from, to) => new Date(date).getTime() >= new Date(from).getTime() &&
    new Date(date).getTime() <= new Date(to).getTime();
exports.dateIsBetweenDates = dateIsBetweenDates;
const dateUtcIsBetweenDates = (date, from, to) => (0, exports.dateIsBetweenDates)((0, exports.dateToUtc)(date), (0, exports.dateToUtc)(from), (0, exports.dateToUtc)(to));
exports.dateUtcIsBetweenDates = dateUtcIsBetweenDates;
const dateIsBefore = (date, compareTo) => new Date(date).getTime() < new Date(compareTo).getTime();
exports.dateIsBefore = dateIsBefore;
const dateIsAfter = (date, compareTo) => new Date(date).getTime() > new Date(compareTo).getTime();
exports.dateIsAfter = dateIsAfter;
const dateUtcIsBefore = (date, compareTo) => (0, exports.dateIsBefore)((0, exports.dateToUtc)(date), (0, exports.dateToUtc)(compareTo));
exports.dateUtcIsBefore = dateUtcIsBefore;
