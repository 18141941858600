import { parseWithNames } from "model/report/normalization";
import fetchReport from "./fetchReport";

type TFetchFlatReportParsedArgs = {
  query;
  eventNames: string[];
  signal?: AbortSignal;
};
export const fetchFlatReportParsed = <T>({
  query,
  eventNames,
  signal,
}: TFetchFlatReportParsedArgs): Promise<T> => {
  if (!query) {
    return Promise.reject({ message: "Empty report query" });
  }

  return fetchReport(query, signal)
    .then((resp) => resp.text())
    .then((rawData) => parseWithNames(rawData, eventNames) as T);
};
