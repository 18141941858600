"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrafficDimensionIds = void 0;
//Dimensions: columns in TRAFFIC table
exports.TrafficDimensionIds = {
    GRANULARITY_COLUMN_ID: "GRANULARITY",
    APP_BUNDLE_COLUMN_ID: "APP_BUNDLE",
    CONNECTION: "CONNECTION",
    GEO_COUNTRY: "GEO_COUNTRY",
    DEVICE_OS: "DEVICE_OS",
    DEVICE_VERSION: "DEVICE_VERSION",
    DEVICE_OS_VERSION: "DEVICE_OS_VERSION",
    DEVICE_TYPE: "DEVICE_TYPE",
    EXCHANGE: "EXCHANGE",
    INVENTORY_CATEGORIES: "INVENTORY_CATEGORIES",
    INVENTORY_CREATIVE_TYPE: "INVENTORY_CREATIVE_TYPE",
    INVENTORY_TYPE: "INVENTORY_TYPE",
    CREATIVE_FORMAT_SIZE: "CREATIVE_FORMAT_SIZE",
    BANNER_FORMAT_SIZE: "BANNER_FORMAT_SIZE",
    VIDEO_PLAYER_ORIENTATION: "VIDEO_PLAYER_ORIENTATION",
    TRACKING_ALLOWED: "TRACKING_ALLOWED",
    TAG_ID: "TAG_ID",
    BUCKET_BID_FLOOR_COLUMN_ID: "BUCKET_BID_FLOOR",
    LOG_VOLUME_COLUMN_ID: "LOG_VOLUME",
};
