import { dbTableCampaign } from "@dataseat-dsp-client/lib-reporter";
import { safeDivision } from "helpers";

export const CTRRaw = dbTableCampaign.CTRRaw;
export const CTR = dbTableCampaign.CTR;
export const eCPM = dbTableCampaign.eCPM;
export const eCPMfromRawSpend = dbTableCampaign.eCPMfromRawSpend;
export const winrateRaw = dbTableCampaign.winrateRaw;
export const winrate = dbTableCampaign.winrate;
export const IPM = dbTableCampaign.IPM;
export const SKANIPM = dbTableCampaign.SKANIPM;
export const ROAS = dbTableCampaign.ROAS;
export const CPC = dbTableCampaign.CPC;
export const IR = dbTableCampaign.IR;
export const CPI = dbTableCampaign.CPI;
export const CPR = dbTableCampaign.CPR;
export const eCPC = dbTableCampaign.eCPC;
export const eCPI = dbTableCampaign.eCPI;
export const ecpiUnsafe = dbTableCampaign.ecpiUnsafe;
export const CVR = dbTableCampaign.CVR;
export const CTI = dbTableCampaign.CTI;
export const SKANCTI = dbTableCampaign.SKANCTI;
export const averageRPI = dbTableCampaign.averageRPI;
export const SKANCPI = dbTableCampaign.SKANCPI;
export const SKANIR = dbTableCampaign.SKANIR;
export const bidFloor = dbTableCampaign.bidFloor;
export const skanFineRatio = ({
  skanFineRatioDividend,
  skanFineRatioDivisor,
}) => safeDivision(skanFineRatioDividend / skanFineRatioDivisor);
// export const skanCoarseRatio = "skanCoarseRatio",
// export const skanDigit1Ratio = "skanDigit1Ratio",
// export const skanDigit2Ratio = "skanDigit2Ratio",
// export const skanSourceAppIdRatio = "skanSourceAppIdRatio",

export type TCalculateMetric = dbTableCampaign.TCalculateMetric;

export const calculateMetric = dbTableCampaign.calculateMetric;
export const calculateMetricUnsafe = dbTableCampaign.calculateMetricUnsafe;

export const ADDITIVE_METRICS = dbTableCampaign.ADDITIVE_METRICS;
