import endpointMaker from "configuration/endpoints";
import request from "http/request";

const REQUEST_DIMENSIONS = "REQUEST_DIMENSIONS";
const FAILED_DIMENSIONS = "FAILED_DIMENSIONS";
function action(type) {
  return {
    type,
  };
}

const OK_DIMENSIONS = "OK_DIMENSIONS";
function handleOkDimensions(table, data) {
  return {
    type: OK_DIMENSIONS,
    table,
    data,
  };
}

function getDimensions(table) {
  return function (dispatch) {
    dispatch(action(REQUEST_DIMENSIONS));
    if (!table) {
      dispatch(action(FAILED_DIMENSIONS));
    }

    request
      .getJson(endpointMaker.reporter.dimensions(table))
      .then((data) => dispatch(handleOkDimensions(table, data)))
      .catch(() => dispatch(action(FAILED_DIMENSIONS)));
  };
}

export const NAMES = {
  OK_DIMENSIONS,
};

export const actions = {
  getDimensions,
};
