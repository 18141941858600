"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUERY_DISTINCT_VALUES = exports.CAMPAIGN_SELECT_QUERY_MAP = void 0;
const dbSchema_1 = require("../dbSchema");
const queryMap_1 = require("../query/queryMap");
const templates_1 = require("../query/templates");
const dimensions_1 = require("./dimensions");
const numericMeasures_1 = require("./numericMeasures");
const PV_INSTALL_RELATED_EVENTS = [
    "PVA_INSTALL",
    "PVA30_INSTALL",
    "PVN_INSTALL",
];
const PC_INSTALL_RELATED_EVENTS = ["PCA_INSTALL", "PCN_INSTALL"];
const INSTALL_RELATED_EVENTS = [
    ...PV_INSTALL_RELATED_EVENTS,
    ...PC_INSTALL_RELATED_EVENTS,
];
const bidfloorSumDividend = {
    expr: {
        $sum: {
            args: [
                {
                    expr: {
                        $if: {
                            args: [
                                [
                                    {
                                        $eq: ["EVENT_NAME", "impression"],
                                    },
                                ],
                                {
                                    expr: {
                                        $mul: {
                                            args: ["NORMALIZED_BID_FLOOR", "EVENT_COUNT"],
                                        },
                                    },
                                },
                                {
                                    expr: {
                                        $mul: {
                                            args: [0, "EVENT_COUNT"],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
};
exports.CAMPAIGN_SELECT_QUERY_MAP = {
    //Check formulas under aliases here https://git.dataseat.com/xenoss-team/dataseat-aggregator/-/blob/master/xenoss-reporter-legacy/src/main/resources/reporter/functions.conf
    [numericMeasures_1.Events.bid]: (0, templates_1.aliasExprTemplate)("$BIDS"),
    [numericMeasures_1.Events.impression]: (0, templates_1.aliasExprTemplate)("$IMPRESSIONS"),
    [numericMeasures_1.Events.click]: (0, templates_1.aliasExprTemplate)("$CLICKS"),
    [numericMeasures_1.Events.pvInstall]: (0, templates_1.aliasExprTemplate)("$PV_INSTALL"),
    [numericMeasures_1.Events.pcInstall]: (0, templates_1.aliasExprTemplate)("$PC_INSTALL"),
    [numericMeasures_1.Events.mmpInstall]: (0, templates_1.aliasExprTemplate)("$MMP_INSTALLS"),
    [numericMeasures_1.Events.pvaInstall]: (0, templates_1.aliasExprTemplate)("$PVA_INSTALL"),
    [numericMeasures_1.Events.pcaInstall]: (0, templates_1.aliasExprTemplate)("$PCA_INSTALLS"),
    [numericMeasures_1.Events.pvnInstall]: (0, templates_1.aliasExprTemplate)("$PVN_INSTALLS"),
    [numericMeasures_1.Events.pcnInstall]: (0, templates_1.aliasExprTemplate)("$PCN_INSTALLS"),
    [numericMeasures_1.Events.installDeprecated]: Object.assign(Object.assign({}, (0, templates_1.countMultiTemplate)(INSTALL_RELATED_EVENTS)), { alias: "install" }),
    [numericMeasures_1.Events.skadInstall]: (0, templates_1.aliasExprTemplate)("$SKAD_INSTALL"),
    [numericMeasures_1.Events.skanAssist]: (0, templates_1.aliasExprTemplate)("$SKAD_ASSIST"),
    [numericMeasures_1.Events.skanRedownload]: (0, templates_1.aliasExprTemplate)("$SKAD_REDOWNLOAD"),
    [numericMeasures_1.Events.skadSecondPostback]: (0, templates_1.aliasExprTemplate)("$SKAD_SECOND_POSTBACK"),
    [numericMeasures_1.Events.skadThirdPostback]: (0, templates_1.aliasExprTemplate)("$SKAD_THIRD_POSTBACK"),
    [numericMeasures_1.Events.spend]: (0, templates_1.aliasExprTemplate)("$SPENT"),
    [numericMeasures_1.Events.reattribution]: (0, templates_1.aliasExprTemplate)("$REATTRIBUTION"),
    [numericMeasures_1.Events.rejectedInstall]: (0, templates_1.countTemplate)("rejectedInstall"),
    [numericMeasures_1.Metrics.ecpm]: (0, templates_1.aliasExprTemplate)("$eCPM"),
    [numericMeasures_1.Metrics.ctr]: (0, templates_1.aliasExprTemplate)("$CTR"),
    [numericMeasures_1.Metrics.ecpc]: (0, templates_1.aliasExprTemplate)("$eCPC"),
    [numericMeasures_1.Metrics.cti]: Object.assign(Object.assign({}, (0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$MMP_INSTALLS"), (0, templates_1.aliasExprTemplate)("$CLICKS"))), { alias: "cti" }),
    [numericMeasures_1.Metrics.ir]: (0, templates_1.aliasExprTemplate)("$IR"),
    [numericMeasures_1.Metrics.ecpi]: (0, templates_1.aliasExprTemplate)("$eCPI"),
    [numericMeasures_1.Metrics.skanCPI]: (0, templates_1.aliasExprTemplate)("$SKAD_CPI"),
    [numericMeasures_1.Metrics.skanCTI]: Object.assign(Object.assign({}, (0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$SKAD_INSTALL"), (0, templates_1.aliasExprTemplate)("$CLICKS"))), { alias: "skanCTI" }),
    [numericMeasures_1.Metrics.skanIR]: (0, templates_1.aliasExprTemplate)("$SKAD_IR"),
    [numericMeasures_1.Metrics.winrate]: (0, templates_1.aliasExprTemplate)("$WINRATE"),
    [numericMeasures_1.Metrics.cpr]: (0, templates_1.aliasExprTemplate)("$CPR"),
    [numericMeasures_1.Metrics.cvr]: (0, templates_1.aliasExprTemplate)("$IR"),
    [numericMeasures_1.Metrics.ipm]: (0, templates_1.aliasExprTemplate)("$IPM"),
    [numericMeasures_1.Metrics.skanIPM]: (0, templates_1.aliasExprTemplate)("$SKAD_IPM"),
    [numericMeasures_1.NumericDimensions.priceOriginal]: Object.assign(Object.assign({}, (0, templates_1.sumDimensionTemplate)(dimensions_1.DimensionIds.PRICE_ORIGINAL)), { alias: "priceOriginal" }),
    [numericMeasures_1.Metrics.bidfloorSumDividend]: Object.assign(Object.assign({}, bidfloorSumDividend), { alias: "bidfloorSumDividend" }),
    [numericMeasures_1.Metrics.bidFloor]: Object.assign(Object.assign({}, (0, templates_1.divTemplate)(bidfloorSumDividend, {
        expr: {
            $div: {
                args: [(0, templates_1.aliasExprTemplate)("$IMPRESSIONS"), 1000000],
            },
        },
    })), { alias: "bidfloorsum" }),
    [numericMeasures_1.Metrics.priceOriginalSum]: {
        expr: {
            $sum: {
                args: ["PRICE_ORIGINAL"],
            },
        },
        alias: "priceOriginalSum",
    },
    [numericMeasures_1.Metrics.commission]: (0, templates_1.aliasExprTemplate)("$COMMISSION"),
    [numericMeasures_1.Metrics.delta]: (0, templates_1.aliasExprTemplate)("$MARGIN"),
    //skan ratios
    [numericMeasures_1.Metrics.skanFineRatio]: (0, templates_1.aliasExprTemplate)("$SKAN_FINE_RATIO"),
    [numericMeasures_1.Metrics.skanCoarseRatio]: (0, templates_1.aliasExprTemplate)("$SKAN_COARSE_RATIO"),
    [numericMeasures_1.Metrics.skanDigit1Ratio]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT1_RATIO"),
    [numericMeasures_1.Metrics.skanDigit2Ratio]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT2_RATIO"),
    [numericMeasures_1.Metrics.skanSourceAppIdRatio]: (0, templates_1.aliasExprTemplate)("$SOURCE_APP_RATIO"),
    [numericMeasures_1.Metrics.skanTier0Ratio]: (0, templates_1.aliasExprTemplate)("$SKAD_TIER_0_RATIO"),
    //skan ratios utility metrics
    [numericMeasures_1.Metrics.skanFineRatioDividend]: (0, templates_1.aliasExprTemplate)("$SKAN_FINE_RATIO_DIVIDEND"),
    [numericMeasures_1.Metrics.skanCoarseRatioDividend]: (0, templates_1.aliasExprTemplate)("$SKAN_COARSE_RATIO_DIVIDEND"),
    [numericMeasures_1.Metrics.skanDigit1RatioDividend]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT1_RATIO_DIVIDEND"),
    [numericMeasures_1.Metrics.skanDigit2RatioDividend]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT2_RATIO_DIVIDEND"),
    [numericMeasures_1.Metrics.skanSourceAppIdRatioDividend]: (0, templates_1.aliasExprTemplate)("$SOURCE_APP_RATIO_DIVIDEND"),
    [numericMeasures_1.Metrics.skanFineRatioDivisor]: (0, templates_1.aliasExprTemplate)("$SKAN_FINE_RATIO_DIVISOR"),
    [numericMeasures_1.Metrics.skanCoarseRatioDivisor]: (0, templates_1.aliasExprTemplate)("$SKAN_COARSE_RATIO_DIVISOR"),
    [numericMeasures_1.Metrics.skanDigit1RatioDivisor]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT1_RATIO_DIVISOR"),
    [numericMeasures_1.Metrics.skanDigit2RatioDivisor]: (0, templates_1.aliasExprTemplate)("$SKAN_DIGIT2_RATIO_DIVISOR"),
    [numericMeasures_1.Metrics.skanSourceAppIdRatioDivisor]: (0, templates_1.aliasExprTemplate)("$SOURCE_APP_RATIO_DIVISOR"),
};
function QUERY_DISTINCT_VALUES({ columnName, campaignId, lineitemId, dateRange, whereExpr, limit, }) {
    const query = {
        from: dbSchema_1.ReporterTables.Campaign,
        groupBy: [columnName],
    };
    if (campaignId) {
        query.where = [{ $eq: ["CAMPAIGN_ID", campaignId] }];
        if (lineitemId) {
            if (typeof lineitemId === "string") {
                query.where.push({ $eq: ["LINEITEM_ID", lineitemId] });
            }
            else if (lineitemId.length) {
                query.where.push({ $in: ["LINEITEM_ID", lineitemId] });
            }
        }
    }
    if (whereExpr) {
        query.where = [...(query.where || []), whereExpr];
    }
    if (dateRange) {
        query.where = [
            ...(query.where || []),
            (0, queryMap_1.TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED)(dateRange.startDate, dateRange.endDate),
        ];
    }
    if (limit) {
        query.limit = limit;
    }
    return query;
}
exports.QUERY_DISTINCT_VALUES = QUERY_DISTINCT_VALUES;
