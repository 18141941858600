import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import { minutesToMilliseconds } from "date-fns";
import request from "http/request";
import { TCreative } from "model/creative";
import { ICreativeApprovalStatusServer } from "model/creative/creativeApprovalStatus";
import { IRootState } from "redux/reducer";
import { creativesSlice } from "./creativesSlice";

export const createCreative = createAsyncThunk(
  "creatives/createCreative",
  async ({ creative }: { creative: TCreative }, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    if (!organizationId || !accountId) return null;

    return request.postJson(
      endpointMaker.api.newCreativeByAccount(organizationId, accountId),
      creative
    );
  }
);

export const updateCreative = createAsyncThunk(
  "creatives/updateCreative",
  async ({ creative }: { creative: TCreative }, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    if (!organizationId || !accountId || !creative.id) return null;

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { approvalStatus, ...cleanCreative } = creative;

    return request.postJson(
      endpointMaker.api.updateCreativeByAccount(organizationId, accountId),
      cleanCreative
    );
  }
);

export const loadCreativeApprovalStatus = createAsyncThunk(
  "creatives/loadCreativeApprovalStatus",
  async (creativeId: string, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;

    const { organizationId, accountId } = state.auth;
    if (!organizationId || !accountId) return null;

    return request.getJson(
      endpointMaker.api.creativeCheckGoogleApproval({
        organizationId,
        accountId,
        creativeId,
      })
    );
  }
);

const DEFAULT_FETCH_INTERVAL = 60;

export const loadCreativeListApprovalStatuses = createAsyncThunk(
  "creatives/loadCreativeListApprovalStatuses",
  async (arg, thunkAPI): Promise<ICreativeApprovalStatusServer[] | null> => {
    const state: IRootState = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    if (!organizationId || !accountId) return null;

    const lastFetched = state.creatives.lastCreativesApprovalFetch || 0;

    const now = +new Date();
    if (now - lastFetched < minutesToMilliseconds(DEFAULT_FETCH_INTERVAL))
      return null;

    return request.getJson(
      endpointMaker.api.creativeCheckGoogleApproval({
        organizationId,
        accountId,
      })
    );
  }
);

export const loadActiveCreatives = createAsyncThunk(
  "creatives/loadActiveCreatives",
  async (shouldLoadApprovalStatuses: boolean, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;
    const dispatch = thunkAPI.dispatch;
    const { organizationId, accountId } = state.auth;

    if (!organizationId || !accountId) return null;

    return request
      .getJson(endpointMaker.api.creativesByAccount(organizationId, accountId))
      .then((data) => {
        if (shouldLoadApprovalStatuses) {
          dispatch(loadCreativeListApprovalStatuses());
        }
        return data;
      });
  }
);

export const loadArchivedCreatives = createAsyncThunk(
  "creatives/loadArchivedCreatives",
  async (arg, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;

    const { organizationId, accountId } = state.auth;
    if (!organizationId || !accountId) return null;

    return request.getJson(
      endpointMaker.api.creativesByAccount(organizationId, accountId, true)
    );
  }
);

export const archiveCreative = createAsyncThunk(
  "creatives/archiveCreative",
  async (creativeId: string, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;

    const { organizationId, accountId } = state.auth;
    if (!organizationId || !accountId || !creativeId) return null;

    return request
      .get(
        endpointMaker.api.archiveCreative(organizationId, accountId, creativeId)
      )
      .then(() => creativeId);
  }
);

export const unarchiveCreative = createAsyncThunk(
  "creatives/unarchiveCreative",
  async (creativeId: string, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;

    const { organizationId, accountId } = state.auth;
    if (!organizationId || !accountId || !creativeId) return null;

    return request
      .get(
        endpointMaker.api.unarchiveCreative(
          organizationId,
          accountId,
          creativeId
        )
      )
      .then(() => creativeId);
  }
);

export const submitCreativeForApproval = createAsyncThunk(
  "creatives/submitCreativeForApproval",
  async (creativeId: string, thunkAPI) => {
    const state: IRootState = thunkAPI.getState() as IRootState;
    const dispatch = thunkAPI.dispatch;

    const { organizationId, accountId } = state.auth;
    if (!organizationId || !accountId || !creativeId) return null;

    const apiConfig = { organizationId, accountId, creativeId };

    const { creativeCheckClickThroughUrl, creativeSubmitForApproval } =
      endpointMaker.api;

    await request.getJson(creativeCheckClickThroughUrl(apiConfig));

    const submitted = await request.getJson(
      creativeSubmitForApproval(apiConfig)
    );

    if (!submitted) return null;

    dispatch(
      creativesSlice.actions.updateCreativeApprovalStatus({
        creativeId,
        approvalStatus: {
          label: "SUBMITTED",
          timestamp: +new Date(),
        },
      })
    );
  }
);
