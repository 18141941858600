import { unprintableChars } from "helpers";

export const normalizeAppData = (appData) => {
  if (appData.image) {
    appData.logo = appData.image;
  }
  if (appData.offeredBy) {
    appData.company = appData.offeredBy;
  }
  if (appData.ratingValue) {
    appData.rating = appData.ratingValue;
  }
  if (appData.name) {
    appData.name = appData.name.replace(unprintableChars, "");
  }
  return appData;
};

export const getAppFromLocalStorage = (bundleId) =>
  JSON.parse(localStorage.getItem(`bundleId-${bundleId}`) || '""');
