import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type IAnlyticsDimension = analyticsReport.IAnlyticsDimension;
export const GRANULARITY_DICTIONARY = analyticsReport.GRANULARITY_DICTIONARY;
export const GRANULARITY_DIMENSION = analyticsReport.GRANULARITY_DIMENSION;
export const DEAL_ID_DIMENSION = analyticsReport.DEAL_ID_DIMENSION;
export const NETWORK_ANONYMITY_DIMENSION =
  analyticsReport.NETWORK_ANONYMITY_DIMENSION;
export const ANONYMITY_SERVICE_DIMENSION =
  analyticsReport.ANONYMITY_SERVICE_DIMENSION;
