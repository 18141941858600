import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { primaryColor } from "vendor/colors";
import Linear from "./Linear";

type Props = {
  value: number;
};
function LinearLabeledLoader({ value, ...props }: Props) {
  return (
    <Box
      display="flex"
      alignItems="center">
      <Box
        width="100%"
        mr={1}>
        <Linear
          variant="determinate"
          value={value}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          style={{ color: primaryColor[0] }}>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearLabeledLoader;
