import CancelIcon from "@mui/icons-material/Cancel";
import cx from "classnames";
import { InputAdornment, makeStyles } from "components/mui-core";
import React from "react";
import { CustomInput } from "vendor/components";

const useOwnStyles = makeStyles({
  wrapper: {
    // Needed for endAdorment properly to be placed inside old react table
    ".ReactTable .rt-th & div": {
      display: "flex",
    },
    padding: 0,
    margin: 0,
  },
  input: {
    width: "100%",
  },
  clearInputIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "0",
    transform: "scale(0.85)",
  },
});

const INITIAL_FILTER_VALUE = "";

interface ICustomFilterProps {
  clearOnUnmount?: boolean;
  customInputClass?: string;
  customWrapperClass?: string;
  onFilter: (val) => void;
  placeholder?: string;
  value?: string;
  focus?: boolean;
  onKeyDown?: (e: KeyboardEvent) => void;
  isMultiValue?: boolean;
  shouldShowCleanIcon?: boolean;
  dataQa?: string;
}

const CustomFilter = ({
  clearOnUnmount,
  customWrapperClass,
  customInputClass,
  onFilter,
  onKeyDown,
  placeholder = "Filter",
  value,
  focus,
  isMultiValue,
  shouldShowCleanIcon,
  dataQa,
}: ICustomFilterProps) => {
  const ownClasses = useOwnStyles();
  const filterRef = React.useRef<HTMLInputElement>(null);

  const [filterValue, setFilterValue] =
    React.useState<string>(INITIAL_FILTER_VALUE);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);
    if (isMultiValue) {
      const values = value.split(/[, \r\n]/g)?.filter(Boolean);
      onFilter(values);
    } else {
      onFilter(value);
    }
  };

  const clearInput = () => {
    setFilterValue(INITIAL_FILTER_VALUE);
    onFilter(isMultiValue ? [] : INITIAL_FILTER_VALUE);
  };

  React.useEffect(() => {
    if (focus) {
      if (filterRef.current) {
        filterRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  React.useEffect(() => {
    if (value !== undefined) {
      setFilterValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    // Unfilter parent on unmount, cause filterValue is cleared
    if (clearOnUnmount) {
      return () => onFilter([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomInput
      formControlProps={{
        className: cx(ownClasses.wrapper, customWrapperClass),
        fullWidth: true,
      }}
      inputProps={{
        className: cx(ownClasses.input, customInputClass),
        onChange: handleChange,
        onKeyDown,
        value: filterValue,
        placeholder: placeholder,
        inputProps: {
          "aria-label": "Filter",
          "data-qa": dataQa,
          ref: filterRef,
        },
        ...(shouldShowCleanIcon
          ? {
              endAdornment: filterValue ? (
                <InputAdornment position="end">
                  <CancelIcon
                    onClick={clearInput}
                    className={ownClasses.clearInputIcon}
                  />
                </InputAdornment>
              ) : null,
            }
          : {}),
      }}
    />
  );
};

export default CustomFilter;
