import { StyledEngineProvider } from "@mui/material/styles";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { SnackbarProvider } from "components/Notifications/SnackbarProvider";
import RouteAgnosticDialogs from "components/RouteAgnosticDialogs";
import { AnimateSharedLayout } from "framer-motion";
import { createIDBPersister } from "helpers/useQuery/idbPersister";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { RecoilRoot } from "recoil";
import store from "redux/store";
import Routes from "routing/Routes";
import history from "routing/history";
import ThemeProvider from "styles/ThemeProvider";

/* Query setup */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10 * 1000,
      gcTime: 10 * 1000, // Refer to README for explanations
    },
  },
});

const persister = createIDBPersister();

class App extends React.PureComponent {
  render() {
    return (
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}>
        <ErrorBoundary>
          <AnimateSharedLayout type="crossfade">
            <Router history={history}>
              <LastLocationProvider>
                <ThemeProvider>
                  <StyledEngineProvider injectFirst>
                    <Provider store={store}>
                      <SnackbarProvider>
                        <RouteAgnosticDialogs />
                        <RecoilRoot>
                          <Routes />
                        </RecoilRoot>
                      </SnackbarProvider>
                    </Provider>
                  </StyledEngineProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </Router>
          </AnimateSharedLayout>
        </ErrorBoundary>
      </PersistQueryClientProvider>
    );
  }
}

export default App;
