import endpointMaker from "configuration/endpoints";
import request from "http/request";
import {
  getAppleLink,
  getGoogleLink,
  isAppleLink,
  isGoogleLink,
} from "./linkOrigin";

export enum LINK_TYPES {
  INVALID = "INVALID",
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
}

const ID_PATTERNS = {
  APPLE: /^(\d+)$/,
  GOOGLE: /(.*?)($|&)/,
};

const getTypeById = (bundleId) => {
  return ID_PATTERNS.APPLE.test(bundleId)
    ? LINK_TYPES.APPLE
    : LINK_TYPES.GOOGLE;
};

const getBundleId = (link, type) => {
  let match;
  if (type === LINK_TYPES.APPLE) {
    if (link.indexOf("id") !== -1) {
      match = link.match(/(id(?:\d+))/);
      if (match && match[1]) {
        return match[1].replace("id", "");
      }
    }
    const linkParts = link.split("/");
    return linkParts[linkParts.length - 1];
  } else if (type === LINK_TYPES.GOOGLE) {
    match = link.match(/id=(.*?)($|&)/);
    if (match && match[1]) {
      return match[1];
    }
  }

  return "";
};

const appleIdRegexp = /^(id|ID)?\d+$/;
const cleanAppleIdRegex = /^(?:id|ID)/g;

const fetchAppStoreData = (trackId, retryWithID = true) => {
  return request
    .getJson(endpointMaker.apps.appById(retryWithID ? `id${trackId}` : trackId))
    .then(([result]) => {
      const details = { bundleId: trackId.replace(cleanAppleIdRegex, "") };
      if (result) {
        return {
          bundleId: result.id.replace(cleanAppleIdRegex, ""),
          name: result.name,
          company: result.offeredBy,
          logo: result.image,
          rating: Math.round(result.ratingCount),
        };
      } else if (retryWithID) {
        return fetchAppStoreData(`id${trackId}`, false);
      }
      // eslint-disable-next-line
      console.log(`error fetching by trackId ${trackId}`); //TODO add internal error system
      return details;
    });
};

const getAppStoreData = (trackId) => fetchAppStoreData(trackId, true);

const getGooglePlayData = (bundleId) =>
  request.getJson(endpointMaker.apps.appById(bundleId)).then(([result]) => {
    if (!result) {
      // eslint-disable-next-line
      console.log(`error fetching by bundleId ${bundleId}`); //TODO add internal error system
      return { bundleId };
    } else if (result.error) {
      // eslint-disable-next-line
      console.log(
        `${result.error.status} error fetching by bundleId ${bundleId}`
      ); //add internal error system
      return { bundleId };
    } else if (result) {
      return {
        bundleId,
        name: result.name,
        company: result.offeredBy,
        logo: result.image,
        rating: Math.round(result.ratingCount),
      };
    }
  });
/* eslint-enable no-console */

export const getAppData = (link, type) => {
  const id = getBundleId(link, type);
  if (type === LINK_TYPES.APPLE) {
    return getAppStoreData(id);
  } else if (type === LINK_TYPES.GOOGLE) {
    return getGooglePlayData(id);
  }
};

export const getLinkType = (link): LINK_TYPES =>
  isAppleLink(link)
    ? LINK_TYPES.APPLE
    : isGoogleLink(link)
    ? LINK_TYPES.GOOGLE
    : LINK_TYPES.INVALID;

export const storeAppData = (appData) => {
  localStorage.setItem(`bundleId-${appData.bundleId}`, JSON.stringify(appData));
};

export const getAppDataById = (bundleId) => {
  const type = getTypeById(bundleId);
  const link =
    type === LINK_TYPES.APPLE
      ? getAppleLink(bundleId)
      : getGoogleLink(bundleId);
  return getAppData(link, type);
};

export const isBundleIdOfApple = (bundleId) => appleIdRegexp.test(bundleId);
