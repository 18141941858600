import TimerIcon from "@mui/icons-material/Timer";
import { makeStyles } from "@mui/styles";
import { Integer } from "model/modelTypes";
import React from "react";
import { grayColor } from "vendor/colors";

const useStyles = makeStyles({
  root: {
    whiteSpace: "pre",
    display: "flex",
    alignItems: "center",
    color: grayColor[2],
  },
});

type Props = {
  seconds: Integer;
  onExpire: () => void;
};
function NewAttemptTimeout({ seconds, onExpire }: Props) {
  const classes = useStyles();
  const [wait, setWait] = React.useState(seconds);
  React.useEffect(
    () => {
      let timeoutId;
      if (wait > 0) {
        timeoutId = setTimeout(() => setWait(wait - 1), 1000);
      } else if (wait === 0) {
        onExpire();
      }
      return () => clearTimeout(timeoutId);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [wait]
  );

  return wait ? (
    <div className={classes.root}>
      <TimerIcon />
      Please wait {wait} seconds
    </div>
  ) : null;
}

export default NewAttemptTimeout;
