import type { Integer, Percentage } from "model/modelTypes";

export const THOUSANDS_DELIMITER_REGEXP = new RegExp(",", "g");

// optional '$' sign, non-capturing group for 0 or more digits plus comma,
// one or more digits, optional dot followed by zero or more digits.
export const FORMATTED_NUMBER_REGEXP = /^\$?(?:\d+,)*\d+\.?\d*$/m;

export const toNumber = (numRaw, defaultValue = 0) => {
  let num = numRaw;
  if (typeof num === "string") {
    num = num.replace(THOUSANDS_DELIMITER_REGEXP, "");
  }
  return Number.isFinite(parseFloat(num)) ? parseFloat(num) : defaultValue;
};

export const numberFromString = (value) => {
  // one or more digit(s) optionally followed by a dot and zero or more digits
  // optionally started with negative sign
  const digitsOnlyRegExp = /(-?\d+\.?\d*)/m;

  if (typeof value !== "string") return value;

  const valueWithoutDelimiters = value.replace(THOUSANDS_DELIMITER_REGEXP, "");
  const match = valueWithoutDelimiters.match(digitsOnlyRegExp);

  if (!match) return 0;

  const [digitsOnly] = match;

  return toNumber(digitsOnly);
};

export function floatFormatted(
  numRaw: string | number = 0,
  decimal: Integer = 2
): string {
  if (typeof numRaw === "string") {
    return parseFloat(numRaw).toLocaleString("en-US", {
      maximumFractionDigits: decimal,
    });
  }

  return numRaw.toLocaleString("en-US", { maximumFractionDigits: decimal });
}

export const stringSort = (a, b) => {
  return a.localeCompare(b);
};

export const numberSort = (a, b) => {
  if (!a && a !== 0) return -1;
  if (!b && b !== 0) return 1;
  return numberFromString(a) - numberFromString(b);
};
export const numberSortWithInfLowest = (a: any, b: any) => {
  if (a === Infinity) return -1;
  if (b === Infinity) return 1;
  return numberSort(a, b);
};

export const percentSort = numberSort;

export const priceSort = percentSort;

export const booleanSort = (a) => {
  return a === true ? -1 : 1;
};

export const safeDivision = (a = 0, b = 0) => {
  if (b === 0) return 0;
  return a / b;
};

export const calcDelta = ({ prevVal, newVal }) => {
  if (prevVal === undefined || newVal === undefined) {
    return 0;
  }

  const prevNum =
    typeof prevVal === "string" ? numberFromString(prevVal) : toNumber(prevVal);
  if (prevNum === 0) return 0;

  const newNum =
    typeof newVal === "string" ? numberFromString(newVal) : toNumber(newVal);

  return Math.round((100 * (newNum - prevNum)) / prevNum);
};

type ObjectsDeltaArgs<T1, T2> = { prevData: T1; newData: T2 };
export function calcObjectsDelta<T1, T2>({
  prevData,
  newData,
}: ObjectsDeltaArgs<T1, T2>): T2 | Record<string, unknown> {
  if (!newData || !prevData) {
    return {};
  }

  return Object.entries(newData).reduce((acc, [key, val]) => {
    acc[key] = calcDelta({ prevVal: prevData[key], newVal: val });
    return acc;
  }, {});
}

type PercentageArgs<T> = { part?: T; total: T };
export function percentage<T extends number>({
  part,
  total,
}: PercentageArgs<T>): Percentage {
  if (!part && total) {
    return 0 as Percentage;
  }
  return Math.abs(100 * safeDivision(part, total)) as Percentage;
}

export function addFloats(
  floats: any[],
  decimalPlaces: Integer = 2 as Integer
): number {
  const sum = floats.reduce((acc, float) => acc + toNumber(float), 0);
  return parseFloat(sum.toFixed(decimalPlaces));
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function roundUp(num: number, precision: Integer): number {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
}
