import { dbSchema } from "@dataseat-dsp-client/lib-reporter";

//Main tables in Clickhouse db
//that UI consumes through Reporter API
export const ReporterTables = dbSchema.ReporterTables;
export type ReporterTables = dbSchema.ReporterTables;

//Dimension: column in db table
export type IDimension = dbSchema.IDimension;

//Tags are added to each dimension
//by API that returns db table's dimensions list
export type ITaggedDimension<TagsT> = dbSchema.ITaggedDimension<TagsT>;
