import { ILanding } from "model/landings";
import { ILineitem } from "model/lineitems";
import { Integer, Microdollars } from "model/modelTypes";
import { TPostbackConversionValues } from "./campaignProps/postbackConversionValues";

export type TAbGroup = {
  number: number;
  name: string;
};

export type IFrequencyCap = {
  intervalMs?: Integer;
  periodMs?: Integer;
  total: number;
  value: number;
};
export enum CapType {
  NONE = "NONE",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
}
export type ICap = {
  type: CapType;
  value: Microdollars;
};

export type IExtEdit = {
  autoBlacklist?: boolean;
  disableDefaultCampaignId?: boolean;
  optimizationTarget?: "CPC" | "CPI" | "CPA";
  optimizationEvent?: string; // deprecated, will be not shown in fields anymore
  alternateBundleId?: string;
  numberOfAbGroups?: number;
  abGroups?: TAbGroup[];
  viewThroughAttribution?: boolean;
  bundles?: {
    blacklist?: string[];
    whitelist?: string[];
  };
  linearTrackingEnabled?: boolean;
};

export type IExtCopy = IExtEdit & IExtServerOnly;

export type IExtServerOnly = {
  mobileWebTarget?: boolean;
  installCap?: Integer;
};
export type IExtModification = {
  modificationDate?: number;
  lastModifiedUser?: string;
};
export type IExt = IExtEdit & IExtServerOnly & IExtModification;

export type ICampaignBase = {
  name: string;
  description?: string;

  bundleId: string;
  advertiserId: string;
  advertiserDomains: string[];
  roasEvents?: string[];

  maxCpm?: number;

  landings?: ILanding[];
  lineitems?: ILineitem[];
  retargeting?: boolean;

  campaignDigit?: string | number;
  endDate?: string;

  isSkanSupportedCampaign?: boolean; //skan 4+
  postbackConversionValues?: TPostbackConversionValues;
  ironsourceBundleOverride?: string | null;
  prioritiseExchangeBidding?: boolean;
  evergreen?: boolean;
  paid?: string;
  behalf?: string;
};

export type ICampaignServer = ICampaignBase & {
  id: string;
  budget: Microdollars;
  ext?: IExt;
  frequencyCap?: IFrequencyCap;
  cap?: ICap;
};
