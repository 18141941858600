"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterInAppEvents = exports.CALCULATED_EVENTS_DICT = exports.isAppMeasureColumnType = exports.appMeasuresDict = void 0;
const regexp_1 = require("../../../../helpers/regexp");
const numericMeasures_1 = require("../../../reporterDb/dbTableCampaign/numericMeasures");
const columnTypes_1 = require("../columnTypes");
exports.appMeasuresDict = {
    [columnTypes_1.ColumnTypes.AppMeasure]: true,
    [columnTypes_1.ColumnTypes.AppMeasureCPA]: true,
    [columnTypes_1.ColumnTypes.CohortedAppMeasure]: true,
    [columnTypes_1.ColumnTypes.CohortedAppMeasureCPA]: true,
};
const isAppMeasureColumnType = (c) => !!c && exports.appMeasuresDict[c];
exports.isAppMeasureColumnType = isAppMeasureColumnType;
var CALCULATED_EVENTS_DICT;
(function (CALCULATED_EVENTS_DICT) {
    CALCULATED_EVENTS_DICT["PVA_INSTALL"] = "PVA_INSTALL";
    CALCULATED_EVENTS_DICT["PVA30_INSTALL"] = "PVA30_INSTALL";
    CALCULATED_EVENTS_DICT["PCA_INSTALL"] = "PCA_INSTALL";
    CALCULATED_EVENTS_DICT["PVN_INSTALL"] = "PVN_INSTALL";
    CALCULATED_EVENTS_DICT["PVN30_INSTALL"] = "PVN30_INSTALL";
    CALCULATED_EVENTS_DICT["PCN_INSTALL"] = "PCN_INSTALL";
    CALCULATED_EVENTS_DICT["OTHER_INSTALL"] = "OTHER_INSTALL";
    CALCULATED_EVENTS_DICT["pv_install"] = "pv_install";
    CALCULATED_EVENTS_DICT["pc_install"] = "pc_install";
    CALCULATED_EVENTS_DICT["pc_mmp_install"] = "pc_mmp_install";
})(CALCULATED_EVENTS_DICT = exports.CALCULATED_EVENTS_DICT || (exports.CALCULATED_EVENTS_DICT = {}));
const filterInAppEvents = (eventId) => {
    const eventDuplicatesExistingOne = !!numericMeasures_1.Events[eventId] || CALCULATED_EVENTS_DICT[eventId.replace("_COHORT", "")];
    const eventIdIsValid = (eventId === null || eventId === void 0 ? void 0 : eventId.length) < 60 && //length check was initially added to filter out utility events that are usually very long-named
        new RegExp(regexp_1.alphanumericalNameWithDash).test(eventId);
    return eventIdIsValid && !eventDuplicatesExistingOne;
};
exports.filterInAppEvents = filterInAppEvents;
