import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDateRange } from "model/AnalyticsPage/dateRange";
import { GranularityValueType } from "model/TrafficPage/granularity";
import {
  FilterCollectionType,
  SplitType,
} from "model/TrafficPage/splitsAndFilters";
import { IReportTable } from "model/TrafficPage/trafficTable";
import { LoadingState } from "model/Types/loading";
import { DataDisplayMode } from "model/report";
import { TimeRange } from "model/report/dateRange";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import { getSplitsByView } from "./helpers";
import {
  setTableWithDataReducer,
  sortReportDataReducer,
  updateTableReportDataReducer,
} from "./trafficExtraReducers";

export interface ITrafficState {
  status: LoadingState;
  filters: FilterCollectionType;
  splits: SplitType[];
  tables: IReportTable[];
  granularity: GranularityValueType;
  view: DataDisplayMode;
  dateRange: {
    dates: [Date, Date];
    range: TimeRange;
  };
  initialValues: {
    splits: SplitType[];
    filters: FilterCollectionType;
    view: DataDisplayMode;
    granularity: GranularityValueType;
    dateRange: {
      dates: [Date, Date];
      range: TimeRange;
    };
  };
}

const lastWeek = getDateRange(TimeRange.last7Days);

const TRAFFIC_INITIAL_STATE: ITrafficState = {
  status: LoadingState.initial,
  tables: [],
  filters: {},
  splits: [],
  view: DataDisplayMode.table,
  granularity: GRANULARITY_TYPES.ALL,
  dateRange: {
    dates: [lastWeek.start, lastWeek.finish],
    range: TimeRange.last7Days,
  },
  initialValues: {
    splits: [],
    filters: {},
    view: DataDisplayMode.table,
    granularity: GRANULARITY_TYPES.ALL,
    dateRange: {
      dates: [lastWeek.start, lastWeek.finish],
      range: TimeRange.last7Days,
    },
  },
};

const trafficSlice = createSlice({
  name: "traffic",
  initialState: TRAFFIC_INITIAL_STATE,
  reducers: {
    setDateRange(
      state,
      action: PayloadAction<{ dates: [Date, Date]; range: TimeRange }>
    ) {
      state.dateRange = action.payload;
    },
    setView(state, action) {
      const newView = action.payload;
      const splitsByView = getSplitsByView(newView, state.splits);

      state.splits = splitsByView.splits;

      state.granularity = splitsByView.granularity || state.granularity;

      state.view = action.payload;
    },
    setSplits(state, action) {
      state.splits = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setGranularity(state, action) {
      state.granularity = action.payload;
    },
    removeTable(state, action) {
      state.tables = state.tables.filter((tb) => tb.id !== action.payload);
    },
    removeAllTables(state) {
      state.tables = [];
    },
    populateWithTableValues(
      state,
      action: PayloadAction<{ tableId?: string; table?: IReportTable }>
    ) {
      // set filters, splits and view from table
      const { tableId, table } = action.payload;
      const selectedTable = tableId
        ? state.tables.find((tb) => tb.id.toString() === tableId)
        : table;

      if (selectedTable) {
        // populate initial values with current state
        if (tableId) {
          state.initialValues.filters = state.filters;
          state.initialValues.splits = state.splits;
          state.initialValues.view = state.view;
          state.initialValues.granularity = state.granularity;
          state.initialValues.dateRange = {
            ...state.dateRange,
            dates: [
              new Date(state.dateRange.dates[0]),
              new Date(state.dateRange.dates[1]),
            ],
          };
        }
        // populate state with table values
        state.filters = selectedTable.filters;
        state.splits = selectedTable.splits;
        state.view = selectedTable.view;
        state.granularity = selectedTable.granularity;
        state.dateRange = {
          ...selectedTable.dateRange,
          dates: [
            new Date(selectedTable.dateRange.dates[0]),
            new Date(selectedTable.dateRange.dates[1]),
          ],
        };
      }
    },
    resetToInitialValues(state) {
      // reset filters, splits and view to intial
      state.filters = state.initialValues.filters;
      state.splits = state.initialValues.splits;
      state.view = state.initialValues.view;
      state.granularity = state.initialValues.granularity;
      state.dateRange = state.initialValues.dateRange;
    },
    allTablesLoaded(state) {
      state.status = LoadingState.loaded;
    },
  },
  extraReducers: (builder) => {
    setTableWithDataReducer(builder);
    updateTableReportDataReducer(builder);
    sortReportDataReducer(builder);
  },
});

export const {
  setDateRange,
  setView,
  setFilters,
  setSplits,
  removeTable,
  removeAllTables,
  setGranularity,
  populateWithTableValues,
  resetToInitialValues,
  allTablesLoaded,
} = trafficSlice.actions;

export default trafficSlice.reducer;
