export const SCROLL_TO_CLASSNAME = "scroll-to-anchor";

type IArgs = {
  hash: string;
  className?: string;
  scrollOptions?: ScrollIntoViewOptions;
};
export const scrollElementIntoView = ({
  hash,
  className = SCROLL_TO_CLASSNAME,
  scrollOptions,
}: IArgs) => {
  const scrollToElements = document.getElementsByClassName(className);
  const anchor = Array.prototype.find.call(
    scrollToElements,
    (el) => el.id === hash
  );
  anchor && anchor.scrollIntoView(scrollOptions);
};
