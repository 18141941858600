import { toTitleCase } from "helpers";
import { ReporterTables } from "model/reporterDb/dbSchema";
import { NAMES } from "./actions";

type IDimension = {
  id: string;
  name?: string;
  tags?: string[];
};
type IReporterState = {
  dimensions?: {
    [ReporterTables.Campaign]?: IDimension[];
    [ReporterTables.Traffic]?: IDimension[];
  };
};

const defaultState: IReporterState = {
  dimensions: {
    [ReporterTables.Campaign]: [],
    [ReporterTables.Traffic]: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case NAMES.OK_DIMENSIONS: {
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          dimensions: ((action?.data?.values || []) as IDimension[]).map(
            (d) => ({
              ...d,
              name: d.name || toTitleCase(d.id),
            })
          ),
        },
      };
    }
    default:
      return state || defaultState;
  }
};

export default reducer;
