"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumnsListByReportMeasures_deprecated = void 0;
const cohortedReportColumns_1 = require("./cohortedReportColumns");
const columnTypes_1 = require("./columnTypes");
function getColumnsListByReportMeasures_deprecated(mco, splits, granularity, hiddenColumns) {
    let splitsFiltered = [...splits];
    const indexOfGranularity = splits.map((s) => s.id).indexOf("GRANULARITY");
    if (indexOfGranularity > -1 && granularity === "ALL") {
        //granularity will not be apllied as a split in case of 'ALL' value, in reporter
        splitsFiltered = splits
            .slice(0, indexOfGranularity)
            .concat(splits.slice(indexOfGranularity + 1));
    }
    const { eventColumns = [], metrics = [], appMeasures = [], cohortedMeasuresFlatList = [], } = mco;
    const columns = [
        ...splitsFiltered.map((split) => ({
            label: split.name,
            id: split.id,
            columnType: columnTypes_1.ColumnTypes.Dimension,
        })),
        ...eventColumns,
        ...cohortedMeasuresFlatList
            .filter((c) => !!c && !(hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns[c === null || c === void 0 ? void 0 : c.columnId]))
            .map((c) => {
            const { columnId, cohort, eventLabel, columnType } = c;
            const id = (0, cohortedReportColumns_1.getCohortColumnId)({ columnId, cohort });
            const { label } = (0, cohortedReportColumns_1.getCohortColumnLabels)({ cohort, eventLabel });
            return {
                id,
                label,
                columnType,
            };
        }),
        ...appMeasures,
        ...metrics,
    ];
    return hiddenColumns ? columns.filter((c) => !hiddenColumns[c.id]) : columns;
}
exports.getColumnsListByReportMeasures_deprecated = getColumnsListByReportMeasures_deprecated;
