import Snackbar from "components/Notifications/Snackbar";

function SnackbarOnAction({ duration, ...props }) {
  return (
    <Snackbar
      {...props}
      duration={duration}
      variant="info"
    />
  );
}

SnackbarOnAction.defaultProps = {
  duration: 5000,
};

export default SnackbarOnAction;
