//Appropriate format
//https://apps.apple.com/*/app/*/id284910350
//http://itunes.apple.com/*/app/*/idXXXXXXXXX
//http://itunes.apple.com/app/idXXXXXXXXX
export const isAppleLink = (link) => {
  return (
    /^https?:\/\/apps.apple.com\//.test(link) ||
    /^https?:\/\/itunes.apple.com\//.test(link)
  );
};

//Appropriate format
//https://play.google.com/store/apps/details?id=com.yelp.android
//https://play.google.com/store/apps/details?id=com.yelp.android&hl=en
export const isGoogleLink = (link) => {
  return /^https?:\/\/play.google.com\//.test(link);
};

export const getAppleLink = (trackId) =>
  `http://itunes.apple.com/app/${trackId}`;

export const getGoogleLink = (bundleId) =>
  `https://play.google.com/store/apps/details?id=${bundleId}`;
