import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { IMonetizationApp } from "model/Monetization/apps";
import { LoadingState } from "model/Types/loading";
import {
  getMonetizationAppsReducer,
  saveMonetizationAppReducer,
  updateMonetizationAppReducer,
} from "./monetizationAppsExtraReducer";

interface IMonetizationAppsState {
  status: LoadingState;
  error: null | string;
  apps: IMonetizationApp[];
  saveStatus: {
    status: LoadingState;
    error: null | SerializedError;
  };
}

const MONETIZATION_APPS_INITIAL_STATE: IMonetizationAppsState = {
  status: LoadingState.initial,
  error: null,
  apps: [],
  saveStatus: {
    status: LoadingState.initial,
    error: null,
  },
};

const monetizationAppsSlice = createSlice({
  name: "monetizationApps",
  initialState: MONETIZATION_APPS_INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    getMonetizationAppsReducer(builder);
    saveMonetizationAppReducer(builder);
    updateMonetizationAppReducer(builder);
  },
});

export default monetizationAppsSlice.reducer;
