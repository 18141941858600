import { GRANULARITY_EXPR } from "../query";

export const TRAFFIC_SELECT_QUERY_MAP = {
  granularity: GRANULARITY_EXPR,
  //real number of requests
  requestsWithLogVolume: {
    expr: {
      $sum: {
        args: [
          {
            expr: {
              $mul: {
                args: ["EVENT_COUNT", "LOG_VOLUME"],
              },
            },
          },
        ],
      },
    },
  },
  //requests sub-sampled by approx. 1:LOG_VOLUME (a few thousand requests)
  requests: {
    expr: {
      $sum: {
        args: ["EVENT_COUNT"],
      },
    },
  },
  bidFloor: {
    expr: {
      $sum: {
        args: ["BUCKET_BID_FLOOR"],
      },
    },
  },
};
