import CheckIcon from "@mui/icons-material/Check";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/EditOutlined";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { unwrapResult } from "@reduxjs/toolkit";
import { IconButton, ToggleButton, makeStyles } from "components/mui-core";
import { SnackbarOnError } from "components/Notifications";
import withSnackbar from "components/Notifications/withSnackbar";
import OrganizationForm from "components/OrganizationForm/OrganizationForm";
import { generateDasherizedId } from "components/OrganizationForm/utils";
import { dollarsToMicro } from "helpers";
import noop from "lodash.noop";
import { IOrganizationAccount, IOrganizationItem } from "model/organizations";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as orgActions from "redux/auth/orgActions";
import * as orgAsyncActions from "redux/auth/orgAsyncActions";
import {
  useAccountId,
  useIsAdminCheck,
  useOrganizationId,
  useOrganizations,
} from "redux/auth/selectors";
import { useAccountService } from "services/account/AccountService";
import { useOrganizationService } from "services/organization/OrganizationService";
import { whiteColor } from "vendor/colors";
import DropdownMenu from "./DropdownMenu";
import { getAccountById, getOrganizationById } from "./utils";

const useStyles = makeStyles({
  root: {
    color: "#fff",
    width: "100%",
  },
  toggle: {
    display: "flex",
    color: "#fff",
    margin: "10px 15px 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  check: {
    "& path": { fill: "#fff" },
  },
});

const ROUTE_DICT = {
  campaign: "campaigns",
  creative: "creatives",
};

function AdminSelect({ showErrorSnackbar }) {
  const organizations = useOrganizations();
  const organizationId = useOrganizationId();
  const accountId = useAccountId();
  const styles = useStyles();

  const dispatch = useDispatch();
  const changeOrganizationAction = (newOrgId) =>
    dispatch(orgActions.changeOrganization(newOrgId));
  const changeAccountAction = (newAccId) =>
    dispatch(orgActions.changeAccount(newAccId));
  const getAccounts = (orgId) =>
    (dispatch(orgAsyncActions.getAccountsByOrganizationId(orgId)) as any).then(
      unwrapResult
    );

  const history = useHistory();
  const location = useLocation();
  const isAdmin = useIsAdminCheck();

  const [isOrgExpanded, setOrgExpanded] = React.useState(false);
  const [isAccExpanded, setAccExpanded] = React.useState(false);

  const [orgModalOpen, setOrgModalOpen] = React.useState(false);
  const {
    createOrganization,
    setAdServingCommission,
    setBillingCommission,
    setMaxCpm,
    includeAdServingFee,
    removeAdServingFee,
  } = useOrganizationService();
  const { createAccount } = useAccountService();
  const [createOrgErrorModalOpen, setCreateOrgErrorModalOpen] = useState(false);
  const [createOrgErrors, setCreateOrgErrors] = useState<string[]>([]);

  const selectedOrganization = getOrganizationById(
    organizations,
    organizationId
  );
  const selectedAccount = selectedOrganization
    ? getAccountById(selectedOrganization, accountId, !!isAdmin)
    : undefined;

  const redirect = () => {
    let currentLocation = location.pathname.split("/").filter(Boolean)?.[0];
    if (ROUTE_DICT[currentLocation])
      currentLocation = ROUTE_DICT[currentLocation];
    history.push(`/${currentLocation || ""}`);
  };

  const onChangeAccount = (acc: IOrganizationAccount) => {
    changeAccountAction(acc.id);
    setAccExpanded(false);
    redirect();
  };

  const onChangeOrganization = async (org: IOrganizationItem) => {
    let accounts = org.accounts;
    if (!accounts) {
      try {
        const accountsResp = await getAccounts(org?.id);
        accounts = accountsResp.accounts;
      } catch (e: any) {
        showErrorSnackbar(
          `Can't load accounts for organization "${org?.name || ""}" Error: ${
            e?.message
          }`
        );
        return;
      }
    }

    const account = accounts?.[0];

    if (!account) {
      showErrorSnackbar(
        `Organization "${
          org?.name || ""
        }" can't be selected: it has no accounts!`
      );
      return;
    }

    changeOrganizationAction(org.id);
    changeAccountAction(account.id);
    setOrgExpanded(false);
    redirect();
  };

  const handleCreateOrganization = (formData) => {
    const orgAndAccountPayload = {
      description: formData.organizationName,
      name: formData.organizationName,
      id: generateDasherizedId(formData.organizationName),
    };

    const commissionPayload = {
      amount: Number(formData.adServingCommission),
      comment: "AdServing",
    };

    const maxCpmPayload = {
      amount: dollarsToMicro(40),
      comment: "Create organization",
    };

    createOrganization(orgAndAccountPayload)
      .then(() => {
        // Org creation OK, create account now
        createAccount(orgAndAccountPayload.id, orgAndAccountPayload)
          .then(() => {
            // Account creation OK, call the rest of the endpoints
            const promises = [
              setAdServingCommission(
                orgAndAccountPayload.id,
                commissionPayload
              ),
              setMaxCpm(orgAndAccountPayload.id, maxCpmPayload),
              formData.commissionIncluded
                ? includeAdServingFee(orgAndAccountPayload.id)
                : removeAdServingFee(orgAndAccountPayload.id),
            ];

            Promise.allSettled(promises).then((responses) => {
              const errors: string[] = [];

              setBillingCommission(
                orgAndAccountPayload.id,
                Number(formData.billingCommission)
              ).then((response) => {
                setOrgModalOpen(false);

                if (response.status === "rejected") {
                  errors.push("Failed to set billing commission");
                }

                if (responses[0].status === "rejected") {
                  errors.push("Failed to set ad serving commission");
                }

                if (responses[1].status === "rejected") {
                  errors.push("Failed to set max cpm");
                }

                if (responses[2].status === "rejected") {
                  errors.push("Failed to set include/remove ad serving fee");
                }

                setCreateOrgErrors(errors);

                (dispatch(orgAsyncActions.getUserOrganizations()) as any).then(
                  () => {
                    changeOrganizationAction(orgAndAccountPayload.id);
                    changeAccountAction(orgAndAccountPayload.id);
                    setOrgExpanded(false);
                    redirect();
                  }
                );
              });
            });
          })
          .catch(() => {
            setCreateOrgErrors(["Account could not be created"]);
            setCreateOrgErrorModalOpen(true);
            setOrgModalOpen(false);
          });
      })
      .catch(() => {
        setCreateOrgErrors(["Organization could not be created"]);
      });
  };

  const onAddNewOrganization = () => {
    setOrgModalOpen(true);
  };

  const [showArchived, setShowArchived] = useState(false);
  const notArchivedOrganizations = useMemo(
    () => organizations?.filter(({ archived }) => !archived) ?? [],
    [organizations]
  );

  // TODO: Refactor to a component this and the one in FeatureToggle.tsx
  const getIcon = (checked) => {
    if (checked) return <CheckIcon className={styles.check} />;

    return <UncheckedIcon className={styles.check} />;
  };

  const orgDropdownMenu = (
    <DropdownMenu<IOrganizationItem>
      label="Organization"
      isExpanded={isOrgExpanded}
      setExpanded={setOrgExpanded}
      items={!showArchived ? notArchivedOrganizations : organizations}
      selectedItem={selectedOrganization}
      onChange={onChangeOrganization}
      disabled={
        (!showArchived ? notArchivedOrganizations : organizations)?.length === 1
      }
      noResultsMessage={"No organizations for this query"}
      onAddNewClick={isAdmin ? onAddNewOrganization : undefined}
      extraButtons={
        isAdmin ? (
          <div className={styles.toggle}>
            Show archived orgs
            <ToggleButton
              value={showArchived}
              onChange={() => {
                setShowArchived((prev) => !prev);
              }}>
              {getIcon(showArchived)}
            </ToggleButton>
          </div>
        ) : null
      }
    />
  );

  return (
    <>
      {orgModalOpen && (
        <Modal
          open={orgModalOpen}
          onClose={() => setOrgModalOpen(false)}
          aria-labelledby="add-organization-modal">
          <OrganizationForm
            onSubmit={handleCreateOrganization}
            onCancel={() => setOrgModalOpen(false)}
            errors={createOrgErrors}
          />
        </Modal>
      )}

      <SnackbarOnError
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: false,
          children: null,
          onClickAway: noop,
        }}
        isOpen={createOrgErrorModalOpen}
        message={
          <Grid container>
            <Grid xs={12}>Organization created, but some errors happened:</Grid>
            {createOrgErrors.map((e) => (
              <Grid xs={12}>- {e}</Grid>
            ))}
          </Grid>
        }
        onClose={() => setCreateOrgErrorModalOpen(false)}
      />
      {isOrgExpanded ? (
        orgDropdownMenu
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          {orgDropdownMenu}
          {isAdmin && (
            <IconButton
              aria-label="edit"
              onClick={() => {
                history.push(`/organization`);
              }}
              color="info">
              <EditIcon
                style={{
                  color: whiteColor,
                  marginTop: 4,
                  marginLeft: -16,
                  width: 16,
                }}
              />
            </IconButton>
          )}
        </div>
      )}
      <DropdownMenu<IOrganizationAccount>
        label="Account"
        isExpanded={isAccExpanded}
        setExpanded={setAccExpanded}
        items={selectedOrganization?.accounts}
        selectedItem={selectedAccount}
        onChange={onChangeAccount}
        disabled={selectedOrganization?.accounts?.length === 1}
        noResultsMessage={"No accounts for this query"}
      />
    </>
  );
}

export default withSnackbar(AdminSelect);
