import { dbTableCampaign } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { CustomEventKind } from "@dataseat-dsp-client/lib-reporter";

//SKAD_EVENT_sum_SKAD_EVENT_SUMM
export const toCustomEventName = dbTableCampaign.toCustomEventName;
export const fromCustomEventName = dbTableCampaign.fromCustomEventName;
export const toCustomEventCPAName = dbTableCampaign.toCustomEventCPAName;
export const fromCustomEventCPAName = dbTableCampaign.fromCustomEventCPAName;

export type CustomEventName = dbTableCampaign.CustomEventName;
export type CustomEventCPAName = dbTableCampaign.CustomEventCPAName;
