"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceAll = exports.shortSiNumber = exports.safelyParseJson = exports.parseResponse = exports.abbreviateAndFormat = exports.numberAbbreviated = exports.NumberAbbreviations = exports.bytesToFileSize = exports.numberToCurrencyShort = exports.numberToCurrency = exports.numberFormatted = exports.removeTrailing_ = exports.addTrailing_ = exports.toTitleCase = void 0;
const toTitleCase = (text) => text && `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;
exports.toTitleCase = toTitleCase;
const addTrailing_ = (text) => `_${text}`;
exports.addTrailing_ = addTrailing_;
const removeTrailing_ = (text) => /^_/.test(text) ? text.slice(1) : text;
exports.removeTrailing_ = removeTrailing_;
const numberFormatted = (num) => { var _a; return (_a = num === null || num === void 0 ? void 0 : num.toString()) === null || _a === void 0 ? void 0 : _a.replace(/\B(?=(\d{3})+(?!\d))/g, ","); };
exports.numberFormatted = numberFormatted;
const numberToCurrency = (num = 0, options) => num.toLocaleString("en-US", options !== null && options !== void 0 ? options : { style: "currency", currency: "USD" });
exports.numberToCurrency = numberToCurrency;
const numberToCurrencyShort = (number = 0) => `$${(0, exports.numberToCurrency)(number, { maximumFractionDigits: 2 })}`;
exports.numberToCurrencyShort = numberToCurrencyShort;
// inspired heavily by https://stackoverflow.com/a/14919494
const bytesToFileSize = (bytes = 0) => {
    // Paul suggested to do size / 1024 but show as MBs
    // const threshold = si ? 1000 : 1024;
    const threshold = 1024;
    if (Math.abs(bytes) < threshold)
        return `${bytes} B`;
    // const units = si
    //   ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    //   : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
        bytes /= threshold;
        ++u;
    } while (Math.abs(bytes) >= threshold && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};
exports.bytesToFileSize = bytesToFileSize;
var NumberAbbreviations;
(function (NumberAbbreviations) {
    NumberAbbreviations[NumberAbbreviations["K"] = 1000] = "K";
    NumberAbbreviations[NumberAbbreviations["M"] = 1000000] = "M";
})(NumberAbbreviations = exports.NumberAbbreviations || (exports.NumberAbbreviations = {}));
const ORDERED_NUM_ABBREVIATIONS = ["K", "M", "B", "T"];
const numberAbbreviated = ({ num = 0, isCurrency, startFrom = NumberAbbreviations.M, customOptions = {}, }) => {
    const options = isCurrency ? { style: "currency", currency: "USD" } : {};
    const isNegative = num < 0;
    num = Math.abs(num);
    if (num < startFrom)
        return num.toLocaleString("en-US", options);
    const threshold = 1000;
    let u = -1;
    do {
        num /= threshold;
        ++u;
    } while (Math.abs(num) >= threshold &&
        u < ORDERED_NUM_ABBREVIATIONS.length - 1);
    return `${isNegative ? "-" : ""}${num.toLocaleString("en-US", Object.assign(Object.assign({ maximumFractionDigits: 2 }, customOptions), options))}${ORDERED_NUM_ABBREVIATIONS[u]}`;
};
exports.numberAbbreviated = numberAbbreviated;
const abbreviateAndFormat = ({ num, isCurrency, startFrom = NumberAbbreviations.M, }) => {
    // eslint-disable-next-line eqeqeq
    if (num == undefined)
        return "-";
    return (0, exports.numberAbbreviated)({ num, isCurrency, startFrom });
};
exports.abbreviateAndFormat = abbreviateAndFormat;
const parseResponse = (text) => {
    return text
        .substring(0, text.length - 1)
        .replace("Response(", "")
        .split(",")
        .reduce((acc, item) => {
        const [key, value] = item.split("=");
        return Object.assign(Object.assign({}, acc), { [key.trim()]: value.trim() });
    }, {});
};
exports.parseResponse = parseResponse;
const safelyParseJson = (text, { handleError, shouldReturnOriginalOnFail, defaultValue, } = {
    defaultValue: {},
}) => {
    let result = defaultValue;
    try {
        result = JSON.parse(text);
    }
    catch (error) {
        if (handleError) {
            result = handleError(text);
        }
        if (shouldReturnOriginalOnFail) {
            result = text;
        }
    }
    return result;
};
exports.safelyParseJson = safelyParseJson;
// inspired heavily by https://stackoverflow.com/a/9462382
const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
];
const shortSiNumber = (num, toFixed) => {
    const trailingZeroRegex = /\.0+$|(\.[0-9]*[1-9])0+$/;
    for (let i = si.length - 1; i > -1; i--) {
        if (Math.abs(num) >= si[i].value) {
            return ((num / si[i].value).toFixed(toFixed).replace(trailingZeroRegex, "$1") +
                si[i].symbol);
        }
    }
};
exports.shortSiNumber = shortSiNumber;
const replaceAll = (str, find, replaceWith) => {
    if (find === replaceWith)
        return str;
    return str.replace(new RegExp(find, "gm"), replaceWith);
};
exports.replaceAll = replaceAll;
