import { v4 as uuid } from "uuid";
import { commaOrSpaceOrQuote } from "./regexp";

type csvDownloaderType = {
  data: string;
  fileName: string;
};

export const triggerCsvDownload = ({ data, fileName }: csvDownloaderType) => {
  const csvFile = new Blob([data], { type: "text/csv" });
  const downloadLink = document.createElement("a");
  const id = `csv-${uuid()}`;

  downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  downloadLink.id = id;
  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(document.getElementById(id) as Node);
};

export const toEscapedCsv = (cell: any = ""): string => {
  if (
    typeof cell === "string" &&
    cell.replace(/ /g, "").match(commaOrSpaceOrQuote)
  ) {
    return `"${cell.replace(/"/g, '""')}"`;
  }

  return cell;
};

export const toUnEscapedCsv = (cell: any = ""): string => {
  if (typeof cell === "string") {
    return cell.replaceAll('""', '"');
  }

  return cell;
};

export const convertCsvToArray = (
  contentRaw: string,
  trimDoubleQuotes = true
) => {
  const content = contentRaw.replace(/\r\n|\n|\r/g, "\n"); //line-end char can be \r, \r\n or \n
  const hasHeadersOnly = content.indexOf("\n") === -1;

  const csvHeader = (
    hasHeadersOnly ? content : content.slice(0, content.indexOf("\n"))
  )
    .split(",")
    .map((s) => {
      if (
        trimDoubleQuotes &&
        typeof s === "string" &&
        s.charAt(0) === '"' &&
        s.charAt(s.length - 1) === '"'
      ) {
        s = s.substring(1, s.length - 1); //trim if wrapped in quotes: "header"
      }
      const trimmed = s.replace ? s.replace(/^\s+|\s+$/g, "") : s; //trim newlines
      return trimmed;
    });

  const csvRows = hasHeadersOnly
    ? []
    : content.slice(content.indexOf("\n") + 1).split("\n");

  const array: Array<Record<string, string>> = csvRows.map((r) => {
    const values = r.split(",");
    const obj = csvHeader.reduce((object, header, index) => {
      let val = values[index];

      if (
        trimDoubleQuotes &&
        typeof val === "string" &&
        val.charAt(0) === '"' &&
        val.charAt(val.length - 1) === '"'
      ) {
        val = val.substring(1, val.length - 1);
      }

      object[header] = toUnEscapedCsv(val);
      return object;
    }, {});
    return obj;
  });

  return array;
};

export const convertArrayToCSV = (
  columnHeaders,
  data: Record<string, string | number | undefined>[]
) => {
  if (!data) return columnHeaders;
  const rows = data
    .map((row) => columnHeaders.map((k) => row[k]))
    .map((row) =>
      row.map((cell) => {
        const trimmed = cell?.replace ? cell.replace(/^\s+|\s+$/g, "") : cell;
        const escaped = toEscapedCsv(trimmed);
        return escaped;
      })
    )
    .map((row) => row.join(","));
  return [columnHeaders].concat(rows).join("\r\n");
};
