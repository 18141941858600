import produce from "immer";
import { NAMES } from "./actions";
import { IDictionariesState } from "./types";

const defaultState: IDictionariesState = {
  errors: {},
  loadedTimestamp: {},
  data: {},
};

const reducer = produce((draftState, action) => {
  if (action.type === NAMES.OK_DICTIONARY) {
    draftState.data[action.key] = action.values;
    if (draftState.errors[action.key]) {
      draftState.errors[action.key] = null;
    }
  }
  if (action.type === NAMES.FAIL_DICTIONARY) {
    draftState.errors[action.key] = action.error;
    draftState.loadedTimestamp[action.key] = undefined;
  }
  if (action.type === NAMES.REQUEST_DICTIONARY) {
    draftState.loadedTimestamp[action.key] = Date.now();
  }
}, defaultState);

export default reducer;
