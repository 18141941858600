import { isBefore } from "date-fns";
import { Microdollars } from "model/modelTypes";

export enum LineitemStatus { //displayed on UI
  Paused = "paused",
  AutoPaused = "autoPaused",
  Running = "running",
  Finished = "finished",
  Unknown = "unknown",
}

export enum LineitemRunningStateServer { //values from server
  AutoPaused = "AUTO_PAUSED",
  Stopped = "STOPPED",
  Running = "RUNNING",
}

export interface ILineItemState {
  archived: boolean;
  id: string;
  removable: boolean;
  runState: LineitemRunningStateServer;
  running: boolean;
}

//values from statistics/{lineitem} endpoint on server
export type ILineitemStatusServer = {
  bids: number;
  hourlySpent: Microdollars;
  dailySpent: Microdollars;
  monthlySpent: Microdollars;
  events: {
    install: number;
    click: number;
  };
  impressions: number;
  modelBids: number;
  lineitemState: ILineItemState;
  spent: Microdollars;
};

export const getLineitemStatus = (
  runState: LineitemRunningStateServer,
  finishDate?: Date | null
) => {
  if (finishDate && isBefore(finishDate, new Date())) {
    return LineitemStatus.Finished;
  }
  if (runState === LineitemRunningStateServer.Running)
    return LineitemStatus.Running;
  if (runState === LineitemRunningStateServer.AutoPaused)
    return LineitemStatus.AutoPaused;
  if (runState === LineitemRunningStateServer.Stopped)
    return LineitemStatus.Paused;
  return LineitemStatus.Unknown;
};

export const getLineitemAllowedActions = (runState: LineitemStatus) => {
  const isPauseActionAllowed =
    runState === LineitemStatus.Running ||
    runState === LineitemStatus.AutoPaused;
  const isStartActionAllowed =
    runState === LineitemStatus.Paused ||
    runState === LineitemStatus.AutoPaused;
  return { isStartActionAllowed, isPauseActionAllowed };
};

export const DEFAULT_LINEITEM_STATUS_SERVER: Omit<
  ILineitemStatusServer,
  "lineitemState"
> & {
  lineitemState: Omit<ILineItemState, "id">;
} = {
  bids: 0,
  hourlySpent: 0,
  dailySpent: 0,
  monthlySpent: 0,
  events: {
    install: 0,
    click: 0,
  },
  impressions: 0,
  modelBids: 0,
  lineitemState: {
    archived: false,
    removable: true,
    runState: LineitemRunningStateServer.Stopped,
    running: false,
  },
  spent: 0,
};
