"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUSTOM_COLUMN_ORDER = exports.CUSTOM_COLUMN_POSITION = void 0;
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
exports.CUSTOM_COLUMN_POSITION = {
    6: { id: dbTableCampaign_1.Events.mmpInstall, label: "MMPInstalls" },
    8: { id: dbTableCampaign_1.Metrics.ecpi, label: "eCPI" },
    9: { id: dbTableCampaign_1.Events.skadInstall, label: "SKAN Installs" },
    11: { id: dbTableCampaign_1.Metrics.skanCPI, label: "SKAN CPI" },
    12: { id: dbTableCampaign_1.Events.skanAssist, label: "SKAN Assists" },
};
exports.CUSTOM_COLUMN_ORDER = [
    dbTableCampaign_1.Events.bid,
    dbTableCampaign_1.Events.impression,
    dbTableCampaign_1.Events.click,
    dbTableCampaign_1.Events.pvInstall,
    dbTableCampaign_1.Events.pcInstall,
    dbTableCampaign_1.Events.mmpInstall,
    dbTableCampaign_1.Events.rejectedInstall,
    dbTableCampaign_1.Metrics.ecpi,
    dbTableCampaign_1.Events.skadInstall,
    dbTableCampaign_1.Events.skanRedownload,
    dbTableCampaign_1.Events.skadSecondPostback,
    dbTableCampaign_1.Events.skadThirdPostback,
    dbTableCampaign_1.Metrics.skanCPI,
    dbTableCampaign_1.Events.skanAssist,
    dbTableCampaign_1.Events.reattribution,
    dbTableCampaign_1.Events.spend,
    dbTableCampaign_1.Events.sales,
    dbTableCampaign_1.Events.revenue,
    dbTableCampaign_1.Metrics.bidFloor,
    dbTableCampaign_1.Metrics.winrate,
    dbTableCampaign_1.Metrics.ecpm,
    dbTableCampaign_1.Metrics.ctr,
    dbTableCampaign_1.Metrics.ecpc,
    dbTableCampaign_1.Metrics.ir,
    dbTableCampaign_1.Metrics.cpr,
    dbTableCampaign_1.Metrics.skanIR,
    dbTableCampaign_1.Metrics.ipm,
    dbTableCampaign_1.Metrics.skanIPM,
    dbTableCampaign_1.Metrics.skanCpa,
    dbTableCampaign_1.Metrics.skanPcpa,
    dbTableCampaign_1.Metrics.roas,
    dbTableCampaign_1.Metrics.bidfloorSumDividend,
    dbTableCampaign_1.Metrics.delta,
    dbTableCampaign_1.Metrics.commission,
    dbTableCampaign_1.Metrics.skanFineRatio,
    dbTableCampaign_1.Metrics.skanCoarseRatio,
    dbTableCampaign_1.Metrics.skanDigit1Ratio,
    dbTableCampaign_1.Metrics.skanDigit2Ratio,
    dbTableCampaign_1.Metrics.skanSourceAppIdRatio,
    //utility cols
    dbTableCampaign_1.Metrics.skanFineRatioDividend,
    dbTableCampaign_1.Metrics.skanCoarseRatioDividend,
    dbTableCampaign_1.Metrics.skanDigit1RatioDividend,
    dbTableCampaign_1.Metrics.skanDigit2RatioDividend,
    dbTableCampaign_1.Metrics.skanSourceAppIdRatioDividend,
    dbTableCampaign_1.Metrics.skanFineRatioDivisor,
    dbTableCampaign_1.Metrics.skanCoarseRatioDivisor,
    dbTableCampaign_1.Metrics.skanDigit1RatioDivisor,
    dbTableCampaign_1.Metrics.skanDigit2RatioDivisor,
    dbTableCampaign_1.Metrics.skanSourceAppIdRatioDivisor,
];
