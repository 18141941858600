"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.METRIC_COLUMNS = exports.EVENT_COLUMNS = exports.UTILITY_COLUMNS_ID_CHECK = void 0;
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
//Metrics that are only fetched to be used for Total calculations
const UTILITY_COLUMNS = [
    { id: dbTableCampaign_1.Metrics.bidfloorSumDividend, label: "util-bfSumDiv" },
    { id: dbTableCampaign_1.Metrics.skanFineRatioDividend, label: "util-skanFineRatioDividend" },
    {
        id: dbTableCampaign_1.Metrics.skanCoarseRatioDividend,
        label: "util-skanCoarseRatioDividend",
    },
    {
        id: dbTableCampaign_1.Metrics.skanDigit1RatioDividend,
        label: "util-skanDigit1RatioDividend",
    },
    {
        id: dbTableCampaign_1.Metrics.skanDigit2RatioDividend,
        label: "util-skanDigit2RatioDividend",
    },
    {
        id: dbTableCampaign_1.Metrics.skanSourceAppIdRatioDividend,
        label: "util-skanSourceAppIdRatioDividend",
    },
    { id: dbTableCampaign_1.Metrics.skanFineRatioDivisor, label: "util-skanFineRatioDivisor" },
    { id: dbTableCampaign_1.Metrics.skanCoarseRatioDivisor, label: "util-skanCoarseRatioDivisor" },
    { id: dbTableCampaign_1.Metrics.skanDigit1RatioDivisor, label: "util-skanDigit1RatioDivisor" },
    { id: dbTableCampaign_1.Metrics.skanDigit2RatioDivisor, label: "util-skanDigit2RatioDivisor" },
    {
        id: dbTableCampaign_1.Metrics.skanSourceAppIdRatioDivisor,
        label: "util-skanSourceAppIdRatioDivisor",
    },
];
exports.UTILITY_COLUMNS_ID_CHECK = {
    [dbTableCampaign_1.Metrics.bidfloorSumDividend]: true,
    [dbTableCampaign_1.Metrics.skanFineRatioDividend]: true,
    [dbTableCampaign_1.Metrics.skanCoarseRatioDividend]: true,
    [dbTableCampaign_1.Metrics.skanDigit1RatioDividend]: true,
    [dbTableCampaign_1.Metrics.skanDigit2RatioDividend]: true,
    [dbTableCampaign_1.Metrics.skanSourceAppIdRatioDividend]: true,
    [dbTableCampaign_1.Metrics.skanFineRatioDivisor]: true,
    [dbTableCampaign_1.Metrics.skanCoarseRatioDivisor]: true,
    [dbTableCampaign_1.Metrics.skanDigit1RatioDivisor]: true,
    [dbTableCampaign_1.Metrics.skanDigit2RatioDivisor]: true,
    [dbTableCampaign_1.Metrics.skanSourceAppIdRatioDivisor]: true,
};
exports.EVENT_COLUMNS = [
    dbTableCampaign_1.EVENTS_LABELED.bid,
    dbTableCampaign_1.EVENTS_LABELED.impression,
    dbTableCampaign_1.EVENTS_LABELED.click,
    dbTableCampaign_1.EVENTS_LABELED.pvInstall,
    dbTableCampaign_1.EVENTS_LABELED.pcInstall,
    Object.assign(Object.assign({}, dbTableCampaign_1.EVENTS_LABELED.mmpInstall), { label: "MMPInstalls" }),
    dbTableCampaign_1.EVENTS_LABELED.rejectedInstall,
    dbTableCampaign_1.EVENTS_LABELED.skadInstall,
    dbTableCampaign_1.EVENTS_LABELED.skadRedownload,
    dbTableCampaign_1.EVENTS_LABELED.skadSecondPostback,
    dbTableCampaign_1.EVENTS_LABELED.skadThirdPostback,
    dbTableCampaign_1.EVENTS_LABELED.skanAssist,
    dbTableCampaign_1.EVENTS_LABELED.reattribution,
    dbTableCampaign_1.EVENTS_LABELED.spend,
    dbTableCampaign_1.EVENTS_LABELED.sales,
    dbTableCampaign_1.EVENTS_LABELED.revenue,
];
exports.METRIC_COLUMNS = [
    dbTableCampaign_1.METRICS_LABELED.bidFloor,
    dbTableCampaign_1.METRICS_LABELED.winrate,
    dbTableCampaign_1.METRICS_LABELED.ecpm,
    dbTableCampaign_1.METRICS_LABELED.ctr,
    dbTableCampaign_1.METRICS_LABELED.ecpc,
    dbTableCampaign_1.METRICS_LABELED.ir,
    Object.assign(Object.assign({}, dbTableCampaign_1.METRICS_LABELED.ecpi), { label: "eCPI" }),
    dbTableCampaign_1.METRICS_LABELED.cpr,
    dbTableCampaign_1.METRICS_LABELED.delta,
    dbTableCampaign_1.METRICS_LABELED.commission,
    dbTableCampaign_1.METRICS_LABELED.skanCPI,
    dbTableCampaign_1.METRICS_LABELED.skanIR,
    dbTableCampaign_1.METRICS_LABELED.ipm,
    dbTableCampaign_1.METRICS_LABELED.skanIPM,
    dbTableCampaign_1.METRICS_LABELED.skanCpa,
    dbTableCampaign_1.METRICS_LABELED.skanPcpa,
    dbTableCampaign_1.METRICS_LABELED.roas,
    dbTableCampaign_1.METRICS_LABELED.skanFineRatio,
    dbTableCampaign_1.METRICS_LABELED.skanCoarseRatio,
    dbTableCampaign_1.METRICS_LABELED.skanDigit1Ratio,
    dbTableCampaign_1.METRICS_LABELED.skanDigit2Ratio,
    dbTableCampaign_1.METRICS_LABELED.skanSourceAppIdRatio,
    ...UTILITY_COLUMNS,
];
