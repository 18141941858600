import Snackbar from "components/Notifications/Snackbar";

function SnackbarOnWarning({ duration, ...props }) {
  return (
    <Snackbar
      {...props}
      duration={duration}
      variant="warning"
    />
  );
}

SnackbarOnWarning.defaultProps = {
  duration: 5000,
};

export default SnackbarOnWarning;
