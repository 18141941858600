"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventDollarFormatted = exports.eventNumberFormatted = exports.onlyNumericFloat = exports.onlyNumericDollar = exports.onlyNumericPercent = exports.onlyNumericCalculatePercent = exports.isInfinity = exports.NAN_VALUE_LABEL = exports.percentageToDecimalPlace = exports.dollarsToMicro = exports.microToDollars = exports.microToDollarsStringShort = exports.microToDollarsString = exports.stringDollarsToMicro = exports.stringToDollars = exports.numberToCurrencyShort = exports.numberToCurrency = exports.floatFormatted = void 0;
function floatFormatted(numRaw = 0, decimal = 2) {
    if (typeof numRaw === "string") {
        return parseFloat(numRaw).toLocaleString("en-US", {
            maximumFractionDigits: decimal,
        });
    }
    return numRaw.toLocaleString("en-US", { maximumFractionDigits: decimal });
}
exports.floatFormatted = floatFormatted;
// export const numberFormatted = (num: number) =>
//   num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const numberToCurrency = (num = 0, options) => num.toLocaleString("en-US", options !== null && options !== void 0 ? options : { style: "currency", currency: "USD" });
exports.numberToCurrency = numberToCurrency;
const numberToCurrencyShort = (number = 0) => `$${(0, exports.numberToCurrency)(number, { maximumFractionDigits: 2 })}`;
exports.numberToCurrencyShort = numberToCurrencyShort;
function stringToDollars(value = "0") {
    return parseFloat(value);
}
exports.stringToDollars = stringToDollars;
function stringDollarsToMicro(value = "0") {
    return dollarsToMicro(stringToDollars(value));
}
exports.stringDollarsToMicro = stringDollarsToMicro;
const microToDollarsString = (micro = 0) => {
    const dollars = microToDollars(micro);
    const result = Number.isFinite(dollars) ? dollars : 0;
    return (0, exports.numberToCurrency)(result);
};
exports.microToDollarsString = microToDollarsString;
const microToDollarsStringShort = (micro = 0) => {
    const dollars = microToDollars(micro);
    const result = Number.isFinite(dollars) ? dollars : 0;
    return (0, exports.numberToCurrency)(result);
};
exports.microToDollarsStringShort = microToDollarsStringShort;
function microToDollars(value = 0) {
    return (Math.round(Math.round(value) / 10000) / 100);
}
exports.microToDollars = microToDollars;
function dollarsToMicro(value = 0) {
    return Math.round(value * 1000000);
}
exports.dollarsToMicro = dollarsToMicro;
const percentageToDecimalPlace = (a, b) => b > 0 ? Math.round((1000 * a) / b) / 10 : 0;
exports.percentageToDecimalPlace = percentageToDecimalPlace;
exports.NAN_VALUE_LABEL = "-";
const isInfinity = (value) => {
    return value.toString() === "Infinity";
};
exports.isInfinity = isInfinity;
const onlyNumericCalculatePercent = (value) => {
    if (isNaN(value) || (0, exports.isInfinity)(value)) {
        return exports.NAN_VALUE_LABEL;
    }
    return Math.round(value * 10000) / 100;
};
exports.onlyNumericCalculatePercent = onlyNumericCalculatePercent;
const onlyNumericPercent = (value) => {
    if (isNaN(value) || (0, exports.isInfinity)(value)) {
        return exports.NAN_VALUE_LABEL;
    }
    return Math.round(value * 100) / 100;
};
exports.onlyNumericPercent = onlyNumericPercent;
const onlyNumericDollar = (value) => {
    if (isNaN(value) || (0, exports.isInfinity)(value)) {
        return exports.NAN_VALUE_LABEL;
    }
    return microToDollars(value);
};
exports.onlyNumericDollar = onlyNumericDollar;
const onlyNumericFloat = (value) => {
    return isNaN(value) || (0, exports.isInfinity)(value)
        ? exports.NAN_VALUE_LABEL
        : floatFormatted(value);
};
exports.onlyNumericFloat = onlyNumericFloat;
const eventNumberFormatted = (num = 0) => num; //numberFormatted(num);
exports.eventNumberFormatted = eventNumberFormatted;
const eventDollarFormatted = (num = 0) => (0, exports.onlyNumericDollar)(num);
exports.eventDollarFormatted = eventDollarFormatted;
