"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANALYTICS_COLUMN_CONVERT_FNS = exports.COLUMN_TYPE_NORMALIZATION = exports.normalizeGranularity = exports.TRANSFORM_FNS = void 0;
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
const granularity_1 = require("../../reporterDb/granularity");
const measuresAsColumns_1 = require("../measuresAsColumns");
const normalizationLabels_1 = require("./normalizationLabels");
//TODO https://youtrack.dataseat.com/issue/PLAT-903
const primitiveNormalization_1 = require("./primitiveNormalization");
exports.TRANSFORM_FNS = {
    bid: primitiveNormalization_1.eventNumberFormatted,
    impression: primitiveNormalization_1.eventNumberFormatted,
    click: primitiveNormalization_1.eventNumberFormatted,
    pvInstall: primitiveNormalization_1.eventNumberFormatted,
    pcInstall: primitiveNormalization_1.eventNumberFormatted,
    pvaInstall: primitiveNormalization_1.eventNumberFormatted,
    pcaInstall: primitiveNormalization_1.eventNumberFormatted,
    pvnInstall: primitiveNormalization_1.eventNumberFormatted,
    pcnInstall: primitiveNormalization_1.eventNumberFormatted,
    mmpInstall: primitiveNormalization_1.eventNumberFormatted,
    skadInstall: primitiveNormalization_1.eventNumberFormatted,
    skanRedownload: primitiveNormalization_1.eventNumberFormatted,
    sales: primitiveNormalization_1.eventNumberFormatted,
    revenue: (num = 0) => (0, primitiveNormalization_1.floatFormatted)(num),
    spend: primitiveNormalization_1.eventDollarFormatted,
    ecpm: primitiveNormalization_1.eventDollarFormatted,
    ctr: (num = 0) => (0, primitiveNormalization_1.onlyNumericPercent)(num) + "%",
    ecpc: primitiveNormalization_1.eventDollarFormatted,
    ir: (num = 0) => (0, primitiveNormalization_1.onlyNumericPercent)(num) + "%",
    ecpi: primitiveNormalization_1.eventDollarFormatted,
    skanIR: (num = 0) => (0, primitiveNormalization_1.onlyNumericPercent)(num) + "%",
    skanCPI: primitiveNormalization_1.eventDollarFormatted,
    cpr: primitiveNormalization_1.eventDollarFormatted,
    cpa: primitiveNormalization_1.eventDollarFormatted,
    winrate: (num = 0) => (0, primitiveNormalization_1.onlyNumericCalculatePercent)(num) + "%",
    cvr: (num = 0) => (0, primitiveNormalization_1.onlyNumericPercent)(num) + "%",
    ipm: (num = 0) => (0, primitiveNormalization_1.onlyNumericFloat)(num),
    skanIPM: (num = 0) => (0, primitiveNormalization_1.onlyNumericFloat)(num),
    roas: (num = 0) => (0, primitiveNormalization_1.onlyNumericCalculatePercent)(num) + "%",
    priceOriginal: primitiveNormalization_1.eventDollarFormatted,
    bidFloor: (num = 0) => (0, primitiveNormalization_1.onlyNumericFloat)(num),
    [dbTableCampaign_1.Metrics.skanCpa]: primitiveNormalization_1.eventDollarFormatted,
    [dbTableCampaign_1.Metrics.skanPcpa]: (val, abbreviate, startFrom, { skanCoarseRatio } = {}) => skanCoarseRatio >= 0.2 ? (0, primitiveNormalization_1.eventDollarFormatted)(val) : "NULL",
};
//TODO https://youtrack.dataseat.com/issue/PLAT-903
const DAY_OF_WEEK_LABEL = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
function normalizeGranularity(granularity, value) {
    if (!value || !granularity)
        return value;
    if (granularity === granularity_1.GRANULARITY_TYPES.DAYS) {
        return value.replace(" 00:00:00", "");
    }
    if (granularity === granularity_1.GRANULARITY_TYPES.DAY_OF_WEEK) {
        const dayOfWeekIndex = parseInt(value);
        return isNaN(dayOfWeekIndex)
            ? "Unknown"
            : DAY_OF_WEEK_LABEL[dayOfWeekIndex - 1];
    }
    return value;
}
exports.normalizeGranularity = normalizeGranularity;
exports.COLUMN_TYPE_NORMALIZATION = {
    [measuresAsColumns_1.ColumnTypes.AppMeasure]: (value) => (0, primitiveNormalization_1.floatFormatted)(value),
    [measuresAsColumns_1.ColumnTypes.AppMeasureCPA]: (value) => (0, primitiveNormalization_1.onlyNumericDollar)(value),
    [measuresAsColumns_1.ColumnTypes.CohortedAppMeasureCPA]: (value) => (0, primitiveNormalization_1.onlyNumericDollar)(value),
    [measuresAsColumns_1.ColumnTypes.Cohorted]: (value) => (0, primitiveNormalization_1.floatFormatted)(value),
    [measuresAsColumns_1.ColumnTypes.CohortedAppMeasure]: (value) => (0, primitiveNormalization_1.floatFormatted)(value),
};
exports.ANALYTICS_COLUMN_CONVERT_FNS = {
    [dbTableCampaign_1.DimensionIds.CREATIVE_TYPE_COLUMN_ID]: (val) => normalizationLabels_1.CREATIVE_TYPE_LABELS[val],
    [dbTableCampaign_1.DimensionIds.COARSE_CONVERSION_NUMBER]: (val) => normalizationLabels_1.COARSE_CONVERSION_NUMBER_LABELS[val],
    // [DimensionIds.SKAN_LOWER_DIGITS]: (value) => {
    //   return toNumber(value);
    // },
    [dbTableCampaign_1.DimensionIds.SKAN_FIDELITY_COLUMN_ID]: (value) => normalizationLabels_1.SKAN_FIDELITY_LABELS[value],
};
