import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import { differenceInMilliseconds } from "date-fns";
import { FIVE_MINUTES_MS } from "helpers";
import request from "http/request";
import { PredictQueryParameters } from "model/lineitems";
import store from "redux/store";
import { fetchPredictThunk } from "./predictSlice";

const PREDICT_LOAD_THROTTLE_MS = FIVE_MINUTES_MS;

export const archivePredict = createAsyncThunk(
  "predict/archivePredict",
  async (modelId: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .get(
        endpointMaker.predictMC.predictArchive(
          organizationId,
          accountId,
          modelId
        )
      )
      .then(() => modelId);
  }
);

export const unarchivePredict = createAsyncThunk(
  "predict/unarchivePredict",
  async (modelId: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request
      .get(
        endpointMaker.predictMC.predictUnarchive(
          organizationId,
          accountId,
          modelId
        )
      )
      .then(() => modelId);
  }
);

export const fetchPredictThrottled =
  (
    queryParams: PredictQueryParameters,
    throttleMS = PREDICT_LOAD_THROTTLE_MS
  ) =>
  async (dispatch) => {
    const state = store.getState();
    const lastLoadedTimestamp = state.predict.loadedTimestamp;

    if (
      lastLoadedTimestamp &&
      differenceInMilliseconds(Date.now(), lastLoadedTimestamp) < throttleMS
    ) {
      return;
    }

    dispatch(fetchPredictThunk(queryParams));
  };
