import { UrlString } from "model/modelTypes";

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function checkFileExists(url: string): Promise<boolean> {
  return fetch(url, {
    method: "HEAD",
    mode: "no-cors",
  })
    .then((r: Response) => r.ok)
    .catch(() => false);
}

export function downloadFileByUrl(url: UrlString, name?: string) {
  const link = document.createElement("a");
  if (name) {
    link.download = name;
  }

  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
