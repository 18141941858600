import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { useDispatch } from "react-redux";
import { authSlice } from "redux/auth";
import { get, post } from "services/reporter/fetch/rest";

export interface CreateOrganizationPayload {
  id: string;
  name: string;
  description: string;
}

type IOrganizationService = () => {
  createOrganization: (payload: CreateOrganizationPayload) => Promise<any>;
  setAdServingCommission: (
    organizationId: string,
    payload: { amount: number; comment: string }
  ) => Promise<any>;
  setBillingCommission: (
    organizationId: string,
    payload: number
  ) => Promise<any>;
  setMaxCpm: (
    organizationId: string,
    payload: { amount: number; comment: string }
  ) => Promise<any>;
  includeAdServingFee: (organizationId: string) => Promise<any>;
  removeAdServingFee: (organizationId: string) => Promise<any>;
  findOrganizationFromCampaign: (campaignId: string) => Promise<any>;
  update: (organizationId: string, payload: any) => Promise<any>;
  getDSA: (organizationId: string) => Promise<any>;
};

export const useOrganizationService: IOrganizationService = () => {
  const dispatch = useDispatch();

  const createOrganization = (payload: CreateOrganizationPayload) =>
    post({
      url: endpointMaker.account.organizations(),
      body: payload,
    });

  const setAdServingCommission = (
    organizationId: string,
    payload: { amount: number; comment: string }
  ) =>
    post({
      url: endpointMaker.api.setAdServingCommission(organizationId),
      body: payload,
    });

  const setBillingCommission = (organizationId: string, payload: number) =>
    post({
      url: endpointMaker.api.setBillingCommission(organizationId),
      body: payload,
    });

  const setMaxCpm = (
    organizationId: string,
    payload: { amount: number; comment: string }
  ) =>
    post({
      url: endpointMaker.api.setMaxCpm(organizationId),
      body: payload,
    });

  const includeAdServingFee = (organizationId: string) =>
    post({ url: endpointMaker.account.includeAdServingFee(organizationId) });

  const removeAdServingFee = (organizationId: string) =>
    post({ url: endpointMaker.account.removeAdServingFee(organizationId) });

  const findOrganizationFromCampaign = (campaignId: string) =>
    get({ url: endpointMaker.api.findOrgFromCampaign(campaignId) });

  const update = async (organizationId, payload) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const upToDateOrganization = await request.getJson(
          endpointMaker.account.organization(organizationId)
        );

        const body = {
          ...upToDateOrganization, //to not accidentaly overwrite org with outdated fields
          ...payload,
        };

        await request.postJson(
          endpointMaker.account.organization(organizationId),
          body
        );

        await request.postAsText(
          endpointMaker.account.behalf(organizationId),
          payload.behalf ?? ""
        );

        await request.postAsText(
          endpointMaker.account.paid(organizationId),
          payload.paid ?? ""
        );
        //if success, update org in redux store

        dispatch(
          authSlice.actions.updateOrganization({
            id: organizationId,
            ...payload,
          })
        );

        resolve(true);
      } catch (e: any) {
        reject(e.message);
      }
    });
  };

  // TODO: Refactor this to use useQuery when PR for PLAT-900 gets approved
  const getDSA = (organizationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const behalf = await get({
          url: endpointMaker.account.behalf(organizationId),
        });

        const paid = await get({
          url: endpointMaker.account.paid(organizationId),
        });

        resolve({ behalf, paid });
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    createOrganization,
    setAdServingCommission,
    setBillingCommission,
    setMaxCpm,
    includeAdServingFee,
    removeAdServingFee,
    findOrganizationFromCampaign,
    update,
    getDSA,
  };
};
