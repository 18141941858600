"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = void 0;
const templates_1 = require("../query/templates");
const customEvent_1 = require("./customEvent");
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = {
    dimensionAsMeasure: ({ eventName, whereValues = [], kind = customEvent_1.CustomEventKind.count, }) => {
        if (kind === customEvent_1.CustomEventKind.sum) {
            //in future, add 'whereValues' support for sum, if/when needed
            return (0, templates_1.sumDimensionTemplate)(eventName);
        }
        return {
            expr: {
                $sum: {
                    args: ["EVENT_COUNT"],
                    where: [{ $in: [eventName, whereValues] }],
                },
            },
        };
    },
};
