import classnames from "classnames";
import { makeStyles } from "components/mui-core";

export const useOwnStyles = makeStyles({
  icon: {
    marginRight: 5,
  },
  warning: {
    fontSize: 12,
  },
  wrapper: {
    color: "#fb8c00",
    display: "inline-flex",
    verticalAlign: "middle",
  },
});

type Props = {
  isVisible: boolean;
  text: string;
  className?: string;
};

function FormFieldWarning({ isVisible, text, className }: Props) {
  const ownClasses = useOwnStyles();

  return isVisible ? (
    <div className={classnames(ownClasses.wrapper, className)}>
      <div className={ownClasses.icon}>⚠</div>
      <div className={ownClasses.warning}>{text}</div>
    </div>
  ) : null;
}

export default FormFieldWarning;
