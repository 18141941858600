import { IOrganizationItem } from "model/organizations";

export const getOrganizationById = (
  organizations: IOrganizationItem[] | undefined,
  id?: string
) => organizations?.find((o) => o.id === id);

export const getAccountById = (
  org: IOrganizationItem,
  id: string,
  isAdmin: boolean
) => {
  if (!org?.accounts) return;
  if (org?.accounts?.length) return org?.accounts?.find((a) => a.id === id);
  // organization can have no accounts, in such case Admin should be able to access it
  if (isAdmin) return { id: "admin", name: "ADMIN" } as IOrganizationItem;
};
