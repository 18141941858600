import { createSlice } from "@reduxjs/toolkit";
import {
  AttributionSource,
  CampaignsFilterBy,
  CampaignsSortBy,
} from "model/campaigns";
import { TimestampMS } from "model/modelTypes";
import { TimeRange } from "model/report/dateRange";

type IViewSettingsState = {
  lineitemsTimestamps: { [lineitemId: string]: TimestampMS | 0 }; //no need in parent campaignId since all lineitemId's are unique
  //ineitemsSortBy:
  //lineitemsFilterBy:
  campaignsSortBy: CampaignsSortBy;
  campaignsFilterBy: CampaignsFilterBy;
  campaignsTimeRange: TimeRange;
  campaignTimeRange: TimeRange;
  attributionSource: AttributionSource;
};
const INITIAL_STATE = {
  lineitemsTimestamps: {},
  //lineitemsSortBy:
  //lineitemsFilterBy:
  campaignsSortBy: CampaignsSortBy.RunState,
  campaignsFilterBy: CampaignsFilterBy.Active,
  campaignsTimeRange: TimeRange.allTime,
  campaignTimeRange: TimeRange.allTime,
  attributionSource: "MMP",
};

//Slice
export const viewSettingsSlice = createSlice({
  name: "viewSettings",
  initialState: INITIAL_STATE as IViewSettingsState,
  reducers: {
    markLineitemsUpdated(state, action) {
      if (!action.payload.ids?.length) return;

      action.payload.ids?.forEach((id) => {
        state.lineitemsTimestamps[id] = action.payload.timestamp;
      });
    },
    markLineitemUpdated(state, action) {
      state.lineitemsTimestamps[action.payload.id] = action.payload.timestamp;
    },
    // sortLineitems(state, action) {
    //   state.lineitemsSortBy = action.payload
    // },
    // filterLineitems(state, action) {
    //   state.lineitemsFilterBy = action.payload
    // },
    sortCampaigns(state, action) {
      state.campaignsSortBy = action.payload;
    },
    filterCampaigns(state, action) {
      state.campaignsFilterBy = action.payload;
    },
    changeCampaignsTimeRange(state, action) {
      state.campaignsTimeRange = action.payload;
    },
    changeCampaignTimeRange(state, action) {
      state.campaignTimeRange = action.payload;
    },
    setAttributionSource(state, action) {
      state.attributionSource = action.payload;
    },
  },
});

export const {
  markLineitemsUpdated,
  markLineitemUpdated,
  sortCampaigns,
  filterCampaigns,
  changeCampaignsTimeRange,
  changeCampaignTimeRange,
  setAttributionSource,
} = viewSettingsSlice.actions;

export const actions = viewSettingsSlice.actions;

export default viewSettingsSlice.reducer;
