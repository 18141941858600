export const USER_ROLES = {
  USER: "USER",
  ADMIN: "ADMIN",
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  USER_READ_ONLY: "USER_READ_ONLY",
  REPORTER_USER: "REPORTER_USER",
};

export const USER_ROLES_LABELS = {
  USER: "User",
  ADMIN: "Admin",
  ORGANIZATION_ADMIN: "Organization Admin",
  USER_READ_ONLY: "Readonly",
  REPORTER_USER: "Reporter",
};
