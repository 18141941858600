"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DimensionTags = exports.DimensionIds = void 0;
//Dimensions: columns in CAMPAIGN table
exports.DimensionIds = {
    GRANULARITY_COLUMN_ID: "GRANULARITY",
    CAMPAIGN_ID_COLUMN_ID: "CAMPAIGN_ID",
    LINEITEM_ID_COLUMN_ID: "LINEITEM_ID",
    LANDING_ID_COLUMN_ID: "LANDING_ID",
    CREATIVE_ID_COLUMN_ID: "CREATIVE_ID",
    CREATIVE_TYPE_COLUMN_ID: "CREATIVE_TYPE",
    CREATIVE_NAME: "CREATIVE_NAME",
    EXCHANGE_COLUMN_ID: "EXCHANGE",
    APP_BUNDLE_COLUMN_ID: "APP_BUNDLE",
    CAMPAIGN_BUNDLE_COLUMN_ID: "BUNDLE_ID",
    LAST_BUNDLE_COLUMN_ID: "USER_LAST_BUNDLE",
    TAG_ID_COLUMN_ID: "TAG_ID",
    CITY_COLUMN_ID: "GEO_CITY",
    SKAN_FIDELITY_COLUMN_ID: "FIDELITY",
    SKAN_CONVERSION_VALUE: "CONVERSION_VALUE",
    BUCKET_BID_FLOOR_COLUMN_ID: "BUCKET_BID_FLOOR",
    ORGANIZATION_COLUMN_ID: "ORGANIZATION",
    PRICE_ORIGINAL: "PRICE_ORIGINAL",
    COARSE_CONVERSION_NUMBER: "COARSE_CONVERSION_NUMBER",
    DEVICE_OS_COLUMN_ID: "DEVICE_OS",
    CREATIVE_SIZE_COLUMN_ID: "BANNER_FORMAT_SIZE",
    INVENTORY_CREATIVE_TYPE_COLUMN_ID: "INVENTORY_CREATIVE_TYPE",
};
var DimensionTags;
(function (DimensionTags) {
    DimensionTags["Report"] = "REPORT";
    DimensionTags["Monetization"] = "MONETIZATIONREPORT";
})(DimensionTags = exports.DimensionTags || (exports.DimensionTags = {}));
