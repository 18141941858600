import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";

export interface IOptimiserRule {
  id: string;
  path: string;
  name: string;
  className: string;
  archived: boolean;
  description: string;
}

export const fetchOptimiserRules = createAsyncThunk<IOptimiserRule[]>(
  "campaignOptimiser/fetchRules",
  async () => request.getJson(endpointMaker.api.getCampaignOptimiserRules())
);
