import endpointMaker from "configuration/endpoints";
import { get, postJson } from "services/reporter/fetch/rest";

export interface CreateAccountPayload {
  id: string;
  name: string;
  description: string;
}

type IAccountService = () => {
  createAccount: (
    organizationId: string,
    payload: CreateAccountPayload
  ) => Promise<any>;
  confirmEmail: (organizationId: string, email: string) => Promise<any>;
};

export const useAccountService: IAccountService = () => {
  const createAccount = (
    organizationId: string,
    payload: CreateAccountPayload
  ) =>
    postJson({
      url: endpointMaker.account.account(organizationId),
      body: payload,
    });

  const confirmEmail = (organizationId: string, email: string) =>
    get({ url: endpointMaker.account.confirm(email, organizationId) });

  return {
    createAccount,
    confirmEmail,
  };
};
