import {
  formatDate,
  monAndDayFormat,
  monDayAndTimeFormat,
  parseDate,
  toNumber,
} from "helpers";
import { EVENTS_N_METRICS_NORMALIZATION } from "model/report/normalization";
import { convertBucketBidFloor } from "model/reporterDb/dbTableTraffic";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import * as R from "ramda";

const nonNumericValues = [
  "APP_BUNDLE",
  "CONNECTION",
  "GEO_COUNTRY",
  "DEVICE_OS",
  "DEVICE_VERSION",
  "DEVICE_OS_VERSION",
  "DEVICE_TYPE",
  "EXCHANGE",
  "TRACKING_ALLOWED",
  "INVENTORY_CATEGORIES",
  "INVENTORY_CREATIVE_TYPE",
  "INVENTORY_TYPE",
  "SKAD_VERSION",
  "TAG_ID",
  "GRANULARITY",
  "CAMPAIGN_ID",
];

const convertBidFloorFn = (val, row) => {
  return toNumber(convertBucketBidFloor(val, { requests: row.requests }));
};

const normalizationFnDict = {
  bidFloor: convertBidFloorFn,
  requestsWithLogVolume: Math.round,
};

export const normalizeChartData =
  (granularityValue?: GRANULARITY_TYPES, dateKey = "date") =>
  (data) =>
    R.mapObjIndexed((val, key) => {
      if (key === dateKey) {
        return formatDate(
          parseDate(data[dateKey]),
          granularityValue === GRANULARITY_TYPES.HOURS
            ? monDayAndTimeFormat
            : monAndDayFormat
        );
      }

      if (normalizationFnDict[key]) {
        return normalizationFnDict[key](val, data);
      }

      if (nonNumericValues.includes(key)) return val;

      const normalized = toNumber(
        EVENTS_N_METRICS_NORMALIZATION[key]?.(val) ?? val, //TODO separate CAMPAIGN and TRAFFIC tables normalizations
        0
      );

      return normalized;
    }, data);
