"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GRANULARITY_TYPES = void 0;
var GRANULARITY_TYPES;
(function (GRANULARITY_TYPES) {
    GRANULARITY_TYPES["ALL"] = "ALL";
    GRANULARITY_TYPES["DAYS"] = "DAY";
    GRANULARITY_TYPES["HOURS"] = "HOUR";
    GRANULARITY_TYPES["WEEKS"] = "WEEK";
    GRANULARITY_TYPES["MONTHS"] = "MONTH";
    GRANULARITY_TYPES["FIFTEEN_MINITES"] = "FIFTEEN_MIN";
    GRANULARITY_TYPES["FIVE_MINUTES"] = "FIVE_MIN";
    GRANULARITY_TYPES["MINUTES"] = "MIN";
    GRANULARITY_TYPES["HOUR_OF_DAY"] = "HOUR_OF_DAY";
    GRANULARITY_TYPES["DAY_OF_WEEK"] = "DAY_OF_WEEK";
})(GRANULARITY_TYPES = exports.GRANULARITY_TYPES || (exports.GRANULARITY_TYPES = {}));
