import { makeStyles } from "components/mui-core";
import {
  grayColor,
  whiteColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";
import { hexToRgb } from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react.jsx";
import vendorSidebarStyle from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react/components/sidebarStyle";

export const DRAWER_WIDTH = 280;
export const DRAWER_WIDTH_CLOSED = 85;

const sidebarStyle = (theme) => ({
  listItem: {
    zIndex: 4,
    color: "#fff",
    width: "auto",
    minWidth: 52,
    display: "flex",
    position: "relative",
    transition: "all 300ms linear",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: "30px",
    fontSize: 14,
    borderRadius: 3,
    backgroundColor: "transparent",
    margin: "10px 15px 0",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
      background: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
    },
    "&.Mui-disabled": {
      opacity: "1",
    },
  },
  activeListItem: {
    boxShadow:
      "0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)",
  },
  simpleIcon: {
    color: "inherit",
  },
  icon: {
    color: "#fff",
  },
  menuList: {
    zIndex: 4,
    color: "#FFF",
    "&:before": {
      content: '""',
      right: 15,
      width: "calc(100% - 30px)",
      bottom: 0,
      height: 1,
      position: "absolute",
      backgroundColor: "rgba(180, 180, 180, 0.3)",
    },
  },
  toolbarIcon: {
    zIndex: 4,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px 0 20px",
    ...(theme.mixins.toolbar || {}),
    marginLeft: 0,
  },
  drawerRoot: {
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    position: "fixed",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  backgroundImage: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "block",
    zIndex: 1,
    position: "absolute",
    background: `url(${process.env.PUBLIC_URL}/assets/image.jpg)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    "&:after": {
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      opacity: 0.8,
      zIndex: 3,
      position: "absolute",
      background: "#000",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: DRAWER_WIDTH_CLOSED,
  },
  logo: {
    display: "none",
    "& img": { height: 30 },
    margin: "0 20px",
  },
});

const useStyles = makeStyles((theme) => ({
  ...vendorSidebarStyle,
  ...sidebarStyle(theme),
}));

export default useStyles;
