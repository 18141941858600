"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeCohortableCustomEventName = exports.isCohortableCustomEventName = exports.fromCohortedEventName = exports.toCohortedEventName = exports.Cohorts = void 0;
var Cohorts;
(function (Cohorts) {
    Cohorts["d1"] = "1";
    Cohorts["d3"] = "3";
    Cohorts["d5"] = "5";
    Cohorts["d7"] = "7";
    Cohorts["d15"] = "15";
    Cohorts["d30"] = "30";
    Cohorts["d60"] = "60";
    Cohorts["d90"] = "90";
    Cohorts["d90plus"] = "90+";
})(Cohorts = exports.Cohorts || (exports.Cohorts = {}));
function toCohortedEventName(event, cohort) {
    return `${event}_cohort_${cohort}`;
}
exports.toCohortedEventName = toCohortedEventName;
function fromCohortedEventName(name) {
    const caseInsensitive_cohort_ = new RegExp("_cohort_", "i");
    return name.split(caseInsensitive_cohort_);
}
exports.fromCohortedEventName = fromCohortedEventName;
const isCohortableCustomEventName = (ev) => ev && ev.match(/_COHORT$/);
exports.isCohortableCustomEventName = isCohortableCustomEventName;
const makeCohortableCustomEventName = (ev) => ev && (0, exports.isCohortableCustomEventName)(ev) ? ev : `${ev}_COHORT`;
exports.makeCohortableCustomEventName = makeCohortableCustomEventName;
