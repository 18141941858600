export enum RouteNames {
  analytics = "analytics",
  analyticsReport = "analyticsReport",
  analyticsReportWithParams = "analyticsReportWithParams",
  analyticsNamedReport = "analyticsNamedReport",
  analyticsJustSavedReport = "analyticsJustSavedReport",
  monetizationRoot = "monetizationRoot",
  monetizationReport = "monetizationReport",
  monetizationAppsList = "monetizationAppsList",
  campaigns = "campaigns",
  campaignsNew = "campaignsNew",
  campaignEdit = "campaignEdit",
  campaignCopy = "campaignCopy",
  campaignView = "campaignView",
  suggestedOptimisations = "suggestedOptimisations",
  lineitemNew = "lineitemNew",
  lineitemEdit = "lineitemEdit",
  lineitemCopy = "lineitemCopy",
  landingNew = "landingNew",
  landingEdit = "landingEdit",
  landingCopy = "landingCopy",
  audiences = "audiences",
  audienceNew = "audienceNew",
  creatives = "creatives",
  creativeNew = "creativeNew",
  creativeEdit = "creativeEdit",
  creativeCopy = "creativeCopy",
  creativeCopyMany = "creativeCopyMany",
  playableNew = "playableNew",
  playableEdit = "playableEdit",
  playableCopy = "playableCopy",
  traffic = "traffic",
  inventory = "inventory",
  billing = "billing",
  users = "users",
  userNew = "userNew",
  userEdit = "userEdit",
  predictModels = "predictModels",
  predictModelNew = "predictModelNew",
  predictModelEdit = "predictModelEdit",
  organization = "organization",
  testReporterQuery = "testReporterQuery",
  notFound = "notFound",
}
