import { USER_ROLES } from "model/auth";
import { RouteNames } from "./routeNames";

export const ROUTES_WHITELIST_FOR_ROLE = {
  [USER_ROLES.USER_READ_ONLY]: {
    [RouteNames.analytics]: true,
    [RouteNames.analyticsReport]: true,
    [RouteNames.analyticsReportWithParams]: true,
    [RouteNames.analyticsNamedReport]: true,
    [RouteNames.analyticsJustSavedReport]: true,
    [RouteNames.campaigns]: true,
    [RouteNames.campaignView]: true,
    [RouteNames.creatives]: true,
    [RouteNames.traffic]: true,
    [RouteNames.inventory]: true,
    [RouteNames.billing]: true,
    [RouteNames.notFound]: true,
    [RouteNames.monetizationRoot]: true,
    [RouteNames.monetizationAppsList]: true,
    [RouteNames.monetizationReport]: true,
  },
};

export const ROUTES_BLACKLIST_FOR_ROLE = {
  DEFAULT: {
    [RouteNames.users]: true,
    [RouteNames.userNew]: true,
    [RouteNames.userEdit]: true,
    [RouteNames.predictModels]: true,
    [RouteNames.suggestedOptimisations]: true,
    [RouteNames.organization]: true,
  },
  [USER_ROLES.ADMIN]: {
    [RouteNames.users]: false,
    [RouteNames.userNew]: false,
    [RouteNames.userEdit]: false,
    [RouteNames.predictModels]: false,
    [RouteNames.suggestedOptimisations]: false,
  },
  [USER_ROLES.ORGANIZATION_ADMIN]: {
    [RouteNames.users]: false,
    [RouteNames.userNew]: false,
    [RouteNames.userEdit]: false,
    [RouteNames.predictModels]: false,
    [RouteNames.suggestedOptimisations]: false,
  },
};

export const ROOT_ROUTE_FOR_ROLE = {
  [USER_ROLES.USER_READ_ONLY]: RouteNames.analytics,
  DEFAULT: RouteNames.campaigns,
};
