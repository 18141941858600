import CheckIcon from "@mui/icons-material/Check";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import cx from "classnames";
import { makeStyles, ToggleButton } from "components/mui-core";
import React from "react";
import { useFeatureTogglesList } from "./useFeatureToggles";

const useFeatureTogglesStyles = makeStyles({
  root: {
    color: "#fff",
    width: "100%",
  },
  toggle: {
    display: "flex",
    color: "#fff",
    margin: "10px 15px 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  check: {
    "& path": { fill: "#fff" },
  },
});

type FeatureTogglesProps = {
  className?: string;
  showLabels?: boolean;
};

function FeatureToggles({ className, showLabels }: FeatureTogglesProps) {
  const cls = useFeatureTogglesStyles();
  const toggles = useFeatureTogglesList();

  React.useEffect(() => {
    toggles.forEach(({ id, onChange }) => {
      const keyExists = localStorage.getItem(id);
      onChange(!!keyExists);
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const saveToLocalStorage = (id: string, enabled: boolean) => {
    if (enabled) {
      localStorage.setItem(id, "enabled");
    } else {
      localStorage.removeItem(id);
    }
  };

  const getIcon = (checked) => {
    if (checked) return <CheckIcon className={cls.check} />;

    return <UncheckedIcon className={cls.check} />;
  };

  return (
    <div className={cx(className, cls.root)}>
      {toggles.map(({ id, label, onChange, value }) => (
        <div
          key={id}
          className={cls.toggle}>
          {showLabels && <label>{label}</label>}
          <ToggleButton
            value={label}
            selected={value as boolean}
            onChange={() => {
              const newValue = !value;
              onChange(newValue);
              saveToLocalStorage(id, newValue);
            }}>
            {getIcon(value)}
          </ToggleButton>
        </div>
      ))}
    </div>
  );
}

export default FeatureToggles;
