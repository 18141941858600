/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import classNames from "classnames";
import React from "react";

// @mui/material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import withStyles from "@mui/styles/withStyles";
// core components
import CircularCenteredLoader from "components/Loaders/CircularCentered";
import { makeStyles } from "components/mui-core";
import { LoadingState } from "model/Types/loading";
import customDropdownStyle from "../../assets/jss/material-dashboard-pro-react/components/customDropdownStyle";
import Button from "../CustomButtons/Button";

const useOwnStyles = makeStyles({
  menu: {
    display: "flex !important",
    flexDirection: "column",
  },
  menuItem: {
    display: "inline-flex !important",
    overflow: "visible",
    padding: "0 !important",
    "& > span:first-child": {
      display: "flex !important",
      justifyContent: "space-between",
      flexGrow: 1,
      padding: "10px 20px",
      position: "relative !important",
    },
    "&:hover": {
      background: "transparent !important",
      boxShadow: "none !important",
      "& > span:first-child": {
        color: "white",
        boxShadow:
          "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%)",
        backgroundColor: "#00acc1",
      },
    },
  },
});
interface ICustomDropdownProps {
  classes?;
  buttonText?: React.ReactNode;
  dropdownList?: any[];
  buttonProps?;
  dropup?: boolean;
  dropdownFilter?: React.ReactNode;
  dropdownHeader?: React.ReactNode;
  caret?: boolean;
  hoverColor?:
    | "dark"
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "rose";
  dropPlacement?:
    | "bottom"
    | "top"
    | "right"
    | "left"
    | "bottom-start"
    | "bottom-end"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  rtlActive?: boolean;
  noLiPadding?: boolean;
  innerDropDown?: boolean;
  navDropdown?: boolean;
  loadStatus?: LoadingState;
  preventCloseOnClick?: boolean;
  onClose?: () => void;
  onClick?: (param) => void;
  ButtonIcon?: React.JSXElementConstructor<any>;
  disablePortal?: boolean;
  CustomButton?: React.FC<any>;
}

const CustomDropdown = ({
  classes = {},
  buttonText,
  ButtonIcon,
  dropdownList,
  buttonProps,
  dropup = false,
  dropdownFilter,
  dropdownHeader,
  caret = true,
  hoverColor = "primary",
  dropPlacement,
  rtlActive,
  noLiPadding,
  innerDropDown,
  navDropdown,
  preventCloseOnClick = false,
  onClose,
  onClick,
  loadStatus,
  disablePortal = true,
  CustomButton,
}: ICustomDropdownProps) => {
  const ownClasses = useOwnStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((o) => !o);
  };

  const handleClose = (event) => {
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    onClose?.();
    setOpen(false);
  };

  const handleCloseMenu = (param) => {
    if (!preventCloseOnClick) {
      onClose?.();
      setOpen(false);
    }
    onClick?.(param);
  };

  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretDropup]: dropup && !open,
    [classes.caretActive]: open && !dropup,
    [classes.caretRTL]: rtlActive,
  });

  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + "Hover"]]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });

  const dropDownMenu = (
    <div>
      {dropdownFilter && (
        <MenuItem
          className={classes.dropdownHeader}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}>
          {dropdownFilter}
        </MenuItem>
      )}
      <MenuList
        role="menu"
        className={classNames(ownClasses.menu, classes.menuList)}>
        {loadStatus === "loading" && (
          <MenuItem
            className={classes.dropdownHeader}
            onKeyDown={(e) => e.stopPropagation()}>
            <CircularCenteredLoader size={30} />
          </MenuItem>
        )}
        {!dropdownList?.length ? (
          <MenuItem
            className={classes.dropdownHeader}
            onKeyDown={(e) => e.stopPropagation()}
            style={{ textAlign: "center" }}>
            {loadStatus === "initial" ? "Start typing to search..." : null}
            {loadStatus === "loaded" || !loadStatus ? "No results" : null}
            {loadStatus === "failed"
              ? "There was an error fetching options."
              : null}
          </MenuItem>
        ) : null}
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}>
            {dropdownHeader}
          </MenuItem>
        ) : null}

        {dropdownList?.map((prop: any, key) => {
          if (prop.divider) {
            return (
              <Divider
                key={key}
                onClick={() => handleCloseMenu("divider")}
                className={classNames(
                  ownClasses.menuItem,
                  classes.dropdownDividerItem
                )}
              />
            );
          } else if (
            prop.props !== undefined &&
            prop.props["data-ref"] === "multi"
          ) {
            return (
              <MenuItem
                key={key}
                className={classNames(ownClasses.menuItem, dropdownItem)}>
                {prop}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={key}
              onClick={() => handleCloseMenu(prop)}
              className={classNames(ownClasses.menuItem, dropdownItem)}>
              {prop}
            </MenuItem>
          );
        })}
      </MenuList>
    </div>
  );

  return (
    <div className={innerDropDown ? classes.innerManager : classes.manager}>
      <div className={buttonText !== undefined ? "" : classes.target}>
        {CustomButton ? (
          <CustomButton
            buttonRef={anchorRef}
            onClick={handleClick}
            {...buttonProps}
          />
        ) : (
          <Button
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            buttonRef={anchorRef}
            {...buttonProps}
            onClick={handleClick}>
            {ButtonIcon ? <ButtonIcon className={classes.buttonIcon} /> : null}
            {buttonText !== undefined ? buttonText : null}
            {caret ? <b className={caretClasses} /> : null}
          </Button>
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        disablePortal={disablePortal}
        placement={dropPlacement}
        className={classNames({
          [classes.popperClose]: !open,
          [classes.popperResponsive]: true,
          [classes.popperNav]: open && navDropdown,
        })}
        style={{ maxWidth: 300 }}>
        {() => (
          <Grow
            in={open}
            style={
              dropup
                ? { transformOrigin: "0 100% 0" }
                : { transformOrigin: "0 0 0" }
            }>
            <Paper
              className={classes.dropdown}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrag={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {innerDropDown ? (
                dropDownMenu
              ) : (
                <ClickAwayListener onClickAway={handleClose}>
                  {dropDownMenu}
                </ClickAwayListener>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default withStyles(customDropdownStyle as any)(CustomDropdown);
