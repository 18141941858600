import Snackbar, { ISnackbarProps } from "components/Notifications/Snackbar";

function SnackbarOnError({
  duration,
  ...props
}: Omit<ISnackbarProps, "variant">) {
  return (
    <Snackbar
      {...props}
      duration={duration}
      variant="error"
    />
  );
}

export default SnackbarOnError;
