import { abbreviateAndFormat, floatFormatted, shortSiNumber } from "helpers";
import { DimensionIds } from "model/reporterDb/dbTableCampaign/dimensions";
import {
  Events,
  Metrics,
  NumericDimensions,
} from "model/reporterDb/dbTableCampaign/numericMeasures";
import {
  eventDollarFormatted,
  eventNumberFormatted,
  isInfinity,
  onlyNumericCalculatePercent,
  onlyNumericFloat,
  onlyNumericPercent,
} from "./primitiveNormalization";

export const APP_TYPES = {
  [DimensionIds.APP_BUNDLE_COLUMN_ID]: true,
  [DimensionIds.CAMPAIGN_BUNDLE_COLUMN_ID]: true,
  [DimensionIds.LAST_BUNDLE_COLUMN_ID]: true,
};

export const PRICE_TYPES = {
  [Events.spend]: true,
  [Metrics.ecpm]: true,
  [Metrics.ecpc]: true,
  [Metrics.ecpi]: true,
  [Metrics.skanCPI]: true,
  [Metrics.cpr]: true,
  [Events.revenue]: true,
  [Metrics.skanCpa]: true,
  [Metrics.skanPcpa]: true,
};

export const PERCENT_TYPES = {
  [Metrics.ctr]: true,
  [Metrics.ir]: true,
  [Metrics.skanIR]: true,
  [Metrics.roas]: true,
  [Metrics.delta]: true,
  [Metrics.commission]: true,
};

export const EVENTS_N_METRICS_NORMALIZATION: Record<
  string,
  (
    value?: any,
    abbreviate?: any,
    startFrom?: any,
    otherValues?: Record<string, any>
  ) => any
> = {
  [Events.bid]: eventNumberFormatted,
  [Events.impression]: eventNumberFormatted,
  [Events.click]: eventNumberFormatted,
  [Events.pvInstall]: eventNumberFormatted,
  [Events.pcInstall]: eventNumberFormatted,
  [Events.pvaInstall]: eventNumberFormatted,
  [Events.pcaInstall]: eventNumberFormatted,
  [Events.pvnInstall]: eventNumberFormatted,
  [Events.pcnInstall]: eventNumberFormatted,
  [Events.mmpInstall]: eventNumberFormatted,
  [Events.skadInstall]: eventNumberFormatted,
  [Events.skanRedownload]: eventNumberFormatted,
  [Events.skadSecondPostback]: eventNumberFormatted,
  [Events.skadThirdPostback]: eventNumberFormatted,
  [Events.sales]: eventNumberFormatted,
  [Events.revenue]: (num = 0) => floatFormatted(num),
  [Events.spend]: eventDollarFormatted,
  [Metrics.ecpm]: eventDollarFormatted,
  [Metrics.ctr]: (num = 0) => onlyNumericPercent(num),
  [Metrics.ecpc]: eventDollarFormatted,
  [Metrics.ir]: (num = 0) => onlyNumericPercent(num),
  [Metrics.ecpi]: eventDollarFormatted,
  [Metrics.skanIR]: (num = 0) => onlyNumericPercent(num),
  [Metrics.skanCPI]: eventDollarFormatted,
  [Metrics.cpr]: eventDollarFormatted,
  [Metrics.cpa]: eventDollarFormatted,
  [Metrics.skanCpa]: eventDollarFormatted,
  [Metrics.skanPcpa]: (val, abbreviate, startFrom, { skanCoarseRatio } = {}) =>
    skanCoarseRatio >= 0.2 ? eventDollarFormatted(val) : "NULL",
  [Metrics.winrate]: (num = 0) => onlyNumericCalculatePercent(num),
  [Metrics.cvr]: (num = 0) => onlyNumericPercent(num),
  [Metrics.ipm]: (num = 0) => onlyNumericFloat(num),
  [Metrics.skanIPM]: (num = 0) => onlyNumericFloat(num),
  [Metrics.roas]: (num = 0) => onlyNumericPercent(num, true),
  [NumericDimensions.priceOriginal]: eventDollarFormatted,
};

export const METRICS_WHERE_SMALLER_IS_BETTER = {
  [Metrics.ecpi]: true,
  [Metrics.skanCPI]: true,
  [Metrics.cpa]: true,
  [Metrics.skanCpa]: true,
  [Metrics.skanPcpa]: true,
};

export const FORMAT = {
  spend: (value) => `$${value}`,
  ecpm: (value) => `$${value}`,
  ctr: (value) => `${value}%`,
  ecpc: (value) => `$${value}`,
  ir: (value) => `${value}%`,
  ecpi: (value) => `$${value}`,
  cpr: (value) => `$${value}`,
  winrate: (value) => `${value}%`,
  cvr: (value) => `${value}%`,
  AppMeasureCPA: (value) => `$${value}`,
  cpa: (value) => `$${value}`,
  skanIR: (value) => `${value}%`,
  skanCPI: (value) => `$${value}`,
  roas: (value) => `${value}%`,
  revenue: (value) => `$${value}`,
  skanCpa: (value) => `$${value}`,
  skanPcpa: (value) => `$${value}`,
};

export const UNITS_LABELS = {
  spend: "spend ($)",
  ecpm: "ecpm ($)",
  ctr: "ctr (%)",
  ecpc: "ecpc ($)",
  ir: "ir (%)",
  ecpi: "ecpi ($)",
  winrate: "winrate (%)",
  skanIR: "skan ir (%)",
  skanCPI: "skan cpi ($)",
};

export const normalizeAndFormatByKey = ({
  key,
  value = 0,
  abbreviate,
}: {
  key: string;
  value?: number;
  abbreviate?: boolean;
}) => {
  if (isNaN(value) || isInfinity(value)) {
    return 0;
  }

  if (key === "number") {
    if (abbreviate) return abbreviateAndFormat({ num: value });

    return value.toLocaleString("en-US", {
      style: "decimal",
      useGrouping: true,
    });
  }

  if (key === "percent") {
    return value.toLocaleString("en-US", {
      style: "percent",
      maximumFractionDigits: 2,
    });
  }

  if (key === "short") {
    return shortSiNumber(value, 1);
  }

  const normalizer = EVENTS_N_METRICS_NORMALIZATION[key];
  const formatter = FORMAT[key];

  if (normalizer) {
    const normalized = normalizer(value);
    return formatter ? formatter(normalized) : normalized;
  }

  if (formatter) {
    if (abbreviate)
      return abbreviateAndFormat({ num: value, isCurrency: true });

    return formatter(value);
  }

  return value;
};
