import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import React from "react";

export type TriggerButtonProps = {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
};

export type IFormDialogProps = {
  TriggerButton?: React.FC<TriggerButtonProps>;
  onConfirm?: () => void;
  children?: React.ReactNode;
  dialogTitle: React.ReactNode;
  dialogSubtitle?: string;
  isDisabled?: boolean;
  confirmButtonTooltip?: string;
  isOpen?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onClose?: () => void;
  extraButtonsLeft?;
  extraButtonsBetween?;
  extraButtonsRight?;
  closeOnConfirm?: boolean;
  closeOnBackdropClick?: boolean;
  maxWidth?: "sm" | "md" | "lg";
  classes?: {
    root?: string;
    contentRoot?: string;
  };
};

export default function FormDialog({
  TriggerButton,
  onConfirm,
  onClose,
  children,
  dialogTitle,
  dialogSubtitle,
  isDisabled,
  confirmButtonTooltip,
  isOpen = false,
  confirmButtonLabel = "Ok",
  cancelButtonLabel = "Cancel",
  extraButtonsLeft,
  extraButtonsBetween,
  extraButtonsRight,
  closeOnConfirm = true,
  closeOnBackdropClick = true,
  maxWidth,
  classes,
}: IFormDialogProps) {
  const [open, setOpen] = React.useState(isOpen);
  React.useEffect(() => setOpen(isOpen), [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (ev, reason) => {
    if (reason === "backdropClick" && !closeOnBackdropClick) {
      return;
    }
    setOpen(false);
    onClose?.();
  };

  const handleConfirm = () => {
    onConfirm?.();
    if (closeOnConfirm) {
      setOpen(false);
    }
  };

  return (
    <>
      {TriggerButton && <TriggerButton onClick={handleClickOpen} />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes?.root,
        }}
        maxWidth={maxWidth}>
        {dialogTitle && (
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        )}
        <DialogContent classes={{ root: classes?.contentRoot }}>
          {dialogSubtitle && (
            <DialogContentText>{dialogSubtitle}</DialogContentText>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          {extraButtonsLeft}
          <Button
            simple
            onClick={handleClose as (event: MouseEvent) => void}
            color="primary"
            data-qa="dialog_cancel">
            {cancelButtonLabel}
          </Button>
          {extraButtonsBetween}
          {onConfirm && (
            <Tooltip title={confirmButtonTooltip}>
              <Button
                onClick={handleConfirm}
                color="primary"
                data-qa="dialog_confirm"
                disabled={isDisabled}>
                {confirmButtonLabel}
              </Button>
            </Tooltip>
          )}
          {extraButtonsRight}
        </DialogActions>
      </Dialog>
    </>
  );
}
