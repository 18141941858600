"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCohortedAppMeasureExpr = exports.APP_MEASURE_SELECT_QUERY = void 0;
const query_1 = require("../query");
const customEvent_1 = require("./customEvent");
const queryMapCohorted_1 = require("./queryMapCohorted");
exports.APP_MEASURE_SELECT_QUERY = {
    appMeasure: ({ eventName, kind }) => {
        // default is "count"
        if (kind === customEvent_1.CustomEventKind.sum)
            return (0, query_1.valueTemplate)(eventName);
        return (0, query_1.countTemplate)(eventName);
    },
    appMeasureCPA: (eventName) => (0, query_1.divTemplate)((0, query_1.aliasExprTemplate)("$SPENT"), (0, query_1.countTemplate)(eventName)),
};
function getCohortedAppMeasureExpr({ eventName, columnType, eventKind, cohort, onException, }) {
    const cohortCondition = (0, queryMapCohorted_1.getCohortCondition)(cohort);
    if (columnType === "CohortedAppMeasure") {
        return (0, query_1.withCondition)(exports.APP_MEASURE_SELECT_QUERY.appMeasure({ eventName, kind: eventKind }), cohortCondition);
    }
    if (columnType === "CohortedAppMeasureCPA") {
        return (0, query_1.withCondition)(exports.APP_MEASURE_SELECT_QUERY.appMeasureCPA(eventName), cohortCondition, ["$div", "args", "1", "expr", "$sum"]);
    }
    onException === null || onException === void 0 ? void 0 : onException(`No query expression for cohorted, eventName:${eventName}, columnType:${columnType}, eventKind:${eventKind}, cohort:${cohort}`);
    return query_1.DUMMY_EXPRESSION;
}
exports.getCohortedAppMeasureExpr = getCohortedAppMeasureExpr;
