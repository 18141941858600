const ERRORS = {
  BAD_REQUEST: {
    status: 400,
  },
  UNAUTHORIZED: {
    status: 401,
  },
  FORBIDDEN: {
    status: 403,
  },
  NOT_FOUND: {
    status: 404,
  },
  INTERNAL_SERVER_ERROR: {
    status: 500,
  },
  BAD_GATEWAY: {
    status: 502,
  },
  SERVICE_UNAVAILABLE: {
    status: 503,
  },
};
export default ERRORS;

export const ERROR_MESSAGES = {
  FORBIDDEN_ORGANIZATION: {
    regexp:
      /Logged user(.*?)does not belong to organization (?<organizationGroup>.*)/,
    label: "Logged user does not belong to organization",
  },
};
