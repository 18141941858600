import { createSlice } from "@reduxjs/toolkit";
import {
  applyOrganizationExtraReducers,
  organizationReducers,
} from "./orgReducers";
import { IAuthState } from "./types";
import { applyUserExtraReducers } from "./userExtraReducers";

const defaultState: IAuthState = {
  username: "",
  organizations: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    ...organizationReducers,
    setServiceUnavailable(state, action) {
      state.isServiceUnavailable = action.payload;
    },
    expireSession(state) {
      state.username = "";
      state.isLoggedIn = false;
    },
    setOrganization(state, action) {
      state.organizationId = action.payload;
    },
    updateOrganization(state, action) {
      if (!action?.payload?.id) return;
      state.organizations.forEach((org) => {
        if (org.id === action.payload.id) {
          Object.assign(org, action.payload);
        }
        return org;
      });
    },
    setAccount(state, action) {
      state.accountId = action.payload;
    },
    setupMFA(state) {
      state.mfa = state.mfa || {};
      state.mfa.mfaEnabled = true;
      state.mfa.mfaSharedSecret = true;
    },
    removeMFA(state) {
      state.mfa = {};
    },
  },
  extraReducers: (builder) => {
    applyUserExtraReducers(builder);
    applyOrganizationExtraReducers(builder);
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
