import CsvDownloadsDialog from "./CsvDownloadsDialog";

function RouteAgnosticDialogs() {
  return (
    <>
      <CsvDownloadsDialog kind="analytics" />
      <CsvDownloadsDialog kind="traffic" />
      <CsvDownloadsDialog kind="inventory" />
    </>
  );
}

export default RouteAgnosticDialogs;
