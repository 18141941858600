import * as R from "ramda";
import {
  IGroupedData,
  ILabeledData,
  ILabeledRow,
  IParsedData,
  IRawTextData,
} from "../reportData";

export const splitRows = (reportTextRaw) =>
  reportTextRaw.split("\n").filter((textRow) => !!textRow);

type IParseData = (reportTextRaw: IRawTextData) => IParsedData;
export const parseData: IParseData = (reportTextRaw) =>
  splitRows(reportTextRaw).map((line) => line.split("\t"));

export const getDistinctValues = (data) => [
  ...new Set(
    splitRows(data)
      .map((line) => line.split("\t")[0])
      .sort()
  ),
];

export const identifyData = (data: IParsedData, columnNames: string[]) =>
  R.chain(R.zipObj(columnNames))(data as any) as any as ILabeledData;

export type IColumnConvertFn = (val: any, row: ILabeledRow) => any;

export type IIdentifyConvertData = (
  data: IParsedData,
  columnNames: string[],
  columnConvertFns?: { [key: string]: IColumnConvertFn }
) => ILabeledData;

export const identifyConvertData = (data, columnNames, columnConvertFns) => {
  const transform = (val, key, row) => {
    if (columnConvertFns?.[key]) {
      return columnConvertFns[key](val, row);
    }

    return val;
  };

  return R.chain(R.mapObjIndexed(transform))(
    identifyData(data, columnNames) as any
  ) as ILabeledData;
};

export const simpleFold = (data: ILabeledData, groupBy: string[]) => {
  //0-level only
  const groupedDataR = R.groupBy<ILabeledRow, string>(
    (row: ILabeledRow) => row[groupBy[0]],
    data
  );
  const mapGrouped = (key) => {
    return {
      [groupBy[0]]: key,
      rows: groupedDataR[key],
    };
  };
  const groupedData = R.chain(mapGrouped)(R.keys(groupedDataR) as any);

  return groupedData as any as IGroupedData;
};

export const foldKeepColumns = (
  data: ILabeledData,
  groupBy: string[],
  columnNames: string[]
) => {
  //0-level only
  const groupedDataR = R.groupBy((row: ILabeledRow) => row[groupBy[0]], data);
  const mapGrouped = (key) => {
    const row = {
      [groupBy[0]]: key,
      rows: groupedDataR[key],
    };
    R.forEach((name: string) => {
      if (!row[name]) {
        row[name] = "";
      }
    }, columnNames);
    return row;
  };

  const groupedData = R.chain(mapGrouped)(R.keys(groupedDataR) as any);

  return groupedData as unknown as IGroupedData;
};

export const foldAggrColumns = (
  data: ILabeledData,
  groupBy: string[],
  columnNames: string[],
  columnAggrFns: Record<string, (args: any) => any>
) => {
  //0-level only
  const groupedDataR = R.groupBy((row: any) => row[groupBy[0] as string], data);

  const mapGrouped = (key) => {
    const row = {
      [groupBy[0]]: key,
      rows: groupedDataR[key],
    };
    R.forEach((name: any) => {
      if (!row[name]) {
        row[name] = columnAggrFns[name]
          ? columnAggrFns[name](R.chain(R.prop(name))(row.rows as any))
          : "";
      }
    }, columnNames);
    return row;
  };

  const groupedData = R.chain(mapGrouped)(R.keys(groupedDataR) as any);

  return groupedData as unknown as IGroupedData;
};

export type IAggrFns = {
  default?: (v: any) => any;
  splitDefault?: (v: any) => any;
} & {
  [key: string]: (v: any) => any;
};
export const foldAggrColumnsMulti = (
  data: ILabeledData,
  groupBy: string[],
  topLevelColumns: string[],
  columnAggrFns: IAggrFns,
  shouldAppendRows = true
) => {
  const groupedDataR = R.groupBy(
    (row) => JSON.stringify(R.pick(groupBy, row)),
    data
  );

  const mapGrouped = (key) => {
    const parsedKey = JSON.parse(key);
    const rows = groupedDataR[key];
    const row = {
      ...parsedKey,
      ...(shouldAppendRows
        ? {
            rows,
          }
        : {}),
    };

    R.forEach((name: string) => {
      const isSplit = groupBy.includes(name);
      if (!row[name]) {
        const aggrFn: (v: any) => any | undefined =
          columnAggrFns[name] ||
          (isSplit ? columnAggrFns.splitDefault : columnAggrFns.default);

        row[name] = aggrFn
          ? aggrFn(
              R.chain(R.prop<any>(name))(rows as any).filter((v) => !isNaN(v))
            )
          : "";
      }
    }, topLevelColumns);

    return row;
  };

  const groupedData = R.chain(mapGrouped)(R.keys(groupedDataR) as any);

  return groupedData as IGroupedData;
};

export const foldAsLinked = (data, groupBy, columnNames, columnAggrFns) => {
  //0-level only
  const foldedData = foldAggrColumns(
    data,
    groupBy,
    columnNames,
    columnAggrFns
  ) as any;
  const root = R.chain((row: any) => {
    //eslint-disabled-next-line @typescript-eslint/no-unused-vars
    const { rows, ...columns } = row;
    return columns;
  }, foldedData);
  const link = groupBy[0];
  const inner = {};

  R.forEach((row: any) => {
    inner[row[link]] = {
      root: row.rows,
    };
  }, foldedData);

  return {
    root,
    link: groupBy[0],
    inner,
  };
};

export const parseWithNames = (rawData: string, eventNames: string[]) =>
  identifyData(parseData(rawData), eventNames);

export const groupByKey = (key: string, data: Record<string, any>[]) => {
  return R.groupBy<Record<string, any>, string>((r: any) => r[key], data);
};
