import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { TPlayable } from "model/playable";
import { IRootState } from "redux/reducer";

//  CREATE / UPDATE
export const createUpdatePlayable = (action: "create" | "update") =>
  createAsyncThunk(
    `playables/${action}Playable`,
    async ({ playable }: { playable: TPlayable }, thunkAPI) => {
      const state: IRootState = thunkAPI.getState() as IRootState;
      const { organizationId, accountId } = state.auth;

      if (!organizationId || !accountId) return null;

      return request.postJson(endpointMaker.playables.createUpdatePlayable(), {
        ...playable,
        organizationId,
        accountId,
      });
    }
  );

export const createPlayable = createUpdatePlayable("create");
export const updatePlayable = createUpdatePlayable("update");

// FETCH ACTIVE / ARCHIVED
export const fetchPlayables = (status: "active" | "archived") =>
  createAsyncThunk(
    `playables/fetch${status}Playables`,
    async (arg, thunkAPI) => {
      const state: IRootState = thunkAPI.getState() as IRootState;
      const { organizationId, accountId } = state.auth;

      if (!organizationId || !accountId) return null;

      const isArchived = status === "archived";

      return request.getJson(
        endpointMaker.playables.getPlayables(
          organizationId,
          accountId,
          isArchived
        )
      );
    }
  );

export const fetchActivePlayables = fetchPlayables("active");
export const fetchArchivedPlayables = fetchPlayables("archived");

// ARCHIVE / UNARCHIVE
export const changePlayableArchiveStatus = (status: "archive" | "unarchive") =>
  createAsyncThunk(
    `playables/${status}Playable`,
    async (playableId: string, thunkAPI) => {
      const state: IRootState = thunkAPI.getState() as IRootState;

      const { organizationId, accountId } = state.auth;
      if (!organizationId || !accountId || !playableId) return null;

      const currentStatus = status === "archive" ? "active" : "archived";

      const playable = state.playables[currentStatus][playableId];

      const archived = status === "archive";

      return request
        .postJson(endpointMaker.playables.createUpdatePlayable(), {
          ...playable,
          archived,
        })
        .then(() => playableId);
    }
  );

export const archivePlayable = changePlayableArchiveStatus("archive");
export const unarchivePlayable = changePlayableArchiveStatus("unarchive");
