"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cohortedColumnsToCohortedMeasureItem = void 0;
const _1 = require(".");
function cohortedColumnsToCohortedMeasureItem(columns) {
    return columns.flatMap((cohortedMeasure) => {
        var _a;
        return (((_a = cohortedMeasure.cohorts) === null || _a === void 0 ? void 0 : _a.map((cohort) => ({
            columnId: cohortedMeasure.id,
            eventId: cohortedMeasure.columnType === _1.ColumnTypes.Cohorted
                ? cohortedMeasure.eventId
                : cohortedMeasure.value,
            cohort,
            eventKind: cohortedMeasure.kind,
            columnType: cohortedMeasure.columnType,
            eventLabel: cohortedMeasure.label,
        }))) || []);
    });
}
exports.cohortedColumnsToCohortedMeasureItem = cohortedColumnsToCohortedMeasureItem;
