import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import { Integer } from "model/modelTypes";
import { IPlayablesObject } from "model/playable";
import {
  changePlayableArchiveStatusReducer,
  createUpdatePlayableReducer,
  getPlayablesReducer,
} from "./playablesExtraReducers";

export interface IPlayablesState {
  loadingState: LoadingState;
  error: null | SerializedError;
  active: IPlayablesObject;
  archived: IPlayablesObject;
  lastActivePlayablesFetch: Integer;
  expanded: { [tabNo: number]: Record<string, unknown> };
  filter: {
    folders: string;
    loose: string;
  };
  showArchived: boolean;
}

const initialState: IPlayablesState = {
  loadingState: LoadingState.initial,
  error: null,
  active: {},
  archived: {},
  lastActivePlayablesFetch: 0,
  expanded: {},
  filter: {
    folders: "",
    loose: "",
  },
  showArchived: false,
} as IPlayablesState;

export const playablesSlice = createSlice({
  name: "playables",
  initialState,
  reducers: {
    setExpanded(state, action) {
      state.expanded = action.payload;
    },
    setFoldersFilter(state, action) {
      state.filter.folders = action.payload;
    },
    clearFoldersFilter(state) {
      state.filter.folders = "";
    },
    setLooseFilter(state, action) {
      state.filter.loose = action.payload;
    },
    clearLooseFilter(state) {
      state.filter.loose = "";
    },
    clearFilters(state) {
      state.filter.loose = "";
      state.filter.folders = "";
    },
    setShowArchived(state, action) {
      state.showArchived = action.payload;
    },
  },
  extraReducers: (builder) => {
    getPlayablesReducer(builder, "active");
    getPlayablesReducer(builder, "archived");

    createUpdatePlayableReducer(builder, "create");
    createUpdatePlayableReducer(builder, "update");

    changePlayableArchiveStatusReducer(builder, "archive");
    changePlayableArchiveStatusReducer(builder, "unarchive");
  },
});

export default playablesSlice.reducer;
