"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOOLEAN_TYPED_FILTERS_MAP = void 0;
exports.BOOLEAN_TYPED_FILTERS_MAP = {
    TRACKING_ALLOWED: true,
    DIRECT_TRAFFIC: true,
    MODEL_IGNORED: true,
    SKOVERLAY_RESPONSE: true,
    AUTO_STOREKIT_RESPONSE: true,
    DUAL_ENDCARD_RESPONSE: true,
    RE_DOWNLOAD: true,
    DID_WIN: true,
    SOURCE_APP_ID_PRESENT: true,
};
