export enum CampaignsSortBy {
  AppName = "appName",
  // OldestToNewest = 'oldestToNewest',
  // NewestToOldest = 'newestToOldest',
  CampaignNameAZ = "campaignNameAz",
  RunState = "runState",
}

export enum CampaignsFilterBy {
  Active = "active",
  Archived = "archived",
}

export type AttributionSource = "MMP" | "SKAN";
