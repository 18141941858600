import {
  ANY_PAGE_AUTH_QUERY_PARAMS,
  IOrganizationAccount,
  IOrganizationItem,
} from "model/organizations";
import { IRootState } from "../reducer";
import { authSlice } from "./authSlice";
import { getAccountsByOrganizationId } from "./orgAsyncActions";
import { IAuthState } from "./types";

const setInitialOrgAccFromDefault = () => {
  return async function (dispatch, getState) {
    const state: IRootState = getState();
    const { defaultOrganizationId, username } = state.auth as IAuthState;

    if (!defaultOrganizationId) {
      return dispatch(
        authSlice.actions.setOrgSwitchError(
          `There is no default organization for user ${username}`
        )
      );
    }
    let { defaultAccountId } = state.auth as IAuthState;
    if (defaultAccountId) {
      return dispatch(
        authSlice.actions.setInitialOrgAndAcc({
          organizationId: defaultOrganizationId,
          accountId: defaultAccountId,
        })
      );
    }

    try {
      const accountsResp:
        | {
            payload: {
              accounts: IOrganizationAccount[];
              organizationId: string;
            };
          }
        | undefined = await dispatch(
        getAccountsByOrganizationId(defaultOrganizationId)
      );
      defaultAccountId = accountsResp?.payload?.accounts?.[0]?.id;
      if (!defaultAccountId) {
        return dispatch(
          authSlice.actions.setOrgSwitchError(
            `There are no accounts available for user ${username} in organization with id ${defaultOrganizationId}`
          )
        );
      }
      return dispatch(
        authSlice.actions.setInitialOrgAndAcc({
          organizationId: defaultOrganizationId,
          accountId: defaultAccountId,
        })
      );
    } catch (e: any) {
      dispatch(
        authSlice.actions.setOrgSwitchError(
          `Unable to select default account for ${username} in organization with id ${defaultOrganizationId}: ${e.message}`
        )
      );
    }
  };
};

const setInitialOrgAccFromLocalStorage = () => {
  return async function (dispatch, getState) {
    const state: any = getState();
    const { username, organizations } = state.auth as IAuthState;

    const storedOrgId = localStorage.getItem(`organization-${username}`);
    const storedAccId = localStorage.getItem(`account-${username}`);

    if (storedOrgId && storedAccId) {
      const initialOrg = organizations.find((o) => o.id === storedOrgId);
      if (initialOrg) {
        let accounts = initialOrg.accounts;
        if (!accounts) {
          try {
            const accountsResp = await dispatch(
              getAccountsByOrganizationId(initialOrg.id)
            );
            accounts = accountsResp?.payload?.accounts;
          } catch (e) {
            //set acc from local storage is not user's action, I think no need of showing error
            return dispatch(setInitialOrgAccFromDefault());
          }
        }

        const initialAcc = accounts?.find((a) => a.id === storedAccId);
        if (initialAcc) {
          return dispatch(
            authSlice.actions.setInitialOrgAndAcc({
              organizationId: initialOrg.id,
              accountId: initialAcc.id,
            })
          );
        }
      }
    }

    return dispatch(setInitialOrgAccFromDefault());
  };
};

export const switchOrgAndAccAtOnce = (
  orgId: string,
  accId: string,
  onSuccess?: any,
  onError?: any
) => {
  return async function (dispatch, getState) {
    const state: any = getState();
    const { username, organizations } = state.auth as IAuthState;

    if (!orgId || !accId) return;

    const org = organizations.find((o) => o.id === orgId);
    if (org) {
      let accounts = org.accounts;
      if (!accounts) {
        try {
          const accountsResp = await dispatch(
            getAccountsByOrganizationId(org.id)
          );
          accounts = accountsResp?.payload?.accounts;
        } catch (e) {
          onError?.();
        }
      }

      const acc = accounts?.find((a) => a.id === accId);
      if (acc) {
        localStorage.setItem(`organization-${username}`, orgId);
        localStorage.setItem(`account-${username}`, accId);
        dispatch(
          authSlice.actions.setInitialOrgAndAcc({
            organizationId: org.id,
            accountId: acc.id,
          })
        );

        if (onSuccess) {
          onSuccess();
        } else {
          window.location.reload();
        }
      } else {
        onError?.();
      }
    }
  };
};

export const setInitialOrgAndAcc = () => {
  return async function (dispatch, getState) {
    const state: any = getState();
    const { organizations, username } = state.auth as IAuthState;

    //1st priority, error if fail: set org/acc from url param
    const parsedUrl = new URL(window.location.href);
    const urlParamOrganization = parsedUrl.searchParams.get(
      ANY_PAGE_AUTH_QUERY_PARAMS.organizationId
    );
    const urlParamAccount = parsedUrl.searchParams.get(
      ANY_PAGE_AUTH_QUERY_PARAMS.accountId
    );
    //2nd priority, optional: org/acc from localstorage
    if (!urlParamOrganization || !urlParamAccount) {
      return dispatch(setInitialOrgAccFromLocalStorage());
    }

    const initialOrg = organizations.find(
      (o) => o.id === urlParamOrganization
    ) as IOrganizationItem;
    if (!initialOrg) {
      dispatch(
        authSlice.actions.setOrgSwitchError(
          `User ${username} does not have access to the organization with id ${urlParamOrganization}`
        )
      );
      return dispatch(setInitialOrgAccFromDefault());
    }
    let accounts: IOrganizationAccount[] | undefined = initialOrg.accounts;

    if (!accounts) {
      try {
        const accountsResp = await dispatch(
          getAccountsByOrganizationId(initialOrg.id)
        );
        accounts = accountsResp?.payload?.accounts;
      } catch (e: any) {
        dispatch(
          authSlice.actions.setOrgSwitchError(
            `User ${username} does not have access to the accounts of organization with id ${urlParamOrganization}: ${e.message}`
          )
        );
        return dispatch(setInitialOrgAccFromDefault());
      }
    }

    const initialAcc = accounts?.find(
      (a) => a.id === urlParamAccount
    ) as IOrganizationAccount;
    if (!initialAcc) {
      dispatch(
        authSlice.actions.setOrgSwitchError(
          `User ${username} does not have access to the account with id ${urlParamAccount} of organization ${
            initialOrg.name ? initialOrg.name : "with id" + initialOrg.id
          }`
        )
      );
      return dispatch(setInitialOrgAccFromDefault());
    }

    dispatch(
      authSlice.actions.setInitialOrgAndAcc({
        organizationId: initialOrg.id,
        accountId: initialAcc.id,
      })
    );
  };
};

export const changeOrganization = (organizationId: string) => {
  return function (dispatch, getState) {
    const username = getState().auth?.username;
    if (organizationId) {
      localStorage.setItem(`organization-${username}`, organizationId);
    } else {
      localStorage.removeItem(`organization-${username}`);
    }
    dispatch(changeAccount(undefined));
    dispatch(authSlice.actions.setOrganization(organizationId));
  };
};

export const changeAccount = (accountId: string | undefined) => {
  return function (dispatch, getState) {
    const username = getState().auth?.username;
    if (accountId) {
      localStorage.setItem(`account-${username}`, accountId);
    } else {
      localStorage.removeItem(`account-${username}`);
    }
    dispatch(authSlice.actions.setAccount(accountId));
  };
};
