import Snackbar from "components/Notifications/Snackbar";

function SnackbarOnSave({ duration, ...props }) {
  return (
    <Snackbar
      {...props}
      duration={duration}
      variant="success"
    />
  );
}

SnackbarOnSave.defaultProps = {
  duration: 6000,
};

export default SnackbarOnSave;
