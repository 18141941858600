import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";

export const getUserOrganizations = createAsyncThunk(
  "auth/getUserOrganizations",
  async () => request.getJson(endpointMaker.account.organizations())
);

export const getAccountsByOrganizationId = createAsyncThunk(
  "auth/getAccountsByOrganizationId",
  async (organizationId: string) =>
    request
      .getJson(endpointMaker.account.account(organizationId))
      .then((accounts) => ({ organizationId, accounts }))
);
