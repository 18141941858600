import Layout from "components/Layout/Layout";
import { ROOT_ROUTE_FOR_ROLE } from "model/routing";
import navigationMenuItems from "model/routing/navigationMenuItems";
import { useOrganizationId, useRole } from "redux/auth/selectors";
import useGATracker from "../useGATracker";
import { ROUTES } from "./allRoutesList";

function NavigationLayout({ children }) {
  const role = useRole();
  const organizationId = useOrganizationId();
  const rootRouteName =
    (role && ROOT_ROUTE_FOR_ROLE[role]) || ROOT_ROUTE_FOR_ROLE.DEFAULT;
  const rootPath = ROUTES?.[rootRouteName]?.path;
  useGATracker(role, organizationId);

  if (!role) return null;

  const allowedMenuItems = navigationMenuItems.map((itemsGroup) =>
    itemsGroup.filter((mi) => {
      const isItemWhitelisted = !mi.whitelistRoles || mi.whitelistRoles[role];
      const isItemBlacklisted = mi.blacklistRoles && mi.blacklistRoles[role];
      return isItemWhitelisted && !isItemBlacklisted;
    })
  );

  return (
    <Layout
      navigationMenuItems={allowedMenuItems}
      rootPath={rootPath}>
      {children}
    </Layout>
  );
}

export default NavigationLayout;
