import { sortAlpha } from "helpers/arrays";
import { IOrganizationAccount } from "model/organizations";
import {
  getAccountsByOrganizationId,
  getUserOrganizations,
} from "./orgAsyncActions";
import { IAuthError } from "./types";

//async thunk actions reducers
export function applyOrganizationExtraReducers(builder) {
  builder.addCase(getUserOrganizations.fulfilled, (state, { payload = [] }) => {
    const organizationsSortedAlpha = payload.sort(({ name: a }, { name: b }) =>
      sortAlpha(a, b)
    );
    state.organizations = organizationsSortedAlpha;
  });

  builder.addCase(
    getAccountsByOrganizationId.fulfilled,
    (
      state,
      {
        payload,
      }: {
        payload: {
          accounts?: IOrganizationAccount[];
          organizationId: string;
        };
      }
    ) => {
      const updatedOrganization = state.organizations.find(
        (o) => o.id === payload.organizationId
      );
      if (updatedOrganization) {
        updatedOrganization.accounts = payload.accounts;
      }
    }
  );

  builder.addCase(getUserOrganizations.rejected, (state, action) => {
    state.orgSwitchError = `Failed to load organizations: ${
      action.error?.message || "unknown error"
    }`;
  });
}

//actions reducers
export const organizationReducers = {
  setInitialOrgAndAcc(
    state,
    action: { payload: { organizationId: string; accountId: string } }
  ) {
    state.organizationId = action.payload.organizationId;
    state.accountId = action.payload.accountId;
  },
  setOrgSwitchError(state, action: { payload: string }) {
    state.orgSwitchError = action.payload;
  },
  setError(state, { payload }: { payload: IAuthError }) {
    state = { ...state, ...payload };
  },
};
