"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NUMERIC_MEASURES_LABELED = exports.NUMERIC_DIMENSION_LABELED = exports.UTILITY_COLUMNS_LABELED = exports.METRICS_LABELED = exports.EVENTS_LABELED = void 0;
const numericMeasures_1 = require("./numericMeasures");
exports.EVENTS_LABELED = {
    [numericMeasures_1.Events.bid]: { id: numericMeasures_1.Events.bid, label: "Bids" },
    [numericMeasures_1.Events.impression]: { id: numericMeasures_1.Events.impression, label: "Impressions" },
    [numericMeasures_1.Events.click]: { id: numericMeasures_1.Events.click, label: "Clicks" },
    [numericMeasures_1.Events.pvInstall]: { id: numericMeasures_1.Events.pvInstall, label: "PVInstalls" },
    [numericMeasures_1.Events.pcInstall]: { id: numericMeasures_1.Events.pcInstall, label: "PCInstalls" },
    [numericMeasures_1.Events.mmpInstall]: { id: numericMeasures_1.Events.mmpInstall, label: "MMP Installs" },
    [numericMeasures_1.Events.rejectedInstall]: {
        id: numericMeasures_1.Events.rejectedInstall,
        label: "Rejected Install",
    },
    [numericMeasures_1.Events.skadInstall]: { id: numericMeasures_1.Events.skadInstall, label: "SKAN Installs" },
    [numericMeasures_1.Events.skanRedownload]: {
        id: numericMeasures_1.Events.skanRedownload,
        label: "SKAN Redownloads",
    },
    [numericMeasures_1.Events.skadSecondPostback]: {
        id: numericMeasures_1.Events.skadSecondPostback,
        label: "SKAD 2nd Postback",
    },
    [numericMeasures_1.Events.skadThirdPostback]: {
        id: numericMeasures_1.Events.skadThirdPostback,
        label: "SKAD 3rd Postback",
    },
    [numericMeasures_1.Events.skanAssist]: { id: numericMeasures_1.Events.skanAssist, label: "SKAN Assists" },
    [numericMeasures_1.Events.reattribution]: { id: numericMeasures_1.Events.reattribution, label: "Reattribution" },
    [numericMeasures_1.Events.spend]: { id: numericMeasures_1.Events.spend, label: "Spend" },
    [numericMeasures_1.Events.sales]: { id: numericMeasures_1.Events.sales, label: "Sales" },
    [numericMeasures_1.Events.revenue]: { id: numericMeasures_1.Events.revenue, label: "Revenue" },
};
exports.METRICS_LABELED = {
    [numericMeasures_1.Metrics.winrate]: { id: numericMeasures_1.Metrics.winrate, label: "Winrate" },
    [numericMeasures_1.Metrics.ecpm]: { id: numericMeasures_1.Metrics.ecpm, label: "eCPM" },
    [numericMeasures_1.Metrics.ctr]: { id: numericMeasures_1.Metrics.ctr, label: "CTR" },
    [numericMeasures_1.Metrics.ecpc]: { id: numericMeasures_1.Metrics.ecpc, label: "eCPC" },
    [numericMeasures_1.Metrics.ir]: { id: numericMeasures_1.Metrics.ir, label: "IR" },
    [numericMeasures_1.Metrics.ecpi]: { id: numericMeasures_1.Metrics.ecpi, label: "MMP CPI" },
    [numericMeasures_1.Metrics.cpr]: { id: numericMeasures_1.Metrics.cpr, label: "CPR" },
    [numericMeasures_1.Metrics.cvr]: { id: numericMeasures_1.Metrics.cvr, label: "CVR" },
    [numericMeasures_1.Metrics.cti]: { id: numericMeasures_1.Metrics.cti, label: "MMP CTI" },
    [numericMeasures_1.Metrics.skanCTI]: { id: numericMeasures_1.Metrics.cti, label: "SKAN CTI" },
    [numericMeasures_1.Metrics.cpa]: { id: numericMeasures_1.Metrics.cpa, label: "CPA" },
    [numericMeasures_1.Metrics.skanCpa]: { id: numericMeasures_1.Metrics.skanCpa, label: "SKAN CPA" },
    [numericMeasures_1.Metrics.skanPcpa]: { id: numericMeasures_1.Metrics.skanPcpa, label: "SKAN pCPA" },
    [numericMeasures_1.Metrics.skanCPI]: { id: numericMeasures_1.Metrics.skanCPI, label: "SKAN CPI" },
    [numericMeasures_1.Metrics.skanIR]: { id: numericMeasures_1.Metrics.skanIR, label: "SKAN IR" },
    [numericMeasures_1.Metrics.ipm]: { id: numericMeasures_1.Metrics.ipm, label: "IPM" },
    [numericMeasures_1.Metrics.skanIPM]: { id: numericMeasures_1.Metrics.skanIPM, label: "SKAN IPM" },
    [numericMeasures_1.Metrics.roas]: { id: numericMeasures_1.Metrics.roas, label: "ROAS" },
    [numericMeasures_1.Metrics.bidFloor]: { id: numericMeasures_1.Metrics.bidFloor, label: "Bid Floor" },
    [numericMeasures_1.Metrics.commission]: { id: numericMeasures_1.Metrics.commission, label: "Commission" },
    [numericMeasures_1.Metrics.delta]: { id: numericMeasures_1.Metrics.delta, label: "Delta" },
    [numericMeasures_1.Metrics.skanFineRatio]: {
        id: numericMeasures_1.Metrics.skanFineRatio,
        label: "SKAN Fine Ratio",
    },
    [numericMeasures_1.Metrics.skanCoarseRatio]: {
        id: numericMeasures_1.Metrics.skanCoarseRatio,
        label: "SKAN CV Ratio",
    },
    [numericMeasures_1.Metrics.skanDigit1Ratio]: {
        id: numericMeasures_1.Metrics.skanDigit1Ratio,
        label: "SKAN Digit 1 Ratio",
    },
    [numericMeasures_1.Metrics.skanDigit2Ratio]: {
        id: numericMeasures_1.Metrics.skanDigit2Ratio,
        label: "SKAN Digit 2 Ratio",
    },
    [numericMeasures_1.Metrics.skanSourceAppIdRatio]: {
        id: numericMeasures_1.Metrics.skanSourceAppIdRatio,
        label: "SKAN Source App Ratio",
    },
};
exports.UTILITY_COLUMNS_LABELED = {
    [numericMeasures_1.Metrics.skanCoarseRatioDividend]: {
        id: numericMeasures_1.Metrics.skanCoarseRatioDividend,
        label: "util-skanFineRatioDividend",
    },
    [numericMeasures_1.Metrics.skanCoarseRatioDivisor]: {
        id: numericMeasures_1.Metrics.skanCoarseRatioDivisor,
        label: "util-skanCoarseRatioDivisor",
    },
    [numericMeasures_1.Metrics.skanFineRatioDividend]: {
        id: numericMeasures_1.Metrics.skanFineRatioDividend,
        label: "util-skanFineRatioDividend",
    },
    [numericMeasures_1.Metrics.skanFineRatioDivisor]: {
        id: numericMeasures_1.Metrics.skanFineRatioDivisor,
        label: "util-skanFineRatioDivisor",
    },
    [numericMeasures_1.Metrics.skanDigit1RatioDividend]: {
        id: numericMeasures_1.Metrics.skanDigit1RatioDividend,
        label: "util-skanDigit1RatioDividend",
    },
    [numericMeasures_1.Metrics.skanDigit1RatioDivisor]: {
        id: numericMeasures_1.Metrics.skanDigit1RatioDivisor,
        label: "util-skanDigit1RatioDivisor",
    },
    [numericMeasures_1.Metrics.skanDigit2RatioDividend]: {
        id: numericMeasures_1.Metrics.skanDigit2RatioDividend,
        label: "util-skanDigit2RatioDividend",
    },
    [numericMeasures_1.Metrics.skanDigit2RatioDivisor]: {
        id: numericMeasures_1.Metrics.skanDigit2RatioDivisor,
        label: "util-skanDigit2RatioDivisor",
    },
    [numericMeasures_1.Metrics.skanSourceAppIdRatioDividend]: {
        id: numericMeasures_1.Metrics.skanSourceAppIdRatioDividend,
        label: "util-skanSourceAppIdRatioDividend",
    },
    [numericMeasures_1.Metrics.skanSourceAppIdRatioDivisor]: {
        id: numericMeasures_1.Metrics.skanSourceAppIdRatioDivisor,
        label: "util-skanSourceAppIdRatioDivisor",
    },
    [numericMeasures_1.Metrics.bidfloorSumDividend]: {
        id: numericMeasures_1.Metrics.bidfloorSumDividend,
        label: "util-bfSumDiv",
    },
};
exports.NUMERIC_DIMENSION_LABELED = {
    [numericMeasures_1.NumericDimensions.priceOriginal]: {
        id: numericMeasures_1.NumericDimensions.priceOriginal,
        label: "Revenue",
    },
    [numericMeasures_1.NumericDimensions.SKAN_CONVERSION_VALUE]: {
        id: numericMeasures_1.NumericDimensions.SKAN_CONVERSION_VALUE,
        label: "SKAN CV",
    },
};
exports.NUMERIC_MEASURES_LABELED = Object.assign(Object.assign(Object.assign({}, exports.EVENTS_LABELED), exports.METRICS_LABELED), exports.NUMERIC_DIMENSION_LABELED);
