type queueDictType = {
  [key: string]: {
    name: string;
    list: string[];
    lastCallAttempt: number;
    timer?: ReturnType<typeof setTimeout>;
  };
};

const queueDict: queueDictType = {};

type debounceCallback = (ids: string[]) => void;

type debounceCallsInput = {
  id: string;
  cb: debounceCallback;
  queueName: string;
  debounceTime?: number;
  queueLimit?: number;
};

const callAndClear = (queueName: string, cb: debounceCallback) => {
  cb(queueDict[queueName].list);
  queueDict[queueName].list = [];
};

const createQueue = (queueName) => ({
  name: queueName,
  list: [],
  lastCallAttempt: 0,
});

const debounceCalls = ({
  id,
  cb,
  queueName,
  debounceTime = 300,
  queueLimit = 0,
}: debounceCallsInput) => {
  const now = Date.now();

  if (!queueDict[queueName]) {
    queueDict[queueName] = createQueue(queueName);
  }

  queueDict[queueName].list.push(id);

  const { lastCallAttempt, timer } = queueDict[queueName];
  if (
    lastCallAttempt &&
    now - lastCallAttempt <= debounceTime &&
    typeof timer !== "undefined"
  ) {
    clearTimeout(timer);
  }

  if (queueLimit && queueDict[queueName].list.length > queueLimit) {
    callAndClear(queueName, cb);
    queueDict[queueName].lastCallAttempt = now;
    return null;
  }

  queueDict[queueName].timer = setTimeout(() => {
    callAndClear(queueName, cb);
  }, debounceTime);

  queueDict[queueName].lastCallAttempt = now;
};

export default debounceCalls;
