import { CampaignRunStateServer } from "model/campaigns";
import { LoadingState } from "model/Types/loading";
import { actions as authActions } from "redux/auth/authSlice";
import { startCampaign, stopCampaign } from "redux/campaigns/campaignActions";
import { fetchAllCampaignsStatuses } from "./campaignStatusActions";

export function fetchAllCampaignStatusesReducer(builder) {
  builder.addCase(fetchAllCampaignsStatuses.pending, (state) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
    state.error = null;
  });
  builder.addCase(fetchAllCampaignsStatuses.fulfilled, (state, action) => {
    state.loadingState = LoadingState.loaded;
    (action.payload || []).forEach(
      ({ id, ...rest }) => (state.data[id] = rest)
    );
  });
  builder.addCase(fetchAllCampaignsStatuses.rejected, (state, action) => {
    state.loadingState = LoadingState.failed;
    state.error = action.error?.message
      ? action.error
      : new Error("Unknown error");
  });
}

export function changeCampaignStatusReducer(builder) {
  builder.addCase(startCampaign.fulfilled, (state, action) => {
    if (state.data[action.payload]) {
      state.data[action.payload].runState = CampaignRunStateServer.Running;
    }
  });

  builder.addCase(stopCampaign.fulfilled, (state, action) => {
    if (state.data[action.payload]) {
      state.data[action.payload].runState = CampaignRunStateServer.Stopped;
    }
  });
}

export function clearStatusesOnAccountChangeReducer(builder) {
  builder.addCase(authActions.setAccount, (state) => {
    state.loadingState = LoadingState.initial;
    state.data = {};
    state.error = null;
  });
}
