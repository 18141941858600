import request from "http/request";
import { authSlice } from "./authSlice";
import * as orgActions from "./orgActions";
import * as orgAsyncActions from "./orgAsyncActions";
import * as asyncActions from "./userAsyncActions";

export const loginWithCredentials = (username: string, password: string) => {
  return async function (dispatch) {
    const oldUser = localStorage.getItem("username");
    if (oldUser) {
      try {
        await dispatch(asyncActions.logout(oldUser));
      } catch (e) {
        //return Promise.reject({ message: e?.message || "Failed to logout first" })
      }
    }

    dispatch(asyncActions.loginWithCredentials({ username, password })).then(
      () => localStorage.setItem("username", username)
    );
  };
};

export const loginWithMFA = (
  username: string,
  password: string,
  mfaCode: string
) => {
  return async function (dispatch) {
    dispatch(asyncActions.loginWithMFA({ username, password, mfaCode }));
  };
};

export const logout = (username: string) => {
  return function (dispatch) {
    dispatch(asyncActions.logout(username)).then(() => {
      const localItemsToBeKept = [
        `account-${username}`,
        `organization-${username}`,
        `traffic-${username}`,
      ];
      const vals = {};
      localItemsToBeKept.forEach((li) => (vals[li] = localStorage.getItem(li)));
      localStorage.clear();
      localItemsToBeKept.forEach(
        (li) => vals[li] !== null && localStorage.setItem(li, vals[li])
      );
    });
  };
};

export const getUserInfo = () => {
  return function (dispatch) {
    request.setExpireSessionHandler(() =>
      dispatch(authSlice.actions.expireSession())
    );
    request.setServiceUnavailableHandler(() =>
      dispatch(authSlice.actions.setServiceUnavailable(true))
    );

    dispatch(asyncActions.getUserInfo())
      .then(() => dispatch(orgAsyncActions.getUserOrganizations()))
      .then(() => dispatch(orgActions.setInitialOrgAndAcc()));
  };
};
