import { makeStyles } from "components/mui-core";
import { Snackbar } from "components/Notifications";
import React, { useContext, useEffect, useState } from "react";

const SnackbarContext = React.createContext<any>({});

interface Props {
  children: React.ReactNode;
}

interface SnackbarPayload {
  data;
  type: string;
}

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

const DEFAULTS = {
  body: "",
  payload: null,
  variant: "success" as const,
  closeTimeout: 8000,
  onClose: () => {
    //do nothing
  },
  onSave: () => {
    //do nothing
  },
};

const useStyles = makeStyles({
  topSnackbar: {
    zIndex: 3000,
  },
});

export const SnackbarProvider = ({ children }: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState<string | React.ReactNode>(DEFAULTS.body);
  const [onClose, setOnClose] = useState<() => void>(DEFAULTS.onClose);
  const [onSave, setOnSave] = useState<() => void>(DEFAULTS.onSave);
  const [payload, setPayload] = useState<SnackbarPayload | null>(
    DEFAULTS.payload
  );
  const [variant, setVariant] = useState<
    "success" | "warning" | "error" | "info"
  >(DEFAULTS.variant);
  const [closeTimeout, setCloseTimeout] = useState<number | undefined>(
    DEFAULTS.closeTimeout
  );

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
    restoreDefaults();
  };

  useEffect(() => {
    if (isOpen && closeTimeout) {
      setTimeout(() => {
        handleClose();
      }, closeTimeout);
    }
  }, [isOpen]);

  const restoreDefaults = () => {
    //so that one snackbar updated context does not leak into next opened snackbar.
    //For example, first one was modified as 'info' and next is default('success')
    setBody(DEFAULTS.body);
    setOnClose(DEFAULTS.onClose);
    setOnSave(DEFAULTS.onSave);
    setPayload(DEFAULTS.payload);
    setVariant(DEFAULTS.variant);
    setCloseTimeout(DEFAULTS.closeTimeout);
  };

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        body,
        setBody,
        onClose,
        setOnClose,
        onSave,
        setOnSave,
        payload,
        setPayload,
        variant,
        setVariant,
        closeTimeout,
        setCloseTimeout,
      }}>
      <Snackbar
        isOpen={isOpen}
        onClose={handleClose}
        message={body}
        variant={variant}
        className={classes.topSnackbar}></Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};
