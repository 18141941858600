import get from "lodash.get";
import {
  ICreativeApprovalStatus,
  ICreativeApprovalStatusServer,
  POLICY_COMPLIANCE_REQUIRED_FOR_APPROVAL,
} from "model/creative/creativeApprovalStatus";

export const getKindAndCreative = ({
  state,
  id,
}): { kind?: "active" | "archived"; creative } => {
  const active = get(state, ["active", id]);
  const archived = get(state, ["archived", id]);
  if (active) return { kind: "active", creative: active };
  if (archived) return { kind: "archived", creative: archived };
  return { creative: {} };
};

export const arrayToCreativesObject = (items) =>
  items?.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

export const updateCreativesObject = (currentCreatives, newCreatives) =>
  newCreatives?.reduce((acc, item) => {
    const current = currentCreatives[item.id] || {};
    acc[item.id] = {
      ...current,
      ...item,
    };
    return acc;
  }, {});

export const getNewApprovalStatus = (item: ICreativeApprovalStatusServer) => {
  const id = item.creativeId;

  const timestamp = item.creativeServingDecision.lastStatusUpdate;
  const status: { [key: string]: number } = {
    APPROVED: 0,
    DISAPPROVED: 0,
    PENDING_REVIEW: 0,
  };
  let disapprovalDetails = "";
  let disapprovalReason = "";

  Object.keys(item.creativeServingDecision)
    .filter((key) => POLICY_COMPLIANCE_REQUIRED_FOR_APPROVAL[key])
    .forEach((key) => {
      if (item.creativeServingDecision[key].status) {
        status[item.creativeServingDecision[key].status] += 1;

        if (item.creativeServingDecision[key].status === "DISAPPROVED") {
          disapprovalDetails += item.creativeServingDecision[key].topics
            .map((t) => JSON.stringify(t.evidences))
            .join(", ");
          disapprovalReason += item.creativeServingDecision[key].topics
            .map((t) => t.policyTopic)
            .join(", ");
        }
      }
    });

  const approvalStatus: ICreativeApprovalStatus = {
    label: status.DISAPPROVED
      ? "DISAPPROVED"
      : status.PENDING_REVIEW
      ? "PENDING_REVIEW"
      : "APPROVED",
    tooltip: disapprovalDetails,
    disapprovalReason,
    timestamp,
  };

  return { id, approvalStatus };
};
