/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import PropTypes from "prop-types";

// @mui/materialponents
import withStyles from "@mui/styles/withStyles";

import typographyStyle from "../../assets/jss/material-dashboard-pro-react/components/typographyStyle.jsx";

function Muted({ ...props }) {
  const { classes, children } = props;
  return (
    <div className={classes.defaultFontStyle + " " + classes.mutedText}>
      {children}
    </div>
  );
}
Muted.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

function _MutedInline({ ...props }) {
  const { classes, children } = props;
  return (
    <div
      className={classes.defaultFontStyle + " " + classes.mutedText}
      style={{ display: "inline-block" }}>
      {children}
    </div>
  );
}
_MutedInline.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export const MutedInline = withStyles(typographyStyle)(_MutedInline);
export default withStyles(typographyStyle)(Muted);
