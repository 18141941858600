import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import { Integer } from "model/modelTypes";
import { ICreativesObject } from "redux/creatives/selectors";
import {
  archiveCreativeReducer,
  createCreativeReducer,
  fetchActiveCreativesReducer,
  loadArchivedCreativesReducer,
  loadCreativeApprovalStatusReducer,
  loadCreativeListApprovalStatusesReducer,
  unarchiveCreativeReducer,
  updateCreativeReducer,
} from "./creativesExtraReducers";
import { getKindAndCreative } from "./helpers";

export interface ICreativesState {
  loadingState: LoadingState;
  error: null | SerializedError;
  active: ICreativesObject;
  archived: ICreativesObject;
  errorLoadingActiveCreatives: null | SerializedError;
  lastActiveCreativesFetch: Integer;
  lastCreativesApprovalFetch: Integer;
  errorLoadingCreativesApprovals: null | SerializedError;
  expanded: { [tabNo: number]: Record<string, unknown> };
  filter: {
    folders: string;
    loose: string;
  };
  showArchived: boolean;
  cacheVersions: { [filename: string]: string };
}

const initialState: ICreativesState = {
  loadingState: LoadingState.initial,
  error: null,
  active: {},
  archived: {},
  errorLoadingActiveCreatives: null,
  lastActiveCreativesFetch: 0,
  lastCreativesApprovalFetch: 0,
  errorLoadingCreativesApprovals: null,
  expanded: {},
  filter: {
    folders: "",
    loose: "",
  },
  showArchived: false,
  cacheVersions: {},
} as ICreativesState;

export const creativesSlice = createSlice({
  name: "creatives",
  initialState,
  reducers: {
    clearApprovalsError(state) {
      state.errorLoadingCreativesApprovals = null;
    },
    setExpanded(state, action) {
      state.expanded = action.payload;
    },
    updateCreativeApprovalStatus(state, action) {
      const { creativeId, approvalStatus } = action.payload;
      const { kind } = getKindAndCreative({
        state,
        id: creativeId,
      });
      if (kind && approvalStatus) {
        state[kind][creativeId].approvalStatus = approvalStatus;
      }
    },
    setFoldersFilter(state, action) {
      state.filter.folders = action.payload;
    },
    clearFoldersFilter(state) {
      state.filter.folders = "";
    },
    setLooseFilter(state, action) {
      state.filter.loose = action.payload;
    },
    clearLooseFilter(state) {
      state.filter.loose = "";
    },
    clearFilters(state) {
      state.filter.loose = "";
      state.filter.folders = "";
    },
    setShowArchived(state, action) {
      state.showArchived = action.payload;
    },
    setCacheVersions(state, action) {
      Object.entries(action.payload as { [name: string]: string }).forEach(
        ([k, v]) => (state.cacheVersions[k] = v)
      );
    },
  },
  extraReducers: (builder) => {
    fetchActiveCreativesReducer(builder);
    createCreativeReducer(builder);
    updateCreativeReducer(builder);
    loadCreativeListApprovalStatusesReducer(builder);
    loadCreativeApprovalStatusReducer(builder);
    loadArchivedCreativesReducer(builder);
    archiveCreativeReducer(builder);
    unarchiveCreativeReducer(builder);
  },
});

export default creativesSlice.reducer;
