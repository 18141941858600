import { makeStyles } from "components/mui-core";
import { grayColor } from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

const useOwnStyles = makeStyles({
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  wrapper: {
    color: grayColor[2],
    textAlign: "center",
  },
});

const ErrorMessage = () => {
  const ownClasses = useOwnStyles();

  return (
    <div className={ownClasses.wrapper}>
      <h1>⚠</h1>
      <h1>Oops! An unexpected error occurred</h1>
      <h4
        onClick={() => window.location.reload()}
        className={ownClasses.link}>
        Click here to reload the page
      </h4>
      <h6>
        This error has been logged and will be investigated. If the problem
        persists, please contact your account manager
      </h6>
    </div>
  );
};

export default ErrorMessage;
