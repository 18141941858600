import {
  differenceInDays,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { TimeRange, TimeRangeLabelsDict } from "model/report/dateRange";

export interface IQuickLink {
  id: TimeRange;
  value: Array<Date | null>;
  compareTo: Array<Date | null>;
  label: string;
}

export const today = () => new Date();
export const yesterday = () => subDays(today(), 1);
const dayBeforeYesterday = () => subDays(today(), 2);
export const day7BeforeToday = () => subDays(today(), 7);
const day8BeforeToday = () => subDays(today(), 8);
export const day14BeforeToday = () => subDays(today(), 14);
const day15BeforeToday = () => subDays(today(), 15);
export const day30BeforeToday = () => subDays(today(), 30);
const day28BeforeToday = () => subDays(today(), 28);
const day31BeforeToday = () => subDays(today(), 31);
const day60BeforeToday = () => subDays(today(), 60);
const startOfThisWeek = () => startOfISOWeek(today());
const startOfLastWeek = () => subWeeks(startOfThisWeek(), 1);
const startOfWeekBeforeLast = () => subWeeks(startOfThisWeek(), 2);
const startOfThisMonth = () => startOfMonth(today());
const thisMonthLength = () => differenceInDays(today(), startOfThisMonth());
const startOfLastMonth = () => subMonths(startOfThisMonth(), 1);
const lastMonthLength = differenceInDays(
  endOfMonth(startOfLastMonth()),
  startOfLastMonth()
);
const startOfMonthBeforeLast = subMonths(startOfThisMonth(), 2);

export function getQuickLinks(): { [key in TimeRange]?: IQuickLink } {
  return {
    [TimeRange.allTime]: {
      id: TimeRange.allTime,
      value: [null, null],
      compareTo: [null, null],
      label: TimeRangeLabelsDict.allTime,
    },
    [TimeRange.today]: {
      id: TimeRange.today,
      value: [startOfDay(today()), endOfDay(today())],
      compareTo: [startOfDay(yesterday()), subDays(today(), 1)],
      label: TimeRangeLabelsDict.today,
    },
    [TimeRange.yesterday]: {
      id: TimeRange.yesterday,
      value: [startOfDay(yesterday()), endOfDay(yesterday())],
      compareTo: [
        startOfDay(dayBeforeYesterday()),
        endOfDay(dayBeforeYesterday()),
      ],
      label: TimeRangeLabelsDict.yesterday,
    },
    [TimeRange.last7Days]: {
      id: TimeRange.last7Days,
      label: TimeRangeLabelsDict.last7Days,
      value: [startOfDay(day7BeforeToday()), endOfDay(yesterday())],
      compareTo: [startOfDay(day14BeforeToday()), endOfDay(day8BeforeToday())],
    },
    [TimeRange.last14Days]: {
      id: TimeRange.last14Days,
      label: TimeRangeLabelsDict.last14Days,
      value: [startOfDay(day14BeforeToday()), endOfDay(yesterday())],
      compareTo: [startOfDay(day28BeforeToday()), endOfDay(day15BeforeToday())],
    },
    [TimeRange.lastWeek]: {
      id: TimeRange.lastWeek,
      label: TimeRangeLabelsDict.lastWeek,
      value: [startOfLastWeek(), endOfISOWeek(startOfLastWeek())],
      compareTo: [
        startOfWeekBeforeLast(),
        endOfISOWeek(startOfWeekBeforeLast()),
      ],
    },
    [TimeRange.last30Days]: {
      id: TimeRange.last30Days,
      label: TimeRangeLabelsDict.last30Days,
      value: [startOfDay(day30BeforeToday()), endOfDay(yesterday())],
      compareTo: [startOfDay(day60BeforeToday()), endOfDay(day31BeforeToday())],
    },
    [TimeRange.mtd]: {
      id: TimeRange.mtd,
      label: TimeRangeLabelsDict.mtd,
      value: [startOfThisMonth(), endOfDay(yesterday())],
      compareTo: [
        startOfDay(
          subDays(endOfMonth(startOfLastMonth()), thisMonthLength() - 1)
        ),
        endOfMonth(startOfLastMonth()),
      ],
    },
    [TimeRange.lastMonth]: {
      id: TimeRange.lastMonth,
      label: TimeRangeLabelsDict.lastMonth,
      value: [startOfLastMonth(), endOfMonth(startOfLastMonth())],
      compareTo: [
        startOfDay(
          subDays(endOfMonth(startOfMonthBeforeLast), lastMonthLength)
        ),
        endOfMonth(startOfMonthBeforeLast),
      ],
    },
  };
}
