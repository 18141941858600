import * as Sentry from "@sentry/browser";
import set from "lodash.set";
import {
  DEFAULT_LINEITEM_STATUS_SERVER,
  ILineitemStatusServer,
} from "model/lineitems/lineitemStatus";

export function addNewLineitemStatusReducer(state, action: { payload: any }) {
  const { campaignId, lineitemId } = action.payload;
  const lineitem = state.data?.[campaignId]?.lineitems?.find(
    (l) => l?.lineitemState?.id === lineitemId
  );
  if (lineitem) {
    Sentry.captureException(
      `Trying to add default status for new lineitem but it already exists ${campaignId} ${lineitemId}`
    );
  } else {
    const newLineitemStatus: ILineitemStatusServer = {
      ...DEFAULT_LINEITEM_STATUS_SERVER,
      lineitemState: {
        ...DEFAULT_LINEITEM_STATUS_SERVER.lineitemState,
        id: lineitemId,
      },
    };
    if (!state.data?.[campaignId]?.lineitems) {
      set(state, ["data", campaignId, "lineitems"], []);
    }
    state.data[campaignId].lineitems.push(newLineitemStatus);
  }
}
