import { CLIENT_HOSTS, isAlpha } from "configuration/endpoints";
import { ILocationState } from "model/routing";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import {
  getUserInfo,
  useIsAdminCheck,
  useIsLoggedIn,
  useRole,
} from "redux/auth";

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const isAdmin = useIsAdminCheck();
  const role = useRole();

  React.useEffect(
    () => {
      dispatch(getUserInfo());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // redirect alpha->prod if user acc is not ADMIN
  React.useEffect(() => {
    if (isLoggedIn && isAlpha && isAdmin === false) {
      const url = new URL(window.location.href);
      url.hostname = CLIENT_HOSTS.prod;
      window.location.href = url.href;
    }
  }, [isAdmin, isLoggedIn]);

  // redirect only if isLoggedIn is false, not if undefined
  if (isLoggedIn === false) {
    const locationState: ILocationState = {
      referrer: `${location.pathname}${location.search ?? ""}`,
    };
    return (
      <Redirect
        to={{
          pathname: "/login/",
          state: locationState,
        }}
      />
    );
  }

  return isLoggedIn === true && role ? <>{children}</> : null;
}

export default AuthProvider;
