import { dbQuery } from "@dataseat-dsp-client/lib-reporter";

export const TIME_RANGE_EXPR = dbQuery.TIME_RANGE_EXPR;
export const TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED =
  dbQuery.TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED;

export const WHERE_VALUES_EXPR = dbQuery.WHERE_VALUES_EXPR;

export const EXACT_QUERY_CURRY = dbQuery.EXACT_QUERY_CURRY;

export const LIKE_VALUES_EXPR = dbQuery.LIKE_VALUES_EXPR;
export const LIKE_QUERY_CURRY = dbQuery.LIKE_QUERY_CURRY;
export const MULTI_LIKE_VALUES_EXPR = dbQuery.MULTI_LIKE_VALUES_EXPR;
export const MULTI_LIKE_QUERY_CURRY = dbQuery.MULTI_LIKE_QUERY_CURRY;
export const STRICT_LIKE_VALUES_EXPR = dbQuery.STRICT_LIKE_VALUES_EXPR;
export const STRICT_LIKE_QUERY_CURRY = dbQuery.STRICT_LIKE_QUERY_CURRY;
export const STRICT_MULTI_LIKE_VALUES_EXPR =
  dbQuery.STRICT_MULTI_LIKE_VALUES_EXPR;
export const STRICT_MULTI_LIKE_QUERY_CURRY =
  dbQuery.STRICT_MULTI_LIKE_QUERY_CURRY;

export const GRANULARITY_EXPR = dbQuery.GRANULARITY_EXPR;
export const DUMMY_EXPRESSION = dbQuery.DUMMY_EXPRESSION;
