import { Tooltip as MuiTooltip } from "components/mui-core";
import { TooltipProps as MuiTooltipProps } from "components/mui-core-types";
import React from "react";

type TooltipProps = {
  className?: string;
  title?: React.ReactNode;
  placement?: "left" | "top" | "right" | "bottom";
  arrow?: boolean;
  children?: React.ReactNode;
  tooltipProps?: MuiTooltipProps;
  style?;
};

function Tooltip({
  className,
  title,
  children,
  placement,
  arrow,
  tooltipProps,
  style,
}: TooltipProps) {
  if (!children) return null;

  if (!title) return <span className={className}>{children}</span>;

  return (
    <MuiTooltip
      className={className}
      title={title}
      arrow={arrow}
      placement={placement}
      style={style}
      {...tooltipProps}>
      <span>{children}</span>
    </MuiTooltip>
  );
  // Span around children makes tooltip work for disabled buttons:
  // https://material-ui.com/components/tooltips/#disabled-elements
}

export default Tooltip;
