import {
  dbTableTraffic,
  ITaggedDimension,
} from "@dataseat-dsp-client/lib-reporter";

//Dimensions: columns in TRAFFIC table
export const DimensionIds = dbTableTraffic.TrafficDimensionIds;

export type ICampaignDimension = ITaggedDimension<DimensionTags>;
export enum DimensionTags {
  Report = "REPORT",
  Monetization = "MONETIZATIONREPORT",
}
