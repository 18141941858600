import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { fetchCampaignById } from "redux/campaigns/campaignsActions";
import { IRootState } from "redux/reducer";

interface ILineitemActionArgs {
  campaignId: string;
  lineItemId: string;
  onSuccess?: () => void;
  onError?: (err: Error) => void;
}

export const updateLineItemRunStatus = createAsyncThunk(
  "campaignStatus/updateLineItemStatus",
  async (
    { campaignId, lineItemId, onSuccess, onError }: ILineitemActionArgs,
    thunkApi
  ) => {
    const state = thunkApi.getState() as IRootState;
    const { organizationId, accountId } = state?.auth ?? {};
    if (!organizationId || !accountId || !lineItemId) {
      return;
    }

    const archivedStatus = state.campaigns.active.data[campaignId]
      ? "active"
      : "archived";

    return request
      .getJson(
        endpointMaker.stats.lineItemStats(
          organizationId,
          accountId,
          campaignId,
          lineItemId
        )
      )
      .then(({ lineitemState } = {}) => {
        onSuccess?.();
        thunkApi.dispatch(fetchCampaignById(archivedStatus)(campaignId));
        return { campaignId, lineItemId, lineitemState };
      })
      .catch((e) => {
        onError?.(e);
        return e;
      });
  }
);
