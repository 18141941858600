import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import pluck from "lodash.pluck";
import uniq from "lodash.uniq";
import { ICampaignServer } from "model/campaigns";
import { getAppListData } from "redux/apps/appsAsyncActions";
import { IRootState } from "redux/reducer";
import { IArchivedStatus } from "./types";

export const fetchCampaignById = (archivedStatus: IArchivedStatus) =>
  createAsyncThunk(
    `campaigns/fetch${archivedStatus}CampaignById`,
    async (campaignId: string, thunkAPI) => {
      const state = thunkAPI.getState() as IRootState;
      const { organizationId, accountId } = state.auth;
      return request.getJson(
        endpointMaker.api.campaignById(organizationId, accountId, campaignId)
      );
    }
  );

const getFetchCampaigns = (
  archivedStatus: IArchivedStatus,
  typePrefix: string
) =>
  createAsyncThunk<ICampaignServer[]>(typePrefix, async (arg, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.api.campaignsByAccount(
        organizationId,
        accountId,
        archivedStatus === "archived"
      )
    );
  });

const getFetchCampaignsWithApps =
  (archivedStatus: IArchivedStatus) => () => (dispatch) =>
    dispatch(fetchCampaigns[archivedStatus]()).then((action) => {
      const campaigns = action.payload as ICampaignServer[];
      if (!campaigns?.length) {
        return;
      }
      const appIds = uniq(pluck(campaigns, "bundleId"));
      dispatch(getAppListData(appIds));
    });

export const fetchCampaignsWithApps = {
  active: getFetchCampaignsWithApps("active"),
  archived: getFetchCampaignsWithApps("archived"),
};
export const fetchCampaigns = {
  active: getFetchCampaigns("active", "campaigns/fetchActive"),
  archived: getFetchCampaigns("archived", "campaigns/fetchArchived"),
};
