import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { differenceInHours } from "date-fns";
import { getIsoDateNow } from "helpers/dateISO";
import { LoadingState } from "model/Types/loading";
import { IDownloadsList } from "./types";

const DOWNLOAD_EXPIRATION_IN_HOURS = 48;
export interface IInventoryCsvDownloadsState {
  status: LoadingState;
  error: null | SerializedError;
  list: IDownloadsList;
  isModalOpened?: boolean;
}

const INITIAL_STATE: IInventoryCsvDownloadsState = {
  status: LoadingState.initial,
  error: null,
  list: {},
};

export const inventoryCsvDownloadsSlice = createSlice({
  name: "inventoryCsvDownloads",
  initialState: INITIAL_STATE,
  reducers: {
    loadList(state) {
      if (state.status !== LoadingState.initial) return;

      state.status = LoadingState.loading;
      const storedDownloads = localStorage.getItem("inventoryCsvDownloads");
      if (storedDownloads) {
        try {
          const list: IDownloadsList =
            JSON.parse(storedDownloads) || ({} as IDownloadsList);
          const recentDownloads = Object.entries(list).reduce(
            (acc, [dId, d]) => {
              if (
                differenceInHours(new Date(d?.addedTS), new Date()) <
                DOWNLOAD_EXPIRATION_IN_HOURS
              ) {
                acc[dId] = d;
                if (d.status === "loading") {
                  //was stored before completion
                  acc[dId].status = "paused";
                }
              }
              return acc;
            },
            {}
          );
          state.list = recentDownloads;
          state.status = LoadingState.loaded;
        } catch {
          state.status = LoadingState.failed;
        }
      }
    },
    addToList(state, action) {
      state.list[action.payload.id] = {
        id: action.payload.id,
        fileName: action.payload.fileName,
        fileUrl: action.payload.fileUrl,
        errorLogFilename: action.payload.errorLogFilename,
        errorLogUrl: action.payload.errorLogUrl,
        status: "loading",
        addedDatetime: getIsoDateNow(),
        addedTS: Date.now(),
        userFriendlyFilename: action.payload.userFriendlyFilename,
        userFriendlyErrorLogFilename:
          action.payload.userFriendlyErrorLogFilename,
        estimatedTime: action.payload.estimatedTime,
      };
      localStorage.setItem("inventoryCsvDownloads", JSON.stringify(state.list));
    },
    completeDownload(state, action) {
      state.list[action.payload.id].completedDatetime = getIsoDateNow();
      state.list[action.payload.id].status = "completed";
      state.list[action.payload.id].completedMetadata = action.payload.metadata;
      localStorage.setItem("inventoryCsvDownloads", JSON.stringify(state.list));
    },
    resumeDownload(state, action) {
      state.list[action.payload.id].status = "loading";
      localStorage.setItem("inventoryCsvDownloads", JSON.stringify(state.list));
    },
    failDownload(state, action) {
      state.list[action.payload.id].status = "failed";
      localStorage.setItem("inventoryCsvDownloads", JSON.stringify(state.list));
    },
    cancelDownload(state, action) {
      delete state.list[action.payload.id];
      localStorage.setItem("inventoryCsvDownloads", JSON.stringify(state.list));
    },
    //TODO: clear list
    toggleModal(state, action) {
      state.isModalOpened = action.payload;
    },
  },
});

export default inventoryCsvDownloadsSlice.reducer;
