"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultPeriodByDateRange = exports.getDefaultDayOfPeriod = exports.SUPPORTED_DATE_RANGES = exports.DAY_OF_MONTH_OPTIONS = exports.WEEKDAY_DICT = exports.WEEKDAY_OPTIONS = exports.SCHEDULE_FREQUENCY_DICT = exports.SCHEDULE_FREQUENCY_OPTIONS = exports.ScheduleFrequency = exports.WeekDay = exports.SCHEDULE_EMAIL_AS_OPTIONS = exports.ScheduleEmailAs = void 0;
const dateRange_1 = require("../genericReport/dateRange");
var ScheduleEmailAs;
(function (ScheduleEmailAs) {
    ScheduleEmailAs["link"] = "link";
    ScheduleEmailAs["attachment"] = "attachment";
})(ScheduleEmailAs = exports.ScheduleEmailAs || (exports.ScheduleEmailAs = {}));
exports.SCHEDULE_EMAIL_AS_OPTIONS = [
    { value: ScheduleEmailAs.link, label: "Link" },
    { value: ScheduleEmailAs.attachment, label: "Attachment" },
];
var WeekDay;
(function (WeekDay) {
    WeekDay[WeekDay["Monday"] = 0] = "Monday";
    WeekDay[WeekDay["Tuesday"] = 1] = "Tuesday";
    WeekDay[WeekDay["Wednesday"] = 2] = "Wednesday";
    WeekDay[WeekDay["Thursday"] = 3] = "Thursday";
    WeekDay[WeekDay["Friday"] = 4] = "Friday";
    WeekDay[WeekDay["Saturday"] = 5] = "Saturday";
    WeekDay[WeekDay["Sunday"] = 6] = "Sunday";
})(WeekDay = exports.WeekDay || (exports.WeekDay = {}));
var ScheduleFrequency;
(function (ScheduleFrequency) {
    ScheduleFrequency["day"] = "day";
    ScheduleFrequency["week"] = "week";
    ScheduleFrequency["month"] = "month";
})(ScheduleFrequency = exports.ScheduleFrequency || (exports.ScheduleFrequency = {}));
exports.SCHEDULE_FREQUENCY_OPTIONS = [
    { value: ScheduleFrequency.day, label: "Daily" },
    { value: ScheduleFrequency.week, label: "Weekly" },
    { value: ScheduleFrequency.month, label: "Monthly" },
];
exports.SCHEDULE_FREQUENCY_DICT = {
    [ScheduleFrequency.month]: {
        value: ScheduleFrequency.month,
        label: "Monthly",
    },
    [ScheduleFrequency.week]: { value: ScheduleFrequency.week, label: "Weekly" },
    [ScheduleFrequency.day]: { value: ScheduleFrequency.day, label: "Daily" },
};
exports.WEEKDAY_OPTIONS = [
    { value: WeekDay.Monday.toString(), label: "Monday" },
    { value: WeekDay.Tuesday.toString(), label: "Tuesday" },
    { value: WeekDay.Wednesday.toString(), label: "Wednesday" },
    { value: WeekDay.Thursday.toString(), label: "Thursday" },
    { value: WeekDay.Friday.toString(), label: "Friday" },
    { value: WeekDay.Saturday.toString(), label: "Saturday" },
    { value: WeekDay.Sunday.toString(), label: "Sunday" },
];
exports.WEEKDAY_DICT = {
    [WeekDay.Monday.toString()]: {
        value: WeekDay.Monday.toString(),
        label: "Monday",
    },
    [WeekDay.Tuesday.toString()]: {
        value: WeekDay.Tuesday.toString(),
        label: "Tuesday",
    },
    [WeekDay.Wednesday.toString()]: {
        value: WeekDay.Wednesday.toString(),
        label: "Wednesday",
    },
    [WeekDay.Thursday.toString()]: {
        value: WeekDay.Thursday.toString(),
        label: "Thursday",
    },
    [WeekDay.Friday.toString()]: {
        value: WeekDay.Friday.toString(),
        label: "Friday",
    },
    [WeekDay.Saturday.toString()]: {
        value: WeekDay.Saturday.toString(),
        label: "Saturday",
    },
    [WeekDay.Sunday.toString()]: {
        value: WeekDay.Sunday.toString(),
        label: "Sunday",
    },
};
exports.DAY_OF_MONTH_OPTIONS = Array.from(Array(31).keys()).map((d) => ({
    value: (d + 1).toString(),
    label: (d + 1).toString(),
}));
exports.SUPPORTED_DATE_RANGES = {
    [dateRange_1.TimeRange.yesterday]: true,
    [dateRange_1.TimeRange.last7Days]: true,
    [dateRange_1.TimeRange.lastWeek]: true,
    [dateRange_1.TimeRange.mtd]: true,
    [dateRange_1.TimeRange.thisMonth]: true,
    [dateRange_1.TimeRange.lastMonth]: true,
    [dateRange_1.TimeRange.last30Days]: true,
};
const getDefaultDayOfPeriod = (period) => {
    switch (period) {
        case ScheduleFrequency.week:
            return new Date().getDay() - 1;
        case ScheduleFrequency.month:
            return new Date().getDate();
        default:
            return undefined;
    }
};
exports.getDefaultDayOfPeriod = getDefaultDayOfPeriod;
const getDefaultPeriodByDateRange = (dateRange) => {
    switch (dateRange) {
        case dateRange_1.TimeRange.yesterday:
            return { period: ScheduleFrequency.day };
        case dateRange_1.TimeRange.last7Days:
            return {
                period: ScheduleFrequency.week,
                day: (0, exports.getDefaultDayOfPeriod)(ScheduleFrequency.week),
            };
        default:
            return {
                period: ScheduleFrequency.month,
                day: (0, exports.getDefaultDayOfPeriod)(ScheduleFrequency.month),
            };
    }
};
exports.getDefaultPeriodByDateRange = getDefaultPeriodByDateRange;
