import Alert from "@mui/material/Alert";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { getCurrentOrganization } from "redux/auth";
import { useSelector } from "redux/hooks";
import useStyles from "./styles";

function TabContent(props) {
  const organization = useSelector(getCurrentOrganization);
  const classes = useStyles();

  return (
    <main
      className={classnames(classes.content, {
        [classes.expanded]: props.isExpanded,
      })}>
      {!!organization?.archived && (
        <Alert
          severity="error"
          variant="filled">
          This Organization is archived.{" "}
          <Link
            to="/organization"
            style={{ color: "white" }}>
            Go to organization settings
          </Link>
        </Alert>
      )}

      {props.children}
    </main>
  );
}

export default TabContent;
