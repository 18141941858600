import keyBy from "lodash.keyby";
import { LoadingState } from "model/Types/loading";
import { unarchiveCampaign } from "./campaignActions";
import { fetchCampaignById, fetchCampaigns } from "./campaignsActions";

export function applyCampaignsExtraReducers(builder, archivedStatus) {
  builder.addCase(fetchCampaigns[archivedStatus].pending, (state) => {
    if (state.loadingState !== LoadingState.loaded) {
      state.loadingState = LoadingState.loading;
    }
    state.error = null;
  });

  builder.addCase(fetchCampaigns[archivedStatus].fulfilled, (state, action) => {
    state.loadingState = LoadingState.loaded;
    state.data = keyBy(action.payload, "id");
  });

  builder.addCase(fetchCampaigns[archivedStatus].rejected, (state, action) => {
    state.loadingState = LoadingState.failed;
    state.error = action.error?.message
      ? action.error
      : new Error("Unknown error");
  });

  builder.addCase(unarchiveCampaign.fulfilled, (state, action) => {
    delete state.data[action.payload];
  });

  builder.addCase(
    fetchCampaignById(archivedStatus).fulfilled,
    (state, action) => {
      const campaign = action.payload;
      state.data[campaign.id] = campaign;
    }
  );
}
