import { isTabVisible } from "helpers/browser";
import { Integer } from "model/modelTypes";
import React from "react";

const deepArraysCompareEquals = (a1 = [], a2 = []) => {
  return JSON.stringify(a1.sort()) === JSON.stringify(a2.sort());
};

export function useDeepArraysCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepArraysCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useTraceUpdate(props) {
  const prev = React.useRef(props);
  React.useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line no-console
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}

export function useIntervalEffect(effectFn, delay) {
  React.useEffect(
    () => {
      effectFn();
      const intervalId = setInterval(effectFn, delay);
      return () => clearInterval(intervalId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay]
  );
}

export function useTimeoutIntervalEffect(func, delay) {
  React.useEffect(() => {
    func();

    let timeoutId = setTimeout(function funcByTimeout() {
      func();
      timeoutId = setTimeout(funcByTimeout, delay);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [func, delay]);
}

export function useIsMounted() {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
}

export function useIntersectionObserver<T extends Element>(
  options?: any
): [React.RefObject<T>, boolean, () => void] {
  const containerRef = React.useRef<T>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const callback = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  const observer = React.useMemo(
    () => new IntersectionObserver(callback, options || {}),
    [options]
  );

  React.useEffect(() => {
    if (containerRef.current) {
      observer.observe(containerRef.current);
      const cleanupRef = containerRef.current;

      return () => {
        observer.unobserve(cleanupRef);
      };
    }
  }, [containerRef, options]);

  return [
    containerRef,
    isVisible,
    () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    },
  ];
}

type IArgs = {
  isFrozen?: false;
  options?: any;
};
export function useWasVisible<T extends Element>(
  args: IArgs = {}
): [React.RefObject<T>, boolean] {
  const { isFrozen, options } = args;
  const [containerRef, isVisible, unobserve] =
    useIntersectionObserver<T>(options);
  const [wasVisible, setWasVisible] = React.useState(isVisible);

  React.useEffect(() => {
    if (isFrozen) return;
    if (isVisible && !wasVisible) {
      setWasVisible(true);
      unobserve();
    }
  }, [isVisible, wasVisible, isFrozen]);

  return [containerRef, wasVisible];
}

type IUseReloadIntervalToken = ({
  allowReload,
  intervalLength,
}: {
  allowReload: boolean;
  intervalLength: Integer;
}) => {
  reloadToken: Integer;
  onForceStopLoadingRef: React.MutableRefObject<() => void>;
};

export const useReloadIntervalToken: IUseReloadIntervalToken = ({
  allowReload,
  intervalLength,
}) => {
  const [reloadToken, setReloadToken] = React.useState(Date.now());
  const isMounted = useIsMounted();
  const onForceStopLoadingRef = React.useRef(() => {
    //do nothing
  });

  React.useEffect(
    () => {
      if (allowReload && isMounted.current && intervalLength) {
        const intervalId = setInterval(() => {
          if (isTabVisible()) {
            setReloadToken(Date.now());
          }
        }, intervalLength);
        onForceStopLoadingRef.current = () => clearInterval(intervalId);
      }

      return () => onForceStopLoadingRef.current();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowReload, intervalLength]
  );

  return { reloadToken, onForceStopLoadingRef };
};

export default useReloadIntervalToken;

//Mutating the reference won't trigger a render, therefore, won't trigger the useEffect.
//Here is ref that is safe to use in effect dependencies:
//- refToggle to use as Effect dependency
//- setRefAndRender to be called when changing ref
//- ref is ref

export function useEffectSafeRef(): [
  boolean,
  (node: any) => void,
  React.MutableRefObject<any>
] {
  const ref = React.useRef<any>(null);
  const [refToggle, setToggle] = React.useState(false);
  const setRefAndRender = React.useCallback((node) => {
    ref.current = node;
    setToggle((val) => !val);
  }, []);

  return [refToggle, setRefAndRender, ref];
}
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return debouncedValue;
};

export const useComplexState = (initialState) => {
  const [state, updateState] = React.useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  return [state, updateState];
};
