import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
import { IApps } from "model/app/app";
import { getAppData, getAppListData } from "./appsAsyncActions";
import { normalizeAppData } from "./appsUtils";

export interface IAppsState {
  status: LoadingState;
  error: null | SerializedError;
  apps: IApps;
}

const APPS_INITIAL_STATE: IAppsState = {
  status: LoadingState.initial,
  error: null,
  apps: {},
};

const appsSlice = createSlice({
  name: "apps",
  initialState: APPS_INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    // getAppData
    builder.addCase(getAppData.pending, (state: IAppsState) => {
      state.status = LoadingState.loading;
    });
    builder.addCase(getAppData.fulfilled, (state: IAppsState, action) => {
      state.apps[action.payload.id] = action.payload.app;
      state.status = LoadingState.loaded;
    });
    builder.addCase(getAppData.rejected, (state: IAppsState, action) => {
      state.status = LoadingState.failed;
      state.error = action.error;
    });
    // getAppListData
    builder.addCase(getAppListData.pending, (state: IAppsState) => {
      state.status = LoadingState.loading;
    });
    builder.addCase(getAppListData.fulfilled, (state: IAppsState, action) => {
      action.payload?.forEach((app) => {
        state.apps[app.id] = normalizeAppData(app);
      });
      state.status = LoadingState.loaded;
    });
    builder.addCase(getAppListData.rejected, (state: IAppsState, action) => {
      state.status = LoadingState.failed;
      state.error = action.error;
    });
  },
});

export default appsSlice.reducer;
