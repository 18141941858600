"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCohortableColumnType = exports.getCohortableAppMeasures = exports.isCohortableAppMeasure = exports.getCohortColumnLabels = exports.getCohortColumnId = exports.getEventIdByCohortColumnId = exports.COHORT_ABLE_EVENT_DICT = exports.COHORT_ABLE_EVENT_COLUMNS = exports.COHORT_ABLE_BASE_EVENT_IDS = exports.getCohortOrderedColumnIdByEventId = void 0;
const arrays_1 = require("../../../helpers/arrays");
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
const columnTypes_1 = require("./columnTypes");
const getCohortOrderedColumnIdByEventId = (eventId) => `${eventId}_COHORT`;
exports.getCohortOrderedColumnIdByEventId = getCohortOrderedColumnIdByEventId;
exports.COHORT_ABLE_BASE_EVENT_IDS = [
    dbTableCampaign_1.Events.revenue,
    dbTableCampaign_1.Events.sales,
    dbTableCampaign_1.Metrics.roas,
];
exports.COHORT_ABLE_EVENT_COLUMNS = exports.COHORT_ABLE_BASE_EVENT_IDS.map((eventId) => {
    var _a, _b;
    return ({
        id: (0, exports.getCohortOrderedColumnIdByEventId)(eventId),
        eventId,
        label: `${((_a = dbTableCampaign_1.EVENTS_LABELED === null || dbTableCampaign_1.EVENTS_LABELED === void 0 ? void 0 : dbTableCampaign_1.EVENTS_LABELED[eventId]) === null || _a === void 0 ? void 0 : _a.label) || ((_b = dbTableCampaign_1.METRICS_LABELED === null || dbTableCampaign_1.METRICS_LABELED === void 0 ? void 0 : dbTableCampaign_1.METRICS_LABELED[eventId]) === null || _b === void 0 ? void 0 : _b.label)} (Cohorted)`,
        columnType: columnTypes_1.ColumnTypes.Cohorted,
    });
});
exports.COHORT_ABLE_EVENT_DICT = (0, arrays_1.arrayToObject)({
    arr: exports.COHORT_ABLE_EVENT_COLUMNS,
    keyModifier: (col) => col.id,
});
const getEventIdByCohortColumnId = (id) => id.substring(0, id.indexOf("_"));
exports.getEventIdByCohortColumnId = getEventIdByCohortColumnId;
function getCohortColumnId({ columnId, cohort, }) {
    return `${columnId}_${cohort}`;
}
exports.getCohortColumnId = getCohortColumnId;
function getCohortColumnLabels({ eventLabel, cohort, }) {
    return {
        label: `${eventLabel} d${cohort}`,
        longLabel: `${eventLabel} with cohort = ${cohort}`,
    };
}
exports.getCohortColumnLabels = getCohortColumnLabels;
const isCohortableAppMeasure = (appMeasure) => { var _a; return !!((_a = appMeasure.value) === null || _a === void 0 ? void 0 : _a.endsWith("_COHORT")); };
exports.isCohortableAppMeasure = isCohortableAppMeasure;
const getCohortableAppMeasures = (appMeasures) => appMeasures.filter((am) => am.columnType === columnTypes_1.ColumnTypes.CohortedAppMeasure ||
    am.columnType === columnTypes_1.ColumnTypes.CohortedAppMeasureCPA);
exports.getCohortableAppMeasures = getCohortableAppMeasures;
const isCohortableColumnType = (columnType) => columnType === columnTypes_1.ColumnTypes.Cohorted ||
    columnType === columnTypes_1.ColumnTypes.CohortedAppMeasure ||
    columnType === columnTypes_1.ColumnTypes.CohortedAppMeasureCPA;
exports.isCohortableColumnType = isCohortableColumnType;
