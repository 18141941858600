import { Integer } from "model/modelTypes";
import { IOrganizationItem } from "model/organizations";

export enum LoginErrorReasons {
  NeedMFA = "MFA code is absent",
  WrongMFA = "MFA code is invalid",
  EmptyMFA = "MFA code is empty",
  WrongCredentials = "Username or password is invalid",
}

export type IAuthError = {
  hasError?: boolean;
  errorStatus?: Integer;
  errorMessage?: LoginErrorReasons | string;
};

export type IOrgSwitchError = {
  orgSwitchError?: string;
};

export type IUserMFA = {
  mfaSharedSecret?: boolean;
  mfaEnabled?: boolean;
  forceUseOfMFA?: boolean;
};
export type IAuthState = {
  username: string;
  isLoggedIn?: boolean;
  isMFANeeded?: boolean;
  role?: string;
  mfa?: IUserMFA;

  defaultOrganizationId?: string;
  defaultAccountId?: string;
  organizationId?: string;
  accountId?: string;
  organizations: Array<IOrganizationItem>;

  isServiceUnavailable?: boolean;
} & IAuthError &
  IOrgSwitchError;
