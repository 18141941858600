import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import { arrayToObject } from "helpers";
import request from "http/request";
import { LoadingState } from "model/Types/loading";
import type { ISkadNetworkTemplate } from "model/lineitems";
import { Integer } from "model/modelTypes";
import { DATASEAT_NETWORK_ID, VERVE_NETWORK_ID } from "./constants";

type SkadTemplatesState = {
  loadingState: LoadingState;
  list: ISkadNetworkTemplate[];
  error: null | SerializedError;
  loadedTimestamp?: Integer;
};

export const fetchSkadTemplatesThunk = createAsyncThunk<ISkadNetworkTemplate[]>(
  "skadTemplates/fetchSkadTemplates",
  () => {
    return Promise.allSettled([
      request.getJson(endpointMaker.api.skadNetworkList()),
      Promise.resolve([
        { id: DATASEAT_NETWORK_ID, name: "Dataseat" },
        { id: VERVE_NETWORK_ID, name: "Verve" },
      ]),
    ]).then((responses: any) => {
      return responses[0].value.reduce(
        (allTemplatesPerNetwork, template) => [
          ...allTemplatesPerNetwork,
          // Cycle through all available networks to create 1 template per each network
          ...responses[1].value.map((network) => ({
            ...template,
            networkId: network.id,
            networkName: network.name,
            templateId: `${template.templateId}~${network.id}`,
            __templateId: template.templateId,
          })),
        ],
        []
      );
    });
  }
);

//Slice
const skadTemplatesSlice = createSlice({
  name: "skadTemplates",
  initialState: {
    loadingState: LoadingState.initial,
    list: [] as ISkadNetworkTemplate[],
    error: null,
  } as SkadTemplatesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSkadTemplatesThunk.pending, (state) => {
      state.loadingState = LoadingState.loading;
      state.loadedTimestamp = new Date().getTime();
      state.error = null;
    });
    builder.addCase(fetchSkadTemplatesThunk.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;
      state.list = action.payload;
    });
    builder.addCase(fetchSkadTemplatesThunk.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;
      delete state.loadedTimestamp;
      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
  },
});

export default skadTemplatesSlice.reducer;

//Selectors
export const getSkadTemplates = (state) => state.skadTemplates.list;
export const getSkadTemplatesLoadingState = (state) => [
  state.skadTemplates.loadingState,
  state.skadTemplates.error,
  state.skadTemplates.timestamp,
];
export const getSkadTemplatesDict = (state) =>
  arrayToObject({
    arr: state.skadTemplates.list ?? [],
    keyModifier: ({ templateId }) => templateId,
    valueModifier: ({ type }) => type,
  });
export const getSkadTemplateById = (state, templateId) =>
  state.skadTemplates.list.find((t) => t.templateId === templateId);
