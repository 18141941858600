import { numberAbbreviated, NumberAbbreviations } from "helpers";
import { ETrafficEvents } from "./trafficTable";

export const DEFAULT_SORT_BY = ETrafficEvents.requestsWithLogVolume;

export const columnConvertFns = {
  bidFloor: (val) => `$ ${val}`,
  requestsWithLogVolume: (val, row, abr) =>
    abr
      ? numberAbbreviated({
          num: Math.round(val),
          startFrom: NumberAbbreviations.K,
        })
      : Math.round(val),
};

export const normalizeDisplayValue = (value, row, column, abr?: boolean) => {
  const val = columnConvertFns[column]
    ? columnConvertFns[column](value, row, abr)
    : value;
  return val;
};
