import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { IMonetizationApp, TOsType } from "model/Monetization/apps";
import { IRootState } from "redux/reducer";

export const getMonetizationApps = createAsyncThunk(
  "monetizationApps/getApps",
  async (args, thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.monetization.getApps(organizationId, accountId)
    );
  }
);

export const saveMonetizationApp = createAsyncThunk(
  "monetizationApps/saveApp",
  async (app: IMonetizationApp, thunkAPI) => {
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    return request.post(
      endpointMaker.monetization.saveApp(organizationId, accountId),
      [app]
    );
  }
);

export const updateMonetizationApp = createAsyncThunk(
  "monetizationApps/updateApp",
  async (
    { app, os, data }: { app: string; os: TOsType; data: IMonetizationApp },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as IRootState;
    const { organizationId, accountId } = state.auth;

    return request.patch(
      endpointMaker.monetization.updateApp(organizationId, accountId, app, os),
      data
    );
  }
);
