import * as Sentry from "@sentry/browser";
import { ILineItemState } from "model/lineitems/lineitemStatus";
import { updateLineItemRunStatus } from "./lineitemStatusUpdateAction";

interface ILineItemActionPayload {
  campaignId: string;
  lineItemId: string;
  lineitemState: ILineItemState;
}

export function changeLineItemStatusReducer(builder) {
  builder.addCase(updateLineItemRunStatus.fulfilled, (state, action) => {
    const { campaignId, lineItemId, lineitemState } =
      action.payload as ILineItemActionPayload;

    if (!lineitemState) {
      Sentry.captureException(
        `Lineitem status do not have lineitemState object for ${campaignId} ${lineItemId}`
      );
    }

    const lineitemIndex = state.data[campaignId]?.lineitems?.findIndex(
      (l) => l?.lineitemState?.id && l?.lineitemState?.id === lineitemState?.id
    );

    if (lineitemIndex > -1) {
      state.data[campaignId].lineitems[lineitemIndex].lineitemState =
        lineitemState;
    } else {
      Sentry.captureException(
        `Lineitem status updated but it is not in list ${campaignId} ${lineItemId}`
      );
    }
  });
}
