const HOSTS = {
  account: process.env.REACT_APP_HOSTS_ACCOUNT,
  api: process.env.REACT_APP_HOSTS_API,
  compression: process.env.REACT_APP_HOSTS_COMPRESSION,
  publisher: process.env.REACT_APP_HOSTS_PUBLISHER,
  reporter: process.env.REACT_APP_HOSTS_REPORTER,
  audience: process.env.REACT_APP_HOSTS_AUDIENCE,
  bidderEast: process.env.REACT_APP_HOSTS_BIDDER_EAST,
  geodb: process.env.REACT_APP_HOSTS_GEODB,
  history: process.env.REACT_APP_HOSTS_HISTORY,
  content: process.env.REACT_APP_HOSTS_CONTENT,
  monetizationApps: process.env.REACT_APP_HOSTS_MONETIZATION_APPS,
  billingReporter: process.env.REACT_APP_HOSTS_BILLING_REPORTER,
};

export const AUTH_TOKENS = {
  monetizationApps: process.env.REACT_APP_MONETIZATION_APPS_AUTH_TOKEN || "",
};

export const CLIENT_HOSTS = {
  prod: "admin.dataseat.com",
  alpha: "admin-alpha.dataseat.com",
  alphaDevCluster: /admin-alpha.([\w\d]+).dataseat.org/,
  devCluster: /admin.([\w\d]+).dataseat.org/,
  local: "localhost",
};

export const isDevClusterAlpha = CLIENT_HOSTS.alphaDevCluster.test(
  document?.location?.hostname
); //alpha instance running on dev cluster
export const isDevCluster = CLIENT_HOSTS.devCluster.test(
  document?.location?.hostname
); //regular instance running on dev cluster
export const isDev = isDevClusterAlpha || isDevCluster;
const isDeployedAlpha = document?.location?.hostname === CLIENT_HOSTS.alpha; //alpha instance on dataseat.com
export const isAlpha = isDevClusterAlpha || isDeployedAlpha;
export const isLocal = document?.location?.hostname === CLIENT_HOSTS.local;
export const isLAN = /^192\.168\..+/.test(window.location.host);

export default HOSTS;
