import endpointMaker from "configuration/endpoints";
import request from "http/request";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "redux/hooks";
import { actions } from "./authSlice";

export const SERVICE_UNAVAILABLE_WARNING =
  "Some services are temporary unavailable: status 503. This is usually resolved within a few minutes.";

function useServiceUnavailable() {
  const isServiceUnavailable = useSelector(
    (state) => state.auth.isServiceUnavailable
  );
  const dispatch = useDispatch();

  React.useEffect(
    () => {
      if (isServiceUnavailable) {
        let timeoutId;
        const checkService = () =>
          request
            .get(endpointMaker.api.health())
            .then(() => {
              dispatch(actions.setServiceUnavailable(false));
              clearTimeout(timeoutId);
            })
            .catch(() => {
              timeoutId = setTimeout(checkService, 5000);
            });

        setTimeout(checkService, 5000);

        return () => clearTimeout(timeoutId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isServiceUnavailable]
  );

  return [isServiceUnavailable];
}

export default useServiceUnavailable;
