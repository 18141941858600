import React from "react";
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { IRootState } from "./reducer";

export const useSelector: TypedUseSelectorHook<IRootState> = (selector) =>
  useReduxSelector(selector, shallowEqual);

export function useReload({ action, actionArgs, isReloading, intervalMs }) {
  const dispatch = useDispatch();
  const reloadOnce = React.useCallback(
    () => dispatch(action(...actionArgs)),
    [action, actionArgs, dispatch]
  );

  const [intervalId, setIntervalId] = React.useState();

  React.useEffect(() => {
    if (isReloading) {
      const newIntervalId = setInterval(() => reloadOnce(), intervalMs) as any;
      setIntervalId(newIntervalId);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [intervalId, intervalMs, isReloading, reloadOnce]);

  return reloadOnce;
}

export const useParamSelector = (selector, ...params) =>
  useSelector((state: IRootState) => selector(state, ...params));
