import _isEqual from "lodash.isequal";
import { Integer } from "model/modelTypes";

export function move(
  arrOrigin: Array<any>,
  old_index: Integer,
  new_index: Integer
) {
  const arr = [...arrOrigin];
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr.filter(Boolean);
}

export function removeAt<T>(arr: Array<T>, index: Integer): Array<T> {
  if (index < 0) {
    return arr;
  }
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

export function replaceAt(arr: Array<any>, index: Integer, value: any) {
  return arr
    .slice(0, index)
    .concat([value])
    .concat(arr.slice(index + 1));
}

type IPutAfterArgs = {
  arr: Array<any>;
  index: Integer;
  findIndex?: ((elem: any) => boolean) | null;
  value: any;
};
export function putAfter({
  arr,
  index,
  findIndex = null,
  value,
}: IPutAfterArgs) {
  let targetIndex = -1;
  if (findIndex) targetIndex = arr.findIndex(findIndex);
  if (Number.isInteger(index)) targetIndex = index;
  if (targetIndex < 0) return arr;

  return arr
    .slice(0, targetIndex)
    .concat([value])
    .concat(arr.slice(targetIndex));
}

export function moveAfter(
  arr: Array<any>,
  index: Integer,
  afterIndex: Integer
) {
  const valToMove = arr[index];
  const arrWithout = arr.slice(0, index).concat(arr.slice(index + 1));
  let updatedAfterIndex = afterIndex;
  if (index < afterIndex) {
    updatedAfterIndex--;
  }

  return arrWithout
    .slice(0, updatedAfterIndex + 1)
    .concat(valToMove)
    .concat(arrWithout.slice(updatedAfterIndex + 1));
}
export function moveBefore(
  arr: Array<any>,
  index: Integer,
  beforeIndex: Integer
) {
  const valToMove = arr[index];
  const arrWithout = arr.slice(0, index).concat(arr.slice(index + 1));
  let updatedBeforeIndex = beforeIndex;
  if (index < beforeIndex) {
    updatedBeforeIndex--;
  }

  return arrWithout
    .slice(0, updatedBeforeIndex)
    .concat(valToMove)
    .concat(arrWithout.slice(updatedBeforeIndex));
}

type IArrayToObjectArgs = {
  arr: Array<any>;
  keyModifier?: (elem: any, index?: Integer) => any;
  valueModifier?: (elem: any, index?: Integer) => any;
};
export function arrayToObject({
  arr,
  keyModifier = (elem) => elem,
  valueModifier = (elem) => elem,
}: IArrayToObjectArgs) {
  return arr.reduce((acc, elem, index) => {
    acc[keyModifier(elem, index)] = valueModifier(elem, index);
    return acc;
  }, {});
}

export function getIdsToNamesDict(arr?: Array<any>) {
  if (!arr) return {};

  return arrayToObject({
    arr,
    keyModifier: ({ id }) => id,
    valueModifier: ({ name }) => name,
  });
}

export function filterDistinct(value: any, index: Integer, arr: Array<any>) {
  return arr.indexOf(value) === index;
}

export const sortAlpha = (a = "", b = "") => {
  const compareA = a.toLowerCase();
  const compareB = b.toLowerCase();
  if (compareA < compareB) {
    return -1;
  }
  if (compareA > compareB) {
    return 1;
  }
  return 0;
};

export const sortAlphaNum = (a: string, b: string) => {
  return a
    ?.toLowerCase()
    .localeCompare(b?.toLowerCase(), "en", { numeric: true });
};

export const sortCustomOrder = <T>(
  arrayToSort: T[],
  customOrder: T[],
  arrayKeyToSortBy: string
): T[] =>
  arrayToSort.sort(
    (a, b) =>
      customOrder.indexOf(a[arrayKeyToSortBy]) -
      customOrder.indexOf(b[arrayKeyToSortBy])
  );

export const sortByCustomPosition = <T>(
  arrayToSort: T[],
  customPositionObj: { [position: number]: T },
  comparisonKey: string
) => {
  const sortedArr: T[] = [];
  const toRemoveMap: { [key: string]: true } = {};

  Object.keys(customPositionObj).map(
    (key) => (toRemoveMap[customPositionObj[key][comparisonKey]] = true)
  );

  let arrPos = 0;
  for (let i = 0; i < arrayToSort.length; i++) {
    const current = arrayToSort[i];

    while (customPositionObj[arrPos + 1]) {
      sortedArr.push(customPositionObj[arrPos + 1]);
      arrPos++;
    }

    if (!toRemoveMap[current[comparisonKey]]) {
      sortedArr.push(arrayToSort[i]);
      arrPos++;
    }
  }

  return sortedArr;
};

export const trim = (arr) => arr.map((a) => a.trim());

export const compareArrayOfObjectsByKey = (a, b, key) => {
  const sameObjectsByKey = a.filter((aEl) =>
    b.find((bEl) => bEl[key] === aEl[key])
  );
  return !!sameObjectsByKey.length;
};

export const findDuplicates = (
  array: (string | number)[]
): (string | number)[] => {
  const map = {};
  const res: (string | number)[] = [];
  array.forEach((v) => {
    map[v] = (map[v] || 0) + 1;
  });
  Object.keys(map).forEach((key) => {
    if (map[key] > 1) {
      res.push(key);
    }
  });
  return res;
};

export const sortBySortingArr = (
  arr: any[],
  sortingArr: any[],
  isDesc: boolean,
  key = ""
) => {
  return [...arr].sort((a, b) => {
    const A = sortingArr.findIndex(
      (c) => (key ? c[key] : c) === (key ? a[key] : a)
    );
    const B = sortingArr.findIndex(
      (c) => (key ? c[key] : c) === (key ? b[key] : b)
    );
    return isDesc ? A - B : B - A;
  });
};

export const isEqual = (val1: any, val2: any) => {
  if (Array.isArray(val1) && Array.isArray(val2)) {
    return _isEqual([...val1].sort(), [...val2].sort());
  }
  return _isEqual(val1, val2);
};
