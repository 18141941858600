"use strict";
//TODO: customEvent is same as AppMeasure and same as InAppEvent.. need to merge their model files
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeSkanEventName = exports.isSkanEvent = exports.SKAN_EVENT_REGEXP = exports.fromCustomEventCPAName = exports.toCustomEventCPAName = exports.fromCustomEventName = exports.toCustomEventName = exports.CustomEventKind = void 0;
var CustomEventKind;
(function (CustomEventKind) {
    CustomEventKind["count"] = "count";
    CustomEventKind["sum"] = "sum";
})(CustomEventKind = exports.CustomEventKind || (exports.CustomEventKind = {}));
//SKAD_EVENT_sum_SKAD_EVENT_SUMM
function toCustomEventName(event, kind, label) {
    return `${event}_${kind}_${label}`;
}
exports.toCustomEventName = toCustomEventName;
function fromCustomEventName(name) {
    if (name.includes(`_${CustomEventKind.count}_`)) {
        const [event, label] = name.split(`_${CustomEventKind.count}_`);
        return { event, kind: CustomEventKind.count, label };
    }
    else if (name.includes(`_${CustomEventKind.sum}_`)) {
        const [event, label] = name.split(`_${CustomEventKind.sum}_`);
        return { event, kind: CustomEventKind.sum, label };
    }
    return null; //should never happen unless bug in code
}
exports.fromCustomEventName = fromCustomEventName;
function toCustomEventCPAName(event) {
    return `${event}_CPA`;
}
exports.toCustomEventCPAName = toCustomEventCPAName;
function fromCustomEventCPAName(name) {
    return name.replace("_CPA", "");
}
exports.fromCustomEventCPAName = fromCustomEventCPAName;
//SKAN custom events
exports.SKAN_EVENT_REGEXP = /^SK_/;
const isSkanEvent = (name) => exports.SKAN_EVENT_REGEXP === null || exports.SKAN_EVENT_REGEXP === void 0 ? void 0 : exports.SKAN_EVENT_REGEXP.test(name);
exports.isSkanEvent = isSkanEvent;
const makeSkanEventName = (name) => `SK_${name}`;
exports.makeSkanEventName = makeSkanEventName;
