import { LoadingState } from "model/Types/loading";
import {
  getMonetizationApps,
  saveMonetizationApp,
  updateMonetizationApp,
} from "./monetizationAppsAsyncActions";

export const getMonetizationAppsReducer = (builder) => {
  builder.addCase(getMonetizationApps.pending, (state) => {
    state.status = LoadingState.loading;
  });
  builder.addCase(getMonetizationApps.fulfilled, (state, action) => {
    state.status = LoadingState.loaded;
    const monetizationApps = action.payload;
    state.apps = monetizationApps;
  });
  builder.addCase(getMonetizationApps.rejected, (state, action) => {
    state.status = LoadingState.failed;
    state.error = action.error.message;
  });
};

export const saveMonetizationAppReducer = (builder) => {
  builder.addCase(saveMonetizationApp.pending, (state) => {
    state.saveStatus.status = LoadingState.loading;
  });
  builder.addCase(saveMonetizationApp.fulfilled, (state, action) => {
    const addedApp = action.meta.arg;
    state.apps.push(addedApp);
    state.saveStatus.status = LoadingState.loaded;
  });
  builder.addCase(saveMonetizationApp.rejected, (state, action) => {
    state.saveStatus.status = LoadingState.failed;
    state.saveStatus.error = action.error.message;
  });
};

export const updateMonetizationAppReducer = (builder) => {
  builder.addCase(updateMonetizationApp.pending, (state) => {
    state.saveStatus.status = LoadingState.loading;
  });
  builder.addCase(updateMonetizationApp.fulfilled, (state, action) => {
    state.saveStatus.status = LoadingState.loaded;
    const updatedAppPayload = action.meta.arg;
    const updatedAppIdx = state.apps.findIndex(
      (a) => a.app === updatedAppPayload.app
    );
    if (state.apps[updatedAppIdx].os === updatedAppPayload.os) {
      state.apps[updatedAppIdx] = updatedAppPayload.data;
    }
  });
  builder.addCase(updateMonetizationApp.rejected, (state, action) => {
    state.saveStatus.status = LoadingState.failed;
    state.saveStatus.error = action.error.message;
  });
};
