import {
  dangerColor,
  whiteColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react.jsx";

type InlineErrorProps = {
  text?: string;
};

function InlinePageError({ text }: InlineErrorProps) {
  if (!text) {
    return null;
  }
  return (
    <div
      style={{
        color: whiteColor,
        background: dangerColor[0],
        padding: 12,
      }}>
      {text}
    </div>
  );
}

InlinePageError.defaultProps = {
  text: "Unknown error",
};

export default InlinePageError;
