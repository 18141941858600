"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRAFFIC_EVENT_COLUMNS = exports.TRAFFIC_EVENTS_DICT = void 0;
const events_1 = require("../reporterDb/dbTableTraffic/events");
exports.TRAFFIC_EVENTS_DICT = {
    [events_1.ETrafficEvents.requests]: {
        id: events_1.ETrafficEvents.requests,
        label: "Bid Requests sub-sampled",
        isShownInTable: false,
        isShownInChart: false,
    },
    [events_1.ETrafficEvents.requestsWithLogVolume]: {
        id: events_1.ETrafficEvents.requestsWithLogVolume,
        label: "Bid Requests",
        isShownInTable: true,
        isShownInChart: true,
    },
    [events_1.ETrafficEvents.bidFloor]: {
        id: events_1.ETrafficEvents.bidFloor,
        label: "Bid Floor",
        isShownInTable: true,
        isShownInChart: true,
        isCurrency: true,
    },
};
exports.TRAFFIC_EVENT_COLUMNS = [
    exports.TRAFFIC_EVENTS_DICT.requests,
    exports.TRAFFIC_EVENTS_DICT.requestsWithLogVolume,
    exports.TRAFFIC_EVENTS_DICT.bidFloor,
];
