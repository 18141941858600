"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeRangeLabelsDict = exports.TimeRange = void 0;
var TimeRange;
(function (TimeRange) {
    TimeRange["allTime"] = "allTime";
    TimeRange["today"] = "today";
    TimeRange["yesterday"] = "yesterday";
    TimeRange["thisWeek"] = "thisWeek";
    TimeRange["lastWeek"] = "lastWeek";
    TimeRange["thisMonth"] = "thisMonth";
    TimeRange["lastMonth"] = "lastMonth";
    TimeRange["last7Days"] = "last7Days";
    TimeRange["last14Days"] = "last14Days";
    TimeRange["mtd"] = "mtd";
    TimeRange["last30Days"] = "last30Days";
    TimeRange["custom"] = "custom";
})(TimeRange = exports.TimeRange || (exports.TimeRange = {}));
exports.TimeRangeLabelsDict = {
    [TimeRange.allTime]: "Live (all time)",
    [TimeRange.today]: "Today",
    [TimeRange.yesterday]: "Yesterday",
    [TimeRange.thisWeek]: "This Week",
    [TimeRange.lastWeek]: "Last Week",
    [TimeRange.thisMonth]: "This Month",
    [TimeRange.lastMonth]: "Last Month",
    [TimeRange.last7Days]: "Last 7 Days",
    [TimeRange.last14Days]: "Last 2 Weeks",
    [TimeRange.mtd]: "MTD",
    [TimeRange.last30Days]: "Last 30 Days",
    [TimeRange.custom]: "Custom",
};
