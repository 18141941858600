export const parseQueryString = () => {
  const str = window.location.search;
  const objURL = {};

  const intoObject = (matched: string, ...captured: string[]) => {
    const [key, value] = captured;
    objURL[key] = value;
    return matched;
  };

  str.replace(new RegExp("([^?=&]+)(?:=([^&]*))?", "g"), intoObject);
  return objURL;
};

export const imageURLsFromMarkup = (markup: string) => {
  const images: string[] = [];

  if (!markup) return images;

  markup
    .replace(/\r\n|\n|\r|\u21B5/g, "")
    .replace(/<img.+src=(?:"|')(.+?)(?:"|')(?:.+?)>/gm, (match, src) => {
      images.push(src);
      return match;
    });

  return images;
};

export const videoURLsFromMarkup = (markup: string) => {
  const videos: string[] = [];

  if (!markup) return videos;

  markup
    .replace(/\r\n|\n|\r|\u21B5/g, "")
    .replace(/<video.+src=(?:"|')(.+?)(?:"|')(?:.+?)>/gm, (match, src) => {
      videos.push(src);
      return match;
    });

  return videos;
};

type objectIntoQueryStringType = {
  [key: string]: any;
};

export const objectIntoQueryString = (obj: objectIntoQueryStringType) =>
  Object.entries(obj)
    .map(([key, value]) => `${key.replace(/Id$/, "")}=${value}`)
    .join("&");

export const objectIntoURLSearchParams = (obj: { [paramName: string]: any }) =>
  new URLSearchParams(
    Object.entries(obj).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v.toString ? (v.toString() as string) : "" + v,
      }),
      {}
    )
  );

export const spacesToSnakeCase = (string: string) => string.replace(/\s/g, "_");

export const getFilenameFromUrl = (string: string) => {
  const lastPart = string?.substr(string.lastIndexOf("/") + 1);
  const queryIndex = lastPart.indexOf("?");

  if (queryIndex === -1) return lastPart;

  return lastPart.substr(0, queryIndex);
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
