import { createAsyncThunk } from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import { IncomingHttpHeaders } from "http";
import request from "http/request";
import { LoginErrorReasons } from "./types";

type ILoginArgs = {
  username: string;
  password: string;
};
type ILoginMFAArgs = ILoginArgs & {
  mfaCode?: string;
};

//Over http (means from http://localhost): 'secure'- and 'httpOnly'-attributed auth cookies will not work.
//Thus over http we're using auth headers from local storage.

//Over https (means on deployed envs): headers are mostly not needed since cookies do work.
//But billing-reporter endpoint expects auth header even over https, thus we can't get rid of header
const setAuthHeaders = (headers: IncomingHttpHeaders) => {
  if (headers) {
    (headers as any).forEach((header, name) => {
      if (name.toLowerCase() === "authorization") {
        localStorage.setItem("authorization", header);
      } else if (name.toLowerCase() === "auth") {
        localStorage.setItem("auth", header);
      }
    });
  }
};

export const loginWithCredentials = createAsyncThunk(
  "auth/loginWithCredentials",
  async ({ username, password }: ILoginArgs, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    return request
      .post(endpointMaker.account.login(), { email: username, password })
      .then((resp) => {
        if (!resp) {
          return rejectWithValue({ username });
        }
        if (resp.ok === false) {
          return rejectWithValue({
            username,
            status: resp.status,
            message: resp.statusText,
          });
        }

        resp.headers && setAuthHeaders(resp.headers);

        return resp.json().then((respData) => ({
          username,
          mfaSharedSecret: !!respData?.user?.ext?.mfaSharedSecret,
          mfaEnabled: !!respData?.user?.ext?.mfaEnabled,
          forceUseOfMFA: !!respData?.user?.ext?.forceUseOfMFA,
        }));
      })
      .catch((e) => {
        if (e.message === LoginErrorReasons.NeedMFA) {
          return { username, mfaEnabled: true, mfaSharedSecret: true };
        }

        return rejectWithValue({
          username,
          status: e.status,
          message: e.message || e.statusText,
        });
      });
  }
);

export const loginWithMFA = createAsyncThunk(
  "auth/loginWithMFA",
  async ({ username, password, mfaCode }: ILoginMFAArgs, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    return request
      .post(endpointMaker.account.login(), {
        email: username,
        password,
        mfaCode,
      })
      .then((resp) => {
        if (!resp) {
          return rejectWithValue({ username });
        }
        if (resp.ok === false) {
          return rejectWithValue({
            username,
            status: resp.status,
            message: resp.statusText,
          });
        }

        resp.headers && setAuthHeaders(resp.headers);

        return { username };
      })
      .catch((e) =>
        rejectWithValue({
          username,
          status: e.status,
          message: e.message || e.statusText,
        })
      );
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (username: string) => {
    return request
      .get(endpointMaker.account.logout(username))
      .then((resp) => {
        if (!resp) {
          return Promise.reject({ username });
        } else if (resp.ok === false) {
          return Promise.reject({
            username,
            status: resp.status,
            message: resp.statusText,
          });
        }
        // else {
        //   localStorage.clear();
        // }
      })
      .catch((resp) => {
        return Promise.reject({
          username,
          status: resp.status,
          message: resp.message || resp.statusText,
        });
      });
  }
);

export const getUserInfo = createAsyncThunk("auth/getUserInfo", async () => {
  return request
    .getJson(endpointMaker.account.userInfo())
    .then((data) => ({
      forceUseOfMFA: data.ext?.forceUseOfMFA,
      mfaEnabled: data.ext?.mfaEnabled,
      mfaSharedSecret: data.ext?.mfaSharedSecret,
      username: data.email,
      role: data.role,
      organizationId: data.organization,
      accounts: data.accounts,
    }))
    .catch((resp = {}) => {
      return Promise.reject({ status: resp.status, message: resp.statusText });
    });
});
