"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANONYMITY_SERVICE_DIMENSION = exports.NETWORK_ANONYMITY_DIMENSION = exports.DEAL_ID_DIMENSION = exports.GRANULARITY_DIMENSION = exports.GRANULARITY_DICTIONARY = void 0;
const dbTableCampaign_1 = require("../reporterDb/dbTableCampaign");
exports.GRANULARITY_DICTIONARY = [
    { value: "ALL", id: "ALL", name: "All" },
    { value: "HOUR", id: "HOUR", name: "Hour" },
    { value: "HOUR_OF_DAY", id: "HOUR_OF_DAY", name: "Hour of day" },
    { value: "DAY", id: "DAY", name: "Day" },
    { value: "DAY_OF_WEEK", id: "DAY_OF_WEEK", name: "Day of week" },
    { value: "WEEK", id: "WEEK", name: "Week" },
    { value: "MONTH", id: "MONTH", name: "Month" },
    { value: "YEAR", id: "YEAR", name: "Year" },
];
exports.GRANULARITY_DIMENSION = {
    type: "Granularity",
    id: dbTableCampaign_1.DimensionIds.GRANULARITY_COLUMN_ID,
    name: "Time",
    dictionary: exports.GRANULARITY_DICTIONARY,
};
exports.DEAL_ID_DIMENSION = {
    id: "DEAL_ID",
    name: "Deal ID",
    type: "STRING",
};
exports.NETWORK_ANONYMITY_DIMENSION = {
    id: "NETWORK_ANONYMITY",
    name: "Network Anonymity Level",
    type: "INTEGER",
};
exports.ANONYMITY_SERVICE_DIMENSION = {
    id: "ANONYMITY_SERVICE",
    name: "Network Anonymity Service",
    type: "INTEGER",
};
