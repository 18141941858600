import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

type Props = {
  value: any;
  onChange: (e: any) => void;
  disabled?: boolean;
  error?: boolean;
  text: string;
};

function TypeToConfirm({ value, onChange, disabled, error, text }: Props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <span>{text}</span>

      <TextField
        size="small"
        value={value}
        onChange={onChange}
        required
        disabled={disabled}
        error={error}
      />
    </Box>
  );
}
export default TypeToConfirm;
