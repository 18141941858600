import { IRootState } from "redux/reducer";
import { IDownload, IDownloadsList } from "./types";

//analytics
export const getAnalyticsCsvDownloadsList = (state: IRootState): IDownload[] =>
  Object.values(state.analyticsCsvDownloads.list as IDownloadsList).sort(
    (a, b) => b.addedTS - a.addedTS
  );
export const isAnalyticsDownloadsModalOpened = (
  state: IRootState
): boolean | undefined => state.analyticsCsvDownloads.isModalOpened;

//traffic
export const getTrafficCsvDownloadsList = (state: IRootState): IDownload[] =>
  Object.values(state.trafficCsvDownloads.list as IDownloadsList).sort(
    (a, b) => b.addedTS - a.addedTS
  );
export const isTrafficDownloadsModalOpened = (
  state: IRootState
): boolean | undefined => state.trafficCsvDownloads.isModalOpened;

// inventory
export const getInventoryCsvDownloadsList = (state: IRootState): IDownload[] =>
  Object.values(state.inventoryCsvDownloads.list as IDownloadsList).sort(
    (a, b) => b.addedTS - a.addedTS
  );
export const isInventoryDownloadsModalOpened = (
  state: IRootState
): boolean | undefined => state.inventoryCsvDownloads.isModalOpened;
