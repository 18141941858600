import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { lazyWithRetry } from "./lazyWithRetry";
import ProtectedRoutes from "./ProtectedRoutes";

function Routes() {
  return (
    <Suspense fallback={""}>
      <Switch>
        <Route
          path="/change-password"
          exact
          component={lazyWithRetry(
            () =>
              import(
                /* webpackChunkName: "ChangePassword" */
                "routes/Auth/ChangePassword/ChangePassword"
              )
          )}
        />
        <Route
          path="/forgot-password/:email?"
          exact
          component={lazyWithRetry(
            () =>
              import(
                /* webpackChunkName: "ForgotPassword" */
                "routes/Auth/ForgotPassword"
              )
          )}
        />
        <Route
          path="/login/"
          exact
          component={lazyWithRetry(
            () =>
              import(
                /* webpackChunkName: "LoginForm" */
                "routes/Auth/Login/Login"
              )
          )}
        />
        <Route
          path="/unsubscribe/:subscriptionType"
          exact
          component={lazyWithRetry(
            () =>
              import(
                /* webpackChunkName: "Unsubscribe" */
                "routes/Unsubscribe"
              )
          )}
        />
        <Route component={ProtectedRoutes} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
