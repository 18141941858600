import { PayloadAction } from "@reduxjs/toolkit";
import { IReportTable, TReportData } from "model/TrafficPage";
import { LoadingState } from "model/Types/loading";
import {
  setTableWithData,
  sortReportData,
  updateTableReportData,
} from "./trafficAsyncActions";
import { ITrafficState } from "./trafficSlice";

export function setTableWithDataReducer(builder) {
  builder.addCase(setTableWithData.pending, (state: ITrafficState, action) => {
    const table = action.meta.arg;
    const newTable = {
      ...table,
      reportStatus: "loading",
    };

    state.tables = [...state.tables, newTable];
  });
  builder.addCase(
    setTableWithData.fulfilled,
    (state: ITrafficState, action) => {
      const tableId = action.meta.arg.id;

      state.tables = state.tables.map((tb) =>
        tb.id.toString() === tableId.toString()
          ? {
              ...tb,
              ...action.payload,
              reportStatus: "success",
            }
          : tb
      );

      let allTablesLoaded = true;
      for (let i = 0; i < state.tables.length; i++) {
        if (state.tables[i].reportStatus !== "success") {
          allTablesLoaded = false;
          return;
        }
      }
      if (allTablesLoaded) {
        state.status = LoadingState.loaded;
      }
    }
  );
  builder.addCase(setTableWithData.rejected, (state: ITrafficState, action) => {
    const tableId = action.meta.arg.id;

    // TODO: we could display action.error?.message
    state.tables = state.tables.map((tb) =>
      tb.id.toString() === tableId.toString()
        ? { ...tb, reportStatus: "error" }
        : tb
    );
  });
}

export function updateTableReportDataReducer(builder) {
  builder.addCase(
    updateTableReportData.pending,
    (state: ITrafficState, action) => {
      const tableId = action.meta.arg.table.id;
      const tbIdx = state.tables.findIndex(
        (tb) => tb.id.toString() === tableId.toString()
      );
      if (state.tables[tbIdx]) {
        state.tables[tbIdx].reportStatus = "loading";
      }
    }
  );
  builder.addCase(
    updateTableReportData.fulfilled,
    (state: ITrafficState, action: PayloadAction<IReportTable, any, any>) => {
      const tableId = action.meta.arg.table.id;
      const tbIdx = state.tables.findIndex(
        (tb) => tb.id.toString() === tableId.toString()
      );
      if (state.tables[tbIdx]) {
        state.tables[tbIdx] = {
          ...state.tables[tbIdx],
          ...action.payload,
        };
        state.tables[tbIdx].reportStatus = "success";
      }
    }
  );
  builder.addCase(
    updateTableReportData.rejected,
    (state: ITrafficState, action) => {
      const tableId = action.meta.arg.table.id;
      const tbIdx = state.tables.findIndex(
        (tb) => tb.id.toString() === tableId.toString()
      );
      if (state.tables[tbIdx]) {
        state.tables[tbIdx].reportStatus = "error";
      }
    }
  );
}

export function sortReportDataReducer(builder) {
  builder.addCase(sortReportData.pending, (state: ITrafficState, action) => {
    const tableId = action.meta.arg.tableId;
    const tbIdx = state.tables.findIndex(
      (tb) => tb.id.toString() === tableId.toString()
    );
    if (state.tables[tbIdx]) {
      state.tables[tbIdx].reportStatus = "loading";
    }
  });
  builder.addCase(
    sortReportData.fulfilled,
    (
      state: ITrafficState,
      action: PayloadAction<{ data; desc: boolean }, any, any>
    ) => {
      const tableId = action.meta.arg.tableId;

      const tbIdx = state.tables.findIndex(
        (tb) => tb.id.toString() === tableId.toString()
      );

      if (state.tables[tbIdx]) {
        state.tables[tbIdx].reportData = {
          ...(state.tables[tbIdx].reportData || {}),
          ...action.payload,
        } as TReportData;

        state.tables[tbIdx].reportStatus = "success";
      }
    }
  );
  builder.addCase(sortReportData.rejected, (state: ITrafficState, action) => {
    const tableId = action.meta.arg.tableId;
    const tbIdx = state.tables.findIndex(
      (tb) => tb.id.toString() === tableId.toString()
    );
    if (state.tables[tbIdx]) {
      state.tables[tbIdx].reportStatus = "error";
    }
  });
}
