export const preventStaleFocus = (e: MouseEvent) => e?.preventDefault();

export const isArrowDownPressed = (e: KeyboardEvent) =>
  e.keyCode === 26 || e.charCode === 26 || e.key === "ArrowDown";
export const isArrowUpPressed = (e: KeyboardEvent) =>
  e.keyCode === 28 || e.charCode === 28 || e.key === "ArrowUp";
export const isEnterPressed = (e: KeyboardEvent) =>
  e.keyCode === 13 || e.charCode === 13 || e.key === "Enter";

export const ifPressEnter = (fn: () => void) => (e: KeyboardEvent) => {
  if (isEnterPressed(e)) {
    fn();
  }
};
