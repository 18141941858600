import endpointMaker from "configuration/endpoints";
import request from "http/request";
import store from "redux/store";

const fetchExchangeReport = (query, signal?: AbortSignal) =>
  request.post(
    endpointMaker.reporter.exchangeReport(
      store.getState()?.auth?.organizationId
    ),
    query,
    signal
  );

export default fetchExchangeReport;
