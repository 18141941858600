import {
  ROOT_ROUTE_FOR_ROLE,
  RouteNames,
  ROUTES_BLACKLIST_FOR_ROLE,
  ROUTES_WHITELIST_FOR_ROLE,
} from "model/routing";
import { Route } from "react-router-dom";
import { IRouteProps, ROUTES } from "./allRoutesList";

function getRootRouteByRole(role) {
  const routeName: RouteNames =
    ROOT_ROUTE_FOR_ROLE[role] || ROOT_ROUTE_FOR_ROLE.DEFAULT;
  return { ...ROUTES[routeName], path: "/" };
}
function getRoutesByRole(role) {
  if (!role) {
    return {};
  }

  let allowedRoutes: { [key: string]: IRouteProps } = {};
  const whitelist = ROUTES_WHITELIST_FOR_ROLE[role];
  const blacklist =
    ROUTES_BLACKLIST_FOR_ROLE[role] || ROUTES_BLACKLIST_FOR_ROLE.DEFAULT;
  allowedRoutes = Object.entries(ROUTES)
    .filter(([k, _]) => (whitelist ? whitelist[k] : true))
    .filter(([k, _]) => (blacklist ? !blacklist[k] : true))
    .reduce((obj, [k, v]) => {
      obj[k] = v;
      return obj;
    }, {});

  return allowedRoutes;
}

export function getProtectedRoutesByRole(role) {
  if (!role) {
    return [];
  }

  const rootRoute = getRootRouteByRole(role);
  const allowedRoutes = getRoutesByRole(role);

  return [rootRoute, ...Object.values(allowedRoutes)].map(
    ({ path, component, ...routeProps }, key) => (
      <Route
        exact
        path={path}
        component={component}
        key={key}
        {...routeProps}
      />
    )
  );
}
