import { dbTableCampaign } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { Events } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { NumericDimensions } from "@dataseat-dsp-client/lib-reporter";

//reexport enum
export { Metrics } from "@dataseat-dsp-client/lib-reporter";

export const BOR_MEASURES = dbTableCampaign.BOR_MEASURES;
