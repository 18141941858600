import {
  ITrafficEventColumn,
  trafficReport,
} from "@dataseat-dsp-client/lib-reporter";
import {
  FilterCollectionType,
  SplitType,
} from "model/AnalyticsPage/splitsNFilters";
import { DataDisplayMode, IGroupBySplitData, ILabeledData } from "model/report";
import { TimeRange } from "model/report/dateRange";
import { GranularityValueType } from "./granularity";

//reexport enum
export { ETrafficEvents } from "@dataseat-dsp-client/lib-reporter";

//reexport interface
export type { ITrafficEventColumn } from "@dataseat-dsp-client/lib-reporter";

type TStatus = "idle" | "loading" | "success" | "error";

export type TStatsBySplit = { [lineId: string]: any };

export interface IReportTable {
  id: number | string;
  splits: SplitType[];
  filters: FilterCollectionType;
  dateRange: {
    dates: [Date, Date];
    range: TimeRange;
  };
  granularity: GranularityValueType;
  view: DataDisplayMode;
  // NOTE: data below are fetched on load
  reportData?: TReportData | null;
  reportStatus?: TStatus;
  statsBySplit?: TStatsBySplit;
  orderedByVolume?: string[];
  // NOTE: legend is being populated on legend state change in the chart
  legend?: { [lineName: string]: boolean };
}

export type TReportData = {
  groupBy: SplitType[];
  data: ILabeledData | IGroupBySplitData;
  sortBy?: string;
  desc?: boolean;
};

export const TRAFFIC_EVENTS_DICT = trafficReport.TRAFFIC_EVENTS_DICT;
export const TRAFFIC_EVENT_COLUMNS: ITrafficEventColumn[] =
  trafficReport.TRAFFIC_EVENT_COLUMNS;
