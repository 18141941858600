import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import mapvalues from "lodash.mapvalues";
import { LoadingState } from "model/Types/loading";
import { Integer } from "model/modelTypes";
import {
  PredictModel,
  PredictQueryParameters,
} from "model/predictModel/predictModelMCTypes";
import {
  ManagedModelQueryParameters,
  ManagedPredictModel,
} from "model/predictModel/predictModelManagerTypes";
import { archivePredict, unarchivePredict } from "./actions";
import {
  addPMModelToLocalStorage,
  getPMModelsFromLocalStorage,
} from "./localStorageUtil";

type PredictState = {
  //models from missioncontrol
  loadingState: LoadingState;
  list: PredictModel[];
  error: null | SerializedError;
  loadedTimestamp?: Integer;

  //models from ModelManager
  managedModelloadingState: LoadingState;
  managedModelsMap: { [name: string]: true }; //don't keep list cause it's big and don't need it
  managedModelError: null | SerializedError;
  managedModelLoadedTimestamp?: Integer;
  pendingManagedModels: {
    [name: string]: ManagedPredictModel;
  };
  pendingManagedModelsFromLS?: Record<string, boolean>;
};

//Do not use this action directly. Use throttled one from ./actions
export const fetchPredictThunk = createAsyncThunk(
  "predict/fetchPredict",
  async (queryParams: PredictQueryParameters, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.predictMC.predict({
        organizationId,
        accountId,
        archived: true,
        ...queryParams,
      })
    );
  }
);

//Do not use this action directly. Use throttled one from ./actions
export const fetchManagedModelsThunk = createAsyncThunk(
  "predict/fetchManagedModels",
  async (queryParams: ManagedModelQueryParameters, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { organizationId, accountId } = state.auth;

    return request.getJson(
      endpointMaker.predictModelManager.filteredModels(
        organizationId,
        accountId,
        queryParams
      )
    );
  }
);
//IPM_Playtika_WSOP_iOS_US_VP_NR_AL_BUNDLE_TAG_ID
//Slice
export const predictSlice = createSlice({
  name: "predict",
  initialState: {
    loadingState: LoadingState.initial,
    list: [] as PredictModel[],
    error: null,
    managedModelloadingState: LoadingState.initial,
    managedModelsMap: {},
    managedModelError: null,
    pendingManagedModels: {},
    pendingManagedModelsFromLS: mapvalues(
      getPMModelsFromLocalStorage(true)?.pendingManagedModels ?? {},
      (modelName) => ({ [modelName]: true })
    ),
  } as PredictState,
  reducers: {
    startCreatingManagedModel(state, action) {
      if (!action.payload?.name || !action.payload?.data) return;
      state.pendingManagedModels[action.payload.name] = {
        ...action.payload.data,
        name: action.payload.name,
      };
      addPMModelToLocalStorage(action.payload.name);
    },
  },
  extraReducers: (builder) => {
    //fetch predict from missioncontrol
    builder.addCase(fetchPredictThunk.pending, (state) => {
      state.loadingState = LoadingState.loading;
      state.error = null;
      state.loadedTimestamp = Date.now();
    });
    builder.addCase(fetchPredictThunk.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;
      state.list = action.payload as PredictModel[];
    });
    builder.addCase(fetchPredictThunk.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;
      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
    //archive-unarchive
    builder.addCase(archivePredict.fulfilled, (state, action) => {
      state.list = state.list.map((p) =>
        p.id === action.payload ? { ...p, archived: true } : p
      );
      state.loadedTimestamp = undefined;
    });
    builder.addCase(unarchivePredict.fulfilled, (state, action) => {
      state.list = state.list.map((p) =>
        p.id === action.payload ? { ...p, archived: false } : p
      );
    });
    //fetch managed predict models from ModelManager
    builder.addCase(fetchManagedModelsThunk.pending, (state) => {
      state.managedModelloadingState = LoadingState.loading;
      state.managedModelError = null;
      state.managedModelLoadedTimestamp = Date.now();
    });
    builder.addCase(fetchManagedModelsThunk.fulfilled, (state, action) => {
      state.managedModelloadingState = LoadingState.loaded;
      state.managedModelsMap = (
        action.payload?.data as ManagedPredictModel[]
      )?.reduce?.((acc, m) => {
        acc[m.name] = true;
        const wasPending =
          !!state.pendingManagedModels[m.name] ||
          state.pendingManagedModelsFromLS?.[m.name];
        const isStillPending =
          m?.status?.state &&
          ["creating", "updating"].includes(m?.status?.state);
        if (wasPending || isStillPending) {
          state.pendingManagedModels[m.name] = m;
        }
        return acc;
      }, {} as { [name: string]: true });
    });
    builder.addCase(fetchManagedModelsThunk.rejected, (state, action) => {
      state.managedModelloadingState = LoadingState.failed;
      state.managedModelError = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
  },
});

export default predictSlice.reducer;
