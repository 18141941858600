"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumnsListByReportMeasures = void 0;
const cohortedReportColumns_1 = require("./cohortedReportColumns");
const columnTypes_1 = require("./columnTypes");
function getColumnsListByReportMeasures(orderedColumns, splits = [], granularity, hiddenColumns) {
    var _a, _b, _c;
    let splitsFiltered = [...splits];
    const indexOfGranularity = splits.map((s) => s.id).indexOf("GRANULARITY");
    if (indexOfGranularity > -1 && granularity === "ALL") {
        //granularity will not be apllied as a split in case of 'ALL' value, in reporter
        splitsFiltered = splits
            .slice(0, indexOfGranularity)
            .concat(splits.slice(indexOfGranularity + 1));
    }
    const splitColumns = splitsFiltered.map((split) => ({
        label: split.name,
        id: split.id,
        columnType: columnTypes_1.ColumnTypes.Dimension,
    }));
    let measureColumns = [];
    const nonHiddenOrderedColumns = orderedColumns.filter((c) => !(hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns[c.id]));
    for (let column of nonHiddenOrderedColumns) {
        if ((0, cohortedReportColumns_1.isCohortableColumnType)(column.columnType)) {
            const cohortedColumn = column;
            const cohortedResultColumns = (_a = cohortedColumn.cohorts) === null || _a === void 0 ? void 0 : _a.map((cohort) => {
                const id = (0, cohortedReportColumns_1.getCohortColumnId)({ columnId: cohortedColumn.id, cohort });
                const { label } = (0, cohortedReportColumns_1.getCohortColumnLabels)({
                    cohort,
                    eventLabel: cohortedColumn.label,
                });
                return {
                    id,
                    label,
                    columnType: cohortedColumn.columnType,
                };
            });
            if (cohortedResultColumns === null || cohortedResultColumns === void 0 ? void 0 : cohortedResultColumns.length) {
                measureColumns.push(...cohortedResultColumns);
            }
        }
        else if (column.columnType &&
            [
                columnTypes_1.ColumnTypes.AppMeasure,
                columnTypes_1.ColumnTypes.AppMeasureCPA,
                columnTypes_1.ColumnTypes.DimensionAsMeasure,
                columnTypes_1.ColumnTypes.DimensionAsMeasureCPA,
            ].includes(column.columnType)) {
            measureColumns.push({
                label: (_b = column.label) !== null && _b !== void 0 ? _b : "CATT",
                id: column.id,
                columnType: column.columnType,
            });
        }
        else {
            measureColumns.push({
                label: (_c = column.label) !== null && _c !== void 0 ? _c : "CATTT",
                id: column.id,
                columnType: column.columnType,
            });
        }
    }
    const columns = [...splitColumns, ...measureColumns];
    return columns;
}
exports.getColumnsListByReportMeasures = getColumnsListByReportMeasures;
