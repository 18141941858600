import { analyticsReport } from "@dataseat-dsp-client/lib-reporter";

export type IReportStoredInUrl = analyticsReport.IReportStoredInUrl;
export type IStoredReportRange = analyticsReport.IStoredReportRange;
export type IDefaultFiltersRange = analyticsReport.IDefaultFiltersRange;
export type IDefaultFiltersDates = analyticsReport.IDefaultFiltersDates;
export type IReportConfig = analyticsReport.IReportConfig;
export type IStoredReport = analyticsReport.IStoredReport;
export type IReportCombinedContextProps =
  analyticsReport.IReportCombinedContextProps;

export const normalizeReportContextToStoredReport =
  analyticsReport.normalizeReportContextToStoredReport;
export const normalizeStoredReportToReportContext =
  analyticsReport.normalizeStoredReportToReportContext;

export const UNNAMED_MULTI_CAMPAIGN_REPORT_PREFIX =
  analyticsReport.UNNAMED_MULTI_CAMPAIGN_REPORT_PREFIX;
export const UNNAMED_REPORT_PREFIX = analyticsReport.UNNAMED_REPORT_PREFIX;
export const NAMED_REPORT_PREFIX = analyticsReport.NAMED_REPORT_PREFIX;

export const generateUnnamedReportId = analyticsReport.generateUnnamedReportId;
export const generateUnnamedMultiReportId =
  analyticsReport.generateUnnamedMultiReportId;
export const generateNamedReportId = analyticsReport.generateNamedReportId;

export const isNamedReport = analyticsReport.isNamedReport;

export const getStartFinishOfStoredReport =
  analyticsReport.getStartFinishOfStoredReport;
