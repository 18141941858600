import { getEnvironment } from "configuration/endpoints/environments";
import { toTitleCase } from "helpers/string";
import history from "./history";

export enum GAEventCategories {
  campaignAction = "campaign_action",
  lineItemAction = "line_item_action",
  analyticsAction = "analytics_action",
  trafficAction = "traffic_action",
  inventoryAction = "inventory_action",
  creativesAction = "creatives_action",
  suggestOptsAction = "suggest_opts_action",
  modelAction = "model_action",
}

const MEASUREMENT_ID = "G-VC54J0XMB3";
const gtag = (window as any).gtag;

export const gtagConfig = (
  location: string,
  pageTitle: string,
  userRole: string,
  organisation: string
) => {
  const isAlpha = window.location.href.includes("alpha");
  // NOTE: set below to true for debugging
  // (to exclude that traffic from GA and enabled debug view)
  const isDebug = false;

  gtag("config", MEASUREMENT_ID, {
    transport_type: "beacon",
    alwaysSendReferrer: true,
    page_title: `Initial Load: ${toTitleCase(pageTitle) || "Home"}`,
    page_location: location,
    page_path: window.location.href,
    send_page_view: false,
    ...(isDebug ? { debug_mode: true } : {}),
  });

  gtag("set", "user_properties", {
    user_role: userRole,
    environment: isAlpha ? "alpha" : isDebug ? "debug" : "prod",
    organisation,
  });
};

const trackPageView = (location: string, pageTitle: string) => {
  if (location.includes("lineItem")) pageTitle = "Line Item";

  gtag("event", "page_view", {
    page_title: `${toTitleCase(pageTitle)}`,
    page_location: location,
    page_path: window.location.href,
  });
};

export const trackEvent = (
  eventName: string,
  eventCategory: GAEventCategories,
  eventLabel?: string
) => {
  gtag("event", eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
    non_interaction: true,
  });
};

let isInitialLoad = true;
let currentLocation = "";

const useGATracker = (role = "", organisation?: string) => {
  if (getEnvironment().useGa && organisation) {
    history.listen((location) => {
      gtag("set", { send_page_view: false });

      if (location.pathname !== currentLocation) {
        currentLocation = location.pathname;
        trackPageView(location.pathname, location.pathname.split("/")[1]);
      }
    });

    if (isInitialLoad) {
      gtagConfig(
        history.location.pathname,
        history.location.pathname.split("/")[1],
        role,
        organisation
      );

      isInitialLoad = false;
    }
  }
};

export default useGATracker;
