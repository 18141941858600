import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React from "react";
import { Button } from "vendor/components";
import useStyles from "./ModalStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...props}
    />
  );
});

function FormSaveError({ isShown, handleClose, formItem, customText }) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isShown}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose("noticeModal")}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description">
      <DialogTitle
        disableTypography
        className={classes.modalHeader}>
        <h4 className={classes.modalTitle}>Error</h4>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        Failed to save {formItem}. Please try again.
        <br />
        {customText && <pre>{`Errors:\n${customText}`}</pre>}
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}>
        <Button
          onClick={() => handleClose("noticeModal")}
          color="danger">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormSaveError;
