import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { LoadingState } from "model/Types/loading";
//TODO
import { format, subDays } from "date-fns";
import { longDateFormat, parseDate } from "helpers";
import { filterInAppEvents } from "model/AnalyticsPage";
import { splitRows } from "model/report/normalization";
import { QUERY_DISTINCT_VALUES } from "model/reporterDb/dbTableCampaign/queryMap";
import { fetchReport } from "services/reporter/fetch";

export const getDropdownOptionLabel = (ev): string =>
  ev.endsWith("_COHORT") ? `${ev.replace("_COHORT", "")} (Cohorted)` : ev;

export type IInAppEventOption = { label: string; value: string };
type InAppEventsState = {
  loadingState: LoadingState;
  list: IInAppEventOption[];
  error: null | SerializedError;
};

//Async actions
type IArgs = { campaignId: string; startDate?: string; endDate?: string };
export const fetchInAppEvents = createAsyncThunk(
  "inAppEvents/fetch",
  async ({
    campaignId,
    startDate = format(
      parseDate(subDays(new Date(), 31)) as Date,
      longDateFormat
    ),
    endDate = format(parseDate(new Date()) as Date, longDateFormat),
  }: IArgs) =>
    fetchReport(
      QUERY_DISTINCT_VALUES({
        columnName: "EVENT_NAME",
        campaignId,
        dateRange: { startDate, endDate },
      })
    )
      .then((resp) => resp.text())
      .then(splitRows)
);

//Slice
const inAppEventsSlice = createSlice({
  name: "inAppEvents",
  initialState: {
    loadingState: LoadingState.initial,
    list: [] as IInAppEventOption[],
    error: null,
  } as InAppEventsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInAppEvents.pending, (state) => {
      state.loadingState = LoadingState.loading;
      state.error = null;
    });
    builder.addCase(fetchInAppEvents.fulfilled, (state, action) => {
      state.loadingState = LoadingState.loaded;
      const events = action.payload.filter(filterInAppEvents).map((e) => ({
        label: getDropdownOptionLabel(e),
        value: e,
      }));

      events.forEach((e) => {
        if (!state.list.find((o) => o.value === e.value)) state.list.push(e);
      });
      state.list = state.list.sort(({ label: labelA }, { label: labelB }) => {
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
    });
    builder.addCase(fetchInAppEvents.rejected, (state, action) => {
      state.loadingState = LoadingState.failed;
      state.error = action.error?.message
        ? action.error
        : new Error("Unknown error");
    });
  },
});

export default inAppEventsSlice.reducer;

//Selectors
export const getInAppEvents = (state) => state.inAppEvents.list;
export const getInAppEventsLoadingState = (state) => [
  state.inAppEvents.loadingState,
  state.inAppEvents.error,
];
