import { parseISO } from "date-fns";
import { fillMissedDates } from "helpers";
import { FilterCollectionType, SplitType } from "model/AnalyticsPage";
import {
  GRANULARITY_DIMENSION,
  TRAFFIC_FILTERS_DICTIONARY,
} from "model/TrafficPage";
import { GranularityValueType } from "model/TrafficPage/granularity";
import {
  IReportTable,
  TRAFFIC_EVENTS_DICT,
  TRAFFIC_EVENT_COLUMNS,
} from "model/TrafficPage/trafficTable";
import { ISOstring } from "model/modelTypes";
import { DataDisplayMode } from "model/report";
import { IGroupBySplitData } from "model/report/reportData";
import { ReporterTables } from "model/reporterDb/dbSchema";
import {
  DimensionIds,
  TRAFFIC_SELECT_QUERY_MAP,
} from "model/reporterDb/dbTableTraffic";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import {
  SelectExprType,
  TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED,
  WHERE_VALUES_EXPR,
} from "model/reporterDb/query";
import { isSplitChartView } from "routes/Traffic/helpers";

export const getSplitsByView = (
  view: DataDisplayMode,
  currentSplits: SplitType[]
): { splits: SplitType[]; granularity?: GranularityValueType } => {
  if (view === DataDisplayMode.table) {
    const splitsWithRestoredGranularity = currentSplits.map((s) =>
      s.id === DimensionIds.GRANULARITY_COLUMN_ID ? GRANULARITY_DIMENSION : s
    );
    return { splits: splitsWithRestoredGranularity };
  }

  const granularityWithoutOptionAll = {
    ...GRANULARITY_DIMENSION,
    dictionary: GRANULARITY_DIMENSION?.dictionary?.filter(
      (o) => o.id !== "ALL"
    ),
    isMandatory: true,
  };

  const splitsWithoutGranularity = currentSplits?.filter(
    (s) => s.id !== DimensionIds.GRANULARITY_COLUMN_ID
  );

  return {
    splits:
      splitsWithoutGranularity?.[0] && view === DataDisplayMode.charts
        ? [granularityWithoutOptionAll, splitsWithoutGranularity[0]]
        : [granularityWithoutOptionAll],
    granularity: GRANULARITY_TYPES.DAYS,
  };
};

interface IGetTrafficQueryProps {
  splits: SplitType[];
  filterBy: FilterCollectionType;
  dateRange: Date[];
  granularity: GranularityValueType;
  limit?: number;
  orderBy?: string[] | { expr: string; direction: "ASC" | "DESC" }[];
}

export const getTrafficQuery = ({
  splits = [],
  filterBy = {},
  dateRange,
  granularity,
  limit,
  orderBy,
}: IGetTrafficQueryProps) => {
  const isSplitGranularity = !!splits.find(
    (split) => split.id === DimensionIds.GRANULARITY_COLUMN_ID
  );

  const groupBy: string[] = splits
    ?.filter((s) => s.id !== DimensionIds.GRANULARITY_COLUMN_ID)
    .reduce((acc: string[], val) => {
      if (TRAFFIC_FILTERS_DICTIONARY[val.id]?.combineDimensionIds) {
        acc = [
          ...acc,
          ...(TRAFFIC_FILTERS_DICTIONARY[val.id]?.combineDimensionIds || []),
        ];
      } else {
        acc.push(val.id);
      }
      return acc;
    }, []);

  const filterByIds = Object.keys(filterBy)
    .filter((key) => !!filterBy[key].value)
    .map((key) => {
      if (TRAFFIC_FILTERS_DICTIONARY[key]) {
        return TRAFFIC_FILTERS_DICTIONARY[key]?.query(filterBy[key].value);
      }
      return WHERE_VALUES_EXPR(key, filterBy[key].value);
    });

  const dateRangeExpr = dateRange?.length
    ? [TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED(dateRange[0], dateRange[1])]
    : [];

  const query = {
    from: ReporterTables.Traffic,
    groupBy,
    select: Object.keys(TRAFFIC_EVENTS_DICT).map(
      (id) => TRAFFIC_SELECT_QUERY_MAP[id] as SelectExprType
    ),
    where: [...dateRangeExpr, ...filterByIds],
    limit,
    orderBy,
  };

  if (isSplitGranularity) {
    query["granularity"] = TRAFFIC_SELECT_QUERY_MAP.granularity(granularity);
  }

  return query;
};

export const getTrafficQueryFromTable = (table: IReportTable) => {
  const {
    dateRange,
    granularity = GRANULARITY_TYPES.ALL,
    splits = [],
    view,
  } = table;

  const query = getTrafficQuery({
    splits,
    filterBy: table.filters,
    dateRange: dateRange.dates,
    granularity,
    orderBy: isSplitChartView(view, splits) ? ["DATETIME"] : [],
  });

  return query;
};

export const populateFullDateRange = (
  data: IGroupBySplitData,
  dateRange: [Date, Date] | [ISOstring, ISOstring],
  granularity: GranularityValueType,
  mainSplitId: string
) => {
  const dummyTrafficColumns = {};
  TRAFFIC_EVENT_COLUMNS.forEach((c) => {
    dummyTrafficColumns[c.id] = 0;
  });

  const dateRangeAsDates: [Date, Date] =
    typeof dateRange?.[0] === "string"
      ? [parseISO(dateRange[0] as string), parseISO(dateRange[1] as string)]
      : (dateRange as [Date, Date]);

  return data.map((d) => {
    return {
      ...d,
      data: fillMissedDates(
        d.data,
        DimensionIds.GRANULARITY_COLUMN_ID,
        dateRangeAsDates,
        granularity,
        {
          ...dummyTrafficColumns,
          [mainSplitId]: d.data[0][mainSplitId],
        }
      ),
    };
  });
};
