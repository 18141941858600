import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_CLOSED,
} from "components/Layout/NavigationMenu/NavigationMenuStyles";
import { makeStyles } from "components/mui-core";
import { grayColor } from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme: any) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: grayColor[5],
    marginLeft: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  expanded: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: DRAWER_WIDTH_CLOSED - DRAWER_WIDTH,
  },
}));

export default useStyles;
