import { JUST_SAVED_NAMED_REPORT, RouteNames } from "model/routing";
import { lazyWithRetry } from "../lazyWithRetry";

export type IRouteProps = {
  path?: string;
  component: any;
  exact?: boolean;
};

export const ROUTES: { [key in RouteNames]: IRouteProps } = {
  [RouteNames.analytics]: {
    path: "/analytics",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AnalyticReportSelector" */
          "routes/Analytics/ReportSelector/ReportSelector"
        )
    ),
  },
  [RouteNames.analyticsReport]: {
    path: "/analytics/report/:campaignId?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AnalyticsPage" */
          "routes/Analytics/AnalyticsPage"
        )
    ),
  },
  [RouteNames.analyticsReportWithParams]: {
    path: "/analytics/report/:campaignId?=:urlParameters?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AnalyticsPage" */
          "routes/Analytics/AnalyticsPage"
        )
    ),
  },
  [RouteNames.analyticsNamedReport]: {
    path: "/analytics/named-report/:reportId/:campaignId?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AnalyticsPage" */
          "routes/Analytics/AnalyticsPage"
        )
    ),
  },
  [RouteNames.analyticsJustSavedReport]: {
    path: `/analytics/${JUST_SAVED_NAMED_REPORT}/:reportId/:campaignId?`,
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AnalyticsPage" */
          "routes/Analytics/AnalyticsPage"
        )
    ),
  },
  [RouteNames.monetizationReport]: {
    path: "/monetization/report",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "MonetizationReportPage" */
          "routes/Monetization/ReportPage/MonetizationReportPage"
        )
    ),
  },
  [RouteNames.monetizationRoot]: {
    path: "/monetization",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "MonetizationAppsPage" */
          "routes/Monetization/AppsSelector/MonetizationAppsPage"
        )
    ),
  },
  [RouteNames.monetizationAppsList]: {
    path: "/monetization/apps/:packageBundleId?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "MonetizationAppsPage" */
          "routes/Monetization/AppsSelector/MonetizationAppsPage"
        )
    ),
  },
  [RouteNames.campaigns]: {
    path: "/campaigns",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CampaignsPage" */
          "routes/Campaigns/CampaignsPage"
        )
    ),
  },
  [RouteNames.campaignsNew]: {
    path: "/campaigns/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CampaignNew" */
          "routes/CampaignEdit/CampaignNew"
        )
    ),
  },
  [RouteNames.campaignEdit]: {
    path: "/campaign/:campaignId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CampaignEdit" */
          "routes/CampaignEdit/CampaignEdit"
        )
    ),
  },
  [RouteNames.campaignCopy]: {
    path: "/campaign/:campaignId/copy",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CampaignCopy" */
          "routes/CampaignEdit/CampaignCopy"
        )
    ),
  },
  [RouteNames.suggestedOptimisations]: {
    path: "/suggested-optimisations",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "SuggestedOptimisations" */
          "routes/SuggestedOptimisations/SuggestedOptimisations"
        )
    ),
  },
  [RouteNames.campaignView]: {
    path: "/campaign/:campaignId/:tabId(lineitems|landings)?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CampaignViewPage" */
          "routes/CampaignView/CampaignViewPage"
        )
    ),
  },
  [RouteNames.lineitemNew]: {
    path: "/campaigns/:campaignId/lineItem/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LineItemNew" */
          "routes/CampaignView/LineItemEdit/LineItemNew"
        )
    ),
  },
  [RouteNames.lineitemEdit]: {
    path: "/campaign/:campaignId/lineItem/:lineItemId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LineItemEdit" */
          "routes/CampaignView/LineItemEdit/LineItemEdit"
        )
    ),
  },
  [RouteNames.lineitemCopy]: {
    path: "/campaign/:campaignId/lineItem/:lineItemId/copy",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LineItemCopy" */
          "routes/CampaignView/LineItemEdit/LineItemCopy"
        )
    ),
  },
  [RouteNames.landingNew]: {
    path: "/campaigns/:campaignId/landing/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LandingNew" */
          "routes/CampaignView/Landings/LandingNew"
        )
    ),
  },
  [RouteNames.landingEdit]: {
    path: "/campaign/:campaignId/landing/:landingId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LandingEdit" */
          "routes/CampaignView/Landings/LandingEdit"
        )
    ),
  },
  [RouteNames.landingCopy]: {
    path: "/campaign/:campaignId/landing/:landingId/copy",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "LandingCopy" */
          "routes/CampaignView/Landings/LandingCopy"
        )
    ),
  },
  [RouteNames.audiences]: {
    path: "/audiences",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Audiences" */
          "routes/Audiences/AudiencesPage"
        )
    ),
  },
  [RouteNames.audienceNew]: {
    path: "/audiences/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "AudiencesNew" */
          "routes/Audiences/Edit/AudiencesNew"
        )
    ),
  },
  [RouteNames.traffic]: {
    path: "/traffic/:tableId?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Traffic" */
          "routes/Traffic/Traffic"
        )
    ),
  },
  [RouteNames.inventory]: {
    path: "/inventory",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Inventory" */
          "routes/Inventory/InventoryPage"
        )
    ),
  },
  [RouteNames.creatives]: {
    path: "/creatives/:tabId(creatives|playables)?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Creatives" */
          "routes/Creatives/Creatives"
        )
    ),
  },
  [RouteNames.creativeEdit]: {
    path: "/creative/:creativeId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CreativeEdit" */
          "routes/Creatives/Creative/CreativeEdit"
        )
    ),
  },
  [RouteNames.creativeCopy]: {
    path: "/creative/:creativeId/copy",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CreativeCopy" */
          "routes/Creatives/Creative/CreativeCopy"
        )
    ),
  },
  [RouteNames.creativeCopyMany]: {
    path: "/creative/:creativeId/copymany",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CreativeCopyMany" */
          "routes/Creatives/Creative/CreativeCopyMany/CreativeCopyMany"
        )
    ),
  },
  [RouteNames.creativeNew]: {
    path: "/creative/new/:creativeType?",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "CreativeNew" */
          "routes/Creatives/Creative/CreativeNew"
        )
    ),
  },
  [RouteNames.playableNew]: {
    path: "/playable/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PlayableNew" */
          "routes/Creatives/Playable/PlayableNew"
        )
    ),
  },
  [RouteNames.playableEdit]: {
    path: "/playable/:playableId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PlayableEdit" */
          "routes/Creatives/Playable/PlayableEdit"
        )
    ),
  },
  [RouteNames.playableCopy]: {
    path: "/playable/:playableId/copy",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PlayableCopy" */
          "routes/Creatives/Playable/PlayableCopy"
        )
    ),
  },
  [RouteNames.billing]: {
    path: "/billing/:tabId",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Billing" */
          "routes/Billing/components/container/BillingContainer"
        )
    ),
  },
  [`${RouteNames.billing}NoTab`]: {
    path: "/billing",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Billing" */
          "routes/Billing/components/container/BillingContainer"
        )
    ),
  },
  [RouteNames.users]: {
    path: "/users",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "UserManagement" */
          "routes/Users/UserManagement"
        )
    ),
  },
  [RouteNames.userEdit]: {
    path: "/users/:userId/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "UserEdit" */
          "routes/Users/UserEdit"
        )
    ),
  },
  [RouteNames.userNew]: {
    path: "/users/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "UserNew" */
          "routes/Users/UserNew"
        )
    ),
  },
  [RouteNames.predictModels]: {
    path: "/predictModels",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PredictModels" */
          "routes/PredictModels/PredictModels"
        )
    ),
  },
  [RouteNames.predictModelNew]: {
    path: "/predictModels/new",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PredictModelNew" */
          "routes/PredictModels/ModelNew"
        )
    ),
  },
  [RouteNames.predictModelEdit]: {
    path: "/predictModels/:modelName/edit",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "PredictModelEdit" */
          "routes/PredictModels/ModelEdit"
        )
    ),
  },
  [RouteNames.organization]: {
    path: "/organization",
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "Organization" */
          "routes/Organization/OrganizationPage"
        )
    ),
  },
  [RouteNames.notFound]: {
    exact: false,
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "NotFound" */
          "routes/PageNotFound"
        )
    ),
  },
  [RouteNames.testReporterQuery]: {
    exact: false,
    component: lazyWithRetry(
      () =>
        import(
          /* webpackChunkName: "TestReporterQuery" */
          "routes/Test/TestReporterQuery"
        )
    ),
  },
};
